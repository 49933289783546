<supy-grid
  #grid
  primaryKey="id"
  class="supy-create-recipe__cost-centers-grid"
  [data]="gridData"
  [height]="null"
  [showAddRowEmptyButton]="false"
  [emptyGridTemplate]="emptyGridTemplate"
  [allowFiltering]="true"
  (cellEditDone)="onCellEditDone()"
  (cellClick)="$event.cell.editMode = true"
>
  <supy-grid-column width="30%" field="branch.name" header="Branch Name" [editable]="false">
    <ng-template supyGridCell let-value>
      <span>{{ value }}</span>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column width="20%" field="location" header="Cost Center" [editable]="false" [filterable]="false">
    <ng-template supyGridCell let-rowData="rowData" let-cell="cell">
      <supy-select
        class="supy-create-recipe__cost-center-grid-selector"
        placeholder="Select Location"
        name="select-location"
        [value]="rowData.location"
        (selected)="onLocationSelected(rowData, $event, cell.row.index)"
        [clearable]="true"
        [disabled]="isReadonly"
      >
        <supy-select-item *ngFor="let location of rowData.branch.children" [value]="location">
          {{ location.name }}
        </supy-select-item>
      </supy-select>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column
    width="10%"
    field="branch"
    header="Recipe Cost ({{ currency }})"
    [editable]="false"
    [filterable]="false"
  >
    <ng-template supyGridCell let-rowData="rowData">
      <span [supyTooltip]="salesTypesCosts" position="right"
        >{{ rowData.cost ?? 0 | supyPrecision: currencyPrecision }}
      </span>

      <supy-tooltip #salesTypesCosts>
        {{ getSalesTypesCostsTooltip(rowData) }}
      </supy-tooltip>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column
    width="10%"
    field="sellingPrice"
    header="Selling Price ({{ currency }})"
    [editable]="!isReadonly"
    [filterable]="false"
  >
    <ng-template supyGridCell let-rowData="rowData">
      <span *ngIf="rowData.location?.id">{{ rowData.sellingPrice }}</span>
    </ng-template>

    <ng-template supyGridCellEditor let-cell="cell" let-rowData="rowData">
      <supy-input
        class="supy-create-recipe__cost-centers-grid-input-control"
        name="sellingPrice"
        type="number"
        [min]="0"
        [step]="0.001"
        [value]="rowData.sellingPrice"
        [focusOnInit]="true"
        (valueChange)="cell.editValue = $event; setSellingPrice($event, rowData)"
        (focusOut)="grid.endEdit()"
        [disabled]="isReadonly"
      ></supy-input>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column
    width="10%"
    field="costThreshold"
    header="Food Cost % Threshold"
    [editable]="!isReadonly"
    [filterable]="false"
  >
    <ng-template supyGridCell let-rowData="rowData">
      {{ rowData.costThreshold }}
    </ng-template>

    <ng-template supyGridCellEditor let-cell="cell" let-rowData="rowData">
      <supy-input
        class="supy-create-recipe__cost-centers-grid-input-control"
        name="target-food-cost"
        type="number"
        [min]="0"
        [step]="0.001"
        [value]="rowData.costThreshold"
        [focusOnInit]="true"
        (valueChange)="cell.editValue = $event"
        (focusOut)="grid.endEdit()"
        [disabled]="isReadonly"
      ></supy-input>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column
    width="10%"
    field="targetCost"
    header="Target Food Cost (%)"
    [editable]="!isReadonly"
    [filterable]="false"
  >
    <ng-template supyGridCell let-rowData="rowData">
      <span *ngIf="rowData.location?.id">{{ rowData.targetCost }}</span>
    </ng-template>

    <ng-template supyGridCellEditor let-cell="cell" let-rowData="rowData">
      <supy-input
        class="supy-create-recipe__cost-centers-grid-input-control"
        name="target-food-cost"
        type="number"
        [min]="0"
        [step]="0.001"
        [focusOnInit]="true"
        [value]="rowData.targetCost"
        (valueChange)="cell.editValue = $event"
        (focusOut)="grid.endEdit()"
        [disabled]="isReadonly"
      ></supy-input>
    </ng-template>
  </supy-grid-column>

  <supy-grid-column width="10%" field="taxes" header="Taxes" [editable]="!isReadonly" [filterable]="false">
    <ng-template supyGridCell let-rowData="rowData">
      <span *ngIf="rowData.location?.id">{{ rowData.taxes?.length ?? 0 }} Selected</span>
    </ng-template>

    <ng-template supyGridCellEditor let-cell="cell" let-rowData="rowData">
      <supy-combo-box
        class="supy-create-recipe__cost-center-grid-selector"
        placeholder="Taxes"
        name="select-tax"
        valueKey="id"
        displayKey="name"
        titleKey="name"
        [value]="rowData.taxes"
        [list]="taxes"
        [multiple]="true"
        (changed)="setTaxes($event, rowData)"
        (closed)="grid.endEdit()"
        [disabled]="isReadonly"
      >
      </supy-combo-box>
    </ng-template>
  </supy-grid-column>

  <ng-template #emptyGridTemplate>
    <span class="supy-create-recipe__cost-center-grid-empty">There are no valid locations for this recipe</span>
  </ng-template>
</supy-grid>
