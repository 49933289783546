import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ToggleButton } from '../../../button';

export interface ButtonGroupCellRendererContext {
  readonly buttons: ToggleButton[];
}

export type ButtonGroupCellRendererParams = ICellRendererParams<unknown, string, ButtonGroupCellRendererContext>;

@Component({
  selector: 'supy-button-group-cell-renderer',
  template: `<div class="supy-button-group-cell-renderer">
    <supy-button-group
      [buttons]="context?.buttons"
      [value]="value"
      (valueChange)="onValueChange($event)"
    ></supy-button-group>
  </div>`,
  styleUrls: ['./button-group-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupCellRendererComponent implements ICellRendererAngularComp {
  protected params: ButtonGroupCellRendererParams;
  protected context: ButtonGroupCellRendererContext;
  protected value: string;

  agInit(params: ButtonGroupCellRendererParams): void {
    this.params = params;
    this.context = this.params.context;
    this.value = this.params.value;
  }

  refresh(): boolean {
    return true;
  }

  onValueChange(value: string): void {
    this.params.setValue(value);
  }
}
