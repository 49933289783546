import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Directive } from '@angular/core';

import { Destroyable } from '@supy/common';

import { TreeNode } from '../components';

@Directive()
export class TreeDirective extends Destroyable {
  readonly treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource: ArrayDataSource<TreeNode>;

  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

  getDescendants = (node: TreeNode) => this.treeControl.getDescendants(node);

  getNonParentNodes = (node: TreeNode) => this.getDescendants(node).filter(n => !n.children);

  get isAllNodesExpanded(): boolean {
    return this.treeControl?.dataNodes?.length
      ? this.treeControl.dataNodes?.every(node => this.treeControl.isExpanded(node))
      : false;
  }

  get isAllNodesSelected(): boolean {
    return this.treeControl?.dataNodes?.length ? this.treeControl.dataNodes?.every(node => node.selected) : false;
  }

  get isSomeNodesSelected(): boolean {
    return this.treeControl?.dataNodes?.length
      ? this.treeControl.dataNodes?.some(node => this.getNonParentNodes(node).some(n => n.selected))
      : false;
  }

  get isAllNodesHidden(): boolean {
    return this.treeControl?.dataNodes?.length ? this.treeControl?.dataNodes.every(node => node.hidden) : false;
  }

  get allLeafNodes(): TreeNode[] {
    return this.treeControl.dataNodes?.reduce(
      (acc, node) => [...acc, ...this.getNonParentNodes(node)],
      [] as TreeNode[],
    );
  }

  get selectedNonParents(): TreeNode[] {
    return this.treeControl.dataNodes
      ?.reduce((acc, node) => [...acc, ...this.getNonParentNodes(node)], [] as TreeNode[])
      .filter(node => node.selected);
  }

  constructor(protected readonly cdr: ChangeDetectorRef) {
    super();
  }

  isAllNodeChildrenSelected(node: TreeNode): boolean {
    const descendants = this.getDescendants(node);
    const result = descendants.every(child => child.selected);

    node.selected = result;

    return result;
  }

  isSomeNodeChildrenSelected(node: TreeNode): boolean {
    const descendants = this.getDescendants(node);

    return descendants.some(child => child.selected);
  }

  isAllChildrenHidden(node: TreeNode): boolean {
    return node.children.every(child => child.hidden || child.forceHidden);
  }

  expandAllNodes(): void {
    this.treeControl.expandAll();

    this.cdr.detectChanges();
  }

  collapseAllNodes(): void {
    this.treeControl.collapseAll();

    this.cdr.detectChanges();
  }

  collapseSelectedNodes(): void {
    this.treeControl.dataNodes.forEach(node => {
      this.collapseNodesRecursively(node);
    });

    this.cdr.detectChanges();
  }

  collapseNodesRecursively(node: TreeNode): void {
    node.selected ? this.treeControl.collapse(node) : this.treeControl.expand(node);

    node?.children?.forEach(child => {
      if (child?.children?.length) {
        this.collapseNodesRecursively(child);
      }
    });
  }

  toggleSelectNode(node: TreeNode, value: boolean, skipHidden?: boolean, detectChanges?: boolean): void {
    if ((node.hidden || node.forceHidden) && skipHidden) {
      return;
    }

    node.selected = value;

    if (detectChanges) {
      this.cdr.detectChanges();
    }
  }

  onToggleSelectAllNodes(value: boolean): void {
    this.treeControl.dataNodes.forEach(node => {
      this.toggleSelectNode(node, value);

      this.toggleSelectChildren(node, value);
    });

    this.cdr.detectChanges();
  }

  toggleSelectChildren(node: TreeNode, value: boolean): void {
    if (node.children) {
      node.children.forEach(child => {
        this.toggleSelectNode(child, value, true);

        this.toggleSelectChildren(child, value);
      });
    }
  }

  searchNode(node: TreeNode, term: string): boolean {
    if (node.name.toLowerCase().includes(term.toLowerCase())) {
      return true;
    }
  }

  search(term: string): void {
    if (!term.trim().length) {
      this.resetHiddenNodes();

      this.collapseSelectedNodes();

      return;
    }

    this.treeControl.dataNodes.forEach(node => {
      if (this.searchNode(node, term)) {
        node.hidden = false;

        return;
      }

      if (!node.children?.length) {
        node.hidden = !this.searchNode(node, term);

        return;
      }

      node.children.forEach(child => {
        if (this.searchNode(child, term)) {
          child.hidden = false;

          return;
        }

        if (!child.children?.length) {
          child.hidden = !this.searchNode(child, term);

          return;
        }

        child.children.forEach(grandchild => {
          grandchild.hidden = !this.searchNode(grandchild, term);
        });

        child.hidden = child.children.every(grandchild => grandchild.hidden) && !this.searchNode(child, term);
      });

      node.hidden = node.children.every(child => child.hidden) && !this.searchNode(node, term);
    });

    this.expandAllNodes();
  }

  private resetHiddenNodes(): void {
    this.treeControl.dataNodes.forEach(node => {
      node.hidden = false;

      if (node.children?.length) {
        node.children.forEach(child => {
          child.hidden = false;

          if (child.children?.length) {
            child.children.forEach(grandchild => {
              grandchild.hidden = false;
            });
          }
        });
      }
    });
  }
}
