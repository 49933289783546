import { takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Destroyable, RelatedBranch } from '@supy/common';

export interface ActiveLocationsForm {
  readonly locations: RelatedBranch[];
}
@Component({
  selector: 'supy-active-locations-grid',
  templateUrl: './active-locations-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./active-locations-grid.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActiveLocationsGridComponent),
      multi: true,
    },
  ],
})
export class ActiveLocationsGridComponent extends Destroyable implements OnInit, ControlValueAccessor {
  @Input() readonly allowFiltering: boolean = true;

  @Input() set isReadonly(value: boolean) {
    setTimeout(() => {
      this.setDisabledState(value);
    });
  }

  protected readonly form = new FormGroup({
    locations: new FormControl<RelatedBranch[]>([]),
  });

  onTouched: () => void;
  onChange: (value: ActiveLocationsForm) => void;

  protected disabled: boolean;

  protected get locations(): RelatedBranch[] {
    return this.form.value?.locations ?? [];
  }

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.onChange?.(value as ActiveLocationsForm);
      this.cdr.markForCheck();
    });
  }

  writeValue(value: ActiveLocationsForm): void {
    if (value) {
      this.form.patchValue(value);
    }
  }

  registerOnChange(onChange: (value: ActiveLocationsForm) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  onValueChange(value: boolean, locationId: string): void {
    this.form.setValue({
      locations: this.locations.map(location => ({
        ...location,
        active: location.id === locationId ? value : location.active,
      })),
    });
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
