import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Branch, BranchId, IQueryResponse, Query, Supplier, SupplierId, User, UserId } from '@supy/common';

import { BRANCHES_BFF_URI, BRANCHES_URI } from '../../config';
import {
  CreateBranchRequest,
  CreateLocationRequest,
  ManageStorageLocationRequest,
  UpdateBranchRequest,
} from '../../core';

@Injectable({ providedIn: 'root' })
export class BranchesService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(BRANCHES_URI) private readonly uri: string,
    @Inject(BRANCHES_BFF_URI) private readonly uriBff: string,
  ) {}

  getBranches(query: Query<Branch>): Observable<IQueryResponse<Branch>> {
    return this.httpClient.get<IQueryResponse<Branch>>(this.uri, { params: query.toQueryParams() });
  }

  getBranchesBff(query: Query<Branch>): Observable<IQueryResponse<Branch>> {
    return this.httpClient.get<IQueryResponse<Branch>>(this.uriBff, { params: query.toQueryParams() });
  }

  getBranch(id: BranchId): Observable<Branch> {
    return this.httpClient.get<Branch>(`${this.uri}/${id}`);
  }

  getBranchUsers(id: BranchId, query: Query<User>): Observable<IQueryResponse<User>> {
    return this.httpClient.get<IQueryResponse<User>>(`${this.uri}/${id}/users`, { params: query.toQueryParams() });
  }

  getBranchesSuppliers(ids: BranchId[], query: Query<Supplier>): Observable<IQueryResponse<Supplier>> {
    query.filtering.setFilter({ by: 'branchIds', op: 'eq', match: ids });

    return this.httpClient.get<IQueryResponse<Supplier>>(`${this.uri}/suppliers`, { params: query.toQueryParams() });
  }

  createBranch(body: CreateBranchRequest): Observable<Branch> {
    return this.httpClient.post<Branch>(this.uri, body);
  }

  createLocation(body: CreateLocationRequest): Observable<Branch> {
    return this.httpClient.post<Branch>(this.uriBff, body);
  }

  editBranch(id: BranchId, body: UpdateBranchRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${id}`, body);
  }

  deleteBranch(id: BranchId): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${id}`);
  }

  addSupplierToBranch(id: BranchId, suppliers: SupplierId[]): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${id}/suppliers/add`, { suppliers });
  }

  removeSupplierFromBranch(id: BranchId, suppliers: SupplierId[]): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${id}/suppliers/remove`, { suppliers });
  }

  manageBranchSupplier(id: BranchId, supplierIds: SupplierId[], shouldAdd: boolean): Observable<void> {
    if (shouldAdd) {
      return this.addSupplierToBranch(id, supplierIds);
    }

    return this.removeSupplierFromBranch(id, supplierIds);
  }

  addAllUsersToBranch(id: BranchId): Observable<Branch> {
    return this.httpClient.patch<Branch>(`${this.uri}/${id}/users/add/all`, null);
  }

  removeAllUsersFromBranch(id: BranchId): Observable<Branch> {
    return this.httpClient.patch<Branch>(`${this.uri}/${id}/users/remove/all`, null);
  }

  manageAllBranchUsers(id: BranchId, shouldAdd: boolean): Observable<Branch> {
    if (shouldAdd) {
      return this.addAllUsersToBranch(id);
    }

    return this.removeAllUsersFromBranch(id);
  }

  addUsersToBranch(id: BranchId, users: UserId[]): Observable<Branch> {
    return this.httpClient.patch<Branch>(`${this.uri}/${id}/users/add`, { users });
  }

  removeUsersFromBranch(id: BranchId, users: UserId[]): Observable<Branch> {
    return this.httpClient.patch<Branch>(`${this.uri}/${id}/users/remove`, { users });
  }

  manageBranchUsers(id: BranchId, users: UserId[], shouldAdd: boolean): Observable<Branch> {
    if (shouldAdd) {
      return this.addUsersToBranch(id, users);
    }

    return this.removeUsersFromBranch(id, users);
  }

  manageStorageLocationsBff(branchId: string, body: ManageStorageLocationRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uriBff}/${branchId}/storage-locations`, body);
  }
}
