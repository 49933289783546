import { BaseChannel } from './channel.base';
import { ChannelType } from './channel.type';

type ChannelName = `${ChannelType.PresenceCache}-${string}`;

export class PresenceCacheChannel extends BaseChannel<ChannelName> {
  get type(): ChannelType {
    return ChannelType.PresenceCache;
  }

  static for(name: string): ChannelName {
    return `${ChannelType.PresenceCache}-${name}`;
  }
}
