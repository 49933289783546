<section class="supy-pdf-viewer">
  <div *ngIf="isCollapsable && isCollapsedChanges()" (click)="onToggleCollapse()" class="supy-pdf-viewer__tail">
    <supy-icon name="sidebar-left" color="primary"></supy-icon>
    <h6 i18n="@@pdfViewer.attachment">Attachment</h6>
  </div>
  <div *ngIf="!isCollapsedChanges()" class="supy-pdf-viewer__wrapper">
    <header class="supy-pdf-viewer__header">
      <h6>{{ headerTitle }}</h6>
      <supy-button *ngIf="isCollapsable" color="secondary" (buttonClick)="onToggleCollapse()">
        <supy-icon name="sidebar-right"></supy-icon>
        <span i18n="@@pdfViewer.collapse">Collapse</span>
      </supy-button>
    </header>
    <div class="supy-pdf-viewer__content">
      <supy-loading-overlay *ngIf="isLoading()"></supy-loading-overlay>
      <ngx-extended-pdf-viewer
        [src]="src"
        [showToolbar]="showToolbar"
        [showSidebarButton]="showSidebarButton"
        [showFindButton]="showFindButton"
        [showPagingButtons]="showPagingButtons"
        [showDrawEditor]="showDrawEditor"
        [showStampEditor]="showStampEditor"
        [showTextEditor]="showTextEditor"
        [showZoomButtons]="showZoomButtons"
        [showPresentationModeButton]="showPresentationModeButton"
        [showOpenFileButton]="showOpenFileButton"
        [showPrintButton]="showPrintButton"
        [showDownloadButton]="showDownloadButton"
        [showSecondaryToolbarButton]="showSecondaryToolbarButton"
        [showRotateButton]="showRotateButton"
        [showRotateCwButton]="showRotateCwButton"
        [showRotateCcwButton]="showRotateCcwButton"
        [showHandToolButton]="showHandToolButton"
        [showScrollingButton]="showScrollingButton"
        [showSpreadButton]="showSpreadButton"
        [showPropertiesButton]="showPropertiesButton"
        [showHighlightEditor]="showHighlightEditor"
        [zoom]="initialZoom"
        [zoomLevels]="zoomLevels"
        (pageRendered)="onPageRendered()"
      ></ngx-extended-pdf-viewer>
    </div>
  </div>
</section>
