import produce from 'immer';
import { catchError, EMPTY, switchMap, tap, withLatestFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DateRange } from '@infragistics/igniteui-angular';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import { EntityListState, EntityListStateModel, Query, QueryBuilder } from '@supy/common';
import { BaseActivity } from '@supy/components';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';

import {
  PostSupplierReturnsResponse,
  SupplierReturn,
  SupplierReturnQueryParams,
  SupplierReturnStatusEnum,
} from '../../core';
import { downloadReturnsList } from '../../helpers/supplier-return-excel.helper';
import { SupplierReturnService } from '../../services';
import {
  ClearSupplierReturnPostMany,
  SupplierReturnCreate,
  SupplierReturnGenerateDocumentNumber,
  SupplierReturnGetMany,
  SupplierReturnGetOne,
  SupplierReturnInitFilters,
  SupplierReturnListExport,
  SupplierReturnLockMany,
  SupplierReturnPatchFilter,
  SupplierReturnPatchRequestMetadata,
  SupplierReturnPost,
  SupplierReturnPostMany,
  SupplierReturnResetFilter,
  SupplierReturnSetFilter,
  SupplierReturnUpdate,
} from '../actions';

const SUPPLIER_RETURN_STATE_TOKEN = new StateToken<SupplierReturnStateModel[]>('supplierReturns');

export interface SupplierReturnStateModel extends EntityListStateModel<SupplierReturn> {
  filters: SupplierReturnFilters;
  requestMetadata: SupplierReturnRequestMetadata;
  responseMetadata: SupplierReturnResponseMetadata;
  postSupplierReturnsResponse?: PostSupplierReturnsResponse;
  generatedDocumentNumber?: string;
  exportLoading: boolean;
}

export interface SupplierReturnFilters {
  status: SupplierReturnStatusEnum;
  locations: string[];
  dateRange?: DateRange;
  start?: Date | number | null;
  end?: Date | number | null;
  suppliers: string[];
  documentNumber: string;
  sorting?: keyof SupplierReturnQueryParams;
}

export interface SupplierReturnRequestMetadata {
  readonly page: number;
  readonly limit: number;
  readonly retailerId: string | null;
}

export interface SupplierReturnResponseMetadata {
  readonly total: number;
  readonly count: number;
}

const FILTERS_DEFAULT = {
  status: null,
  locations: [],
  documentNumber: null,
  suppliers: [],
};

const REQUEST_META_DEFAULT = { page: 0, limit: 25, channelIds: [], retailerId: null };
const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

@State<SupplierReturnStateModel>({
  name: SUPPLIER_RETURN_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    filters: FILTERS_DEFAULT,
    exportLoading: false,
  },
})
@Injectable()
export class SupplierReturnState extends EntityListState {
  constructor(
    private readonly supplierReturnService: SupplierReturnService,
    protected readonly router: Router,
    private readonly store: Store,
  ) {
    super(router);
  }

  @Selector()
  static supplierReturns(state: SupplierReturnStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static postSupplierReturnsResponse(state: SupplierReturnStateModel) {
    return state.postSupplierReturnsResponse;
  }

  @Selector()
  static currentSupplierReturn(state: SupplierReturnStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static filters(state: SupplierReturnStateModel) {
    return EntityListState.currentFilters<SupplierReturnFilters>(state);
  }

  @Selector()
  static appliedFiltersCount(state: SupplierReturnStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static requestMetadata(state: SupplierReturnStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: SupplierReturnStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static isFirst(state: SupplierReturnStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: SupplierReturnStateModel) {
    return EntityListState.isLast(state);
  }

  @Selector()
  static exportLoading(state: SupplierReturnStateModel) {
    return state.exportLoading;
  }

  @Selector()
  static currentSupplierReturnActivities(state: SupplierReturnStateModel) {
    const supplierReturn = SupplierReturnState.currentSupplierReturn(state);

    return supplierReturn?.activities?.reduce<BaseActivity[]>(
      (acc, cur) => [
        {
          action: cur.type,
          user: cur.user,
          createdAt: cur.createdAt,
        },
        ...acc,
      ],
      [],
    );
  }

  @Selector()
  static generatedDocumentNumber(state: SupplierReturnStateModel) {
    return state.generatedDocumentNumber;
  }

  static supplierReturn(id: string, next?: boolean) {
    return createSelector([SupplierReturnState], (state: SupplierReturnStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Action(SupplierReturnInitFilters)
  initFilters(ctx: StateContext<SupplierReturnStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(ClearSupplierReturnPostMany)
  clearPostedSupplierReturns(ctx: StateContext<SupplierReturnStateModel>) {
    return ctx.patchState({ postSupplierReturnsResponse: null });
  }

  @Action(SupplierReturnSetFilter)
  setSupplierReturnFilter(ctx: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnSetFilter) {
    super.setFilter(ctx, payload, FILTERS_DEFAULT);

    ctx.dispatch([new SupplierReturnPatchRequestMetadata({ page: 0 }), new SupplierReturnGetMany()]);
  }

  @Action(SupplierReturnPatchFilter)
  patchSupplierReturnFilter(ctx: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnPatchFilter) {
    super.patchFilter(ctx, payload, FILTERS_DEFAULT);

    ctx.dispatch([new SupplierReturnPatchRequestMetadata({ page: 0 }), new SupplierReturnGetMany()]);
  }

  @Action(SupplierReturnResetFilter)
  resetSupplierReturnFilter(ctx: StateContext<SupplierReturnStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);

    ctx.dispatch([new SupplierReturnPatchRequestMetadata({ page: 0 }), new SupplierReturnGetMany()]);
  }

  @Action(SupplierReturnPatchRequestMetadata)
  patchRequestMetadata(ctx: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnPatchRequestMetadata) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...draft.requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(SupplierReturnGetMany)
  getSupplierReturns(ctx: StateContext<SupplierReturnStateModel>) {
    return this.supplierReturnService.getSupplierReturns(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(SupplierReturnGetOne)
  getSupplierReturn(ctx: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnGetOne) {
    return super.getOne(ctx, {
      ...payload,
      fetchApi: () => this.supplierReturnService.getSupplierReturn(payload.id),
    });
  }

  @Action(SupplierReturnCreate)
  createSupplierReturn(_: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnCreate) {
    return this.supplierReturnService.createSupplierReturn(payload.body);
  }

  @Action(SupplierReturnUpdate)
  updateSupplierReturn(_: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnUpdate) {
    return this.supplierReturnService.updateSupplierReturn(payload.id, payload.body);
  }

  @Action(SupplierReturnPost)
  postSupplierReturn(ctx: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnPost) {
    return this.supplierReturnService.postSingleSupplierReturn(payload);
  }

  @Action(SupplierReturnPostMany)
  postManySupplierReturn(ctx: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnPostMany) {
    return this.supplierReturnService
      .postSupplierReturns(payload)
      .pipe(tap(postSupplierReturnsResponse => ctx.patchState({ postSupplierReturnsResponse })));
  }

  @Action(SupplierReturnLockMany)
  lockManySupplierReturn(ctx: StateContext<SupplierReturnStateModel>, { payload }: SupplierReturnLockMany) {
    return this.supplierReturnService
      .lockSupplierReturns(payload)
      .pipe(switchMap(() => ctx.dispatch([new SupplierReturnGetMany()])));
  }

  @Action(SupplierReturnGenerateDocumentNumber)
  generateDocumentNumber(ctx: StateContext<SupplierReturnStateModel>) {
    const selectedRetailerId = this.store.selectSnapshot(CurrentRetailerState.get);

    return this.supplierReturnService
      .generateDocumentNumber({ retailer: { id: selectedRetailerId } })
      .pipe(tap(({ number }) => ctx.patchState({ generatedDocumentNumber: number })));
  }

  @Action(SupplierReturnListExport, { cancelUncompleted: true })
  downloadItemDetails(ctx: StateContext<SupplierReturnStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.supplierReturnService.getSupplierReturns(query).pipe(
      withLatestFrom(this.store.select(SettingsState.currency), this.store.select(SettingsState.ianaTimeZone)),
      tap(([{ data }, currency, ianaTimeZone]) => {
        ctx.patchState({
          exportLoading: false,
        });

        downloadReturnsList(data, currency, ianaTimeZone);
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  private getQuery(
    state: SupplierReturnStateModel,
    extras: {
      readonly paginationLimit?: number;
      readonly offset?: number;
    } = {},
  ): Query<SupplierReturnQueryParams> {
    const { locations, start, end, status, suppliers, documentNumber, sorting } = state.filters;

    const qb = new QueryBuilder<SupplierReturnQueryParams>({
      filtering: [],
      paging: {
        limit: extras.paginationLimit ? extras.paginationLimit : state.requestMetadata.limit,
        offset: extras.offset ? extras.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        {
          by: sorting ?? 'document.documentDate',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (locations?.length) {
      qb.filtering.setFilter({
        by: 'location.id',
        match: locations,
        op: 'in',
      });
    }

    if (suppliers?.length) {
      qb.filtering.setFilter({
        by: 'supplier.id',
        match: suppliers,
        op: 'in',
      });
    }

    if (status) {
      qb.filtering.setFilter({
        by: 'status',
        match: status,
        op: 'eq',
      });
    }

    if (documentNumber) {
      qb.filtering.setFilter({
        by: 'document.number',
        match: documentNumber,
        op: 'like',
      });
    }

    if (start && end) {
      qb.filtering.withFiltering([
        { by: 'document.documentDate', op: 'lte', match: new Date(end).getTime() },
        { by: 'document.documentDate', op: 'gte', match: new Date(start).getTime() },
      ]);
    }

    return qb.build();
  }
}
