import { ResourceTypeEnum } from '../../../tenant';
import {
  CreateMappingRequest,
  CreateMappingsRequest,
  DeleteMappingsRequest,
  MappingCategoryEnum,
  UpdateMappingRequest,
  UpdateMappingsRequest,
} from '../../models';

export class MappingGetMany {
  static readonly type = '[Mapping] GetMany';

  constructor(
    readonly retailerId: string,
    readonly tenantId: string,
    readonly resourceType: ResourceTypeEnum,
    readonly category: MappingCategoryEnum,
  ) {}
}

export class MappingGet {
  static readonly type = '[Mapping] Get';

  constructor(
    readonly mappingId: string,
    readonly fromCache?: boolean,
  ) {}
}

export class MappingCreate {
  static readonly type = '[Mapping] Create';

  constructor(readonly payload: CreateMappingRequest) {}
}

export class MappingCreateMany {
  static readonly type = '[Mapping] CreateMany';

  constructor(readonly payload: CreateMappingsRequest) {}
}

export class MappingUpdate {
  static readonly type = '[Mapping] Update';

  constructor(
    readonly id: string,
    readonly payload: UpdateMappingRequest,
  ) {}
}

export class MappingUpdateMany {
  static readonly type = '[Mapping] UpdateMany';

  constructor(readonly payload: UpdateMappingsRequest) {}
}

export class MappingDelete {
  static readonly type = '[Mapping] Delete';

  constructor(readonly id: string) {}
}

export class MappingDeleteMany {
  static readonly type = '[Mapping] DeleteMany';

  constructor(readonly payload: DeleteMappingsRequest) {}
}

export class MappingGetBranchesWithTenants {
  static type = '[Mapping] GetBranchesWithTenants';

  constructor(readonly retailerId: string) {}
}
