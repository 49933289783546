import { BaseRequestMetadata } from '@supy/common';

import {
  CreateInventoryTransferRequest,
  InventoryEventItemProps,
  InventoryTransferFilters,
  InventoryTransferMode,
  UpdateInventoryTransferRequest,
  UploadInventoryEventAttachmentQueryParams,
} from '../../../core';

export class InventoryTransferGet {
  static readonly type = '[InventoryTransfer] Get';
  constructor(public payload: { id: string; fromCache?: boolean }) {}
}

export class InventoryTransferGetMany {
  static readonly type = '[InventoryTransfer] GetMany';
}

export class InventoryTransferListExport {
  static readonly type = '[InventoryTransfer] ListExport';
}

export class InventoryTransferCreate {
  static readonly type = '[InventoryTransfer] Create';
  constructor(readonly payload: CreateInventoryTransferRequest) {}
}

export class InventoryTransferUpdate {
  static readonly type = '[InventoryTransfer] Update';
  constructor(readonly payload: UpdateInventoryTransferRequest) {}
}

export class InventoryTransferDelete {
  static readonly type = '[InventoryTransfer] Delete';

  constructor(readonly id: string) {}
}

export class InventoryTransferInitFilters {
  static readonly type = '[InventoryTransfer] InitFilters';
}

export class InventoryTransferPatchFilter {
  static readonly type = '[InventoryTransfer] PatchFilter';
  constructor(public payload: Partial<InventoryTransferFilters>) {}
}

export class InventoryTransferResetFilter {
  static readonly type = '[InventoryTransfer] ResetFilter';
}

export class InventoryTransferPatchRequestMeta {
  static readonly type = '[InventoryTransfer] PatchRequestMeta';
  constructor(public payload: Partial<BaseRequestMetadata>) {}
}

export class InventoryTransferGetItems {
  static readonly type = '[InventoryTransfer] GetItems';

  constructor(readonly query: InventoryEventItemProps) {}
}

export class InventoryTransferResetSequence {
  static readonly type = '[InventoryTransfer] ResetSequence';
}

export class InventoryTransferSetMode {
  static readonly type = '[InventoryTransfer] SetMode';
  constructor(readonly value: InventoryTransferMode) {}
}

export class InventoryTransferCheckExistence {
  static readonly type = '[InventoryTransfer] CheckExistence';
}

export class InventoryTransferUploadAttachment {
  static readonly type = '[InventoryTransfer] UploadAttachment';

  constructor(
    readonly payload: FormData,
    readonly queryParams: UploadInventoryEventAttachmentQueryParams,
  ) {}
}

export class InventoryTransferGetStats {
  static readonly type = '[InventoryTransfer] GetStats';

  constructor(readonly retailerId: string) {}
}
