import { Workbook } from 'exceljs';

import { createWorksheet, DataColumn, saveWorkbook } from '@supy/common';

export function downloadVarianceReport(
  reportData: {
    itemCode: string;
    itemName: string;
    itemType: string;
    storageUnit: string;
    onHandQty: number;
    countedQty: number;
    qtyVariance: number;
    onHandValue: number;
    countedValue: number;
    valueVariance: number;
    category: string;
    subCategory: string;
  }[],
  reportMetadata: {
    locationName: string;
    date: Date;
    time: string;
  },
): void {
  const columns: DataColumn[] = [
    { header: 'Item Code', key: 'itemCode' },
    { header: 'Item Name', key: 'itemName' },
    { header: 'Item Type', key: 'itemType' },
    { header: 'Storage Unit', key: 'storageUnit' },
    { header: 'Category', key: 'category' },
    { header: 'Sub-Category', key: 'subCategory' },
    { header: 'On-Hand Quantity', key: 'onHandQty' },
    { header: 'Counted Quantity', key: 'countedQty' },
    { header: 'Qty Variance', key: 'qtyVariance' },
    { header: 'On-Hand Value', key: 'onHandValue' },
    { header: 'Counted Value', key: 'countedValue' },
    { header: 'Value Variance', key: 'valueVariance' },
  ];

  const workbook = new Workbook();

  const metadataSheetValues: string[][] = [
    ['Variance Report Details'],
    ['Location:', reportMetadata.locationName],
    ['Time:', reportMetadata.time],
    ['Date:', new Date(reportMetadata.date).toDateString()],
  ];

  createWorksheet(workbook, 'Items', { data: reportData, columns });
  createWorksheet(workbook, 'Overview', { data: metadataSheetValues });

  saveWorkbook(workbook, `VarianceReport_${Date.now()}.xlsx`);
}
