import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface ColumnChartEntry {
  categoryLabel: string;
  [serieName: string]: number | string;
}

@Component({
  selector: 'supy-column-chart',
  templateUrl: 'column-chart.component.html',
  styleUrls: ['./column-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnChartComponent {
  @Input() readonly entries: ColumnChartEntry[];
  @Input() readonly yAxisLabel: string;
  @Input() readonly yAxisMinimumValue = 0;
  @Input() readonly yAxisMaximumValue: number;
  @Input() readonly yAxisInterval = 5;
  @Input() readonly brushes: string;
}
