import { BehaviorSubject, first } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CustomRoleScopeType, Retailer, ToggleFeature, UnleashService, User, UserWithBranches } from '@supy/common';

import { Breadcrumb } from '../../../breadcrumb';
import { PageService } from './page.service';

@Component({
  selector: 'supy-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements OnInit, OnDestroy {
  @Input() readonly header: string | null = null;
  @Input() readonly subHeader: string | null = null;
  @Input() readonly description: string | null = null;
  @Input() readonly withBreadcrumbs: boolean = true;
  @Input() readonly withSeparator: boolean = false;
  @Input() readonly noPadding: boolean = false;

  @Input() readonly skipFirstRoute: boolean = false;
  @Input() readonly showBackButton: boolean = true;
  @Input() readonly mergeParams: boolean = true;
  @Input() readonly breadcrumbs?: Breadcrumb[];
  @Input() readonly aliases?: Record<string, string>;
  @Input() set retailers(retailers: Retailer[]) {
    this.#retailers = retailers ?? [];
  }

  get retailers(): Retailer[] {
    return this.#retailers;
  }

  @Input() readonly user?: User;
  @Input() set retailerId(value: string) {
    this.currentRetailer = value;
  }

  @Output() retailerChange = new EventEmitter<string>();

  currentRetailer: string;
  #retailers: Retailer[] = [];
  get currentRetailerName(): string {
    return this.retailers?.find(({ id }) => id === this.currentRetailer)?.name;
  }

  get isSuperAdmin(): boolean {
    return (
      (this.user as UserWithBranches)?.roles?.findIndex(
        ({ scope }) => scope.type === CustomRoleScopeType.Superadmin,
      ) !== -1
    );
  }

  private readonly appendQueryParamsLoading = new BehaviorSubject<boolean>(false);
  protected readonly appendQueryParamsLoading$ = this.appendQueryParamsLoading.asObservable();

  protected readonly hasReferAndEarnFeature = signal<boolean>(false);
  protected readonly hasNewNotifications = this.pageService.hasNewNotifications;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly unleashService: UnleashService,
    private readonly pageService: PageService,
  ) {}

  ngOnInit(): void {
    // FIXME: This is temporary once we have this component implemented in all our pages should be removed
    this.document.getElementById('main')?.classList.add('supy-main__no-padding');
    this.syncReferAndEarnWithUnleash();
  }

  ngOnDestroy(): void {
    this.document.getElementById('main')?.classList.remove('supy-main__no-padding');
  }

  onRetailerChange(retailerId: string) {
    if (retailerId) {
      this.retailerChange.emit(retailerId ?? this.retailers[0].id);
    }
  }

  async onInputClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    await this.router.navigate(['/retailers-list']);
  }

  logout(): Promise<boolean> {
    return this.router.navigateByUrl('/auth/logout');
  }

  private syncReferAndEarnWithUnleash(): void {
    this.unleashService.enabledTogglesChanges$.pipe(first(toggles => toggles.length > 0)).subscribe(toggles => {
      if (toggles.includes(ToggleFeature.ReferralProgram)) {
        this.hasReferAndEarnFeature.set(true);
      }
    });
  }

  protected showNotificationsDrawer(): void {
    this.pageService.showNotificationDrawer();
  }

  protected showUserSettingsDrawer(): void {
    this.pageService.showUserSettingsDrawer();
  }

  protected async referAndEarn(): Promise<void> {
    this.appendQueryParamsLoading.next(true);

    await this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { referAndEarn: true },
        queryParamsHandling: 'merge',
      })
      .finally(() => this.appendQueryParamsLoading.next(false));
  }

  protected readonly toggleFeature = ToggleFeature;
}
