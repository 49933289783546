<ng-container [formGroup]="form">
  <div class="supy-supplier-form">
    <h3 class="supy-supplier-form__title">{{ title }}</h3>
    <div class="supy-supplier-form__uploader">
      <supy-image-uploader
        [initialUrl]="form?.get('logoUrl')?.value"
        (imageCropped)="onSaveLogo()"
        [useCropper]="true"
        (imageRemoved)="imageRemoved()"
        formControlName="logo"
      >
        <span class="supy-supplier-form__uploader-text">Add logo</span> (optional)
        <supy-input-error *supyErrorIf="'maxSize'">File size should be below {{ maxFileSizeKB }}KB</supy-input-error>
        <supy-input-error *supyErrorIf="'image'">File should be an image</supy-input-error>
        <supy-input-error *supyErrorIf="'required'">Please save the logo before submitting the form.</supy-input-error>
      </supy-image-uploader>
    </div>
    <div class="supy-supplier-form__inputs">
      <supy-input placeholder="Name" type="text" name="name" formControlName="name">
        <supy-input-error *supyErrorIf="'required'">Name is required</supy-input-error>
        <supy-input-error *supyErrorIf="'name'">Invalid name</supy-input-error>
      </supy-input>
      <div class="supy-supplier-form__row">
        <div>
          <supy-select placeholder="Country" formControlName="countryId" name="country">
            <supy-select-item *ngFor="let country of countries$ | async" [value]="country.id">{{
              country.name | titlecase
            }}</supy-select-item>
            <supy-input-error *supyErrorIf="'required'">Country is required</supy-input-error>
          </supy-select>
        </div>
      </div>
      <supy-input placeholder="Address" type="text" name="address" formControlName="address">
        <supy-input-error *supyErrorIf="'required'">Address is required</supy-input-error>
      </supy-input>
      <supy-input placeholder="Phone number" type="tel" name="phone" formControlName="phone">
        <supy-input-error *supyErrorIf="'required'">Phone is required</supy-input-error>
        <supy-input-error *supyErrorIf="'phone'">Phone is invalid</supy-input-error>
      </supy-input>
      <supy-input placeholder="Email" type="email" name="email" formControlName="email">
        <supy-input-error *supyErrorIf="'required'">Email is required</supy-input-error>
        <supy-input-error *supyErrorIf="'email'">Email is invalid</supy-input-error>
      </supy-input>
      <div class="supy-supplier-form__row">
        <div>
          <supy-select placeholder="Type" formControlName="type" name="type" (selected)="onTypeSelect($event)">
            <supy-select-item *ngFor="let type of types" [value]="type.value">{{
              type.label | titlecase
            }}</supy-select-item>
            <supy-input-error *supyErrorIf="'required'">Type is required</supy-input-error>
          </supy-select>
        </div>
      </div>

      <div formGroupName="metadata">
        <supy-combo-box
          *ngIf="form.get('type')?.value === supplierType.centralKitchen"
          formControlName="warehouseId"
          name="warehouseId"
          placeholder="Choose related Branch"
          photoKey="logoUrl"
          displayKey="name"
          valueKey="id"
          titleKey="name"
          subtitleKey="phone"
          pipeKey="email"
          [value]="[selectedWarehouseId]"
          [list]="branches"
          [isLoading]="isLoading"
          (searchInputUpdated)="onSearchBranches($event)"
          (changed)="onBranchSelect($event)"
        >
        </supy-combo-box>
        <supy-input
          placeholder="Tax Registration Number"
          type="text"
          name="taxRegistrationNumber"
          formControlName="taxRegistrationNumber"
        >
        </supy-input>
      </div>
      <supy-separator></supy-separator>
      <div class="supy-supplier-form__settings" formGroupName="settings">
        <div>
          <label class="supy-supplier-form__label" data-label="cutOffTime">Cut-off Time</label>
          <supy-time-picker mode="dropdown" formControlName="cutOffTime" name="time"></supy-time-picker>
        </div>
        <div>
          <label class="supy-supplier-form__label" data-label="minOrderValue">
            Minimum Order Value
            <supy-icon name="info" size="small" [supyTooltip]="tooltip" position="top"></supy-icon>
          </label>
          <supy-tooltip #tooltip>Per Supplier Currency</supy-tooltip>
          <supy-input
            type="number"
            [min]="0"
            placeholder="Minimum Order Value"
            formControlName="minOrderValue"
            name="minOrderValue"
          ></supy-input>
        </div>
      </div>
    </div>
  </div>
</ng-container>
