import { Subject, takeUntil } from 'rxjs';
import { NgIf, NgIfContext } from '@angular/common';
import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef } from '@angular/core';

import { Destroyable } from '@supy/common';

import { Principal, Resource } from '../core';
import { PermissionStrategy } from '../strategies';

@Directive({ selector: '[supyHasAccess]', hostDirectives: [{ directive: NgIf }] })
export class AuthzDirective extends Destroyable implements OnInit {
  static ngTemplateGuard_supyHasAccess: 'binding';

  @Input() readonly supyHasAccess: PermissionStrategy;
  @Input() set supyHasAccessElse(value: TemplateRef<NgIfContext<unknown>>) {
    this.#elseTemplate.next(value);
  }

  @Input() readonly principal?: Principal;
  @Input() readonly resource?: Resource;

  readonly #elseTemplate = new Subject<TemplateRef<NgIfContext<unknown>>>();

  constructor(
    private readonly ngIfDirective: NgIf,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.supyHasAccess
      .isAllowed()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(hasAccess => {
        this.ngIfDirective.ngIf = hasAccess;
        this.cdr.markForCheck();
      });

    this.#elseTemplate.pipe(takeUntil(this.destroyed$)).subscribe(elseTemplate => {
      this.ngIfDirective.ngIfElse = elseTemplate;
    });
  }
}
