import { IdType, SimpleUser } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { Uom } from './uom.model';

export interface PackagingGroup {
  name: string;
  items: Packaging[];
}

export class PackagingQuantity {
  parent?: number;
  base: number;
  baseUnit: number;

  static default(): PackagingQuantity {
    return { parent: -1, base: 0, baseUnit: 0 };
  }
}

export type PackagingLevel = 0 | 1 | 2;

export interface Packaging {
  id: string;
  level: PackagingLevel;
  unitName: string;
  packageName: string;
  sources: PackagingSource[];
  quantity: PackagingQuantity;
  amount?: number;
  parent?: IdType;
  usedAsPiece?: boolean;
  baseUnit: Uom;
  fullPackageName?: string;
  state?: PackagingState;
  updatedBy?: SimpleUser;
  createdBy?: SimpleUser;
  createdAt?: Date;
  updatedAt?: Date;
}

export class PackagingSource {
  scope: PackagingScopeEnum;
  id: string;
}

export enum PackagingScopeEnum {
  Recipe = 'recipe',
  Item = 'item',
  Packaging = 'packaging',
  Uom = 'uom',
}

export enum PackagingState {
  Available = 'available',
  Draft = 'Draft',
}

export enum PackagingUnitScope {
  Packaging = 'packaging',
  Uom = 'uom',
}

export class PackagingUnit {
  readonly name: string;
  readonly uomId: string;
  readonly toBaseUom?: number;
  readonly toAtomUom?: number;
  readonly packagingId?: string;
  readonly isPiece?: boolean;
  readonly customId?: string;

  static default(): PackagingUnit {
    const uomId = crypto.randomUUID();

    return { customId: uomId, name: '', uomId };
  }

  constructor(args: NonFunctionProperties<PackagingUnit>) {
    this.name = args.name;
    this.uomId = args.uomId;
    this.toAtomUom = args.toAtomUom;
    this.toAtomUom = args.toAtomUom;
    this.packagingId = args.packagingId;
    this.isPiece = args.isPiece;
    this.customId = `${args.uomId}${args.packagingId ?? ''}`;
  }

  static deserialize(args: NonFunctionProperties<PackagingUnit>): PackagingUnit {
    return new PackagingUnit({ ...args });
  }

  static deserializeList(data: NonFunctionProperties<PackagingUnit>[]): PackagingUnit[] {
    return data.map(packagingUnit => PackagingUnit.deserialize(packagingUnit));
  }
}
