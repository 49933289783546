import { BaseChannel } from './channel.base';
import { ChannelType } from './channel.type';

type ChannelName = `${ChannelType.PrivateCache}-${string}`;

export class PrivateCacheChannel extends BaseChannel<ChannelName> {
  get type(): ChannelType {
    return ChannelType.PrivateCache;
  }

  static for(name: string): ChannelName {
    return `${ChannelType.PrivateCache}-${name}`;
  }
}
