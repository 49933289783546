import { Query } from '@supy/common';

import { CreateRetailerItemCategoryRequest, RetailerItemCategory, UpdateRetailerItemCategoryRequest } from '../../core';

export class RetailerItemCategoriesGetCategories {
  static readonly type = '[RetailerItemCategories] GetCategories';

  constructor(public query: Query<RetailerItemCategory>) {}
}

export class RetailerItemCategoryUpdateCategory {
  static readonly type = '[RetailerItemCategories] UpdateCategory';
  constructor(
    readonly payload: UpdateRetailerItemCategoryRequest,
    readonly categoryId: string,
  ) {}
}

export class RetailerItemCategoryCreateCategory {
  static readonly type = '[RetailerItemCategories] CreateCategory';
  constructor(readonly payload: CreateRetailerItemCategoryRequest) {}
}

export class RetailerItemCategoryRemoveCategory {
  static readonly type = '[RetailerItemCategories] RemoveCategory';
  constructor(readonly categoryId: string) {}
}

export class RetailerItemCategoryGetMany {
  static readonly type = '[RetailerItemCategories] GetMany';
}
