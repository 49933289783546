import * as d3 from 'd3';
import { Selection } from 'd3-selection';
import { Subject, takeUntil } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { HexColor } from '@supy/common';

import { ChartBaseDirective, getTooltipPosition } from '../../../chart-core';
import {
  DivergingChartData,
  DivergingChartDisplayNames,
  DivergingChartMetadata,
  DivergingChartTooltipType,
} from '../../core';
import { HorizontalScaleConfig } from '../stacked-bar-chart-d3';

type D3Data = [string, d3.InternMap<string, number>];
const MIDDLE_OFFSET = 25;

@Component({
  selector: 'supy-diverging-stacked-bar-vertical-chart',
  templateUrl: './diverging-stacked-bar-vertical-chart.component.html',
  styleUrls: ['./diverging-stacked-bar-vertical-chart.component.scss'],
})
export class DivergingStackedBarVerticalChartComponent
  extends ChartBaseDirective<DivergingChartData & { type: DivergingChartTooltipType }>
  implements OnDestroy
{
  @Input() readonly data: DivergingChartData[] = [];
  @Input() title: (d: DivergingChartData) => string; // given d in data, returns the title text
  @Input() label: (d: DivergingChartData) => string; // items list that has same id to get label text
  @Input() readonly isLabelClickable: boolean = true;
  @Input() readonly showLabelTooltip: boolean;
  @Input() readonly maxBars: number;
  @Input() readonly positiveMetadataKey: string = 'varianceIn';
  @Input() readonly negativeMetadataKey: string = 'varianceOut';

  @Input() set horizontalScaleConfig(value: HorizontalScaleConfig) {
    if (value) {
      this.#horizontalScaleConfig = value;
      this.createChart();
    }
  }

  get horizontalScaleConfig(): HorizontalScaleConfig {
    return this.#horizontalScaleConfig;
  }

  @HostBinding('class.supy-diverging-stacked-bar-chart__horizontal-scroll')
  @Input()
  protected set hasHorizontalScroll(scrollable: boolean) {
    if (scrollable) {
      this.barsAmount = this.data.length || 0;
      this.createChart();
    }
    this.#hasHorizontalScroll = scrollable;
  }

  protected get hasHorizontalScroll(): boolean {
    return this.#hasHorizontalScroll;
  }

  #hasHorizontalScroll = false;

  @Output()
  readonly barClick = new EventEmitter<DivergingChartData>();

  @Output()
  readonly labelClick = new EventEmitter<DivergingChartData>();

  #horizontalScaleConfig: HorizontalScaleConfig = {
    yAxisLabelWidth: 0.15,
    barWidth: 0.75,
  };

  private titleMethod: (i: number) => string;
  private readonly click$ = new Subject<MouseEvent>();

  protected readonly marginTop = 40; // top margin, in pixels
  protected readonly marginRight = 0; // right margin, in pixels
  protected readonly marginBottom = 10; // bottom margin, in pixels
  protected readonly marginLeft = 40; // left margin, in pixels
  private readonly xType = d3.scaleLinear; // type of x-scale
  private xDomain: d3.InternSet; // [xmin, xmax]
  private xRange: [number, number] = [this.marginLeft, this.width - this.marginRight]; // [left, right]
  private yDomain: [number, number]; // array of y-values
  private yRange: [number, number]; // [bottom, top]
  private readonly yPadding: number = 0.1; // amount of y-range to reserve to separate bars
  @Input() keys: string[] | Set<string>; // array of z-values
  private readonly offset = d3.stackOffsetDiverging; // stack offset method
  private readonly xFormat: string; // a format specifier string for the x-axis
  @Input() readonly colors: [HexColor, HexColor] = ['#009BCF', '#001A92'];
  private readonly displayNamesMap = new Map<string, DivergingChartDisplayNames>();

  #keys: d3.InternSet;

  /**
   * returns the (quantitative) x-value
   *
   * @param d DivergingChartData one bar from data list.
   */
  @Input() readonly valueFn = (d: DivergingChartData) => d.value;

  /**
   * returns the (ordinal) y-value
   *
   * @param d DivergingChartData one bar from data list.
   * @param i number index in the data array.
   */
  @Input() readonly idFn = (d: DivergingChartData, i?) => d.id;

  /**
   * returns the value that data should be sorted on
   *
   * @param d DivergingChartData one bar from data list.
   */
  @Input() readonly sortingFn = (d: DivergingChartData): number => new Date(d.displayNames.date).getTime();

  /**
   * returns the value that bars should be grouped upon
   *
   * @param d DivergingChartData one bar from data list.
   */
  @Input() readonly groupingFn = (d: DivergingChartData) => d.displayNames.date;

  /**
   * returns the (categorical) z-value to use as key
   *
   * @param d DivergingChartData one bar from data list.
   */
  @Input() readonly keysFn = (d: DivergingChartData) => d.category;

  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(elementRef, cdr);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.removeExistingChartFromParent();
  }

  @HostListener('document:click', ['$event'])
  protected onClick(event: MouseEvent): void {
    this.click$.next(event);
  }

  private removeExistingChartFromParent(): void {
    d3.select(this.hostElement).select('svg').remove();
  }

  protected renderChart() {
    const X = this.getXValues();
    const Y = this.getYValues();
    const Z = this.getZValues();

    this.populateDisplayNamesMap();

    if (this.xDomain === undefined) {
      // sort by lowest first
      const sortedData = d3.groupSort(
        this.data,
        (a, b) => this.sortingFn(a[0]) - this.sortingFn(b[0]),
        d => this.groupingFn(d),
      );

      this.xDomain = new d3.InternSet(
        this.hasHorizontalScroll ? sortedData : sortedData.slice(0, this.maxBars ?? (this.data.length || 0)),
      );
    }

    if (this.keys === undefined || (!(this.keys as string[])?.length && !(this.keys as Set<string>)?.size)) {
      if (this.keysFn) {
        this.#keys = new d3.InternSet(this.data.map(this.keysFn));
      } else {
        this.#keys = new d3.InternSet(Z);
      }
    } else {
      this.#keys = new d3.InternSet(this.keys);
    }

    this.xDomain = new d3.InternSet(this.xDomain);
    this.#keys = new d3.InternSet(this.#keys);

    if (this.height === undefined) {
      this.height = this.calculateHeight();
    }

    if (this.yRange === undefined) {
      this.yRange = [this.height - this.marginBottom, this.marginTop];
    }

    const series = this.computeSeries(Y);

    if (this.yDomain === undefined) {
      this.yDomain = d3.extent(series.flat(2));
    }

    const minValue = this.getMinValue();

    const xScale = this.createXScale();
    const yScale = this.createYScale();
    const labelPosition = Math.abs(this.calculateLabelPosition(yScale, minValue));

    const xAxis: d3.Axis<d3.AxisDomain> = this.createYAxis(xScale, labelPosition);
    const yAxis: d3.Axis<d3.AxisDomain> = this.createXAxis(yScale);

    if (this.title === undefined) {
      this.titleMethod = this.createDefaultTitle(yScale, Y, X, Z);
    } else {
      this.titleMethod = this.createCustomTitle();
    }

    this.svg = this.createSVGElement();

    this.renderYAxis(yAxis, labelPosition, xScale);
    this.renderXAxis(xAxis);
    this.renderBars(series, xScale, yScale, X);
  }

  private getXValues(): string[] {
    return d3.map(this.data, this.groupingFn);
  }

  private getYValues(): number[] {
    return d3.map(this.data, this.valueFn);
  }

  private getZValues(): string[] {
    return d3.map(this.data, this.groupingFn);
  }

  private populateDisplayNamesMap(): void {
    this.data.forEach(data => {
      const key = this.idFn(data);

      if (!this.displayNamesMap.has(key)) {
        this.displayNamesMap.set(key, data.displayNames);
      }
    });
  }

  private calculateHeight(): number {
    return this.xDomain.size * 25 + this.marginTop + this.marginBottom;
  }

  private computeSeries(values: number[]): (d3.SeriesPoint<DivergingChartData> & { i: number })[][] {
    const keys = new Set(this.data.map(item => `${this.keysFn(item)}-${this.idFn(item)}`));
    const stack = d3
      .stack<DivergingChartData>()
      .keys(keys)
      .value((data, z, index) => {
        const [, I] = data as unknown as D3Data;

        return values[I.get(z)];
      })
      .order(() => this.data.map((_, index) => index))
      .offset(this.offset);

    const rolledUp = this.data.reduce((acc, curr, currentIndex) => {
      const key = curr.displayNames.date;
      const valueKey = `${this.keysFn(curr)}-${this.idFn(curr)}`;
      const dateMap = acc.get(key) ?? new Map<string, number>();

      dateMap.set(valueKey, currentIndex);

      if (!acc.has(key)) {
        acc.set(key, dateMap);
      }

      return acc;
    }, new Map<string, Map<string, number>>());
    const series = stack(rolledUp as unknown as Iterable<DivergingChartData>);

    return series.map(s =>
      s.map(d => {
        const data = d.data as unknown as D3Data;

        return Object.assign(d, { i: data[1].get(s.key) });
      }),
    );
  }

  private getMetadataMap() {
    const map = new Map<string, DivergingChartMetadata>();

    this.data.forEach(item => {
      const key = this.groupingFn(item);

      if (!map.has(key)) {
        map.set(key, item.metadata);
      }
    });

    return map;
  }

  private getMinValue(): number {
    return this.data.reduce((a, b) => Math.min(a, b.value), Infinity);
  }

  private createXScale(): d3.ScaleBand<unknown> {
    return d3.scaleBand(this.xDomain, this.xRange).paddingInner(this.yPadding);
  }

  private createYScale(): d3.ScaleLinear<number, number> {
    return this.xType(this.yDomain, [this.yRange[1], this.yRange[0]]).nice();
  }

  private calculateLabelPosition(xScale: d3.ScaleLinear<number, number>, minValue: number): number {
    return xScale(minValue) - this.horizontalScaleConfig.yAxisLabelWidth * this.width - (xScale(0) ?? 0);
  }

  private createXAxis(xScale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
    return d3.axisTop(xScale).ticks(0, this.xFormat);
  }

  private createYAxis(yScale: d3.ScaleBand<unknown>, tickPosition: number): d3.Axis<d3.AxisDomain> {
    return d3
      .axisLeft(yScale as d3.AxisScale<d3.AxisDomain>)
      .tickSize(-tickPosition)
      .tickSizeOuter(0);
  }

  private createDefaultTitle(
    xScale: d3.ScaleLinear<number, number>,
    X: number[],
    Y: string[],
    Z: string[],
  ): (i: number) => string {
    const formatValue = xScale.tickFormat(100, this.xFormat);

    return (i: number) => `${Y[i]}\n${Z[i]}\n${formatValue(X[i])}`;
  }

  private createCustomTitle(): (i: number) => string {
    const O = d3.map(this.data, d => d);

    return (i: number) => this.title(O[i]);
  }

  private renderYAxis(yAxis: d3.Axis<d3.AxisDomain>, labelPosition: number, xScale: d3.ScaleBand<unknown>): void {
    const yAxisSelection = this.svg.select('.y-axis') as Selection<SVGGElement, string, null, undefined>;
    const yAxisElement = yAxisSelection.empty()
      ? this.svg.append('g').classed('axis', true).classed('y-axis', true)
      : yAxisSelection;

    yAxisElement
      .attr('transform', `translate(0,${labelPosition})`) // Adjust the position of the y-axis labels
      .call(yAxis)
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('dy', -3) // Adjust the vertical position of the labels
          .attr('y', x => xScale(x))
          .text((id: string) => {
            return '';
          }),
      )
      .call(g => g.select('.domain').remove());
  }

  private renderXAxis(xAxis: d3.Axis<d3.AxisDomain>): void {
    const xAxisSelection = this.svg.select('.x-axis') as Selection<SVGGElement, string, null, undefined>;
    const xAxisElement = xAxisSelection.empty()
      ? this.svg.append('g').classed('axis', true).classed('x-axis', true)
      : xAxisSelection;

    xAxisElement
      .attr('transform', `translate(${this.marginLeft},0)`) // Adjust the position of the x-axis
      .call(xAxis)
      .call(g =>
        g
          .selectAll('.tick text')
          .classed('clickable', this.isClickable)
          .attr('dy', -3) // Adjust the vertical position of the labels
          .text((id: string) => {
            return '';
          }),
      )
      .call(g => g.select('.domain').remove());
  }

  private renderBars(
    series: (d3.SeriesPoint<DivergingChartData> & { i: number })[][],
    xScale: d3.ScaleBand<unknown>,
    yScale: d3.ScaleLinear<number, number>,
    Y: string[],
  ): void {
    const positiveSeries: (d3.SeriesPoint<DivergingChartData> & { i: number })[][] = [];
    const negativeSeries: (d3.SeriesPoint<DivergingChartData> & { i: number })[][] = [];

    for (const subArr of series) {
      const allPos = subArr.every(([a, b, ...rest]) => [a, b].every(x => x >= 0 || isNaN(x)));
      const allNeg = subArr.every(([a, b, ...rest]) => [a, b].every(x => x <= 0 || isNaN(x)));

      if (allPos) {
        positiveSeries.push(subArr.filter(pair => !pair.some(x => isNaN(x))));
      }

      if (allNeg) {
        negativeSeries.push(subArr.filter(pair => !pair.some(x => isNaN(x))));
      }
    }

    const positiveBar = this.renderPositiveBars(positiveSeries, xScale, yScale, Y);
    const negativeBar = this.renderNegativeBars(negativeSeries, xScale, yScale, Y);

    this.renderLabels(positiveBar, xScale, yScale, Y);
    this.renderLabels(negativeBar, xScale, yScale, Y);

    const metadataMap = this.getMetadataMap();

    this.renderValues(positiveBar, xScale, yScale, Y, metadataMap, this.positiveMetadataKey);
    this.renderValues(negativeBar, xScale, yScale, Y, metadataMap, this.negativeMetadataKey, true);
  }

  private renderPositiveBars(
    positiveSeries: (d3.SeriesPoint<DivergingChartData> & { i: number })[][],
    xScale: d3.ScaleBand<unknown>,
    yScale: d3.ScaleLinear<number, number>,
    Y: string[],
  ) {
    const selection = this.svg.select('.positive-bars');
    const rootElement: Selection<
      SVGGElement,
      d3.Series<{ [p: string]: number }, string>,
      null,
      undefined
    > = selection.empty()
      ? (this.svg.append('g').classed('positive-bars', true) as Selection<
          SVGGElement,
          d3.Series<{ [p: string]: number }, string>,
          null,
          undefined
        >)
      : (selection as Selection<SVGGElement, d3.Series<{ [p: string]: number }, string>, null, undefined>);

    // Select existing g elements
    const g = rootElement.selectAll('g').data(positiveSeries);

    // Enter
    const gEnter = g.enter().append('g');

    // Update
    const mergedG = g.merge(gEnter).attr('fill', () => {
      return this.getCustomColorScale(true);
    });

    // Select existing rects
    const rects = mergedG.selectAll('rect').data(d => d);

    // Enter
    const rectsEnter = rects.enter().append('rect');

    // Update
    const bars = rects
      .merge(rectsEnter)
      .classed('clickable', this.isClickable)
      .classed('single-bar', true)
      .attr('x', ({ i }) => xScale(Y[i]))
      .attr('width', xScale.bandwidth())
      .filter(([y1, y2]) => !Number.isNaN(y1) && !Number.isNaN(y2))
      .attr('height', ([y1, y2]) => Math.abs(yScale(y2) - yScale(y1)))
      .attr('y', ([y1, y2]) => Math.abs(yScale(y2) - MIDDLE_OFFSET));

    // Exit
    rects.exit().remove();

    // Exit
    g.exit().remove();

    if (this.titleMethod) {
      const titleSelection = bars.select('title');
      const title = titleSelection.empty() ? bars.append('title') : titleSelection;

      title.text(({ i }) => this.titleMethod(i));
    }

    return mergedG;
  }

  private renderNegativeBars(
    negativeSeries: (d3.SeriesPoint<DivergingChartData> & { i: number })[][],
    xScale: d3.ScaleBand<unknown>,
    yScale: d3.ScaleLinear<number, number>,
    Y: string[],
  ) {
    const selection = this.svg.select('.negative-bars');
    const rootElement: Selection<
      SVGGElement,
      d3.Series<{ [p: string]: number }, string>,
      null,
      undefined
    > = selection.empty()
      ? (this.svg.append('g').classed('negative-bars', true) as Selection<
          SVGGElement,
          d3.Series<{ [p: string]: number }, string>,
          null,
          undefined
        >)
      : (selection as Selection<SVGGElement, d3.Series<{ [p: string]: number }, string>, null, undefined>);

    // Select existing g elements
    const g = rootElement.selectAll('g').data(negativeSeries);

    // Enter
    const gEnter = g.enter().append('g');

    // Update
    const mergedG = g.merge(gEnter).attr('fill', () => {
      return this.getCustomColorScale(false);
    });

    // Select existing rects
    const rects = mergedG.selectAll('rect').data(d => d);

    // Enter
    const rectsEnter = rects.enter().append('rect');

    // Update
    const bars = rects
      .merge(rectsEnter)
      .classed('clickable', this.isClickable)
      .classed('single-bar', true)
      .attr('x', ({ i }) => xScale(Y[i]))
      .attr('width', xScale.bandwidth())
      .filter(([y1, y2]) => !Number.isNaN(y1) && !Number.isNaN(y2))
      .attr('height', ([y1, y2]) => Math.abs(yScale(y2) - yScale(y1)))
      .attr('y', ([y1, y2]) => Math.abs(yScale(y1) + MIDDLE_OFFSET))
      .attr('transform', ([y1, y2]) => `translate(0, ${yScale(y2) - yScale(y1)})`);

    // Exit
    rects.exit().remove();

    // Exit
    g.exit().remove();

    if (this.titleMethod) {
      const titleSelection = bars.select('title');
      const title = titleSelection.empty() ? bars.append('title') : titleSelection;

      title.text(({ i }) => this.titleMethod(i));
    }

    return mergedG;
  }

  private renderLabels(
    bar: d3.Selection<
      d3.BaseType | SVGGElement,
      (d3.SeriesPoint<DivergingChartData> & { i: number })[],
      SVGGElement,
      unknown
    >,
    xScale: d3.ScaleBand<unknown>,
    yScale: d3.ScaleLinear<number, number>,
    Y: string[],
  ) {
    bar
      .selectAll('.bar-label')
      .data(d => {
        return d.filter(item => item[0] === 0 && !Number.isNaN(item[1]));
      })
      .join('text')
      .classed('bar-label', true)
      .classed('clickable', this.showLabelTooltip)
      .attr('fill', '#000000')
      .filter(([y1, y2]) => !Number.isNaN(y1) && !Number.isNaN(y2))
      .attr('x', ({ i }) => xScale(Y[i]) + xScale.bandwidth() / 2)
      .attr('y', () => yScale(0)) // Center the label vertically
      .text(d => {
        const index = d.i;
        const labelData = this.data[index];

        return this.label ? this.label(labelData) : this.titleMethod(d.i);
      })
      .attr('dy', '0.35em') // Adjust the vertical alignment of the label
      .attr('text-anchor', 'middle'); // Center the label horizontally
  }

  private renderValues(
    bar: d3.Selection<
      d3.BaseType | SVGGElement,
      (d3.SeriesPoint<DivergingChartData> & { i: number })[],
      SVGGElement,
      unknown
    >,
    xScale: d3.ScaleBand<unknown>,
    yScale: d3.ScaleLinear<number, number>,
    Y: string[],
    metadataMap: Map<string, DivergingChartMetadata>,
    metadataKey: string,
    negative?: boolean,
  ) {
    bar
      .selectAll('.bar-value')
      .data(d => d)
      .join('text')
      .classed('bar-value', true)
      .filter(([y1, y2]) => !Number.isNaN(y1) && !Number.isNaN(y2))
      .attr('x', ({ i }) => xScale(Y[i]) + xScale.bandwidth() / 2)
      .attr('y', ([y1, y2]) => {
        return negative ? Math.abs(yScale(y1) + MIDDLE_OFFSET) + 10 : Math.abs(yScale(y2) - MIDDLE_OFFSET) - 10;
      }) // Center the label vertically
      .text((...args) => {
        const [y1, y2] = args[0];
        const result = y1 >= 0 ? y2 : y1;

        const valueFromMetadata = metadataMap.get(
          (args[0] as d3.SeriesPoint<DivergingChartData> & { i: number }).data[0] as string,
        )[metadataKey] as number;

        if (Math.ceil(valueFromMetadata) === Math.ceil(result)) {
          return Number.isNaN(result) ? '' : d3.format(calculateNumberTick(result))(result);
        }
      })
      .attr('dy', '0.35em') // Adjust the vertical alignment of the label
      .attr('text-anchor', 'middle'); // Center the label horizontally
  }

  protected addClickEventListeners(): void {
    const isDisabled = this.isDisabled;
    const isClickable = this.isClickable;
    const isLabelClickable = this.isLabelClickable;
    const cdr = this.cdr;
    const data = this.data;

    const barClick$ = this.barClick;
    const labelClick$ = this.labelClick;
    const outerClick$ = this.click$;
    const destroyed$ = this.destroyed$;
    const filledBar = '#413E4C';

    d3.select(this.hostElement)
      .selectAll('rect')
      .on('mouseup', function (_, d: { data: Record<string, string>; i: number }) {
        if (isDisabled) {
          return;
        }

        if (isClickable) {
          const hoveredElement = this as SVGRectElement;

          const parentElement = hoveredElement.parentNode as SVGGElement;

          const selected = hoveredElement?.getAttribute('fill') === filledBar;

          if (selected) {
            hoveredElement?.removeAttribute('fill');
            barClick$.emit(null);
          } else {
            hoveredElement?.setAttribute('fill', filledBar);

            // getting correct data based on hovered element
            const index = d.i;

            barClick$.emit(data[index]);

            cdr.detectChanges();

            const clickSubscription = outerClick$.pipe(takeUntil(destroyed$)).subscribe((event: MouseEvent) => {
              if (event.target === hoveredElement || event.target === parentElement) {
                return;
              }

              hoveredElement?.removeAttribute('fill');
              clickSubscription.unsubscribe();
            });
          }
        }
      });

    d3.select(this.hostElement)
      .selectAll('.bar-label')
      .on('mouseup', function (_, d: { data: Record<string, string>; i: number }) {
        if (isLabelClickable) {
          const index = d.i;

          labelClick$.emit(data[index]);
        }
      });
  }

  protected addHoverEventListeners(): void {
    const isDisabled = this.isDisabled;
    const showLabelTooltip = this.showLabelTooltip;
    const tooltipConfig = this.tooltipConfig;
    const cdr = this.cdr;
    const data = this.data;

    d3.select(this.hostElement)
      .selectAll('rect')
      .on('mouseover', function (event: MouseEvent, d: { data: Record<string, string>; i: number }) {
        if (isDisabled) {
          return;
        }

        const hoveredElement = this as SVGRectElement;

        hoveredElement.style.strokeWidth = '2px';
        hoveredElement.style.stroke = '#413E4C';

        // getting correct data based on hovered element
        const index = d.i;

        tooltipConfig.set({
          data: { ...data[index], type: DivergingChartTooltipType.Bar },
          positioning: getTooltipPosition(hoveredElement, event),
        });

        cdr.detectChanges();
      })
      .on('mouseleave', function () {
        if (isDisabled) {
          return;
        }

        const hoveredElement = this as SVGRectElement;

        hoveredElement.style.strokeWidth = '';
        hoveredElement.style.stroke = '';

        tooltipConfig.update(config => ({ ...config, data: null }));
        cdr.detectChanges();
      });

    d3.select(this.hostElement)
      .selectAll('.bar-label')
      .on('mouseover', function (event: MouseEvent, d: { data: Record<string, string>; i: number }) {
        if (isDisabled || !showLabelTooltip) {
          return;
        }

        const hoveredElement = this as SVGTextElement;

        // getting correct data based on hovered element
        const index = d.i;

        tooltipConfig.set({
          data: { ...data[index], type: DivergingChartTooltipType.Label },
          positioning: getTooltipPosition(hoveredElement, event),
        });

        cdr.detectChanges();
      })
      .on('mouseleave', function () {
        if (isDisabled || !showLabelTooltip) {
          return;
        }

        tooltipConfig.update(config => ({ ...config, data: null }));
        cdr.detectChanges();
      });
  }

  protected setSizes(margin: { top: number; bottom: number; left: number; right: number }, heightOffset = 0): void {
    super.setSizes(margin, heightOffset);
    this.xRange = [this.marginLeft, this.width];
    this.yRange = [this.marginTop + MIDDLE_OFFSET, this.height - this.marginBottom - MIDDLE_OFFSET];
  }

  private getCustomColorScale(isPositive: boolean): HexColor {
    return isPositive ? this.colors[0] : this.colors[1];
  }
}

function calculateNumberTick(value: number, showDecimals?: boolean): string {
  /**
   *  Method to calculate d3 number format - https://github.com/d3/d3-format#api-reference
   *  ',.2f' => comma-separated, with 2dp. Eg: 12,805.75
   *  ',.2d' => comma-separated integer value. Eg: 12,805
   *  '.3s'  => shorthand-enabled, with 3 significant digits. Eg: 1.06M or 12.5M
   */
  if (showDecimals) {
    return value.toFixed(0).length > 6 ? '.3s' : ',.2f';
  } else {
    return value.toFixed(0).length > 6 ? '.2s' : ',.2d';
  }
}
