import produce from 'immer';
import { catchError, EMPTY, forkJoin, tap, withLatestFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  EntityListDateRangeFilter,
  EntityListState,
  EntityListStateModel,
  EventTime,
  getDateInTimeZone,
  Query,
  QueryBuilder,
} from '@supy/common';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';

import { EventState, InventoryStockCount, InventoryStockCountRequestProps } from '../../core';
import { downloadStockCountsList } from '../../helpers';
import { InventoryStockCountService } from '../../services';
import {
  InventoryStockCountsCreate,
  InventoryStockCountsCreateSub,
  InventoryStockCountsGetFirstEventDate,
  InventoryStockCountsGetLastDate,
  InventoryStockCountsGetLastOpeningCount,
  InventoryStockCountsGetMany,
  InventoryStockCountsInitFilters,
  InventoryStockCountsListExport,
  InventoryStockCountsPatchFilter,
  InventoryStockCountsPatchRequestMeta,
  InventoryStockCountsPatchState,
  InventoryStockCountsResetFilter,
  InventoryStockCountsSetFilter,
} from '../actions';

export interface InventoryStockCountsStateModel extends EntityListStateModel<InventoryStockCount> {
  readonly filters: InventoryStockCountsFilters;
  readonly requestMetadata: InventoryStockCountsRequestMetadata;
  readonly responseMetadata: InventoryStockCountsResponseMetadata;
  readonly lastStockCountDate: Date | null;
  readonly lastOpeningCountDate: Date | null;
  readonly firstEventDate: Date | null;
  readonly exportLoading: boolean;
}

export interface InventoryStockCountsFilters extends EntityListDateRangeFilter {
  readonly name: string | null;
  readonly location: string | null;
  readonly archived: boolean;
}

export interface InventoryStockCountsRequestMetadata {
  readonly limit: number;
  readonly page: number;
  readonly retailerId: string | null;
}

export interface InventoryStockCountsResponseMetadata {
  readonly total: number;
  readonly count: number;
}

const INVENTORY_STOCK_COUNTS_STATE_TOKEN = new StateToken<InventoryStockCountsStateModel[]>('inventoryStockCounts');

const FILTERS_DEFAULT = {
  name: null,
  location: null,
  archived: false,
  start: null,
  end: null,
};

const REQUEST_META_DEFAULT = { page: 0, limit: 50, retailerId: null };
const RESPONSE_META_DEFAULT = { total: 0, count: 0 };

@State<InventoryStockCountsStateModel>({
  name: INVENTORY_STOCK_COUNTS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    lastStockCountDate: null,
    lastOpeningCountDate: null,
    firstEventDate: null,
    exportLoading: false,
  },
})
@Injectable()
export class InventoryStockCountsState extends EntityListState {
  constructor(
    protected readonly router: Router,
    private readonly stockCountService: InventoryStockCountService,
    private readonly store: Store,
  ) {
    super(router);
  }

  @Selector()
  static items(state: InventoryStockCountsStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentItem(state: InventoryStockCountsStateModel) {
    return EntityListState.current(state);
  }

  static item(id: string, next?: boolean) {
    return createSelector([InventoryStockCountsState], (state: InventoryStockCountsStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static appliedFiltersCount(state: InventoryStockCountsStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static filters(state: InventoryStockCountsStateModel) {
    return EntityListState.currentFilters<InventoryStockCountsFilters>(state);
  }

  @Selector()
  static requestMetadata(state: InventoryStockCountsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryStockCountsStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static lastStockCountDate(state: InventoryStockCountsStateModel) {
    return state.lastStockCountDate;
  }

  @Selector()
  static lastOpeningCountDate(state: InventoryStockCountsStateModel) {
    return state.lastOpeningCountDate;
  }

  @Selector()
  static firstEventDate(state: InventoryStockCountsStateModel) {
    return state.firstEventDate;
  }

  @Selector()
  static exportLoading(state: InventoryStockCountsStateModel) {
    return state.exportLoading;
  }

  @Action(InventoryStockCountsInitFilters)
  inventoryStockCountsInitFilter(ctx: StateContext<InventoryStockCountsStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryStockCountsSetFilter)
  setInventoryStockCountsFilter(
    ctx: StateContext<InventoryStockCountsStateModel>,
    action: InventoryStockCountsSetFilter,
  ) {
    super.setFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryStockCountsPatchRequestMeta({ page: 0 }), new InventoryStockCountsGetMany()]);
  }

  @Action(InventoryStockCountsPatchFilter)
  patchInventoryStockCountsFilter(
    ctx: StateContext<InventoryStockCountsStateModel>,
    action: InventoryStockCountsPatchFilter,
  ) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryStockCountsPatchRequestMeta({ page: 0 }), new InventoryStockCountsGetMany()]);
  }

  @Action(InventoryStockCountsResetFilter)
  resetInventoryStockCountsFilter(ctx: StateContext<InventoryStockCountsStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryStockCountsPatchRequestMeta({ page: 0 }), new InventoryStockCountsGetMany()]);
  }

  @Action(InventoryStockCountsGetMany, { cancelUncompleted: true })
  getInventoryStockCounts(ctx: StateContext<InventoryStockCountsStateModel>, { query }: InventoryStockCountsGetMany) {
    return this.stockCountService.getManyBff(query ? query : this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(InventoryStockCountsPatchRequestMeta)
  patchRequestMetadata(
    ctx: StateContext<InventoryStockCountsStateModel>,
    { payload }: InventoryStockCountsPatchRequestMeta,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryStockCountsCreate)
  createStockCount(ctx: StateContext<InventoryStockCountsStateModel>, { payload }: InventoryStockCountsCreate) {
    return this.stockCountService
      .create(payload)
      .pipe(
        tap(({ id, subStockCount }) =>
          this.router.navigateByUrl(`/inventory/stock-counts/details/${id}/sub/${subStockCount.id}`),
        ),
      );
  }

  @Action(InventoryStockCountsCreateSub)
  createStockSubCount(
    ctx: StateContext<InventoryStockCountsStateModel>,
    { id, payload }: InventoryStockCountsCreateSub,
  ) {
    return this.stockCountService
      .createSub(id, payload)
      .pipe(tap(res => this.router.navigateByUrl(`/inventory/stock-counts/details/${id}/sub/${res.id}`)));
  }

  @Action(InventoryStockCountsPatchState)
  patchState(ctx: StateContext<InventoryStockCountsStateModel>, { state }: InventoryStockCountsPatchState) {
    ctx.patchState(state);
  }

  @Action(InventoryStockCountsGetLastDate)
  getLastInLocations(
    ctx: StateContext<InventoryStockCountsStateModel>,
    { locationIds, options }: InventoryStockCountsGetLastDate,
  ) {
    const query = new Query<InventoryStockCount & InventoryStockCountRequestProps>({
      paging: { offset: 0, limit: 1 },
      filtering: [
        { by: 'location.id', op: 'in', match: locationIds },
        { by: 'state', op: 'eq', match: EventState.Submitted },
        { by: 'isOpening', op: 'neq', match: true },
      ],
      ordering: [
        { by: 'eventDate.creationDate', dir: 'desc' },
        { by: 'id', dir: 'desc' },
      ],
    });

    return this.stockCountService.getManyBff(query).pipe(
      tap(({ data }) => {
        if (!data.length) {
          ctx.patchState({ lastStockCountDate: null });
        } else {
          const lastStockCountDate = getDateInTimeZone(
            data[0].eventDate.creationDate,
            this.store.selectSnapshot(SettingsState.ianaTimeZone),
          );

          if (data[0].eventDate.time === EventTime.EndOfDay && !options?.skipTimeCheck) {
            lastStockCountDate.setDate(lastStockCountDate.getDate() + 1);
          }

          ctx.patchState({ lastStockCountDate });
        }
      }),
    );
  }

  @Action(InventoryStockCountsGetLastOpeningCount)
  getLastOpeningCount(
    ctx: StateContext<InventoryStockCountsStateModel>,
    { locationIds }: InventoryStockCountsGetLastOpeningCount,
  ) {
    // TODO: move this logic to BFF
    return forkJoin(locationIds.map(locationId => this.stockCountService.getLastOpeningCount(locationId))).pipe(
      tap(responses => {
        const lastOpeningCount = responses.reduce<Date | null>((acc, cur) => {
          if (cur.lastOpeningCountDate) {
            const lastOpeningCountDate = new Date(cur.lastOpeningCountDate);

            acc = acc ? new Date(Math.max(acc.getTime(), lastOpeningCountDate.getTime())) : lastOpeningCountDate;
          }

          return acc;
        }, null);

        if (!lastOpeningCount) {
          ctx.patchState({ lastOpeningCountDate: null });

          return;
        }

        lastOpeningCount.setDate(lastOpeningCount.getDate() + 1);

        ctx.patchState({
          lastOpeningCountDate: getDateInTimeZone(
            lastOpeningCount,
            this.store.selectSnapshot(SettingsState.ianaTimeZone),
          ),
        });
      }),
    );
  }

  @Action(InventoryStockCountsGetFirstEventDate)
  getFirstEventDate(
    ctx: StateContext<InventoryStockCountsStateModel>,
    { locationId }: InventoryStockCountsGetFirstEventDate,
  ) {
    return this.stockCountService.getFirstEvent(locationId).pipe(
      tap(response => {
        if (!response.firstEventDate) {
          ctx.patchState({ firstEventDate: null });

          return;
        }

        const firstEventDate = new Date(response.firstEventDate);

        firstEventDate.setDate(firstEventDate.getDate() - 1);

        ctx.patchState({
          firstEventDate: getDateInTimeZone(firstEventDate, this.store.selectSnapshot(SettingsState.ianaTimeZone)),
        });
      }),
    );
  }

  @Action(InventoryStockCountsListExport, { cancelUncompleted: true })
  downloadItems(ctx: StateContext<InventoryStockCountsStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.stockCountService.getManyBff(query).pipe(
      withLatestFrom(
        this.store.select(SettingsState.currency),
        this.store.select(SettingsState.ianaTimeZone),
        this.store.select(CurrentRetailerState.locationsWithBranches),
      ),
      tap(([{ data }, currency, ianaTimeZone, branches]) => {
        ctx.patchState({
          exportLoading: false,
        });

        downloadStockCountsList(data, branches, ianaTimeZone);
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  private getQuery(
    state: InventoryStockCountsStateModel,
    options?: { readonly paginationLimit?: number; readonly offset?: number },
  ): Query<InventoryStockCount & InventoryStockCountRequestProps> {
    const { location, start, end, name } = state.filters;

    const qb = new QueryBuilder<InventoryStockCount & InventoryStockCountRequestProps>({
      filtering: [],
      paging: {
        limit: options?.paginationLimit ? options.paginationLimit : state.requestMetadata.limit,
        offset: options?.offset ? options.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        { by: 'createdAt', dir: 'desc' },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (name) {
      qb.filtering.setFilter({
        by: 'subStockCounts.name',
        match: name,
        op: 'like',
      });
    }

    if (location) {
      qb.filtering.setFilter({
        by: 'location.id',
        match: location,
        op: 'eq',
      });
    }

    if (start && end) {
      qb.filtering.withFiltering([
        { by: 'eventDate.creationDate', op: 'gte', match: start },
        { by: 'eventDate.creationDate', op: 'lte', match: end },
      ]);
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    return qb.build();
  }
}
