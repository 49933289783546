import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { IQueryResponse, Query, QueryPaging, Supplier, SupplierState } from '@supy/common';

import { RetailersService } from '../../services';

@Injectable()
export class BranchSuppliersResolver {
  constructor(private readonly retailersService: RetailersService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<IQueryResponse<Supplier>> {
    const query = new Query<Supplier>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'state', op: 'eq', match: SupplierState.Active }],
    });

    return firstValueFrom(this.retailersService.getRetailerUserSuppliers(route.params.id as string, query));
  }
}
