import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import { IconColor, IconSize, IconType } from '../../../icon';

export interface TextWithIconCellRendererContext<TData = unknown> {
  readonly name?: IconType;
  readonly color?: IconColor;
  readonly size?: IconSize;
  readonly text?: string;
  readonly routerLink?: string;
  readonly title?: string;
  readonly iconTitle?: string;
  readonly postfixIcons?: {
    readonly name?: IconType;
    readonly color?: IconColor;
    readonly size?: IconSize;
    readonly title?: string;
  }[];

  callback?: (data: TData, event?: PointerEvent) => void;
}

@Component({
  selector: 'supy-text-with-icon-cell-renderer',
  template: `
    <div class="supy-text-with-icon-renderer__cell">
      <supy-icon
        *ngIf="context?.name"
        [name]="context?.name"
        [color]="context?.color"
        [size]="context?.size"
        [title]="context?.iconTitle"
      ></supy-icon>

      <ng-container *ngIf="context?.routerLink || context?.callback; else text">
        <a [routerLink]="context?.routerLink" (click)="context?.callback ? context.callback(params.data) : null">{{
          context?.text
        }}</a>
      </ng-container>

      <ng-template #text>
        <span *ngIf="context?.text">{{ context.text }}</span>
      </ng-template>

      <supy-icon
        *ngFor="let postfixIcon of context?.postfixIcons"
        [name]="postfixIcon.name"
        [color]="postfixIcon.color"
        [size]="postfixIcon.size ?? 'small'"
        [title]="postfixIcon.title"
      ></supy-icon>
    </div>
  `,
  styleUrls: ['./text-with-icon-cell-renderer.component.scss'],
})
export class TextWithIconCellRendererComponent<TData = unknown> implements ICellRendererAngularComp {
  protected context: TextWithIconCellRendererContext<TData>;
  protected params: ICellRendererParams<TData, unknown, TextWithIconCellRendererContext<TData>>;

  agInit(params: ICellRendererParams<TData, unknown, TextWithIconCellRendererContext<TData>>): void {
    this.context = params.context;
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
