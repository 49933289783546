import { IANATimezone } from '@supy.api/dictionaries';

import {
  getApiDetailsDecorator,
  getDateInTimeZone,
  IdType,
  LocalizedSkeletonObjectType,
  Retailer,
  SimpleUser,
  SkeletonObjectType,
  Supplier,
} from '@supy/common';
import { BadgeStatus } from '@supy/components';
import { NonFunctionProperties } from '@supy/core';

import { AggregatedLinkedOrder, AggregatedOrders, OrderStatus } from '../order';
import {
  GrnBaseRespose,
  GrnDocumentDiscrepancies,
  GrnMetadata,
  GrnResponse,
  GrnStatus,
  GrnTotals,
  GrnType,
  GrnUpdates,
  SimpleGrnResponse,
} from './grn.model';
import { CreditNote, GrnCreditNote } from './grn-credit-note.entity';
import { GrnDocument, GrnSimpleDocument } from './grn-document.entity';
import { GrnCreditNoteItem, GrnItem } from './grn-item.entity';
import { GrnItemType } from './grn-item.model';

export enum GrnUpdateType {
  Drafted = 'drafted',
  Saved = 'saved',
  Pushed = 'pushed',
  Transferred = 'transferred',
  Posted = 'posted',
}

export enum GrnStatisticsCardType {
  UniqueGrns = 'invoices',
  TotalValue = 'total-value',
  PendingDocs = 'pending-docs',
  NegativeInvoices = 'negative-invoices',
  CreditNotes = 'credit-notes',
}

type GrnArgs = Omit<
  Grn,
  | 'closedOn'
  | 'documentDiscrepancies'
  | 'skippedItemCodes'
  | 'hasLinkedOrder'
  | 'hasOpenLinkedOrder'
  | 'isDeliveryNoteType'
  | 'isInvoiceType'
  | 'isDrafted'
  | 'isDisputed'
  | 'isPosted'
  | 'isDiscarded'
  | 'isSaved'
  | 'isIncoming'
  | 'isLocked'
  | 'isPostable'
  | 'isCreditNoteAllocatable'
  | 'isDownloadable'
  | 'isDiscardable'
  | 'isAuto'
  | 'isClosable'
  | 'isSavable'
  | 'isLockable'
  | 'isUnlockable'
  | 'isOnlySavable'
  | 'isLinkedOrderUpdatable'
  | 'isPartialSavable'
  | 'isDisputable'
  | 'isDisputeResolvable'
  | 'isStockUpdatable'
  | 'isDraftable'
  | 'isHighlightable'
>;

const ApiProperty = getApiDetailsDecorator<GrnResponse | SimpleGrnResponse>();

export abstract class BaseGrn {
  protected constructor(args: Omit<NonFunctionProperties<BaseGrn>, 'poNumber'>) {
    this.id = args.id;
    this.supplier = args.supplier;
    this.location = args.location;
    this.linkedOrder = args.linkedOrder ?? null;
    this.outlet = args.outlet;
    this.channel = args.channel;
    this.isSynced = args.isSynced ?? false;
    this.creditIssued = args.creditIssued ?? false;
    this.type = args.type;
    this.metadata = args.metadata ?? null;
    this.totals = args.totals;
    this.status = args.status;

    this.poNumber = this.linkedOrder?.number ?? null;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly supplier?: Supplier;
  @ApiProperty() readonly location?: SkeletonObjectType;
  @ApiProperty({ key: 'linkedOrders' }) readonly linkedOrder: AggregatedLinkedOrder | null;
  @ApiProperty() readonly outlet?: LocalizedSkeletonObjectType;
  @ApiProperty() readonly channel?: IdType;
  @ApiProperty() readonly isSynced?: boolean;
  @ApiProperty() readonly creditIssued?: boolean;
  @ApiProperty() readonly type: GrnType;
  @ApiProperty() readonly metadata: GrnMetadata | null;
  @ApiProperty() readonly totals?: GrnTotals;
  @ApiProperty() readonly status: GrnStatus;

  readonly poNumber?: string;

  protected static deserialize(data: GrnBaseRespose): BaseGrn {
    return {
      id: data.id,
      supplier: data.supplier,
      location: data.location,
      linkedOrder: data.linkedOrders?.at(0),
      outlet: data.outlet,
      channel: data.channel,
      isSynced: data.isSynced,
      creditIssued: data.creditIssued,
      type: data.type,
      metadata: data.metadata,
      totals: data.totals,
      status: data.status,
    };
  }
}

export class SimpleGrn extends BaseGrn {
  private constructor(args: NonFunctionProperties<SimpleGrn>) {
    super(args);
    this.document = args.document;
    this.total = args.total ?? 0;
  }

  @ApiProperty() readonly document?: GrnSimpleDocument;
  @ApiProperty() readonly total?: number;

  static deserialize(data: SimpleGrnResponse): SimpleGrn {
    return new SimpleGrn({
      ...super.deserialize(data),
      document: data.document && GrnSimpleDocument.deserialize(data.document),
      total: data.total,
    });
  }
}

export class Grn extends BaseGrn {
  private constructor(args: NonFunctionProperties<GrnArgs>) {
    super(args);
    this.retailer = args.retailer;
    this.isPreferred = args.isPreferred ?? false;
    this.user = args.user;
    this.updates = args.updates ?? [];
    this.document = args.document;
    this.items = args.items;
    this.comment = args.comment;
    this.creditNotes = args.creditNotes ?? [];
    this.linkedGrns = args.linkedGrns ?? [];
    this.createdAt = args.createdAt ?? new Date();
    this.updatedAt = args.updatedAt;
    this.documentDiscrepancies = args.metadata?.autoGrn?.documentDiscrepancies;
    this.skippedItemCodes = args.metadata?.autoGrn?.skippedItemCodes ?? [];
    this.hasLinkedOrder = this.linkedOrder ? true : false;
    this.hasOpenLinkedOrder = this.hasLinkedOrder && this.linkedOrder.status !== OrderStatus.Received;
    this.closedOn = this.updates.find(update => update.type === GrnUpdateType.Posted)?.createdAt ?? null;

    this.isDeliveryNoteType = this.type === GrnType.DeliveryNote;
    this.isInvoiceType = this.type === GrnType.Invoice;

    this.isIncoming = this.status === GrnStatus.Incoming;
    this.isDisputed = this.status === GrnStatus.Disputed;
    this.isDrafted = this.status === GrnStatus.Draft;
    this.isSaved = this.status === GrnStatus.Saved;
    this.isPosted = this.status === GrnStatus.Posted;
    this.isDiscarded = this.status === GrnStatus.Discarded;
    this.isLocked = this.metadata?.isLocked ?? false;
    this.isAuto = this.isIncoming;

    this.isDisputable =
      this.isInvoiceType &&
      this.status !== GrnStatus.Disputed &&
      GrnStatusTransition[this.status]?.includes(GrnStatus.Disputed);
    this.isDisputeResolvable = this.isDisputed;
    this.isDiscardable = GrnStatusTransition[this.status]?.includes(GrnStatus.Discarded);
    this.isDraftable = !this.id || GrnStatusTransition[this.status]?.includes(GrnStatus.Draft);
    this.isSavable = !this.id || GrnStatusTransition[this.status]?.includes(GrnStatus.Saved);
    this.isLockable = this.isSaved && !this.isLocked;
    this.isUnlockable = this.isSaved && this.isLocked;
    this.isLinkedOrderUpdatable = this.isSavable && this.hasOpenLinkedOrder;
    this.isOnlySavable = this.isSavable && !this.isLinkedOrderUpdatable;
    this.isPartialSavable = this.isLinkedOrderUpdatable;
    this.isClosable = this.isLinkedOrderUpdatable;
    this.isPostable = GrnStatusTransition[this.status]?.includes(GrnStatus.Posted);
    this.isCreditNoteAllocatable = !this.id || ((this.isSaved || this.isDrafted) && !this.isDeliveryNoteType);
    this.isStockUpdatable = !this.isPosted;
    this.isDownloadable = this.isSaved || this.isDisputed || this.isPosted;
    this.isHighlightable = this.isInvoiceType && (this.isIncoming || this.isDisputed || this.isDrafted || this.isSaved);
  }

  @ApiProperty() readonly retailer: Retailer;
  @ApiProperty({ key: 'preferred' }) readonly isPreferred: boolean;
  @ApiProperty() readonly user: SimpleUser;
  @ApiProperty() readonly updates: GrnUpdates[];
  @ApiProperty() readonly document: GrnDocument;
  @ApiProperty() readonly items: Array<GrnItem | GrnCreditNoteItem>;
  @ApiProperty() readonly comment: string | null;
  @ApiProperty() readonly creditNotes: GrnCreditNote[];
  @ApiProperty() readonly linkedGrns: IdType[];
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly updatedAt: Date;
  readonly documentDiscrepancies: GrnDocumentDiscrepancies;
  readonly skippedItemCodes: string[];
  readonly closedOn: Date | null;
  readonly isDeliveryNoteType: boolean;
  readonly isInvoiceType: boolean;
  readonly isDrafted: boolean;
  readonly isDisputed: boolean;
  readonly isPosted: boolean;
  readonly isDiscarded: boolean;
  readonly isIncoming: boolean;
  readonly isSaved: boolean;
  readonly isLocked: boolean;
  readonly isPostable: boolean;
  readonly isCreditNoteAllocatable: boolean;
  readonly isDownloadable: boolean;
  readonly isDiscardable: boolean;
  readonly isAuto: boolean;
  readonly isClosable: boolean;
  readonly isSavable: boolean;
  readonly isLockable: boolean;
  readonly isUnlockable: boolean;
  readonly isOnlySavable: boolean;
  readonly isLinkedOrderUpdatable: boolean;
  readonly isPartialSavable: boolean;
  readonly isDisputable: boolean;
  readonly isDisputeResolvable: boolean;
  readonly isStockUpdatable: boolean;
  readonly isDraftable: boolean;
  readonly hasLinkedOrder: boolean;
  readonly hasOpenLinkedOrder: boolean;
  readonly isHighlightable: boolean;

  static deserialize(data: GrnResponse): Grn {
    const items = data.items.map(item =>
      item.type === GrnItemType.Item ? GrnItem.deserialize(item) : GrnCreditNoteItem.deserialize(item),
    );

    return new Grn({
      ...super.deserialize(data),
      retailer: data.retailer,
      isPreferred: data.preferred,
      user: data.user,
      updates: data.updates,
      document: GrnDocument.deserialize(data.document),
      linkedOrder: data.linkedOrders?.at(0),
      items: items,
      comment: data.comment,
      creditNotes: data.creditNotes.map(creditNote => GrnCreditNote.deserialize(creditNote)),
      linkedGrns: data.linkedGrns,
      createdAt: data.createdAt ?? new Date(),
      updatedAt: data.updatedAt,
    });
  }

  static deserializeExisting({ document, ianaTimeZone, items, channel, ...props }: GrnFromExistingArgs): Grn {
    return new Grn({
      ...props,
      ...(document && {
        document: {
          ...document,
          documentDate: getDateInTimeZone(document.documentDate, ianaTimeZone),
          paymentDueDate: getDateInTimeZone(document.paymentDueDate, ianaTimeZone),
        },
      }),
      channel,
      items: items.map(item =>
        item.type === GrnItemType.Item ? { ...item, prices: { ...item.prices, updatePrice: false } } : item,
      ),
    });
  }

  static deserializeFromReceivingOrders({
    supplier,
    location,
    linkedOrders,
    channel,
    items,
    outlet,
  }: GrnFromReceivingOrdersArgs): Grn {
    return new Grn({
      ...this.commonDefaultProps,
      supplier,
      location,
      items,
      linkedOrder: linkedOrders.at(0),
      channel,
      outlet,
    });
  }

  static deserializeFromReceivingWithoutOrder({
    supplier,
    location,
    outlet,
    channel,
  }: GrnFromReceivingWithoutOrderArgs): Grn {
    return new Grn({
      ...this.commonDefaultProps,
      supplier,
      location,
      channel,
      outlet,
      items: [],
      linkedOrder: null,
    });
  }

  static deserializeFromReceivingCreditNotes({ creditNotes, channel }: GrnFromReceivingCreditNotesArgs): Grn {
    const { supplier, location } = creditNotes.at(0);

    return new Grn({
      ...this.commonDefaultProps,
      supplier,
      location,
      channel,
      items: creditNotes.map(creditNote => GrnCreditNoteItem.fromCreditNote(creditNote)),
      linkedOrder: null,
      outlet: null,
    });
  }

  private static get commonDefaultProps(): GrnCommonDefaultProps {
    return {
      id: null,
      comment: null,
      createdAt: null,
      creditIssued: null,
      creditNotes: null,
      document: null,
      isSynced: false,
      linkedGrns: [],
      metadata: null,
      isPreferred: false,
      retailer: null,
      status: null,
      totals: null,
      type: null,
      updatedAt: null,
      updates: null,
      user: null,
    };
  }
}

export const GrnTypeMap: Record<GrnType, string> = {
  [GrnType.Invoice]: $localize`:@@invoice:Invoice`,
  [GrnType.DeliveryNote]: $localize`:@@deliveryNote:Delivery Note`,
  [GrnType.ConsolidatedInvoice]: $localize`:@@grns.list.typeMapping.consolidatedInvoice:Consolidated Invoice`,
};

export const GrnStatusTransition: Record<GrnStatus, GrnStatus[]> = {
  [GrnStatus.Incoming]: [GrnStatus.Disputed, GrnStatus.Draft, GrnStatus.Saved, GrnStatus.Discarded],
  [GrnStatus.Disputed]: [GrnStatus.Disputed, GrnStatus.Saved],
  [GrnStatus.Draft]: [GrnStatus.Disputed, GrnStatus.Draft, GrnStatus.Saved, GrnStatus.Discarded],
  [GrnStatus.Saved]: [GrnStatus.Saved, GrnStatus.Posted],
  [GrnStatus.Posted]: [],
  [GrnStatus.Discarded]: [],
};

export const GrnStatusNameMapper: Record<GrnStatus, string> = {
  discarded: $localize`:@@statusDiscarded:Discarded`,
  disputed: $localize`:@@statusDisputed:Disputed`,
  draft: $localize`:@@statusDrafted:Drafted`,
  pending: $localize`:@@incoming:Incoming`,
  posted: $localize`:@@posted:Posted`,
  saved: $localize`:@@saved:Saved`,
};

export type GrnStatusWithNew = GrnStatus | 'new';

export const GrnStatusBadgeMapper: Record<GrnStatusWithNew, BadgeStatus> = {
  [GrnStatus.Incoming]: 'primary',
  [GrnStatus.Disputed]: 'warn',
  [GrnStatus.Draft]: 'grey',
  [GrnStatus.Saved]: 'info',
  [GrnStatus.Posted]: 'success',
  [GrnStatus.Discarded]: 'light-error',
  new: 'primary',
};

interface GrnFromExistingArgs extends Grn {
  readonly ianaTimeZone: IANATimezone;
  readonly channel: IdType;
}
interface GrnFromReceivingCreditNotesArgs {
  readonly creditNotes: CreditNote[];
  readonly channel: IdType;
}

type GrnFromReceivingOrdersArgs = Pick<
  AggregatedOrders,
  'supplier' | 'location' | 'linkedOrders' | 'items' | 'outlet'
> & {
  readonly channel: IdType;
};
type GrnFromReceivingWithoutOrderArgs = Pick<Grn, 'location' | 'supplier' | 'outlet' | 'channel'>;
type GrnCommonDefaultProps = Omit<GrnArgs, 'supplier' | 'location' | 'items' | 'outlet' | 'channel' | 'linkedOrder'>;
