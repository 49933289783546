import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  ButtonModule,
  GridModule,
  IconModule,
  ImageModule,
  InputModule,
  LoadingOverlayModule,
  SelectModule,
} from '@supy/components';

import { TenantModule } from '../tenant';
import { AccountingMappingGridComponent, AccountingMappingMenuComponent } from './components';

@NgModule({
  declarations: [AccountingMappingGridComponent, AccountingMappingMenuComponent],
  exports: [AccountingMappingGridComponent, AccountingMappingMenuComponent],
  imports: [
    CommonModule,
    ButtonModule,
    GridModule,
    IconModule,
    ImageModule,
    InputModule,
    LoadingOverlayModule,
    SelectModule,
    TenantModule,
  ],
})
export class AccountingIntegrationModule {}
