import { takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { Attachment, Destroyable, getRetailerTimeZoneHelperMessage, LocalFile, SnackbarService } from '@supy/common';
import { DropdownTreeNode, RemoveFileEvent } from '@supy/components';

import { InventoryEventDetailsRequest, InventoryEventType, InventoryTransferDetailsRequest } from '../../core';

@Component({
  selector: 'supy-inventory-event-details',
  templateUrl: './inventory-event-details.component.html',
  styleUrls: ['./inventory-event-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InventoryEventDetailsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InventoryEventDetailsComponent),
      multi: true,
    },
  ],
})
export class InventoryEventDetailsComponent<T extends InventoryEventDetailsRequest>
  extends Destroyable
  implements ControlValueAccessor, Validator, OnInit
{
  @Input() readonly locations: DropdownTreeNode<string>[];
  @Input() readonly toLocations: DropdownTreeNode<string>[];
  @Input() readonly lastStockCountDate: Date | null;
  @Input() readonly utcOffset: number;
  @Input() readonly minDate: Date | null;
  @Input() readonly maxDate: Date;
  @Input() readonly creationDisabled: boolean;
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() readonly totalItems: number;
  @Input() readonly totalAmount: number;
  @Input() set editMode(value: boolean) {
    this.#editMode = value;
    this.checkForFormDisable();
  }

  get editMode(): boolean {
    return this.#editMode;
  }

  @Input() set isReadonly(value: boolean) {
    this.#isReadonly = value;
    this.checkForFormDisable();
  }

  get isReadonly(): boolean {
    return this.#isReadonly;
  }

  @Input() set canReceive(value: boolean) {
    this.#canReceive = value;
    this.checkForFormDisable();
  }

  get canReceive(): boolean {
    return this.#canReceive;
  }

  @Input() set eventType(value: InventoryEventType) {
    if (value) {
      this.#eventType = value;
      this.adjustFormByEventType(value);
    }
  }

  get eventType(): InventoryEventType {
    return this.#eventType;
  }

  @Output() readonly locationChanged = new EventEmitter<string | null>();
  @Output() readonly fromToLocationsChanged = new EventEmitter<string[]>();
  @Output() readonly dateChanged = new EventEmitter<Date | null>();

  protected readonly inventoryEventType = InventoryEventType;
  protected readonly getRetailerTimeZoneHelperMessage = getRetailerTimeZoneHelperMessage;
  protected readonly form = new UntypedFormGroup({
    eventDate: new UntypedFormControl(null, Validators.required),
    remarks: new UntypedFormControl(null),
    attachments: new UntypedFormControl([]),
    localFiles: new UntypedFormControl([]),
  });

  #eventType: InventoryEventType;
  #editMode: boolean;
  #isReadonly: boolean;
  #canReceive: boolean;

  onTouched: () => void;
  onChange: (value: T) => void;

  get filesControl(): AbstractControl | null {
    return this.form.get('attachments');
  }

  get localFilesControl(): AbstractControl | null {
    return this.form.get('localFiles');
  }

  get fileUrls(): string[] {
    return ((this.filesControl?.value ?? []) as Attachment[]).map(({ signedUrl }) => signedUrl);
  }

  get localFiles(): LocalFile[] {
    return (this.localFilesControl?.value ?? []) as LocalFile[];
  }

  constructor(private readonly snackbarService: SnackbarService) {
    super();
  }

  ngOnInit(): void {
    this.initData();
    this.checkForFormDisable();
  }

  writeValue(value: T): void {
    if (value) {
      this.form.patchValue(value);
    }
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  validate(): ValidationErrors | null {
    if (this.form.invalid) {
      return { valid: false };
    }

    return null;
  }

  getFormRawValue<T>(): T {
    return this.form.getRawValue() as T;
  }

  protected checkForFormDisable(): void {
    let controlsToDisable: Set<string>;

    if (this.isReadonly) {
      controlsToDisable = new Set(['eventDate', 'remarks', 'name', 'attachments']);
      this.form.disable();
    } else {
      controlsToDisable = new Set();
      this.form.enable();
    }

    if (this.editMode) {
      controlsToDisable.add('location.id');
      controlsToDisable.add('fromLocation.id');
      controlsToDisable.add('fromLocation.name');
      controlsToDisable.add('toLocation.id');
      controlsToDisable.add('toLocation.name');
    }

    if (this.canReceive) {
      controlsToDisable.add('eventDate');
    }

    for (const control of controlsToDisable) {
      this.form.get(control)?.disable();
    }
  }

  protected onSelectLocation(locationId: string | null): void {
    const toLocationId = (this.form.value as InventoryTransferDetailsRequest)?.toLocation?.id;

    if (locationId && toLocationId) {
      this.fromToLocationsChanged.emit([locationId, toLocationId]);

      if (locationId === toLocationId) {
        this.showSameLocationTransferErrorMessage();
        this.form.get(['fromLocation', 'id'])?.setValue(null);
      }
    } else {
      this.locationChanged.emit(locationId);
    }
  }

  protected onSelectToLocation(locationId: string | null): void {
    const fromLocationId = (this.form.value as InventoryTransferDetailsRequest)?.fromLocation?.id;

    if (locationId && fromLocationId) {
      this.fromToLocationsChanged.emit([fromLocationId, locationId]);

      if (locationId === fromLocationId) {
        this.showSameLocationTransferErrorMessage();
        this.form.get(['toLocation', 'id'])?.setValue(null);
      }
    }
  }

  protected onFilesUploaded(files: LocalFile[]): void {
    this.localFilesControl?.setValue([...this.localFiles, ...files]);
    this.localFilesControl?.updateValueAndValidity();
  }

  protected onFileRemoved(event: RemoveFileEvent): void {
    if (this.localFiles.findIndex(({ objectUrl }) => objectUrl === event.fileUrl) > -1) {
      const filteredLocalFiles = this.localFiles.filter(({ objectUrl }) => objectUrl !== event.fileUrl);

      this.localFilesControl?.setValue(filteredLocalFiles);
      this.localFilesControl?.updateValueAndValidity();

      return;
    }

    const filteredFiles = (this.filesControl?.value as Attachment[]).filter(
      ({ signedUrl }) => signedUrl !== event.fileUrl,
    );

    this.filesControl?.setValue(filteredFiles);
    this.filesControl?.updateValueAndValidity();
  }

  private adjustFormByEventType(eventType: InventoryEventType): void {
    if (eventType === InventoryEventType.Transfer) {
      this.form.addControl(
        'fromLocation',
        new UntypedFormGroup({
          id: new UntypedFormControl(null, Validators.required),
          name: new UntypedFormControl({ value: null, disabled: true }),
        }),
      );
      this.form.addControl(
        'toLocation',
        new UntypedFormGroup({
          id: new UntypedFormControl(null, Validators.required),
          name: new UntypedFormControl({ value: null, disabled: true }),
        }),
      );
    } else {
      this.form.addControl('name', new UntypedFormControl(null));
      this.form.addControl('location', new UntypedFormGroup({ id: new UntypedFormControl(null) }));
    }
  }

  private showSameLocationTransferErrorMessage(): void {
    this.snackbarService.open(
      $localize`:@@inventory.event.details.showSameLocationTransferErrorMessage:You can't transfer to the same location`,
      { variant: 'error' },
    );
  }

  private initData(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.onChange?.(value as T);
    });
  }
}
