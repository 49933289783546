import * as d3 from 'd3';
import { select } from 'd3';
import { Selection } from 'd3-selection';
import { Subject, takeUntil } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { HexColor } from '@supy/common';

import { calculateNumberTick, ChartBaseDirective, getTooltipPosition } from '../../../chart-core';
import { DivergingChartData, DivergingChartDisplayNames } from '../../core';
import { HorizontalScaleConfig } from '../stacked-bar-chart-d3';

type D3Data = [string, d3.InternMap<string, number>];
const LABEL_OFFSET = 10;

@Component({
  selector: 'supy-diverging-stacked-bar-chart',
  templateUrl: './diverging-stacked-bar-chart.component.html',
  styleUrls: ['./diverging-stacked-bar-chart.component.scss'],
})
export class DivergingStackedBarChartComponent extends ChartBaseDirective<DivergingChartData> implements OnDestroy {
  @Input() readonly data: DivergingChartData[] = [];
  @Input() title: (d: DivergingChartData) => string; // given d in data, returns the title text
  @Input() readonly isLabelClickable: boolean = true;
  @Input() readonly labelOffset: number = LABEL_OFFSET;
  @Input() readonly maxBars: number;

  @Input() set horizontalScaleConfig(value: HorizontalScaleConfig) {
    if (value) {
      this.#horizontalScaleConfig = value;
      this.createChart();
    }
  }

  get horizontalScaleConfig(): HorizontalScaleConfig {
    return this.#horizontalScaleConfig;
  }

  @HostBinding('class.supy-diverging-stacked-bar-chart__vertical-scroll')
  @Input()
  protected set hasVerticalScroll(scrollable: boolean) {
    if (scrollable) {
      this.barsAmount = this.data.length || 0;
      this.createChart();
    }
    this.#hasVerticalScroll = scrollable;
  }

  protected get hasVerticalScroll(): boolean {
    return this.#hasVerticalScroll;
  }

  #hasVerticalScroll = false;

  @Output()
  readonly barClick = new EventEmitter<DivergingChartData>();

  @Output()
  readonly labelClick = new EventEmitter<DivergingChartData>();

  #horizontalScaleConfig: HorizontalScaleConfig = {
    yAxisLabelWidth: 0.15,
    barWidth: 0.75,
  };

  private titleMethod: (i: number) => string;
  private readonly click$ = new Subject<MouseEvent>();

  protected readonly marginTop = 30; // top margin, in pixels
  protected readonly marginRight = 0; // right margin, in pixels
  protected readonly marginBottom = 0; // bottom margin, in pixels
  protected readonly marginLeft = 50; // left margin, in pixels
  private readonly xType = d3.scaleLinear; // type of x-scale
  private xDomain: [number, number]; // [xmin, xmax]
  private xRange: [number, number] = [this.marginLeft, this.width - this.marginRight]; // [left, right]
  private yDomain: d3.InternSet; // array of y-values
  private yRange: [number, number]; // [bottom, top]
  private readonly yPadding: number = 0.3; // amount of y-range to reserve to separate bars
  @Input() keys: string[]; // array of z-values
  private readonly offset = d3.stackOffsetDiverging; // stack offset method
  private readonly xFormat: string; // a format specifier string for the x-axis
  @Input() private readonly colors: [HexColor, HexColor] = ['#009BCF', '#001A92'];
  private readonly displayNamesMap = new Map<string, DivergingChartDisplayNames>();

  #keys: d3.InternSet;
  private readonly x = (d: DivergingChartData) => d.value; // given d in data, returns the (quantitative) x-value
  private readonly y = (d: DivergingChartData, i?) => d.id; // given d in data, returns the (ordinal) y-value
  private readonly z = (d: DivergingChartData) => d.category; // given d in data, returns the (categorical) z-value

  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(elementRef, cdr);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.removeExistingChartFromParent();
  }

  @HostListener('document:click', ['$event'])
  protected onClick(event: MouseEvent): void {
    this.click$.next(event);
  }

  private removeExistingChartFromParent(): void {
    d3.select(this.hostElement).select('svg').remove();
  }

  protected initializeSVG(): void {
    this.yDomain = undefined;
    this.xDomain = undefined;
    this.yRange = undefined;
    this.xRange = [this.marginLeft, this.width - this.marginRight];
    super.initializeSVG();
  }

  protected renderChart() {
    const X = this.getXValues();
    const Y = this.getYValues();
    const Z = this.getZValues();

    this.populateDisplayNamesMap();

    if (this.yDomain === undefined) {
      // sort by lowest first
      const sortedData = d3.groupSort(
        this.data,
        D => d3.sum(D, d => -Math.min(0, d.value)),
        d => d.id,
      );

      this.yDomain = new d3.InternSet(
        this.hasVerticalScroll ? sortedData : sortedData.slice(0, this.maxBars ?? (this.data.length || 0)),
      );
    }

    if (this.keys === undefined || !this.keys?.length) {
      this.#keys = new d3.InternSet(Z);
    } else {
      this.#keys = new d3.InternSet(this.keys);
    }

    this.yDomain = new d3.InternSet(this.yDomain);
    this.#keys = new d3.InternSet(this.#keys);

    const I = this.filterData(X, Y, Z);

    if (this.height === undefined) {
      this.height = this.calculateHeight();
    }

    if (this.yRange === undefined) {
      this.yRange = [this.height - this.marginBottom, this.marginTop];
    }

    const series = this.computeSeries(I, X, Y, Z);

    if (this.xDomain === undefined) {
      this.xDomain = d3.extent(series.flat(2));
    }

    const minValue = this.getMinValue();

    const xScale = this.createXScale();
    const yScale = this.createYScale();
    const color = this.createColorScale();
    const labelPosition = this.calculateLabelPosition(xScale, minValue);
    const xAxis = this.createXAxis(xScale);
    const yAxis = this.createYAxis(yScale, labelPosition);
    const yAxisNegative = this.createYAxisNegative(yScale);
    const yAxisPositive = this.createYAxisPositive(yScale);

    if (this.title === undefined) {
      this.titleMethod = this.createDefaultTitle(xScale, X, Y, Z);
    } else {
      this.titleMethod = this.createCustomTitle();
    }

    this.svg = this.createSVGElement();

    this.renderYAxis(yAxis, xScale);
    this.renderXAxis(xAxis);
    this.renderBars(series, xScale, yScale, color, yAxisNegative, yAxisPositive, Y, Z);
  }

  private getXValues(): number[] {
    return d3.map(this.data, this.x);
  }

  private getYValues(): string[] {
    return d3.map(this.data, this.y);
  }

  private getZValues(): string[] {
    return d3.map(this.data, this.z);
  }

  private populateDisplayNamesMap(): void {
    this.displayNamesMap.clear();
    this.data.forEach(data => {
      const key = this.y(data);

      if (!this.displayNamesMap.has(key)) {
        this.displayNamesMap.set(key, data.displayNames);
      }
    });
  }

  private filterData(X: number[], Y: string[], Z: string[]): number[] {
    return d3.range(X.length).filter(i => this.yDomain.has(Y[i]) && this.#keys.has(Z[i]));
  }

  private calculateHeight(): number {
    return this.yDomain.size * 25 + this.marginTop + this.marginBottom;
  }

  private computeSeries(
    I: number[],
    X: number[],
    Y: string[],
    Z: string[],
  ): (d3.SeriesPoint<DivergingChartData> & { i: number })[][] {
    return d3
      .stack<DivergingChartData>()
      .keys(this.#keys)
      .value((data, z) => {
        const [, I] = data as unknown as D3Data;

        return X[I.get(z)];
      })
      .order(() => this.data.map((_, index) => index))
      .offset(this.offset)(
        d3.rollup(
          I,
          ([i]) => i,
          i => Y[i],
          i => Z[i],
        ) as unknown as Iterable<DivergingChartData>,
      )
      .map(s =>
        s.map(d => {
          const data = d.data as unknown as D3Data;

          return Object.assign(d, { i: data[1].get(s.key) });
        }),
      );
  }

  private getMinValue(): number {
    return this.data.reduce((a, b) => Math.min(a, b.value), Infinity);
  }

  private createXScale(): d3.ScaleLinear<number, number> {
    return this.xType(this.xDomain, this.xRange);
  }

  private createYScale(): d3.ScaleBand<unknown> {
    return d3.scaleBand(this.yDomain, this.yRange).paddingInner(this.yPadding).paddingOuter(0.5);
  }

  private createColorScale(): d3.ScaleOrdinal<unknown, string> {
    return d3.scaleOrdinal(this.#keys, this.colors);
  }

  private calculateLabelPosition(xScale: d3.ScaleLinear<number, number>, minValue: number): number {
    return xScale(minValue) - this.horizontalScaleConfig.yAxisLabelWidth * this.width - xScale(0);
  }

  private createXAxis(xScale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
    return d3.axisTop(xScale).ticks(0, this.xFormat);
  }

  private createYAxis(yScale: d3.ScaleBand<unknown>, tickPosition: number): d3.Axis<unknown> {
    return d3
      .axisLeft(yScale as d3.AxisScale<d3.AxisDomain>)
      .tickSize(-tickPosition)
      .tickSizeOuter(0);
  }

  private createYAxisNegative(yScale: d3.ScaleBand<unknown>): d3.Axis<unknown> {
    return d3
      .axisLeft(yScale as d3.AxisScale<d3.AxisDomain>)
      .ticks(0)
      .tickSize(0);
  }

  private createYAxisPositive(yScale: d3.ScaleBand<unknown>): d3.Axis<unknown> {
    return d3
      .axisLeft(yScale as d3.AxisScale<d3.AxisDomain>)
      .ticks(0)
      .tickSize(0);
  }

  private createDefaultTitle(
    xScale: d3.ScaleLinear<number, number>,
    X: number[],
    Y: string[],
    Z: string[],
  ): (i: number) => string {
    const formatValue = xScale.tickFormat(100, this.xFormat);

    return (i: number) => `${Y[i]}\n${Z[i]}\n${formatValue(X[i])}`;
  }

  private createCustomTitle(): (i: number) => string {
    const O = d3.map(this.data, d => d);

    return (i: number) => this.title(O[i]);
  }

  protected createSVGElement(): Selection<SVGSVGElement, unknown, null, undefined> {
    return (
      this.svg ??
      select(this.hostElement)
        .classed('svg-container', true)
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height)
        .attr('viewBox', [0, 0, this.width, this.height])
        .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
    );
  }

  private renderYAxis(yAxis: d3.Axis<unknown>, xScale: d3.ScaleLinear<number, number>): void {
    const yAxisSelection = this.svg.select('.y-axis') as Selection<SVGGElement, string, null, undefined>;
    const yAxisElement = yAxisSelection.empty()
      ? this.svg.append('g').classed('axis', true).classed('y-axis', true)
      : yAxisSelection;

    yAxisElement
      .attr('transform', `translate(${xScale(0)},0)`)
      .call(yAxis)
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('dx', -3)
          .attr('fill', 'transparent')
          .attr('x', _ => -Math.abs(xScale(0)))
          .text((id: string) => {
            const label = this.displayNamesMap.get(id);

            return getLabel(label);
          }),
      )
      .call(g => g.selectAll('.tick').classed('label-tick', true))
      .call(g => g.attr('text-anchor', 'start'));

    const ticks = yAxisElement.selectAll('.tick');

    const ticksArray = [...ticks];

    ticksArray.forEach((tick: SVGGElement) => {
      const d3Tick = d3.select(tick);
      const foreignObjectSelect: d3.Selection<SVGForeignObjectElement, unknown, null, undefined> =
        d3Tick.select('foreignObject');

      const foreignObject = foreignObjectSelect.empty() ? d3Tick.append('foreignObject') : foreignObjectSelect;

      const elem = foreignObject
        .attr('text-anchor', 'start')
        .attr('x', _ => -Math.abs(xScale(0)))
        .attr('y', -6)
        .attr('height', 14)
        .attr('width', this.getLabelWidth())
        .style('width', this.getLabelWidth());

      const divSelect = elem.select('div');

      const div = divSelect.empty() ? elem.append('xhtml:div') : divSelect;

      div
        .text((id: string) => {
          const label = this.displayNamesMap.get(id);

          return getLabel(label);
        })
        .classed('clickable', this.isLabelClickable)
        .style('overflow', 'hidden')
        .classed('label-tick', true)
        .classed('custom-tick', true)
        .attr('title', (id: string) => {
          const label = this.displayNamesMap.get(id);

          return getLabel(label);
        });
    });
  }

  private renderXAxis(xAxis: d3.Axis<d3.NumberValue>): void {
    const xAxisSelection = this.svg.select('.x-axis') as Selection<SVGGElement, string, null, undefined>;
    const xAxisElement = xAxisSelection.empty()
      ? this.svg.append('g').classed('axis', true).classed('x-axis', true)
      : xAxisSelection;

    xAxisElement
      .attr('transform', `translate(0,${this.marginTop})`)
      .call(xAxis)
      .call(g => g.select('.domain').remove());
  }

  private renderBars(
    series: (d3.SeriesPoint<DivergingChartData> & { i: number })[][],
    xScale: d3.ScaleLinear<number, number>,
    yScale: d3.ScaleBand<unknown>,
    color: d3.ScaleOrdinal<unknown, string>,
    yAxisNegative: d3.Axis<unknown>,
    yAxisPositive: d3.Axis<unknown>,
    Y: string[],
    Z: string[],
  ): void {
    const selection = this.svg.select('.bars');
    const rootElement: Selection<
      SVGGElement,
      d3.Series<{ [p: string]: number }, string>,
      null,
      undefined
    > = selection.empty()
      ? (this.svg.append('g').classed('bars', true) as Selection<
          SVGGElement,
          d3.Series<{ [p: string]: number }, string>,
          null,
          undefined
        >)
      : (selection as Selection<SVGGElement, d3.Series<{ [p: string]: number }, string>, null, undefined>);

    // Select existing g elements
    const g = rootElement.selectAll('g').data(series);

    // Enter
    const gEnter = g.enter().append('g');

    // Update
    const mergedG = g.merge(gEnter).attr('fill', ([data]) => data && color(Z[data.i]));

    // Select existing rects
    const rects = mergedG.selectAll('rect').data(d => d);

    // Enter
    const rectsEnter = rects.enter().append('rect');

    // Update
    const bars = rects
      .merge(rectsEnter)
      .classed('clickable', this.isClickable)
      .attr('x', ([x1, x2]) => Math.min(xScale(x1), xScale(x2)))
      .attr('y', ({ i }) => yScale(Y[i]))
      .attr('width', ([x1, x2]) => Math.abs(xScale(x1) - xScale(x2)))
      .attr('height', yScale.bandwidth());

    // Exit
    rects.exit().remove();

    // Exit
    g.exit().remove();

    if (this.titleMethod) {
      const titleSelection = bars.select('title');
      const title = titleSelection.empty() ? bars.append('title') : titleSelection;

      title.text(({ i }) => this.titleMethod(i));
    }

    const negativeAxisSelection = this.svg.select('.negative-bars') as Selection<SVGGElement, string, null, undefined>;
    const negativeAxisElement = negativeAxisSelection.empty()
      ? this.svg.append('g').classed('negative-bars', true)
      : negativeAxisSelection;

    negativeAxisElement
      .attr('transform', `translate(${xScale(0)},0)`)
      .call(yAxisNegative)
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('dx', -3)
          .attr('x', y => {
            const x = d3.min(series, S => S.find(d => Y[d.i] === y)?.[0]);

            const size = xScale(x) - xScale(0);

            return size < 20 ? size : size - 20;
          })
          .text(y => {
            const result = d3.min(series, S => S.find(d => Y[d.i] === y)?.[0]);

            return Number.isNaN(result) ? '' : d3.format(calculateNumberTick(result))(result);
          }),
      );

    const labelOffset = this.labelOffset;

    const positiveAxisSelection = this.svg.select('.positive-bars') as Selection<SVGGElement, string, null, undefined>;
    const positiveAxisElement = positiveAxisSelection.empty()
      ? this.svg.append('g').classed('positive-bars', true)
      : positiveAxisSelection;

    positiveAxisElement
      .attr('transform', `translate(${xScale(0)},0)`)
      .call(yAxisPositive)
      .call(g =>
        g
          .selectAll('.tick text')
          .attr('dx', -3)
          .attr('x', y => {
            const x = d3.max(series, S => S.find(d => Y[d.i] === y)?.[1]);

            return xScale(x) - xScale(0);
          })
          .text(y => {
            const result = d3.max(series, S => S.find(d => Y[d.i] === y)?.[1]);

            return Number.isNaN(result) ? '' : d3.format(calculateNumberTick(result))(result);
          })
          .attr('transform', function () {
            const selectedElement = this as SVGTextElement;

            return `translateX(${selectedElement.getBoundingClientRect().width + labelOffset}px)`;
          })
          .style('transform', function () {
            const selectedElement = this as SVGTextElement;

            return `translateX(${selectedElement.getBoundingClientRect().width + labelOffset}px)`;
          }),
      );
  }

  protected addClickEventListeners(): void {
    const isDisabled = this.isDisabled;
    const isClickable = this.isClickable;
    const cdr = this.cdr;
    const data = this.data;

    const barClick$ = this.barClick;
    const outerClick$ = this.click$;
    const destroyed$ = this.destroyed$;
    const filledBar = '#413E4C';

    d3.select(this.hostElement)
      .selectAll('rect')
      .on('mouseup', function (_, d: { data: Record<string, string>; i: number }) {
        if (isDisabled) {
          return;
        }

        if (isClickable) {
          const hoveredElement = this as SVGRectElement;

          const parentElement = hoveredElement.parentNode as SVGGElement;

          const selected = hoveredElement?.getAttribute('fill') === filledBar;

          if (selected) {
            hoveredElement?.removeAttribute('fill');
            barClick$.emit(null);
          } else {
            hoveredElement?.setAttribute('fill', filledBar);

            // getting correct data based on hovered element
            const index = d.i;

            barClick$.emit(data[index]);

            cdr.detectChanges();

            const clickSubscription = outerClick$.pipe(takeUntil(destroyed$)).subscribe((event: MouseEvent) => {
              if (event.target === hoveredElement || event.target === parentElement) {
                return;
              }

              hoveredElement?.removeAttribute('fill');
              clickSubscription.unsubscribe();
            });
          }
        }
      });

    d3.select(this.hostElement)
      .selectAll('.label-tick')
      .selectAll('text')
      .on('mouseup', (_, itemId: string) => {
        if (this.isLabelClickable) {
          const item = this.data.find(({ id }) => id === itemId);

          this.labelClick.emit(item);
        }
      });
  }

  protected addHoverEventListeners(): void {
    const isDisabled = this.isDisabled;
    const cdr = this.cdr;
    const data = this.data;
    const tooltipConfig = this.tooltipConfig;
    const getFieldKey = this.z;

    d3.select(this.hostElement)
      .selectAll('rect')
      .on('mouseover', function (event: MouseEvent, d: { data: Record<string, string>; i: number }) {
        if (isDisabled) {
          return;
        }

        const hoveredElement = this as SVGRectElement;

        hoveredElement.style.strokeWidth = '2px';
        hoveredElement.style.stroke = '#413E4C';

        // getting correct data based on hovered element
        const index = d.i;

        tooltipConfig.set({
          data: data[index],
          metadata: data
            .filter(({ id }) => id === data[index].id)
            .reduce((acc, curr) => {
              acc[getFieldKey(curr)] = curr;

              return acc;
            }, {}),
          positioning: getTooltipPosition(hoveredElement, event),
        });

        cdr.detectChanges();
      })
      .on('mouseleave', function () {
        if (isDisabled) {
          return;
        }

        const hoveredElement = this as SVGRectElement;

        hoveredElement.style.strokeWidth = '';
        hoveredElement.style.stroke = '';

        tooltipConfig.update(config => ({ ...config, data: null }));
        cdr.detectChanges();
      });
  }

  protected setSizes(margin: { top: number; bottom: number; left: number; right: number }, heightOffset = 0): void {
    super.setSizes(margin, heightOffset);
    this.xRange = [this.marginLeft * 3, this.width - this.marginRight];
  }

  private getLabelWidth() {
    return this.marginLeft * 2;
  }
}

function getLabel(names: DivergingChartDisplayNames): string {
  return (names && Object.values(names).reduce((acc, name) => (acc ? `${acc} - ${name}` : name), '')) || '';
}
