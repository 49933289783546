import {
  RetailerSupplierItemsFilters,
  RetailerSupplierItemsRequestMetadata,
  UpdateRetailerSupplierItemsModel,
} from '../state';

export class RetailerSupplierItemsGetMany {
  static readonly type = '[RetailerSupplierItems] Get Many';
}

export class RetailerSupplierItemsUpdate {
  static readonly type = '[RetailerSupplierItems] Update';
  constructor(readonly payload: UpdateRetailerSupplierItemsModel) {}
}

export class RetailerSupplierItemsPatchRequestMetadata {
  static readonly type = '[RetailerSupplierItems] Patch Request Metadata';
  constructor(readonly payload: Partial<RetailerSupplierItemsRequestMetadata>) {}
}

export class RetailerSupplierItemsGetAllData {
  static readonly type = '[RetailerSupplierItems] Get All Data';
}

export class RetailerSupplierItemsInitFilters {
  static readonly type = '[RetailerSupplierItems] Init Filters';
}

export class RetailerSupplierItemsSetFilters {
  static readonly type = '[RetailerSupplierItems] Set Filters';
  constructor(readonly payload: RetailerSupplierItemsFilters) {}
}

export class RetailerSupplierItemsPatchFilters {
  static readonly type = '[RetailerSupplierItems] Patch Filters';
  constructor(readonly payload: Partial<RetailerSupplierItemsFilters>) {}
}

export class RetailerSupplierItemsResetFilters {
  static readonly type = '[RetailerSupplierItems] Reset Filters';
}
