import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';

import { DialogComponent, IDialogComponent } from '../../../../../dialog';
import { SankeyLink, SankeyNode } from '../sankey-chart';

@Component({
  selector: 'supy-expanded-sankey-chart',
  templateUrl: './expanded-sankey-chart.component.html',
  styleUrls: ['./expanded-sankey-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandedSankeyChartComponent<N extends SankeyNode, L extends SankeyLink>
  extends Destroyable
  implements IDialogComponent
{
  @Input() readonly customTitle: string;
  @Input() readonly subTitle: string;
  @Input() readonly nodes: N[] = [];
  @Input() readonly links: L[] = [];
  @Input() readonly currency: string;
  @Input() readonly canExport: boolean;
  @Input() readonly tooltipTemplate: TemplateRef<unknown>;

  @Output() readonly exportClicked = new EventEmitter<L[]>();

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  protected readonly svgWidth = window.innerWidth * 0.8;
  protected readonly svgHeight = window.innerHeight * 0.65;

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
