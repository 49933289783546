import { capital } from 'case';
import { Workbook } from 'exceljs';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  saveWorkbook,
} from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { SupplierReturn } from '../core';

export function downloadReturnsList(
  items: SupplierReturn[],
  currency: Currency,
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): void {
  const columns: DataColumn[] = [
    { header: 'Document #', key: 'documentNumber' },
    { header: 'Branch & Location', key: 'locationName' },
    { header: 'Supplier', key: 'supplierName' },
    { header: `Packages`, key: 'count' },
    { header: 'Return Date', key: 'documentDate' },
    { header: `Total Value (${currency})`, key: 'total' },
    { header: 'Status', key: 'status' },
  ];

  const currencyCols = new Set(['total']);
  const dateCols = new Set(['documentDate']);

  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      documentNumber: item.document.number,
      supplierName: item.supplier.name,
      documentDate: getUTCOverriddenDateTime(new Date(item.document.documentDate), ianaTimeZone),
      locationName: `${getLocalizedName(item.outlet?.name)} - ${item.location.name}`,
      count: item.items?.length ?? 0,
      total: item.totals.total,
      status: capital(item.status),
    };
  });

  createWorksheet(workbook, 'Supplier Returns', { data, columns, currencyCols, dateCols });

  const fileName = `supplier-returns-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
