import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';

import { SupyCommonModule } from '@supy/common';
import {
  AutocompleteModule,
  BreadcrumbModule,
  ButtonModule,
  ComboboxModule,
  DateInputModule,
  DialogModule,
  DropdownModule,
  FileUploadModule,
  GridModule,
  IconModule,
  ImageModule,
  InputModule,
  LoaderModule,
  LoadingOverlayModule,
  PageModule,
  SelectModule,
  TabViewSelectModule,
  TooltipModule,
} from '@supy/components';

import { ImportSalesItemsDialogComponent, SalesImportDetailsComponent, SalesTransactionsComponent } from './components';
import { SalesImportState } from './store';

@NgModule({
  declarations: [SalesTransactionsComponent, SalesImportDetailsComponent, ImportSalesItemsDialogComponent],
  exports: [SalesTransactionsComponent, SalesImportDetailsComponent, ImportSalesItemsDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TabViewSelectModule,
    PageModule,
    GridModule,
    ComboboxModule,
    ImageModule,
    InputModule,
    ButtonModule,
    IconModule,
    BreadcrumbModule,
    FileUploadModule,
    DropdownModule,
    DateInputModule,
    SelectModule,
    LoadingOverlayModule,
    SupyCommonModule,
    LoaderModule,
    TooltipModule,
    AutocompleteModule,
    DialogModule,
    NgxsModule.forFeature([SalesImportState]),
  ],
})
export class SalesImportModule {}
