import { distinctUntilChanged, filter, Observable, Subscription, switchMap, takeUntil, tap } from 'rxjs';
import { ChangeDetectorRef, Directive, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';

import { IQueryMetadata, IQueryResponse } from '../../http';
import { Destroyable } from '../../lifecycle';

export interface SearchCallbackParams {
  readonly limit: number;
  readonly pageIndex?: number;
  readonly searchValue?: string;
  readonly newSearchValue?: boolean;
  readonly extra?: Params;
}

export interface SearcherParams {
  readonly withPagination?: boolean;
  readonly appendData?: boolean;
  readonly limit?: number;
}

@Directive()
export abstract class Searcher<T> extends Destroyable implements OnInit {
  protected static readonly SEARCH_QUERY = 'search';
  protected static readonly PAGE_INDEX_QUERY = 'p';

  private readonly appendData: boolean;
  protected readonly limit: number;

  protected abstract readonly route: ActivatedRoute;
  protected abstract readonly router: Router;
  protected abstract readonly cdr: ChangeDetectorRef;

  // TODO: get current route from Router
  protected abstract readonly localRoute: string;

  abstract readonly element?: ElementRef<HTMLElement>;
  private newSearchValue = false;
  private searchSubscriber: Subscription;
  protected abstract searchCallback(params: SearchCallbackParams): Observable<IQueryResponse<T>>;
  abstract listTracker(index: number, value: T): T[keyof T] | number;

  readonly withPagination: boolean;
  searchValue: string;
  pageIndex?: number;
  data: T[] = [];
  isLoading = false;
  isComplete = false;
  pagesNumber: number;

  protected metadata: IQueryMetadata = { total: 0, count: 0 };

  protected queryParams$: Observable<IQueryResponse<T>>;

  // TODO: add load more functionality like in lazy loaded lists

  constructor(options?: SearcherParams) {
    super();
    this.withPagination = options?.withPagination ?? true;
    this.appendData = options?.appendData ?? true;
    this.limit = options?.limit ?? 20;
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const landedUrl = this.router.url.split('/').pop().split('?')[0];

      if (landedUrl !== this.localRoute) {
        this.unsubscribeSearcher();
        this.isLoading = false;
      }
    });

    this.queryParams$ = this.route.queryParams?.pipe(
      takeUntil(this.destroyed$),
      distinctUntilChanged(),
      switchMap(qp => {
        this.searchValue = (qp[Searcher.SEARCH_QUERY] as string) ?? '';

        if (this.withPagination) {
          const currentIndex = +qp[Searcher.PAGE_INDEX_QUERY];

          this.pageIndex = (currentIndex && currentIndex - 1) || 0;
        }

        const { [Searcher.SEARCH_QUERY]: search, [Searcher.PAGE_INDEX_QUERY]: p, ...rest } = qp;

        return this.search(rest);
      }),
    );

    this.subscribeSearcher();
  }

  subscribeSearcher() {
    this.searchSubscriber = this.queryParams$?.subscribe();
  }

  unsubscribeSearcher(): void {
    if (this.searchSubscriber) {
      this.searchSubscriber.unsubscribe();
    }
  }

  protected search(extra?: Params): Observable<IQueryResponse<T>> {
    this.isLoading = true;

    let params: SearchCallbackParams = {
      limit: this.limit,
      newSearchValue: this.newSearchValue,
      extra,
    };

    if (this.searchValue) {
      params = { ...params, searchValue: this.searchValue };
    }

    if (this.withPagination) {
      params = { ...params, pageIndex: this.pageIndex };
    }

    return this.searchCallback({ ...params }).pipe(
      tap(res => {
        this.isComplete = res.data.length < this.limit;
        this.newSearchValue = false;
        this.pagesNumber = Math.ceil(res.metadata.total / this.limit);
        this.metadata = { ...res.metadata, total: res.metadata.total + this.metadata.total };

        if (this.appendData) {
          this.data = res.data;
          this.element?.nativeElement?.scroll(0, 0);
        }
      }),
      tap({
        next: () => (this.isLoading = false),
        error: () => (this.isLoading = false),
      }),
      tap(() => {
        this.cdr.detectChanges();
      }),
    );
  }

  async onSearch(value: string, index = 0): Promise<void> {
    if (this.searchSubscriber.closed) {
      this.searchSubscriber = this.queryParams$?.subscribe();
    }

    const params: Params = {
      [Searcher.SEARCH_QUERY]: value,
    };

    if (this.withPagination) {
      params[Searcher.PAGE_INDEX_QUERY] = index + 1;
    }

    this.newSearchValue = true;

    await this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: { ...params },
      queryParamsHandling: 'merge',
    });
  }
}
