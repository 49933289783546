import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxDateTimeEditorModule, IgxInputGroupModule, IgxTextSelectionModule } from '@infragistics/igniteui-angular';

import { SupyCommonModule } from '@supy/common';

import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { LoaderModule } from '../loader';
import { DateTimeEditorComponent } from './components';

@NgModule({
  declarations: [DateTimeEditorComponent],
  imports: [
    CommonModule,
    IgxInputGroupModule,
    IconModule,
    InputHintsModule,
    IgxTextSelectionModule,
    SupyCommonModule,
    LoaderModule,
    IgxDateTimeEditorModule,
  ],
  exports: [DateTimeEditorComponent],
})
export class DateTimeEditorModule {}
