import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { IconType } from '../../../icon';
import { Tag, TagColor, TagSize } from '../../models';

@Component({
  selector: 'supy-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent<T> implements OnChanges {
  @Input() readonly tags: Tag<T>[];
  @Input() readonly color: TagColor;
  @Input() readonly size: TagSize;
  @Input() readonly removable: boolean;
  @Input() readonly customIcon: IconType;
  @Input() readonly moreTagsLabel: string;
  @Input() readonly moreTagsType: T;
  @Input() readonly limit: number;

  @Output() readonly tagRemoved = new EventEmitter<Tag<T>>();

  moreTags: Tag<T>;

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes?.tags || changes?.limit) && this.tags?.length - 1 > this.limit) {
      this.moreTags = {
        label: this.moreTagsLabel ?? `+${this.tags.length - 1 - this.limit} more`,
        type: this.moreTagsType,
      };
    }
  }

  tackByIndex(index: number): number {
    return index;
  }
}
