import { Injectable } from '@angular/core';

import { LocalStorageService } from '../storage';

const WINDOW_STATE_STORAGE_KEY = 'windowState.check';

@Injectable({
  providedIn: 'root',
})
export class WindowStateManagerStorageService extends LocalStorageService {
  getActiveWindows(): string[] {
    return this.getItem<string[]>(WINDOW_STATE_STORAGE_KEY) ?? [];
  }

  setActiveWindows(windowsState: string[]): void {
    this.setItem(WINDOW_STATE_STORAGE_KEY, windowsState);
  }

  resetWindows() {
    this.removeItem(WINDOW_STATE_STORAGE_KEY);
  }
}
