import produce from 'immer';
import { catchError, EMPTY, forkJoin, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  BASE_REQUEST_META_DEFAULT,
  BASE_RESPONSE_META_DEFAULT,
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  LocalFile,
  Query,
  QueryBuilder,
} from '@supy/common';
import { CurrentRetailerState } from '@supy/retailers';
import { SettingsState } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import {
  InventoryEventFilters,
  InventoryEventRequestProps,
  InventoryEventStateEnum,
  InventoryEventType,
  InventoryWastageEvent,
  InventoryWastageSearchItem,
  UploadInventoryEventAttachmentResponse,
} from '../../../core';
import { downloadInventoryEventList } from '../../../helpers';
import { InventoryWastageService } from '../../../services';
import { InventoryProductionStateModel } from '../../inventory-production';
import {
  InventoryWastageCheckExistence,
  InventoryWastageCreate,
  InventoryWastageDelete,
  InventoryWastageGet,
  InventoryWastageGetItems,
  InventoryWastageGetMany,
  InventoryWastageInitFilters,
  InventoryWastageListExport,
  InventoryWastagePatchFilter,
  InventoryWastagePatchRequestMeta,
  InventoryWastageResetFilter,
  InventoryWastageResetSequence,
  InventoryWastageUpdate,
  InventoryWastageUploadAttachment,
} from '../actions';

export interface InventoryWastageStateModel extends EntityListStateModel<InventoryWastageEvent> {
  readonly filters: InventoryEventFilters;
  readonly requestMetadata: BaseRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
  readonly items: InventoryWastageSearchItem[];
  readonly eventExist: boolean;
  readonly exportLoading: boolean;
}

export const INVENTORY_WASTAGE_TOKEN = new StateToken<InventoryWastageStateModel>('inventoryWastage');

const FILTERS_DEFAULT = { name: null, locations: [], archived: false, start: null, end: null };

@State<InventoryWastageStateModel>({
  name: INVENTORY_WASTAGE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: BASE_REQUEST_META_DEFAULT,
    responseMetadata: BASE_RESPONSE_META_DEFAULT,
    items: [],
    eventExist: true,
    exportLoading: false,
  },
})
@Injectable()
export class InventoryWastageState extends EntityListState {
  constructor(
    protected readonly router: Router,
    private readonly inventoryWastageService: InventoryWastageService,
    private readonly store: Store,
  ) {
    super(router);
  }

  @Selector()
  static events(state: InventoryWastageStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentEvent(state: InventoryWastageStateModel) {
    return EntityListState.current(state);
  }

  @Selector()
  static isFirst(state: InventoryWastageStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: InventoryWastageStateModel) {
    return EntityListState.isLast(state);
  }

  static event(id: string, next?: boolean) {
    return createSelector([InventoryWastageState], (state: InventoryWastageStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static appliedFiltersCount(state: InventoryWastageStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static filters(state: InventoryWastageStateModel) {
    return EntityListState.currentFilters<InventoryEventFilters>(state);
  }

  @Selector()
  static requestMetadata(state: InventoryWastageStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryWastageStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static items(state: InventoryWastageStateModel) {
    return state.items;
  }

  @Selector()
  static eventExist(state: InventoryWastageStateModel) {
    return state.eventExist;
  }

  @Selector()
  static exportLoading(state: InventoryProductionStateModel) {
    return state.exportLoading;
  }

  @Action(InventoryWastageInitFilters)
  inventoryWastageInitFilter(ctx: StateContext<InventoryWastageStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryWastagePatchFilter)
  patchInventoryWastageFilter(ctx: StateContext<InventoryWastageStateModel>, action: InventoryWastagePatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryWastagePatchRequestMeta({ page: 0 }), new InventoryWastageGetMany()]);
  }

  @Action(InventoryWastageResetFilter)
  resetInventoryWastageFilter(ctx: StateContext<InventoryWastageStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryWastagePatchRequestMeta({ page: 0 }), new InventoryWastageGetMany()]);
  }

  @Action(InventoryWastageGet)
  getOrder(ctx: StateContext<InventoryWastageStateModel>, action: InventoryWastageGet) {
    return super.getOne(ctx, {
      ...action.payload,
      fetchApi: () => this.inventoryWastageService.getByIdBff(action.payload.id),
    });
  }

  @Action(InventoryWastageGetMany, { cancelUncompleted: true })
  getInventoryWastages(ctx: StateContext<InventoryWastageStateModel>) {
    return this.inventoryWastageService.getManyBff(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(InventoryWastagePatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<InventoryWastageStateModel>, { payload }: InventoryWastagePatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryWastageGetItems)
  getItems(ctx: StateContext<InventoryWastageStateModel>, { query }: InventoryWastageGetItems) {
    return this.inventoryWastageService.getItemsBff(query).pipe(
      tap(({ data }) => {
        ctx.patchState({ items: InventoryWastageSearchItem.deserializeList(data) });
      }),
    );
  }

  @Action(InventoryWastageResetSequence)
  resetSequenceHandler(ctx: StateContext<InventoryWastageStateModel>) {
    super.resetSequence(ctx);
  }

  @Action(InventoryWastageCreate)
  create(ctx: StateContext<InventoryWastageStateModel>, { payload }: InventoryWastageCreate) {
    const { localFiles, ...rest } = payload;

    return this.getUploadAttachmentsStream(ctx, localFiles).pipe(
      switchMap(uploadedAttachments =>
        this.inventoryWastageService.createBff({ ...rest, attachments: uploadedAttachments }),
      ),
      tap(() => ctx.patchState({ eventExist: true })),
    );
  }

  @Action(InventoryWastageUpdate)
  update(ctx: StateContext<InventoryWastageStateModel>, { payload }: InventoryWastageUpdate) {
    const { localFiles, ...rest } = payload;

    return this.getUploadAttachmentsStream(ctx, localFiles).pipe(
      switchMap(uploadedAttachments =>
        this.inventoryWastageService.updateBff({
          ...rest,
          attachments: [...(rest.attachments ?? []), ...uploadedAttachments],
        }),
      ),
    );
  }

  @Action(InventoryWastageDelete)
  delete(ctx: StateContext<InventoryWastageStateModel>, { id }: InventoryWastageDelete) {
    return this.inventoryWastageService.deleteBff(id).pipe(
      tap(() => {
        ctx.setState(
          produce(draft => {
            draft.entities = draft.entities.filter(event => event.id !== id);
          }),
        );
      }),
    );
  }

  @Action(InventoryWastageCheckExistence)
  checkExistence(ctx: StateContext<InventoryWastageStateModel>) {
    return this.inventoryWastageService.getManyBff(this.getQuery(ctx.getState(), { checkExistence: true })).pipe(
      tap(({ data }) => {
        ctx.patchState({ eventExist: data.length > 0 });
      }),
    );
  }

  @Action(InventoryWastageUploadAttachment)
  uploadAttachment(
    _: StateContext<InventoryWastageStateModel>,
    { payload, queryParams }: InventoryWastageUploadAttachment,
  ) {
    return this.inventoryWastageService.uploadAttachment(payload, queryParams);
  }

  @Action(InventoryWastageListExport, { cancelUncompleted: true })
  downloadItems(ctx: StateContext<InventoryWastageStateModel>) {
    ctx.patchState({
      exportLoading: true,
    });

    const state = ctx.getState();
    const query = this.getQuery(state, {
      paginationLimit: -1,
      offset: 0,
    });

    return this.inventoryWastageService.getManyBff(query).pipe(
      withLatestFrom(this.store.select(SettingsState.currency), this.store.select(SettingsState.ianaTimeZone)),
      tap(([{ data }, currency, ianaTimeZone]) => {
        ctx.patchState({
          exportLoading: false,
        });

        downloadInventoryEventList(
          data,
          currency,
          InventoryEventType.Wastage,
          (locationId: string) => this.store.selectSnapshot(CurrentUserState.branchLocation(locationId))?.name,
          ianaTimeZone,
        );
      }),
      catchError(() => {
        ctx.patchState({
          exportLoading: false,
        });

        return EMPTY;
      }),
    );
  }

  private getQuery(
    state: InventoryWastageStateModel,
    options?: { checkExistence?: boolean; readonly paginationLimit?: number; readonly offset?: number },
  ) {
    const { name, locations, archived, start, end } = state.filters;
    const retailerId = this.store.selectSnapshot(CurrentRetailerState.get) ?? '';

    if (options?.checkExistence) {
      return new Query<InventoryEventRequestProps>({
        filtering: [{ by: 'retailer.id', match: retailerId, op: 'eq' }],
        paging: { offset: 0, limit: 1 },
      });
    }

    const qb = new QueryBuilder<InventoryEventRequestProps>({
      filtering: [
        {
          by: 'state.name',
          match: archived
            ? [InventoryEventStateEnum.Archived]
            : [InventoryEventStateEnum.Submitted, InventoryEventStateEnum.Draft],
          op: 'in',
        },
        { by: 'retailer.id', match: retailerId, op: 'in' },
      ],
      paging: {
        limit: options?.paginationLimit ? options.paginationLimit : state.requestMetadata.limit,
        offset: options?.offset ? options.offset : state.requestMetadata.page * state.requestMetadata.limit,
      },
      ordering: [
        { by: 'state.sort', dir: 'asc' },
        {
          by: 'eventDate',
          dir: 'desc',
        },
        { by: 'id', dir: 'desc' },
      ],
    });

    if (name) {
      qb.filtering.setFilter({
        by: 'name',
        op: 'like',
        match: name,
      });
    }

    if (start && end) {
      qb.filtering.withFiltering([
        { by: 'eventDate', op: 'gte', match: new Date(start) },
        { by: 'eventDate', op: 'lte', match: new Date(end) },
      ]);
    }

    if (locations?.length) {
      qb.filtering.setFilter({ by: 'location.id', match: locations, op: 'in' });
    }

    return qb.build();
  }

  private getUploadAttachmentsStream(
    ctx: StateContext<InventoryWastageStateModel>,
    localFiles?: LocalFile[],
  ): Observable<UploadInventoryEventAttachmentResponse[]> {
    return localFiles?.length
      ? forkJoin(
          localFiles.map(localFile => {
            const formData = new FormData();

            formData.append('attachment', localFile.file);

            return this.uploadAttachment(ctx, {
              payload: formData,
              queryParams: { retailerId: this.store.selectSnapshot(CurrentRetailerState.get) ?? '' },
            });
          }),
        )
      : of(new Array<UploadInventoryEventAttachmentResponse>());
  }
}
