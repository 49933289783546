import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query, Retailer, RetailerId, Supplier, User } from '@supy/common';

import { RETAILERS_BFF_URI, RETAILERS_URI } from '../../config';
import {
  CreateRetailerRequest,
  CreateRetailerWithChildrenRequest,
  DetailedRetailerSupplier,
  GetRetailerSupplierResponse,
  RetailerSupplierItem,
  RetailerSupplierItemsRequestProps,
  UpdateRetailerRequest,
  UpdateRetailerSupplierItemsRequest,
  UploadImageRequest,
  UploadRetailerImageResponse,
} from '../../core';

@Injectable({
  providedIn: 'root',
})
export class RetailersService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(RETAILERS_URI) private readonly uri: string,
    @Inject(RETAILERS_BFF_URI) private readonly uriBff: string,
  ) {}

  getRetailers(query: Query<Retailer>): Observable<IQueryResponse<Retailer>> {
    return this.httpClient.get<IQueryResponse<Retailer>>(this.uri, { params: query.toQueryParams() });
  }

  getAllRetailersBff(query: Query<Retailer>): Observable<IQueryResponse<Retailer>> {
    return this.httpClient.get<IQueryResponse<Retailer>>(`${this.uriBff}/all`, { params: query.toQueryParams() });
  }

  getRetailer(id: RetailerId): Observable<Retailer> {
    return this.httpClient.get<Retailer>(`${this.uri}/${id}`);
  }

  createRetailer(body: CreateRetailerRequest): Observable<Retailer> {
    return this.httpClient.post<Retailer>(this.uri, body);
  }

  createRetailerWithChildrenBff(body: CreateRetailerWithChildrenRequest): Observable<Retailer> {
    return this.httpClient.post<Retailer>(this.uriBff, body);
  }

  editRetailer(id: RetailerId, retailer: UpdateRetailerRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${id}`, retailer);
  }

  deleteRetailer(id: RetailerId): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${id}`);
  }

  uploadRetailerImage(body: UploadImageRequest): Observable<UploadRetailerImageResponse> {
    const formData = new FormData();

    formData.append('file', body.file);

    return this.httpClient.post<UploadRetailerImageResponse>(`${this.uri}/image`, formData);
  }

  getRetailerUserSuppliers(
    id: RetailerId,
    query: Query<Supplier>,
  ): Observable<IQueryResponse<GetRetailerSupplierResponse>> {
    return this.httpClient.get<IQueryResponse<GetRetailerSupplierResponse>>(`${this.uri}/${id}/suppliers`, {
      params: query.toQueryParams(),
    });
  }

  getRetailerUserSuppliersBff(
    id: RetailerId,
    query: Query<Supplier>,
  ): Observable<IQueryResponse<GetRetailerSupplierResponse>> {
    return this.httpClient.get<IQueryResponse<GetRetailerSupplierResponse>>(`${this.uriBff}/${id}/suppliers`, {
      params: query.toQueryParams(),
    });
  }

  getDetailedSupplier({
    retailerId,
    supplierId,
  }: {
    retailerId: string;
    supplierId: string;
  }): Observable<DetailedRetailerSupplier> {
    return this.httpClient.get<DetailedRetailerSupplier>(
      `${this.uriBff}/${retailerId}/suppliers/${supplierId}/detailed`,
    );
  }

  unlinkSupplier({ retailerId, supplierId }: { retailerId: string; supplierId: string }): Observable<void> {
    return this.httpClient.delete<void>(`${this.uriBff}/${retailerId}/suppliers/${supplierId}`);
  }

  getRetailersRelatedSuppliers(
    id: RetailerId,
    query: Query<GetRetailerSupplierResponse>,
  ): Observable<IQueryResponse<GetRetailerSupplierResponse>> {
    return this.httpClient.get<IQueryResponse<GetRetailerSupplierResponse>>(`${this.uriBff}/${id}/related-suppliers`, {
      params: query.toQueryParams(),
    });
  }

  getRetailerUsers(id: RetailerId, query: Query<User>): Observable<IQueryResponse<User>> {
    return this.httpClient.get<IQueryResponse<User>>(`${this.uri}/${id}/users`, { params: query.toQueryParams() });
  }

  getRetailerSupplierItems(
    retailerId: string,
    supplierId: string,
    query: Query<RetailerSupplierItem & RetailerSupplierItemsRequestProps>,
  ): Observable<IQueryResponse<RetailerSupplierItem>> {
    return this.httpClient.get<IQueryResponse<RetailerSupplierItem>>(
      `${this.uriBff}/${retailerId}/suppliers/${supplierId}/items`,
      {
        params: query.toQueryParams(),
      },
    );
  }

  updateRetailerSupplierItems(
    retailerId: string,
    supplierId: string,
    body: UpdateRetailerSupplierItemsRequest,
  ): Observable<void> {
    return this.httpClient.patch<void>(`${this.uriBff}/${retailerId}/suppliers/${supplierId}/items`, body);
  }
}
