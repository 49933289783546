<supy-dialog
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  position="center"
  width="85vw"
  height="90vh"
  initialState="open"
>
  <supy-dialog-header class="expanded-sankey-chart__dialog-header">
    <div class="expanded-sankey-chart__header">
      <div>
        <h5 class="expanded-sankey-chart__title">{{ customTitle }}</h5>
        <p class="expanded-sankey-chart__subtitle" *ngIf="currency && !subTitle">in {{ currency }}</p>
        <p class="expanded-sankey-chart__subtitle" *ngIf="subTitle">{{ subTitle }} in {{ currency }}</p>
      </div>
      <div class="expanded-sankey-chart__header-icons">
        <supy-icon
          *ngIf="canExport"
          (click)="exportClicked.emit(links)"
          name="document-download"
          color="primary"
        ></supy-icon>
        <supy-icon (click)="closeDialog()" name="close-circle" color="error"></supy-icon>
      </div>
    </div>
  </supy-dialog-header>
  <supy-dialog-content class="expanded-sankey-chart__content">
    <supy-sankey-chart [locations]="nodes" [transfers]="links" [svgWidth]="svgWidth" [svgHeight]="svgHeight">
      <ng-template supyChartTooltip let-datum>
        <ng-container *ngIf="tooltipTemplate">
          <ng-container *ngTemplateOutlet="tooltipTemplate; context: { $implicit: datum }"></ng-container>
        </ng-container>
      </ng-template>
    </supy-sankey-chart>
  </supy-dialog-content>
</supy-dialog>
