import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxUploaderModule } from '@angular-ex/uploader';
import { IgxInputGroupModule } from '@infragistics/igniteui-angular';

import { SupyCommonModule } from '@supy/common';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputHintsModule } from '../input-hints';
import { LoaderModule } from '../loader';
import { FileUploadComponent, MultipleMediaUploaderComponent } from './components';

const COMPONENTS = [FileUploadComponent, MultipleMediaUploaderComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    NgxUploaderModule,
    IconModule,
    ButtonModule,
    IgxInputGroupModule,
    InputHintsModule,
    LoaderModule,
    SupyCommonModule,
  ],
  exports: [...COMPONENTS],
})
export class FileUploadModule {}
