import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { IconColor } from '@supy/components';

import { Packaging, PackagingLevel } from '../../core';
import { PackagingPermissionStrategy } from '../../permissions';

@Component({
  selector: 'supy-package-item',
  styleUrls: ['./package-item.component.scss'],
  templateUrl: 'package-item.component.html',
})
export class PackageItemComponent {
  @Input() readonly itemName: string;
  @Input() readonly packageItem: Packaging;
  @Input() readonly isReadonly?: boolean;
  @Input() readonly isEditable?: boolean = true;
  @Input() readonly isDeletable: boolean;

  @Output() readonly addPackageItem = new EventEmitter<Packaging>();
  @Output() readonly editPackageItem = new EventEmitter<Packaging>();
  @Output() readonly deletePackageItem = new EventEmitter<Packaging>();

  protected readonly canDeletePackaging = toSignal(inject(PackagingPermissionStrategy.Delete).isAllowed());
  protected readonly canUpdatePackaging = toSignal(inject(PackagingPermissionStrategy.Update).isAllowed());
  protected readonly canCreatePackaging = toSignal(inject(PackagingPermissionStrategy.Create).isAllowed());

  #levelsAllowedAdding = [0, 1];

  allowedToAdd(level: PackagingLevel): boolean {
    return !this.isReadonly && this.#levelsAllowedAdding.includes(level);
  }

  getLevelColor(level: PackagingLevel): IconColor {
    switch (level) {
      case 0:
        return 'primary';
      case 1:
        return 'info';
      case 2:
        return 'success';

      default:
        return 'primary';
    }
  }
}
