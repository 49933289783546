import produce from 'immer';
import { forkJoin, mergeMap, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';

import {
  EntityListState,
  EntityListStateModel,
  EventTime,
  IQueryFiltering,
  Query,
  QueryBuilder,
  QueryPaging,
} from '@supy/common';

import {
  EventState,
  GetStockCountItemsResponse,
  GetSubStockCountItemsResponse,
  InventoryStockCountItem,
  InventoryStockCountRequestProps,
  InventoryStockCountScopeTypeEnum,
} from '../../core';
import { InventoryStockCountService } from '../../services';
import {
  InventoryStockCountGet,
  InventoryStockCountGetAllItems,
  InventoryStockCountInitFilters,
  InventoryStockCountPatchFilter,
  InventoryStockCountPatchRequestMeta,
  InventoryStockCountResetDetails,
  InventoryStockCountResetFilter,
  InventoryStockCountSetFilter,
  InventoryStockCountSetType,
  InventoryStockCountToggleZeroingUncounted,
  InventoryStockSubCountDiscard,
  InventoryStockSubCountUpdate,
} from '../actions';

export interface InventoryStockCountStateModel extends EntityListStateModel<InventoryStockCountItem> {
  readonly entitiesInitial?: InventoryStockCountItem[] | null;
  readonly filters: InventoryStockCountFilters;
  readonly requestMetadata: InventoryStockCountRequestMetadata;
  readonly responseMetadata: InventoryStockCountResponseMetadata;
  readonly stockCountDetails: StockCountDetail;
  readonly subCount: boolean;
  readonly allItems: InventoryStockCountItem[];
}

export interface StockCountDetail {
  readonly eventDate?: Date | null;
  readonly eventTime?: EventTime | null;
  readonly name?: string | null;
  readonly location: string | null;
  readonly stockCountId: string | null;
  readonly state: EventState | null;
  readonly isOpening?: boolean | null;
}

export interface InventoryStockCountFilters {
  readonly itemsName: string | null;
  readonly recipesName: string | null;
  readonly itemsCategories: string[];
  readonly recipesCategories: string[];
  readonly itemsLocation: string | null;
  readonly status: InventoryStockCountScopeTypeEnum | null;
}

export interface InventoryStockCountRequestMetadata {
  readonly limit: number;
  readonly page: number;
  readonly retailerId: string | null;
  readonly stockCountId: string;
  readonly subStockCountId: string;
}

export interface InventoryStockCountResponseMetadata {
  readonly total: number;
  readonly count: number;
}

const INVENTORY_STOCK_COUNT_STATE_TOKEN = new StateToken<InventoryStockCountStateModel[]>('inventoryStockCount');

const FILTERS_DEFAULT: InventoryStockCountFilters = {
  itemsName: null,
  recipesName: null,
  itemsCategories: [],
  recipesCategories: [],
  itemsLocation: null,
  status: null,
};

const REQUEST_META_DEFAULT: InventoryStockCountRequestMetadata = {
  stockCountId: '',
  subStockCountId: '',
  page: 0,
  limit: 50,
  retailerId: null,
};
const RESPONSE_META_DEFAULT: InventoryStockCountResponseMetadata = { total: 0, count: 0 };

const DETAILS_DEFAULT: StockCountDetail = {
  eventDate: null,
  eventTime: null,
  name: null,
  location: null,
  stockCountId: null,
  state: null,
  isOpening: null,
};

interface GetQueryOptions {
  readonly noLimit?: boolean;
  readonly subCountItems?: boolean;
}

@State<InventoryStockCountStateModel>({
  name: INVENTORY_STOCK_COUNT_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    stockCountDetails: DETAILS_DEFAULT,
    subCount: false,
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    entitiesInitial: null,
    allItems: [],
  },
})
@Injectable()
export class InventoryStockCountState extends EntityListState {
  constructor(
    protected readonly router: Router,
    private readonly stockCountService: InventoryStockCountService,
  ) {
    super(router);
  }

  @Selector()
  static items(state: InventoryStockCountStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static currentItem(state: InventoryStockCountStateModel) {
    return EntityListState.current(state);
  }

  static item(id: string, next?: boolean) {
    return createSelector([InventoryStockCountState], (state: InventoryStockCountStateModel) => {
      return EntityListState.one(id, next)(state);
    });
  }

  @Selector()
  static itemsInitial(state: InventoryStockCountStateModel) {
    return state.entitiesInitial;
  }

  @Selector()
  static appliedFiltersCount(state: InventoryStockCountStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT, ['status']);
  }

  @Selector()
  static filters(state: InventoryStockCountStateModel) {
    return EntityListState.currentFilters<InventoryStockCountFilters>(state);
  }

  @Selector()
  static status(state: InventoryStockCountStateModel) {
    return EntityListState.currentFilters<InventoryStockCountFilters>(state).status;
  }

  @Selector()
  static requestMetadata(state: InventoryStockCountStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryStockCountStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static stockCountDetails({ stockCountDetails }: InventoryStockCountStateModel) {
    return stockCountDetails;
  }

  @Selector()
  static stockCountLocation({ stockCountDetails }: InventoryStockCountStateModel) {
    return stockCountDetails.location;
  }

  @Selector()
  static allItems(state: InventoryStockCountStateModel) {
    return state.allItems;
  }

  @Action(InventoryStockCountInitFilters)
  inventoryStockCountInitFilter(ctx: StateContext<InventoryStockCountStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryStockCountSetFilter)
  setInventoryStockCountFilter(ctx: StateContext<InventoryStockCountStateModel>, action: InventoryStockCountSetFilter) {
    super.setFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryStockCountPatchRequestMeta({ page: 0 }), new InventoryStockCountGet()]);
  }

  @Action(InventoryStockCountPatchFilter)
  patchInventoryStockCountFilter(
    ctx: StateContext<InventoryStockCountStateModel>,
    action: InventoryStockCountPatchFilter,
  ) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new InventoryStockCountPatchRequestMeta({ page: 0 }), new InventoryStockCountGet()]);
  }

  @Action(InventoryStockCountResetFilter)
  resetInventoryStockCountFilter(ctx: StateContext<InventoryStockCountStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryStockCountResetDetails)
  resetInventoryStockCountDetails(ctx: StateContext<InventoryStockCountStateModel>) {
    ctx.patchState({ stockCountDetails: DETAILS_DEFAULT });
  }

  @Action(InventoryStockCountSetType)
  inventoryStockCountSetType(
    ctx: StateContext<InventoryStockCountStateModel>,
    { subCount }: InventoryStockCountSetType,
  ) {
    ctx.patchState({ subCount });
  }

  @Action(InventoryStockSubCountUpdate)
  inventoryStockSubCountUpdate(
    ctx: StateContext<InventoryStockCountStateModel>,
    { payload: { stockCountId, subStockCountId, body } }: InventoryStockSubCountUpdate,
  ) {
    return this.stockCountService
      .updateSub(stockCountId, subStockCountId, body)
      .pipe(mergeMap(() => ctx.dispatch(new InventoryStockCountGet())));
  }

  @Action(InventoryStockSubCountDiscard)
  inventoryStockSubCountDiscard(
    ctx: StateContext<InventoryStockCountStateModel>,
    { id, subCountId }: InventoryStockSubCountDiscard,
  ) {
    return this.stockCountService.discard(id, subCountId).pipe(
      tap(() => ctx.patchState({ subCount: false })),
      switchMap(() => this.router.navigateByUrl('/inventory/stock-counts')),
    );
  }

  @Action(InventoryStockCountGet, { cancelUncompleted: true })
  getInventoryStockCount(ctx: StateContext<InventoryStockCountStateModel>, { payload }: InventoryStockCountGet) {
    const isSubCount = ctx.getState().subCount;
    const type = ctx.getState().filters.status;
    const { stockCountId, subStockCountId } = ctx.getState().requestMetadata;

    const action =
      isSubCount && type === InventoryStockCountScopeTypeEnum.Item
        ? () =>
            this.stockCountService
              .getSubByIdItems(stockCountId, subStockCountId, this.getQuery(ctx.getState(), { subCountItems: true }))
              .pipe(tap(res => this.handleSubStockCountItemsResponse(ctx, res)))
        : isSubCount && type === InventoryStockCountScopeTypeEnum.Recipe
        ? () =>
            this.stockCountService
              .getSubByIdRecipes(stockCountId, subStockCountId, this.getQuery(ctx.getState()))
              .pipe(tap(res => this.handleSubStockCountItemsResponse(ctx, res)))
        : !isSubCount && type === InventoryStockCountScopeTypeEnum.Item
        ? () =>
            this.stockCountService
              .getByIdItems(stockCountId, this.getQuery(ctx.getState()))
              .pipe(tap(res => this.handleStockCountItemsResponse(ctx, res)))
        : () =>
            this.stockCountService
              .getByIdRecipes(stockCountId, this.getQuery(ctx.getState()))
              .pipe(tap(res => this.handleStockCountItemsResponse(ctx, res)));

    return action();
  }

  @Action(InventoryStockCountPatchRequestMeta)
  patchRequestMetadata(
    ctx: StateContext<InventoryStockCountStateModel>,
    { payload }: InventoryStockCountPatchRequestMeta,
  ) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryStockCountToggleZeroingUncounted)
  toggleZeroingUncounted(
    ctx: StateContext<InventoryStockCountStateModel>,
    { id }: InventoryStockCountToggleZeroingUncounted,
  ) {
    return this.stockCountService.toggleZeroingUncounted(id);
  }

  @Action(InventoryStockCountGetAllItems)
  getAllItems(ctx: StateContext<InventoryStockCountStateModel>) {
    const { stockCountId, subStockCountId } = ctx.getState().requestMetadata;

    return forkJoin([
      this.stockCountService.getSubByIdItems(
        stockCountId,
        subStockCountId,
        this.getQuery(ctx.getState(), { noLimit: true, subCountItems: true }),
      ),
      this.stockCountService.getSubByIdRecipes(
        stockCountId,
        subStockCountId,
        this.getQuery(ctx.getState(), { noLimit: true }),
      ),
    ]).pipe(tap(responses => ctx.patchState({ allItems: responses.flatMap(({ items }) => items.data) })));
  }

  private getQuery(
    state: InventoryStockCountStateModel,
    options?: GetQueryOptions,
  ): Query<InventoryStockCountItem & InventoryStockCountRequestProps> {
    const { itemsLocation, itemsCategories, recipesCategories, itemsName, recipesName, status } = state.filters;
    const isSubCount = state.subCount;
    const isItems = status === InventoryStockCountScopeTypeEnum.Item;
    const isRecipes = status === InventoryStockCountScopeTypeEnum.Recipe;

    const qb = new QueryBuilder<InventoryStockCountItem & InventoryStockCountRequestProps>({
      filtering: [],
      paging: options?.noLimit
        ? QueryPaging.NoLimit
        : { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: options?.subCountItems
        ? [
            { by: 'retailerItem.category.id', dir: 'asc' },
            { by: 'retailerItem.name.en', dir: 'asc' },
          ]
        : [
            { by: 'category.id', dir: 'asc' },
            { by: 'name.en', dir: 'asc' },
          ],
    });

    if (options?.noLimit) {
      return qb.build();
    }

    if (itemsName || recipesName) {
      const searchFiltering: IQueryFiltering<InventoryStockCountItem & InventoryStockCountRequestProps>[] = [];

      if (isItems && itemsName) {
        searchFiltering.push({
          by: isSubCount ? 'retailerItem.name.en' : 'name.en',
          match: itemsName,
          op: 'like',
        });

        if (isSubCount) {
          searchFiltering.push({ by: 'retailerItem.code', match: itemsName, op: 'like' });
        }
      }

      if (isRecipes && recipesName) {
        searchFiltering.push({ by: 'name.en', match: recipesName, op: 'like' });

        if (isSubCount) {
          searchFiltering.push({ by: 'code', match: recipesName, op: 'like' });
        }
      }

      qb.filtering.withGroup('or', searchFiltering);
    }

    if (isItems && itemsLocation) {
      qb.filtering.setFilter({
        by: 'storages.id',
        match: itemsLocation,
        op: 'eq',
      });
    }

    if (isItems && itemsCategories?.length) {
      qb.filtering.setFilter({
        by: isSubCount ? 'retailerItem.category.id' : 'category.id',
        match: itemsCategories,
        op: 'in',
      });
    }

    if (isRecipes && recipesCategories?.length) {
      qb.filtering.setFilter({
        by: 'category.id',
        match: recipesCategories,
        op: 'in',
      });
    }

    return qb.build();
  }

  private handleStockCountItemsResponse(
    ctx: StateContext<InventoryStockCountStateModel>,
    res: GetStockCountItemsResponse,
  ): void {
    ctx.patchState({
      stockCountDetails: {
        stockCountId: res.id,
        location: res.location.id,
        state: res.state,
        eventDate: res.eventDate.creationDate,
        eventTime: res.eventDate.time,
        isOpening: res.isOpening,
      },
      responseMetadata: res.items.metadata,
      entitiesInitial: null,
    });
    super.setMany(ctx, res.items?.data ?? []);
  }

  private handleSubStockCountItemsResponse(
    ctx: StateContext<InventoryStockCountStateModel>,
    res: GetSubStockCountItemsResponse,
  ): void {
    ctx.patchState({
      stockCountDetails: {
        stockCountId: res.stockCount.id,
        location: res.location.id,
        state: res.stockCount.state,
        eventDate: res.stockCount.eventDate,
        isOpening: res.stockCount.isOpening,
      },
      responseMetadata: res.items.metadata,
      entitiesInitial: res.items.data.map(item => ({
        ...item,
        packagings: item.packagings.map(packaging => ({ ...packaging })),
      })),
    });
    super.setMany(ctx, res.items?.data ?? []);
  }
}
