import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

import { BadgeWidth } from '../../../badge';
import { BadgeStatus, BadgeWeight } from '../../../status-badge';

export interface IconStatusBadgeCellRendererContext {
  readonly text?: string;
  readonly status?: BadgeStatus;
  readonly weight?: BadgeWeight;
  readonly width?: BadgeWidth;
}

@Component({
  selector: 'supy-icon-status-badge-cell-renderer',
  template: `
    <div class="supy-icon-status-badge-cell-renderer__cell">
      <supy-icon-status-badge
        [status]="context?.status"
        [weight]="context?.weight"
        [width]="context?.width"
        [text]="getStatusText(context?.text) | titlecase"
      ></supy-icon-status-badge>
    </div>
  `,
  styleUrls: ['./icon-status-badge-cell-renderer.component.scss'],
})
export class IconStatusBadgeCellRendererComponent implements ICellRendererAngularComp {
  protected context: IconStatusBadgeCellRendererContext;

  agInit(params: ICellRendererParams<unknown, unknown, IconStatusBadgeCellRendererContext>): void {
    this.context = params.context;
  }

  refresh(): boolean {
    return false;
  }

  protected getStatusText(value: string): string {
    return value.replace(/-/g, ' ');
  }
}
