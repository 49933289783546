import { first, Observable, of, takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IANATimezone } from '@supy.api/dictionaries';

import { Destroyable, getDateWithDaysDifference, getShiftedDate, Supplier } from '@supy/common';
import { DropdownTreeNode, FilterChange, FilterGroup, getWeeksQuartersRange } from '@supy/components';

import { OrdersFilters } from '../../../store';
import { getOrdersFiltersConfig, OrdersMappedFilters } from './filters-config';
import { OrderTab, OrderTabValue } from './tabs';

@Component({
  selector: 'supy-retailer-orders-grid-filters',
  templateUrl: './retailer-orders-grid-filters.component.html',
  styleUrls: ['./retailer-orders-grid-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerOrdersGridFiltersComponent extends Destroyable implements OnInit {
  @Input() protected readonly filters$: Observable<OrdersFilters>;
  @Input() protected readonly suppliersList$: Observable<Supplier[]>;
  @Input() protected readonly locations$: Observable<DropdownTreeNode<string>[]>;
  @Input() protected readonly tabs: OrderTab<OrderTabValue>[];
  @Input() protected readonly utcOffset: number;
  @Input() protected readonly ianaTimeZone: IANATimezone;
  @Input() protected readonly showLegend: boolean;

  @Output() readonly filtersChanged = new EventEmitter<Partial<OrdersFilters>>();
  @Output() readonly filtersCleared = new EventEmitter<void>();

  protected readonly tabFormControl = new FormControl<string | null>(null);

  protected readonly filtersGroup = new FilterGroup<FilterChange<OrdersMappedFilters>>(
    getOrdersFiltersConfig({
      branches$: of<DropdownTreeNode<string>[]>([]),
      suppliers$: of<Supplier[]>([]),
    }),
  );

  protected readonly predefinedDateRanges = getWeeksQuartersRange();

  ngOnInit(): void {
    this.filtersGroup.updateSelectionProperty('branches', {
      options: this.locations$,
    });
    this.filtersGroup.updateSelectionProperty('suppliers', {
      options: this.suppliersList$,
    });

    this.patchTab();
    this.patchPONumberFilter();
    this.patchBranchesFilter();
    this.patchSuppliersFilter();
    this.patchDateRangeFilter();
  }

  protected onFiltersChange({ branches, suppliers, dateRange, search }: FilterChange<OrdersMappedFilters>): void {
    const startDate = dateRange?.start;
    const endDate = dateRange?.end;
    const startDateTime = startDate && this.getRetailerTimeZoneShiftedDate(startDate);
    const endDateTime = endDate ? this.getRetailerTimeZoneShiftedDate(endDate) : startDateTime;

    this.filtersChanged.emit({
      branches,
      suppliers,
      lpoRestaurant: search,
      start: startDateTime,
      end: endDateTime,
    });
  }

  protected onSelectTab($event: string | null): void {
    this.filtersChanged.emit({
      status: $event,
    });
  }

  private patchTab(): void {
    this.filters$.pipe(takeUntil(this.destroyed$)).subscribe(({ status }) => {
      this.tabFormControl.patchValue(status);
    });
  }

  private patchPONumberFilter(): void {
    this.filters$.pipe(first(({ lpoRestaurant }) => Boolean(lpoRestaurant))).subscribe(({ lpoRestaurant }) => {
      this.filtersGroup.updateSearchProperty({
        value: lpoRestaurant,
      });
    });
  }

  private patchBranchesFilter(): void {
    this.filters$.pipe(first(({ branches }) => branches?.length > 0)).subscribe(({ branches }) => {
      this.filtersGroup.updateSelectionProperty('branches', {
        value: branches,
        disabled: false,
      });
    });
  }

  private patchSuppliersFilter(): void {
    this.filters$.pipe(first(({ suppliers }) => suppliers?.length > 0)).subscribe(({ suppliers }) => {
      this.filtersGroup.updateSelectionProperty('suppliers', {
        value: suppliers,
        disabled: false,
      });
    });
  }

  private patchDateRangeFilter(): void {
    this.filters$.pipe(first(({ start, end }) => Boolean(start) && Boolean(end))).subscribe(({ start, end }) => {
      this.filtersGroup.updateSelectionProperty('dateRange', {
        value: {
          start: new Date(Number(start)),
          end: new Date(Number(end)),
        },
      });
    });
  }

  private getRetailerTimeZoneShiftedDate(date: Date | string): number {
    return getShiftedDate(getDateWithDaysDifference(new Date(date), this.ianaTimeZone), this.utcOffset).getTime();
  }
}
