import {
  IdType,
  LocalizedData,
  LocalizedSkeletonObjectType,
  PreferredType,
  Query,
  SkeletonObjectType,
  SupplierItem,
  Uom,
} from '@supy/common';
import { Packaging } from '@supy/packaging';

import { ChannelItem, ChannelItemQuantity, ChannelItemState, ExistingChannelItem } from './channel-item.entity';

export interface UpdateChannelItemRequest {
  readonly id?: string;
  readonly name?: LocalizedData;
  readonly price?: number;
  readonly unit?: string;
  readonly state?: ChannelItemState;
  readonly itemCode?: string;
  readonly preferredType?: PreferredType;
  readonly retailerItemId?: string;
  readonly channelId?: string;
}

export interface CreateChannelItemRequest {
  readonly name?: LocalizedData;
  readonly price?: number;
  readonly description?: string;
  readonly unit?: string;
  readonly itemCode?: string;
  readonly retailerItemId: string;
  readonly supplierId?: string;
  readonly retailerId?: string;
  readonly branchId?: string;
  readonly channelId: string;
  readonly preferredType?: PreferredType;
}

export interface CreateChannelItemsRequest {
  readonly channelItems: CreateChannelItemRequest[];
  readonly replace?: boolean;
}

export interface UpdateRelatedChannelItemsRequest {
  readonly item: UpdateChannelItemRequest;
  readonly branches?: string[];
}

export interface UpdateChannelItemsRequest {
  readonly channelItems: UpdateChannelItemRequest[];
}

export interface CreateChannelItemsResponse {
  readonly channelItems: ChannelItem[];
  readonly existingChannelItems?: ExistingChannelItem[];
}

export interface DeleteChannelItemsRequest {
  readonly channelItemIds: string[];
}

export interface CheckPreferredSiblingsRequest {
  readonly supplierId: string;
  readonly retailerId: string;
  readonly retailerItemIds: string[];
}

export interface GetCatalogGroupedChannelItemsRequest {
  readonly retailer: IdType;
  readonly query: Query<CatalogGroupedChannelItem & CatalogGroupedChannelItemProps>;
}

export interface ChannelItemRetailerItem {
  readonly id: string;
  readonly code: string;
  readonly isLocked?: boolean;
  readonly taxCode?: string;
}

export interface CatalogChannelItemApi {
  readonly id: string;
  readonly name: LocalizedData;
  readonly price: number;
  readonly itemCode?: string;
  readonly preferredType?: PreferredType;
  readonly retailer: IdType;
  readonly branch: IdType;
  readonly channel: IdType;
  readonly supplier: IdType;
  readonly retailerItem: ChannelItemRetailerItem;
  readonly supplierItem?: IdType;
  readonly inventoryItem?: IdType;
  readonly category?: SkeletonObjectType;
  readonly packaging?: Packaging;
  readonly priority?: boolean;
  readonly state: ChannelItemState;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly createdBy?: IdType;
}

export interface CatalogChannelItemApiProps {
  readonly 'supplier.id'?: string;
  readonly 'packaging.id'?: string;
  readonly 'name.en'?: string;
  readonly 'name.ar'?: string;
  readonly 'channel.id'?: string;
  readonly 'category.id'?: string;
}

export enum GroupedChannelItemState {
  Active = 'active',
  Archived = 'archived',
  Deleted = 'deleted',
}

export interface CatalogGroupedChannelItem {
  readonly name: LocalizedData;
  readonly retailer: SkeletonObjectType;
  readonly supplier: SkeletonObjectType;
  readonly packaging: Packaging;
  readonly supplierItem: Partial<SupplierItem>;
  readonly retailerItem: LocalizedSkeletonObjectType;
  readonly uom: Uom;
  readonly price: number;
  readonly itemCode: string;
  readonly category: LocalizedSkeletonObjectType;
  readonly inventoryItem: IdType;
  readonly channelItems: CatalogChannelItemApi[];
  readonly state: GroupedChannelItemState;
  readonly occurrence?: number;
}

export interface CatalogGroupedChannelItemProps {
  readonly 'retailer.id': string;
}

export interface InventoryChannelItemApi extends CatalogChannelItemApi {
  readonly quantityLevels: ChannelItemQuantity;
  readonly quantity: number;
  readonly orderable: boolean;
  readonly ckStock?: number;
}

export interface ChannelItemsProps {
  readonly id: string;
  readonly retailerItemId: string;
  readonly channelId: string;
  readonly branchId: string;
  readonly retailerId: string;
  readonly supplierId: string;
  readonly 'name.ar': string;
  readonly 'name.en': string;
  readonly name: string;
  readonly price: string;
  readonly 'uom.name': string;
  readonly itemCode: string;
  readonly 'channel.id': string;
  readonly 'category.id': string;
  readonly 'branch.id': string;
  readonly 'retailer.id': string;
  readonly 'supplier.id': string;
  readonly 'packaging.id': string;
  readonly state: string;
  readonly 'retailerItem.id': string;
  readonly 'inventoryItem.id': string;
  readonly 'supplierItem.id': string;
  readonly preferredType: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly ckStock: number;
}
