import { TenantRegistry } from './tenant-registry.entity';

export enum ProviderEnum {
  Foodics = 'foodics',
  Revel = 'revel',
  Manual = 'manual',
  Omega = 'omega',
  Forkpos = 'forkpos',
  Sahl = 'sahl-hub',
  Grubtech = 'grubtech',
  Ninja = 'ninja',
  Quadranet = 'quadranet',
  LightSpeed = 'lightspeed',
  Marn = 'marn',
  MobiPos = 'mobi-pos',
  PixelPoint = 'pixel-point',
  LightSpeedX = 'lightspeedx',
  Castle = 'castle',
  DualLink = 'dual-link',
  CompuCash = 'compucash',
  Simphony = 'simphony',
  Geidea = 'geidea',
  LsRetail = 'ls-retail',
  Xero = 'xero',
  OracleNetsuite = 'oracle-netsuite',
  Zoho = 'zoho',
  Quickbooks = 'quickbooks',
  Myob = 'myob',
  Loyverse = 'loyverse',
  Redcat = 'redcat',
  Square = 'square',
  LightSpeedO = 'lightspeedo',
  SquirrelPos = 'squirrel-pos',
  LingaPos = 'lingapos',
}

export enum TenantStateEnum {
  Active = 'active',
  Inactive = 'inactive',
  Archived = 'archived',
  Deleted = 'deleted',
  Revoked = 'revoked',
}

export enum TenantHealthEnum {
  Good = 'good',
  OK = 'ok',
  Expired = 'expired',
  Error = 'error',
  Incomplete = 'incomplete',
}

export enum TenantScope {
  Retailer = 'retailer',
  Branch = 'branch',
  Location = 'location',
}

export enum TenantSyncMethod {
  Push = 'push',
  Pull = 'pull',
}

export enum TenantCategoryEnum {
  Accounting = 'accounting',
  Pos = 'pos',
}

export enum ResourceTypeEnum {
  Branch = 'branch',
  Location = 'location',
  TaxRate = 'tax-rate',
  Supplier = 'supplier',
  AccountingCategory = 'accounting-category',
  SalesType = 'sales-type',
}

export interface TenantSettings {
  readonly blockProviderPosItemsSync?: boolean;
  readonly blockManualPosItemSync?: boolean;
  readonly blockProviderSalesImportSync?: boolean;
  readonly blockManualSalesImportSync?: boolean;
  readonly blockProviderBranchMapping?: boolean;
  readonly lastSyncDate?: Date;
  readonly defaultAccountCode?: string;
  readonly syncMethod?: TenantSyncMethod;
  readonly blockProviderSalesTypes?: boolean;
  readonly blockProviderSalesTypesMapping?: boolean;
  readonly salesTypes?: ProviderSalesType[];
  readonly supportsProviderTenant?: boolean;
}

export interface Tenant {
  readonly id: string;
  readonly name: string;
  readonly providerBrand: ProviderEnum;
  readonly state: TenantStateEnum;
  readonly category: TenantCategoryEnum;
  readonly registries?: TenantRegistry[];
  readonly settings?: TenantSettings;
  readonly retailerId?: string;
  readonly providerName?: string;
  readonly providerTenantId?: string;
  readonly status?: TenantHealthEnum;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface TenantBranchesCount {
  readonly tenantId: string;
  readonly count: number;
}

export interface ProviderTenant {
  readonly id: string;
  readonly displayName: string;
}

export interface ProviderBranch {
  readonly id: string;
  readonly displayName: string;
  readonly phone?: string;
  readonly metadata?: Record<string, unknown>;
}

export interface ProviderLocation {
  readonly id: string;
  readonly displayName: string;
  readonly code: string;
  readonly metadata?: Record<string, unknown>;
}

export interface ProviderSupplier {
  readonly id: string;
  readonly displayName: string;
  readonly metadata?: Record<string, unknown>;
}

export interface ProviderItem {
  readonly id: string;
  readonly number: string;
  readonly displayName: string;
  readonly type: string;
  readonly itemCategoryId?: string;
  readonly unitPrice?: number;
  readonly unitCost?: number;
  readonly metadata?: Record<string, unknown>;
}

export interface ProviderItemCategory {
  readonly id: string;
  readonly code: string;
  readonly displayName: string;
  readonly metadata?: Record<string, unknown>;
}

export interface ProviderTaxRate {
  readonly id?: string;
  readonly displayName: string;
  readonly type?: string;
  readonly rate?: number;
  readonly metadata?: Record<string, unknown>;
}

export interface ProviderSalesType {
  readonly code: string;
  readonly name: string;
}

export type ProviderResource =
  | ProviderBranch
  | ProviderLocation
  | ProviderSupplier
  | ProviderTaxRate
  | ProviderItemCategory
  | ProviderSalesType;
