<cdk-tree #tree [dataSource]="dataSource" [treeControl]="treeControl" class="supy-tree">
  <!-- This is the tree node template for leaf nodes -->
  <cdk-tree-node *cdkTreeNodeDef="let node" class="supy-tree-node">
    <div class="supy-tree__node-wrapper" *ngIf="!node.hidden && !node.forceHidden" @fadeInOut>
      <span class="supy-tree__title">{{ node.level }} {{ node.name }}</span>
      <div class="supy-tree__select">
        <ng-container [ngSwitch]="isReadonly ?? false">
          <ng-container *ngSwitchCase="false">
            <supy-checkbox (checkedChange)="onToggleSelectNode(node, $event)" [checked]="node.selected"></supy-checkbox>
          </ng-container>
          <ng-container *ngSwitchCase="true">
            <supy-icon
              [name]="node.selected ? 'check-circle' : 'close-circle'"
              [color]="node.selected ? 'success' : 'error'"
              size="small"
            ></supy-icon
          ></ng-container>
        </ng-container>
      </div>
    </div>
  </cdk-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="supy-tree-node">
    <div
      class="supy-tree__node-wrapper"
      [class.supy-tree__node-wrapper--parent]="node?.children?.length"
      *ngIf="!node.hidden && !node.forceHidden"
      (click)="treeControl.toggle(node)"
      @fadeInOut
    >
      <div class="supy-tree__flex">
        <button
          [attr.aria-label]="'Toggle ' + node.name"
          cdkTreeNodeToggle
          class="supy-tree__node-toggle"
          [class.supy-tree__node-toggle-expanded]="treeControl.isExpanded(node)"
        >
          <supy-icon name="arrow-right" color="gray" size="small"></supy-icon>
        </button>
        <span class="supy-tree__title supy-tree__title--has-child"
          >{{ node.level }} {{ node.name }} ({{ getNonParentNodes(node)?.length }})</span
        >
      </div>
      <div class="supy-tree__select" (click)="$event.stopPropagation()">
        <ng-container [ngSwitch]="isReadonly ?? false">
          <ng-container *ngSwitchCase="true">
            <supy-icon
              [name]="
                node.selected
                  ? 'check-circle'
                  : isSomeNodeChildrenSelected(node) && !isAllNodeChildrenSelected(node)
                  ? 'minus-square'
                  : 'close-circle'
              "
              [color]="
                node.selected
                  ? 'success'
                  : isSomeNodeChildrenSelected(node) && !isAllNodeChildrenSelected(node)
                  ? 'info'
                  : 'error'
              "
              size="small"
            ></supy-icon
          ></ng-container>
          <ng-container *ngSwitchCase="false">
            <supy-checkbox
              *ngIf="!isAllChildrenHidden(node)"
              (checkedChange)="onToggleSelectParentNode(node, $event)"
              [indeterminate]="isSomeNodeChildrenSelected(node) && !isAllNodeChildrenSelected(node)"
              [checked]="isAllNodeChildrenSelected(node) || node.selected"
            ></supy-checkbox
          ></ng-container>
        </ng-container>
      </div>
    </div>
    <div class="supy-tree-child" [class.supy-tree-expanded]="treeControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
