export function parseJSON<T>(value: string): T | null {
  if (value && value !== 'undefined') {
    const decodedValue = decodeURIComponent(value);

    try {
      return JSON.parse(decodedValue) as T;
    } catch {
      return decodedValue as T;
    }
  }

  return null;
}

export function parseDateJSON(value: string): Date | null {
  return value && value !== 'undefined' ? new Date(+JSON.parse(value)) : null;
}

export function parseBooleanJSON(value: string | boolean): boolean {
  return (typeof value === 'string' && value === 'true') || value === true;
}
