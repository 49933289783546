import '@angular/localize/init';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { IconType } from '../../../icon';

@Component({
  selector: 'supy-note-adder',
  templateUrl: './note-adder.component.html',
  styleUrls: ['./note-adder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteAdderComponent implements ControlValueAccessor {
  @Input() protected readonly titleText: string = $localize`:@@components.noteAdder.notes:Notes`;
  @Input() protected readonly addText: string = $localize`:@@components.noteAdder.addNote:Add Note`;

  @Input() protected readonly icon: IconType = 'invoice';
  @Input() protected readonly disabled: boolean;
  @Input() protected readonly showDelete: boolean;
  @Input() protected readonly showAdd: boolean = true;
  @Input() protected readonly isLoading: boolean;
  @Input() protected readonly isInitialLoading: boolean;
  @Input() protected readonly emptyMessage: string = $localize`:@@components.noteAdder.noData:No data`;

  @Input() protected set notes(notes: string[]) {
    if (notes) {
      this.notesCopy.set(Array.from(notes));
    }
  }

  @Output() readonly noteAdded: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly noteDeleted: EventEmitter<number> = new EventEmitter<number>();

  readonly form: UntypedFormGroup;
  onChange: (value: string[]) => void;
  onTouched: () => void;

  notesCopy = signal<string[]>([]);
  touched = false;

  constructor(
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }

    this.form = new UntypedFormGroup({
      note: new UntypedFormControl(null, [Validators.required]),
    });
  }

  onAddNote(): void {
    const note = this.form.get('note').value as string;

    if (note) {
      this.notesCopy.update(notes => [...notes, note]);
      this.noteAdded.emit(note);

      this.onChange?.(this.notesCopy());
      this.form.reset({ note: null });
    }
  }

  onDeleteNote(index: number): void {
    this.notesCopy.update(notes => {
      notes.splice(index, 1);

      return notes;
    });
    this.noteDeleted.emit(index);
    this.onChange?.(this.notesCopy());
  }

  writeValue(value: string[]): void {
    this.notesCopy.set(value);
  }

  registerOnChange(onChange: (value: string[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
