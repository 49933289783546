<form class="inventory-event-details" [formGroup]="form">
  <div *ngIf="creationDisabled" class="inventory-event-details__section">
    <div class="inventory-event-details__hint" i18n="@@inventory.event.details.hint">
      This action has been disabled as an event cannot be created before the initial or last submitted stock count date,
      please contact admin for further assistance.
    </div>
  </div>

  <div class="inventory-event-details__section">
    <h5 i18n="@@inventory.event.details.title">Event Details</h5>

    <div class="inventory-event-details__inputs">
      <ng-container *ngIf="eventType === inventoryEventType.Transfer; else notTransfer">
        <div class="inventory-event-details__inputs-row">
          <div class="inventory-event-details__input-group" formGroupName="fromLocation">
            <small i18n="@@inventory.event.details.transferFrom">Transfer From*</small>
            <supy-dropdown-tree
              *ngIf="!editMode; else fromLocationInput"
              name="from-location"
              formControlName="id"
              i18n-placeholder="@@common.selectLocation"
              placeholder="Select Location"
              displayStrategy="path"
              [clearable]="!editMode"
              [data]="locations"
              (valueChange)="onSelectLocation($event)"
            ></supy-dropdown-tree>

            <ng-template #fromLocationInput>
              <supy-input formControlName="name" name="from-location"></supy-input>
            </ng-template>
          </div>
          <div class="inventory-event-details__input-group" formGroupName="toLocation">
            <small i18n="@@inventory.event.details.transferTo">Transfer To*</small>
            <supy-dropdown-tree
              *ngIf="!editMode; else toLocationInput"
              name="to-location"
              formControlName="id"
              placeholder="Select Location"
              i18n-placeholder="@@common.selectLocation"
              displayStrategy="path"
              [clearable]="!editMode"
              [data]="toLocations"
              (valueChange)="onSelectToLocation($event)"
            ></supy-dropdown-tree>

            <ng-template #toLocationInput>
              <supy-input formControlName="name" name="to-location"></supy-input>
            </ng-template>
          </div>
          <div class="inventory-event-details__input-group">
            <p class="inventory-event-details__label--flex">
              <small i18n="@@inventory.event.details.eventDate">Event Date*</small>
              <supy-icon name="info" size="small" [supyTooltip]="timezoneTooltip" position="top"></supy-icon>
              <supy-tooltip #timezoneTooltip>{{ getRetailerTimeZoneHelperMessage(utcOffset) }}</supy-tooltip>
            </p>
            <supy-date-input
              formControlName="eventDate"
              displayFormat="dd MMMM yyyy"
              [minValue]="minDate"
              [maxValue]="maxDate"
              [ianaTimeZone]="ianaTimeZone"
              (valueChange)="dateChanged.emit($event)"
            >
              <supy-input-error *supyErrorIf="'required'" i18n="@@inventory.event.details.eventDateRequired"
                >Event date is required</supy-input-error
              >
            </supy-date-input>
          </div>
          <ng-container [ngTemplateOutlet]="uploadAttachments"></ng-container>
        </div>
      </ng-container>

      <ng-template #notTransfer>
        <div class="inventory-event-details__inputs-row">
          <div formGroupName="location" class="inventory-event-details__input-group">
            <small>Location*</small>
            <supy-dropdown-tree
              name="location"
              formControlName="id"
              placeholder="Select Location"
              i18n-placeholder="@@common.selectLocation"
              displayStrategy="path"
              [clearable]="!editMode"
              [data]="locations"
              (valueChange)="onSelectLocation($event)"
              [disabled]="form.get('location')?.disabled ?? false"
            ></supy-dropdown-tree>
          </div>

          <div class="inventory-event-details__input-group">
            <p class="inventory-event-details__label--flex">
              <small i18n="@@inventory.event.details.eventDate">Event Date*</small>
              <supy-icon name="info" size="small" [supyTooltip]="timezoneTooltip" position="top"></supy-icon>
              <supy-tooltip #timezoneTooltip>{{ getRetailerTimeZoneHelperMessage(utcOffset) }}</supy-tooltip>
            </p>
            <supy-date-input
              formControlName="eventDate"
              displayFormat="dd MMMM yyyy"
              [minValue]="minDate"
              [maxValue]="maxDate"
              [ianaTimeZone]="ianaTimeZone"
              (valueChange)="dateChanged.emit($event)"
            >
              <supy-input-error *supyErrorIf="'required'" i18n="@@inventory.event.details.eventDateRequired"
                >Event date is required</supy-input-error
              >
            </supy-date-input>
          </div>
          <div class="inventory-event-details__input-group">
            <label i18n="@@eventName">Event Name</label>
            <supy-input
              id="event-name"
              name="event-name"
              formControlName="name"
              i18n-placeholder="@@eventName"
              placeholder="Event Name"
            ></supy-input>
          </div>
          <ng-container [ngTemplateOutlet]="uploadAttachments"></ng-container>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="inventory-event-details__section inventory-event-details__section--grow">
    <h5 i18n="@@eventItems">Event Items</h5>
    <div class="inventory-event-details__section--grow">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="inventory-event-details__grid">
    <div class="inventory-event-details__section inventory-event-details__card">
      <h5 i18n="@@common.otherDetails">Other Details</h5>

      <div class="inventory-event-details__input-group">
        <small i18n="@@common.remarksCommentsNotes">Remarks/Comments/Notes</small>
        <supy-textarea
          name="remarks"
          id="remarks"
          formControlName="remarks"
          i18n-placeholder="@@common.remarksCommentsNotesPlaceholder"
          placeholder="Enter your remarks/comments/notes here"
          [rows]="2"
        ></supy-textarea>
      </div>
    </div>

    <div class="inventory-event-details__totals inventory-event-details__card">
      <div class="inventory-event-details__totals-row">
        <span class="inventory-event-details__totals-label" i18n="@@inventory.event.details.totalItems"
          >Total Items</span
        >
        <span class="inventory-event-details__totals-value">{{ totalItems }}</span>
      </div>
      <div class="inventory-event-details__totals-row">
        <span class="inventory-event-details__totals-label" i18n="@@common.totalAmount"
          >Total Amount ({{ currency }})</span
        >
        <span class="inventory-event-details__totals-value">{{ totalAmount | supyPrecision: currencyPrecision }}</span>
      </div>
    </div>
  </div>
</form>

<ng-template #uploadAttachments>
  <div class="inventory-event-details__input-group">
    <small i18n="@@mediaSupporting">Supporting Media</small>
    <supy-multiple-media-uploader
      dimensions="2rem"
      iconSize="small"
      [localUpload]="true"
      [disabled]="filesControl?.disabled"
      [fileUrls]="fileUrls"
      (fileUploaded)="onFilesUploaded($event)"
      (fileRemoved)="onFileRemoved($event)"
    ></supy-multiple-media-uploader>
  </div>
</ng-template>
