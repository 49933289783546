import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IgxListBaseDirective } from '@infragistics/igniteui-angular';

import { ListComponent } from '../list';

@Component({
  selector: 'supy-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  providers: [
    {
      provide: IgxListBaseDirective,
      useExisting: ListComponent,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  @Input() readonly isHeader: boolean;
  @Output() readonly clicked = new EventEmitter<PointerEvent | MouseEvent>();

  onClick(event: PointerEvent | MouseEvent): void {
    this.clicked.emit(event);
  }
}
