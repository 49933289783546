import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SupyCommonModule } from '@supy/common';
import {
  ActivityLogModule,
  AutocompleteModule,
  BreadcrumbModule,
  ButtonModule,
  CheckboxModule,
  ColumnChartModule,
  ComboboxModule,
  DialogModule,
  DonutChartModule,
  DrawerComponent,
  DropdownModule,
  DropdownTreeModule,
  FileUploadModule,
  GridModule,
  GridPocModule,
  IconModule,
  InputModule,
  LoaderModule,
  LoadingOverlayModule,
  PaginationModule,
  RatingModule,
  SelectModule,
  SwitchModule,
  TextareaModule,
  TooltipModule,
} from '@supy/components';
import { UomsModule } from '@supy/uoms';

import {
  CostCentersGridComponent,
  CostCentersMultipleGridComponent,
  CreateRecipeFooterComponent,
  FullWidthRowComponent,
  RecipeCookbookComponent,
  RecipeCostingChartComponent,
  RecipeCostingComponent,
  RecipeCreationDetailsComponent,
  RecipeDetailsFormComponent,
  RecipeIngredientsGridComponent,
  RecipePortionFormComponent,
  RecipePriceSimulatorChartComponent,
  RecipePriceSimulatorComponent,
  RecipesListLegendComponent,
  UpdateRecipesCategoryDrawerComponent,
  UpdateRecipesStateDrawerComponent,
} from './components';

const COMPONENTS = [
  RecipeDetailsFormComponent,
  RecipeIngredientsGridComponent,
  RecipePortionFormComponent,
  RecipeCostingComponent,
  RecipePriceSimulatorComponent,
  RecipeCreationDetailsComponent,
  CreateRecipeFooterComponent,
  CostCentersGridComponent,
  CostCentersMultipleGridComponent,
  RecipeCookbookComponent,
  RecipeCostingChartComponent,
  RecipePriceSimulatorChartComponent,
  FullWidthRowComponent,
  RecipesListLegendComponent,
  UpdateRecipesStateDrawerComponent,
  UpdateRecipesCategoryDrawerComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule,
    SupyCommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ButtonModule,
    InputModule,
    SelectModule,
    ComboboxModule,
    IconModule,
    TooltipModule,
    CheckboxModule,
    BreadcrumbModule,
    DropdownTreeModule,
    DropdownModule,
    LoadingOverlayModule,
    GridModule,
    PaginationModule,
    SwitchModule,
    LoaderModule,
    UomsModule,
    RatingModule,
    FileUploadModule,
    TextareaModule,
    DonutChartModule,
    ActivityLogModule,
    DialogModule,
    ColumnChartModule,
    AutocompleteModule,
    GridPocModule,
    DrawerComponent,
  ],
})
export class InventoryRecipeModule {}
