<supy-grid
  class="active-locations-grid"
  primaryKey="id"
  cellSelection="none"
  [height]="null"
  [allowFiltering]="allowFiltering"
  [data]="locations"
>
  <supy-grid-column
    width="60%"
    field="name"
    header="Location Name"
    i18n-header="@@common.locationName"
  ></supy-grid-column>
  <supy-grid-column width="30%" [filterable]="false">
    <ng-template supyGridCell let-rowData="rowData">
      <div class="active-locations-grid__message">
        <supy-icon *ngIf="rowData?.message" name="info" color="warn"></supy-icon>
        <span>{{ rowData?.message }}</span>
      </div>
    </ng-template>
  </supy-grid-column>
  <supy-grid-column
    width="10%"
    field="active"
    header="Active"
    i18n-header="@@grid.headers.active.label"
    [filterable]="false"
  >
    <ng-template supyGridCell let-rowData="rowData">
      <supy-switch
        [disabled]="rowData.disabled ?? disabled"
        [value]="rowData.active"
        (valueChange)="onValueChange($event, rowData.id)"
      ></supy-switch>
    </ng-template>
  </supy-grid-column>
</supy-grid>
