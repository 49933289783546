import { BehaviorSubject, map, switchMap, takeUntil, tap } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { GridPagingMode, GridSelectionMode, IgxGridComponent, RowType } from '@infragistics/igniteui-angular';

import { ChannelItem, ChannelItemsProps, ChannelItemsService, UpdateChannelItemRequest } from '@supy/channel-items';
import {
  Destroyable,
  PreferredType,
  Query,
  QueryPaging,
  SnackbarService,
  SupplierPartnershipTypeEnum,
  trackByIndex,
  User,
} from '@supy/common';
import { DialogService, GridComponent, InputComponent } from '@supy/components';
import { Country } from '@supy/countries';
import { Span } from '@supy/opentelemetry';
import { getLocalizedName } from '@supy/settings';

import {
  ModifyOrderRequest,
  Order,
  OrderItem,
  OrderStatus,
  OrderUpdateStatus,
  UpdateOrderRequest,
} from '../../../../core';
import { getOrdersProducts } from '../../../../helpers';
import { IModifyOrder, ISaveOrder, OrderListColumn, OrderListColumnType, PartialReceivedOrderItem } from '../../models';
import { AddOrderItemsDialogComponent } from '../add-order-items-dialog';
import { OrdersRequestMetadata, OrdersResponseMetadata } from './../../../../store';

@Component({
  selector: 'supy-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class OrdersListComponent extends Destroyable implements OnInit {
  @ViewChild(GridComponent, { static: true }) readonly grid: GridComponent;

  @Input() readonly canEditOrder: boolean;
  @Input() readonly canConfirmRejectOrder: boolean;
  @Input() readonly isAdmin: boolean;
  @Input() readonly currencyPrecision: number;
  @Input() set rowSelection(selection: GridSelectionMode) {
    this.#rowSelection = selection;
  }

  get rowSelection() {
    return this.#rowSelection;
  }

  get gridRowSelection() {
    return this.#rowSelection === GridSelectionMode.multiple ? GridSelectionMode.none : this.#rowSelection;
  }

  @Input() readonly submitDisabled: Record<string, boolean>;
  @Input() readonly showInvoices: boolean;
  @Input() readonly isLoading: boolean;
  @Input() readonly productsLoading: boolean;
  @Input() readonly columns: OrderListColumn[] = [];
  @Input() readonly allowedSelectionStatuses: Set<OrderStatus> = new Set([
    OrderStatus.Confirmed,
    OrderStatus.Submitted,
    OrderStatus.Modified,
  ]);

  @Input() readonly showPreferredFlag: boolean;
  @Input() readonly mergeParams: boolean;
  @Input() readonly countries: Country[];

  @Input() set data(value: Order[]) {
    const preferredMappedOrders = value?.map(order => {
      if (!this.ordersMap.has(order.id)) {
        this.ordersMap.set(order.id, order);
      }

      return {
        ...order,
        preferredType: order.ordered.some(order => order.preferredType === PreferredType.RevenueSharing)
          ? PreferredType.RevenueSharing
          : PreferredType.Regular,
      };
    });

    if (preferredMappedOrders) {
      this.ordersChange$.next(preferredMappedOrders);
    }
  }

  @Input() protected readonly requestMetadata: OrdersRequestMetadata;
  @Input() protected readonly responseMetadata: OrdersResponseMetadata;
  @Input() protected readonly paginationMode: GridPagingMode;

  @Output() readonly submitOrder: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() readonly saveOrder: EventEmitter<ISaveOrder> = new EventEmitter<ISaveOrder>();
  @Output() readonly deleteDraft: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() readonly confirmOrder: EventEmitter<IModifyOrder> = new EventEmitter<IModifyOrder>();
  @Output() readonly rejectOrder: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly modifyOrder: EventEmitter<IModifyOrder> = new EventEmitter<IModifyOrder>();
  @Output() readonly downloadPdf: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly expandRow: EventEmitter<Order> = new EventEmitter<Order>();
  @Output() readonly orderSelectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() readonly pageChange = new EventEmitter<number>();

  readonly clipboardOptions: IgxGridComponent['clipboardOptions'] = {
    enabled: false,
    copyHeaders: false,
    separator: '\t',
    copyFormatters: false,
  };

  readonly ordersChange$ = new BehaviorSubject<Order[]>([]);
  readonly orders$ = this.ordersChange$.asObservable();
  readonly #orderItemsChange = new BehaviorSubject<Map<string, OrderItem[]>>(new Map());
  readonly orderItems$ = this.#orderItemsChange.asObservable();
  readonly isAddItemsLoadingChanges$ = new BehaviorSubject(false);
  readonly isAddItemsLoading$ = this.isAddItemsLoadingChanges$.asObservable();
  readonly preferredType = PreferredType;
  readonly listColumnType = OrderListColumnType;
  readonly changedReceivedItemsPrice = new Map<string, number>();
  readonly ordersMap = new Map<string, Order>();
  readonly changedReceivedItemsQuantity = new Map<string, number>();
  readonly changedPartialReceivedItems = new Map<string, PartialReceivedOrderItem>();
  readonly selectedOrders = new Set<string>();

  isEditingReceivedItems = false;
  isReceivedItemsTouched = false;
  orderStatus = OrderStatus;
  #rowSelection: GridSelectionMode = GridSelectionMode.none;

  protected readonly trackByIndex = trackByIndex();
  protected readonly trackColumnsByIndex = trackByIndex<OrderListColumn>();

  readonly orderStatusClosure = (status: OrderStatus) => {
    return (row: RowType): boolean => {
      return (row.data as Order).status === status;
    };
  };

  constructor(
    private readonly dialogService: DialogService,
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    private readonly channelItemsService: ChannelItemsService,
    private readonly snackbarService: SnackbarService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.orders$.pipe(takeUntil(this.destroyed$)).subscribe(orders => {
      this.#orderItemsChange.next(getOrdersProducts(orders || []));
    });
  }

  getCurrency(countryId: string): string {
    return this.countries?.find(({ id }) => id === countryId)?.currency;
  }

  getUpdatedBy(order: Order): string {
    const updateActions = order.updates.find(
      update => update.status === OrderUpdateStatus.submitted || update.status === OrderUpdateStatus.draft,
    );

    return updateActions ? `${new User(updateActions.user).fullName} - ${new User(updateActions.user).phone}` : '';
  }

  @Span()
  onToggleRow(data: Order): void {
    const { status } = data;

    this.expandRow.emit(data);

    const gridContainer = this.grid.nativeElement.querySelector('.igx-grid__tr-container');

    if (gridContainer) {
      this.renderer.addClass(gridContainer, `igx-grid__tr-container--${status}`);
    }

    this.isEditingReceivedItems = false;
    this.cdr.detectChanges();
  }

  getOrderedTotal(order: Order, productId: string): number {
    const product =
      order.modified?.find(r => r.productId === productId) ??
      order.ordered.find(r => r.productId === productId) ??
      this.changedPartialReceivedItems.get(productId);

    return product ? product.price * product.quantity : 0;
  }

  getItemsCount(order: Order) {
    return order.status === OrderStatus.Received ? order.received.length : order.ordered.length;
  }

  @Span()
  onSubmitOrder(order: Order): void {
    this.submitOrder.emit(order);
  }

  @Span()
  onDeleteDraft(order: Order): void {
    this.deleteDraft.emit(order);
  }

  @Span()
  onConfirmOrder(order: Order): void {
    const modifyOrderRequest: ModifyOrderRequest = {
      products: order.ordered.map(item => {
        const modifiedItem = order.modified?.find(({ productId }) => productId == item.productId);

        return {
          productId: item.productId,
          quantity: this.changedReceivedItemsQuantity.has(item.productId)
            ? this.changedReceivedItemsQuantity.get(item.productId)
            : modifiedItem
            ? modifiedItem.quantity
            : item.quantity,
        };
      }),
    };

    this.confirmOrder.emit({
      orderId: order.id,
      modifyOrderRequest: this.changedReceivedItemsQuantity.size > 0 ? modifyOrderRequest : null,
    });

    this.resetModifyState();
  }

  @Span()
  onRejectOrder(orderId: string): void {
    this.rejectOrder.emit(orderId);
  }

  @Span()
  onModifyOrder(orderId: string, productId: string, inStock = 0): void {
    const modifyOrderRequest: ModifyOrderRequest = {
      products: [{ productId, quantity: inStock }],
    };

    this.modifyOrder.emit({ orderId, modifyOrderRequest });
  }

  @Span()
  onDownloadPdf(orderId: string): void {
    this.downloadPdf.emit(orderId);
  }

  getOrderTotalAfterVAT(order: Order): number {
    const value =
      order.status === OrderStatus.Received
        ? order.receivedTotal
        : order.modifiedTotal > 0
        ? order.modifiedTotal
        : order.orderedTotal;

    return value * (1 + order.vat / 100);
  }

  @Span()
  onEditClick() {
    this.isEditingReceivedItems = true;
  }

  @Span()
  onSaveClick(order: Order, pushAfterSave: boolean): void {
    this.isEditingReceivedItems = false;

    const channelItems: UpdateChannelItemRequest[] = Array.from(this.changedReceivedItemsPrice.entries()).map(
      ([id, price]) => ({ id, price }),
    );

    const updateOrderRequest: UpdateOrderRequest = {
      type: OrderUpdateStatus.received,
      received: order.received.map(item => ({
        productId: item.productId,
        quantity: this.changedReceivedItemsQuantity.has(item.productId)
          ? this.changedReceivedItemsQuantity.get(item.productId)
          : item.quantity,
      })),
    };

    if (this.isReceivedItemsTouched || pushAfterSave) {
      this.saveOrder.emit({
        orderId: order.id,
        channelItems,
        updateOrderRequest,
        pushAfterSave,
        branchId: order.retailerId,
      });
    }

    this.resetModifyState();
  }

  @Span()
  handleRowSelection() {
    this.orderSelectionChange.emit([...this.selectedOrders]);
  }

  resetModifyState(): void {
    this.isReceivedItemsTouched = false;
    this.changedReceivedItemsPrice.clear();
    this.changedReceivedItemsQuantity.clear();
  }

  onChangePartialOrderItem(
    orderItem: OrderItem,
    value: number,
    inputElement: InputComponent<number>,
    changedProperty: 'quantity' | 'price',
  ): void {
    this.isReceivedItemsTouched = true;

    const targetOrderItem = this.changedPartialReceivedItems.get(orderItem.productId);

    if (value > 0) {
      this.changedPartialReceivedItems.set(orderItem.productId, { ...targetOrderItem, [changedProperty]: value });
      changedProperty === 'quantity'
        ? this.changedReceivedItemsQuantity.set(orderItem.productId, value)
        : this.changedReceivedItemsPrice.set(orderItem.productId, value);
    } else {
      this.changedPartialReceivedItems.set(orderItem.productId, { ...targetOrderItem, [changedProperty]: 0 });
      changedProperty === 'quantity'
        ? this.changedReceivedItemsQuantity.set(orderItem.productId, 0)
        : this.changedReceivedItemsPrice.set(orderItem.productId, 0);
      inputElement.writeValue(0);
    }
  }

  onCheckPartialReceivedOrderItem(checked: boolean, orderItem: OrderItem): void {
    const targetOrderItem = this.changedPartialReceivedItems.get(orderItem.productId);

    this.changedPartialReceivedItems.set(orderItem.productId, {
      ...targetOrderItem,
      checked,
    });
  }

  @Span()
  onEditAndReceiveClick(order: Order): void {
    const currentOrderItems = this.#orderItemsChange.getValue();

    currentOrderItems.get(order.id).forEach(({ productId, price, quantity }) =>
      this.changedPartialReceivedItems.set(productId, {
        productId,
        quantity,
        price,
        checked:
          order.status === OrderStatus.Submitted ||
          order.status === OrderStatus.Confirmed ||
          order.status === OrderStatus.Modified
            ? true
            : order.received.findIndex(orderItem => orderItem.productId === productId) > -1,
        sealed:
          order.status === OrderStatus.PartialReceived &&
          order.received.findIndex(orderItem => orderItem.productId === productId) > -1,
      }),
    );

    this.isEditingReceivedItems = true;
  }

  @Span()
  onReceiveClick(order: Order): void {
    const selectedPartialReceivedOrderItems = Array.from(this.changedPartialReceivedItems.values()).reduce<
      PartialReceivedOrderItem[]
    >((acc, { checked, ...props }) => {
      if (checked) {
        acc.push(props);
      }

      return acc;
    }, []);

    const channelItems = Array.from(selectedPartialReceivedOrderItems).map<UpdateChannelItemRequest>(
      ({ productId: id, price }) => ({
        id,
        price,
      }),
    );

    const updateOrderRequest = {
      type: OrderUpdateStatus.received,
      received: selectedPartialReceivedOrderItems.map(({ productId, quantity }) => ({ productId, quantity })),
    };

    this.saveOrder.emit({
      orderId: order.id,
      channelItems,
      updateOrderRequest,
      branchId: order.retailerId,
    });

    this.isEditingReceivedItems = false;
    this.changedPartialReceivedItems.clear();
  }

  onChangeReceivedOrderItem(
    { productId }: OrderItem,
    value: number,
    input: InputComponent<number>,
    changedProperty: 'quantity' | 'price',
  ): void {
    this.isReceivedItemsTouched = true;

    if (value > 0) {
      changedProperty === 'quantity'
        ? this.changedReceivedItemsQuantity.set(productId, value)
        : this.changedReceivedItemsPrice.set(productId, value);
    } else {
      changedProperty === 'quantity'
        ? this.changedReceivedItemsQuantity.set(productId, 0)
        : this.changedReceivedItemsPrice.set(productId, 0);

      input.writeValue(0);
    }
  }

  getOrderStatusForSupplier(status: OrderStatus): string {
    switch (status) {
      case OrderStatus.Submitted:
        return 'New Order';
      case OrderStatus.Received:
        return 'Delivered';
      case OrderStatus.PartialReceived:
        return 'Partially Delivered';
      default:
        return status;
    }
  }

  getRejectedReason(order: Order): string {
    return order.updates.find(item => item.status === OrderUpdateStatus.rejected)?.comment;
  }

  canShowSupplierAction(order: Order): boolean {
    return (
      this.canConfirmRejectOrder && (order.status === OrderStatus.Submitted || order.status === OrderStatus.Modified)
    );
  }

  outOfStock(item: OrderItem): boolean {
    return item && item.quantity === 0;
  }

  getOrderQuantityForItem(order: Order, productId: string): number {
    return order?.ordered?.find(item => item.productId === productId)?.quantity;
  }

  @Span()
  markInStock(order: Order, productId: string): void {
    this.onModifyOrder(order.id, productId, this.getOrderQuantityForItem(order, productId));
  }

  get isPartialReceiveButtonDisabled(): boolean {
    return Array.from(this.changedPartialReceivedItems.values()).every(({ checked }) => !checked);
  }

  showModified({ status, modified }: Order): boolean {
    return status === OrderStatus.Modified || (modified.length && status === OrderStatus.Confirmed);
  }

  canOrderBePartiallyReceived({ status, ...rest }: Order): boolean {
    return (
      status === OrderStatus.Modified ||
      status === OrderStatus.Confirmed ||
      status === OrderStatus.Submitted ||
      status === OrderStatus.PartialReceived
    );
  }

  isOrderItemPartiallyChecked(order: Order, orderItem: OrderItem): boolean {
    return (
      order.status === OrderStatus.Confirmed ||
      order.status === OrderStatus.Submitted ||
      order.received.findIndex(({ productId }) => orderItem.productId === productId) > -1
    );
  }

  @Span()
  onAddItemsClick(targetOrder: Order): void {
    let addOrderItemsDialog: AddOrderItemsDialogComponent;

    const query = new Query<ChannelItem & ChannelItemsProps>({
      paging: QueryPaging.NoLimit,
      filtering: [
        { by: 'channelId', op: 'eq', match: targetOrder.channelId },
        { by: 'state', op: 'neq', match: 'deleted' },
      ],
    });

    this.isAddItemsLoadingChanges$.next(true);

    this.channelItemsService
      .getChannelItems(query)
      .pipe(
        takeUntil(this.destroyed$),
        map(({ data }) =>
          data
            .filter(({ id }) => targetOrder.ordered.findIndex(item => item.productId === id) === -1)
            .map(
              product =>
                new OrderItem({
                  ...product,
                  name: getLocalizedName(product.name),
                  productId: product.id,
                  quantity: this.changedPartialReceivedItems.get(product.id)?.quantity ?? 0,
                }),
            ),
        ),
        tap(orderItems => {
          this.isAddItemsLoadingChanges$.next(false);
          addOrderItemsDialog = this.dialogService.openDialog(AddOrderItemsDialogComponent, {
            partyInfo: targetOrder.partyInfo,
            orderItems,
          });
        }),
        switchMap(() => addOrderItemsDialog.orderItemsAdded),
        tap(newlyAddedOrderItems => {
          const currentOrderItems = this.#orderItemsChange.getValue();

          currentOrderItems.set(targetOrder.id, targetOrder.ordered.concat(newlyAddedOrderItems));
          this.#orderItemsChange.next(currentOrderItems);

          newlyAddedOrderItems.forEach(orderItem => {
            this.changedPartialReceivedItems.set(orderItem.productId, { ...orderItem, checked: true });
          });
        }),
      )
      .subscribe(() => {
        addOrderItemsDialog.closeDialog();
      });
  }

  canToggleSelection(order: Order): boolean {
    return this.rowSelection === GridSelectionMode.multiple && this.allowedSelectionStatuses.has(order.status);
  }

  areAllItemsSelected(): boolean {
    return this.ordersChange$.value.every(order => this.selectedOrders.has(order.id));
  }

  anyItemSelected(): boolean {
    return this.selectedOrders.size > 0;
  }

  @Span()
  onItemHeadCheck($event: boolean): void {
    if ($event) {
      this.ordersChange$.value.forEach(order => {
        if (this.canToggleSelection(order)) {
          const { id } = order;

          this.selectedOrders.add(id);
        }
      });
    } else {
      this.selectedOrders.clear();
    }

    this.handleRowSelection();
  }

  @Span()
  onItemCheck(order: Order): void {
    if (this.canToggleSelection(order)) {
      const { id } = order;

      this.selectedOrders.has(id) ? this.selectedOrders.delete(id) : this.selectedOrders.add(id);

      this.handleRowSelection();
    }
  }

  trackByProductId(_: number, orderItem: OrderItem): string {
    return orderItem.productId;
  }

  onItemPriceChange(order: Order, orderItem: OrderItem, value: number, inputElement: InputComponent<number>): void {
    const supplier = order.partyInfo.supplier;

    if (supplier?.metadata?.partnershipType === SupplierPartnershipTypeEnum.Integrated) {
      this.snackbarService.open(
        `${supplier.name} is an Integrated Supplier. Kindly contact Supy or
        <a href="mailto:${supplier?.metadata?.partnershipContact ?? ''}" target="_blank" rel="noopener noreferrer">
        ${supplier.name} Support
        </a>
        to create or manage items for this Supplier.`,
        { variant: 'error' },
      );
      inputElement.writeValue(orderItem.price);
    } else {
      if (this.canOrderBePartiallyReceived(order)) {
        this.onChangePartialOrderItem(orderItem, value, inputElement, 'price');
      } else {
        this.onChangeReceivedOrderItem(orderItem, value, inputElement, 'price');
      }
    }
  }
}
