export interface SankeyLink {
  readonly source: string;
  readonly target: string;
  readonly fromAmount?: number;
  readonly toAmount?: number;
  readonly value: number;
}

export interface SankeyNode {
  readonly id: string;
  readonly name: string;
  readonly nodeId: string;
}

export enum SankeyChartTooltipType {
  From = 'from',
  To = 'to',
  Link = 'link',
}
