import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ResourceEnum, StockCountActionEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
export class Create extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.Create,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class CreateSubCount extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.CreateSubCount,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class Delete extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.Delete,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class DeleteSubCount extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.DeleteSubCount,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class Submit extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.Submit,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class Update extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.Update,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ViewVarianceReport extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.ViewVarianceReport,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class UpdateZero extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.UpdateZero,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ViewList extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.ViewList,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class ViewStockCount extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.StockCount,
      action: StockCountActionEnum.View,
      resource,
      principal,
    });
  }
}

export class StockCountPermissionsStrategy {
  static Create = Create;
  static CreateSubCount = CreateSubCount;
  static Delete = Delete;
  static DeleteSubCount = DeleteSubCount;
  static Submit = Submit;
  static Update = Update;
  static ViewVarianceReport = ViewVarianceReport;
  static UpdateZero = UpdateZero;
  static ViewList = ViewList;
  static View = ViewStockCount;
}
