<form class="supy-note-adder" (ngSubmit)="onAddNote()" [formGroup]="form">
  <div *ngIf="isInitialLoading" class="supy-note-adder--initial-loading"></div>
  <supy-loading-overlay *ngIf="isLoading"></supy-loading-overlay>
  <div class="supy-note-adder__flex-container">
    <h6 class="supy-note-adder__title">
      {{ titleText }}
    </h6>
    <supy-input
      formControlName="note"
      name="note-input"
      placeholder="Type something..."
      i18n-placeholder="@@components.noteAdder.placeholder"
      density="small"
      [clearable]="true"
      [class.supy-note-adder__input--hidden]="disabled"
      (keyup.enter)="onAddNote()"
      [disabled]="disabled"
      [prefix]="icon"
    ></supy-input>
    <div class="supy-note-adder__content">
      <div class="supy-note-adder__row" *ngFor="let note of notesCopy(); let i = index">
        <supy-input
          class="supy-note-adder__note"
          [prefix]="icon"
          [value]="note"
          [disabled]="true"
          density="small"
          [name]="'note-' + i"
        ></supy-input>
        <supy-icon
          *ngIf="showDelete"
          name="delete"
          size="small"
          class="cursor--pointer"
          (click)="onDeleteNote(i)"
        ></supy-icon>
      </div>
    </div>

    <div class="supy-note-adder__empty-template" *ngIf="!notesCopy()?.length">
      <supy-icon name="exclamation-hex" size="medium"></supy-icon>
      <span>{{ emptyMessage }}</span>
    </div>
  </div>
  <div *ngIf="showAdd" [class.supy-note-adder__row--hidden]="disabled">
    <supy-button type="submit" color="secondary" [disabled]="disabled" name="add">
      {{ addText }}
    </supy-button>
  </div>
</form>
