<div class="supy-column-chart">
  <div class="supy-column-chart__legend">
    <igx-legend orientation="Horizontal" name="supy-column-chart-legend" #legend> </igx-legend>
  </div>
  <igx-category-chart
    chartType="Column"
    yAxisMajorStroke="white"
    yAxisStroke="lightgray"
    xAxisStroke="lightgray"
    isCategoryHighlightingEnabled="false"
    isItemHighlightingEnabled="false"
    [yAxisInterval]="yAxisInterval"
    yAxisLabelLeftMargin="0"
    yAxisTitleLeftMargin="10"
    [yAxisMinimumValue]="yAxisMinimumValue"
    [yAxisMaximumValue]="yAxisMaximumValue"
    yAxisTitleRightMargin="10"
    [yAxisTitle]="yAxisLabel"
    [dataSource]="entries"
    [brushes]="brushes"
    isHorizontalZoomEnabled="false"
    isVerticalZoomEnabled="false"
    crosshairsDisplayMode="None"
    name="supy-column-chart"
  >
  </igx-category-chart>
</div>
