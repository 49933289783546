import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';

import { SupyCommonModule } from '@supy/common';
import { ButtonModule, DialogModule, FileUploadModule, IconModule, PageModule, SelectModule } from '@supy/components';

import { ImportPosItemsDialogComponent } from './components';
import { PosItemState } from './store';

@NgModule({
  declarations: [ImportPosItemsDialogComponent],
  exports: [ImportPosItemsDialogComponent],
  imports: [
    CommonModule,
    SupyCommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    IconModule,
    PageModule,
    FileUploadModule,
    DialogModule,
    SelectModule,
    NgxsModule.forFeature([PosItemState]),
  ],
})
export class PosItemModule {}
