import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CellType } from '@infragistics/igniteui-angular';

export interface GridContextMenuItem {
  readonly name: string;
  readonly handler: (cell: CellType) => void;
}

@Component({
  selector: 'supy-grid-context-menu',
  templateUrl: './grid-context-menu.component.html',
  styleUrls: ['./grid-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridContextMenuComponent {
  @Input()
  protected readonly menuLeft = 0;

  @Input()
  protected readonly menuTop = 0;

  @Input()
  protected readonly targetCell: CellType;

  @Input()
  protected readonly items: GridContextMenuItem[] = [];

  protected handleClick(item: GridContextMenuItem): void {
    item.handler(this.targetCell);
  }
}
