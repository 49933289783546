import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';

import { IWebSocketClientOptions, WebSocketClient } from '../client';

export const WEBSOCKET_CLIENT_OPTIONS = new InjectionToken<IWebSocketClientOptions>('WEBSOCKET_CLIENT_OPTIONS');

@Injectable()
export class WebSocketClientService extends WebSocketClient implements OnDestroy {
  constructor(
    @Inject(WEBSOCKET_CLIENT_OPTIONS)
    protected readonly options: IWebSocketClientOptions,
  ) {
    super(options);
  }

  ngOnDestroy(): void {
    this.disconnect();
  }
}
