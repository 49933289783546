import { Workbook } from 'exceljs';

import { createWorksheet, DataColumn, saveWorkbook } from '@supy/common';

import { SalesTransaction, SalesTransactionStateEnum } from '../..';

type SheetValue = Record<string, string | number | Date | undefined>;
type SheetValues = Array<SheetValue>;

export function downloadTransactionsReport(transactions: SalesTransaction[], branchName: string): void {
  const columns: DataColumn[] = [
    { header: 'Sales Date', key: 'businessDay' },
    { header: 'POS Item ID', key: 'id' },
    { header: 'POS Item Name (* - modifier)', key: 'itemName' },
    { header: 'Supy Recipe Name', key: 'recipeName' },
    { header: 'Quantity Sold', key: 'quantity' },
    { header: 'Total Discount Value', key: 'discount' },
    { header: 'Total Sales Incl. Tax', key: 'inclTax' },
    { header: 'Total Sales Excl. Tax', key: 'exclTax' },
  ];

  const qtyCols = new Set<string>(['quantity']);
  const currencyCols = new Set<string>(['discount', 'inclTax', 'exclTax']);
  const dateCols = new Set<string>(['businessDay']);

  const unmappedSheetValues: SheetValues = [];
  const unlinkedSheetValues: SheetValues = [];
  const canceledSheetValues: SheetValues = [];
  const syncedSheetValues: SheetValues = [];

  transactions.forEach(transaction => {
    const sheetRow: SheetValue = {
      businessDay: new Date(transaction.businessDay),
      id: transaction.posItem?.code,
      itemName: transaction.parentItem ? `*${transaction.posItem?.name}` : transaction.posItem?.name,
      recipeName: transaction.linkedRecipe?.name?.en,
      quantity: transaction.quantity,
      discount: transaction.totalSales?.discount,
      inclTax: transaction.totalSales?.inclTax,
      exclTax: transaction.totalSales?.exclTax,
    };

    switch (transaction.state) {
      case SalesTransactionStateEnum.Unmapped:
        unmappedSheetValues.push(sheetRow);
        break;
      case SalesTransactionStateEnum.Unlinked:
        unlinkedSheetValues.push(sheetRow);
        break;
      case SalesTransactionStateEnum.Canceled:
        canceledSheetValues.push(sheetRow);
        break;
      case SalesTransactionStateEnum.Synced:
      case SalesTransactionStateEnum.Processed:
        syncedSheetValues.push(sheetRow);
        break;
    }
  });

  const workbook = new Workbook();

  createWorksheet(workbook, 'Unmapped', { data: unmappedSheetValues, columns, qtyCols, currencyCols, dateCols });
  createWorksheet(workbook, 'Unlinked', { data: unlinkedSheetValues, columns, qtyCols, currencyCols, dateCols });
  createWorksheet(workbook, 'Canceled', { data: canceledSheetValues, columns, qtyCols, currencyCols, dateCols });
  createWorksheet(workbook, 'Synced', { data: syncedSheetValues, columns, qtyCols, currencyCols, dateCols });

  saveWorkbook(workbook, `sales-transactions-${branchName}-${Date.now()}.xlsx`);
}
