import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GrnStatusBadgeMapper, GrnStatusWithNew } from '@supy/orders';

@Component({
  selector: 'supy-grn-status-badge',
  templateUrl: './grn-status-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrnStatusBadgeComponent {
  @Input() readonly status: GrnStatusWithNew;
  @Input() readonly text?: string;

  get statusText() {
    return this.text ?? this.status;
  }

  readonly grnStatusMapper = GrnStatusBadgeMapper;
}
