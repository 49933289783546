import { Attachment, EntityListDateRangeFilter, LocalFile } from '@supy/common';
import { PackagingUnitRequest } from '@supy/packaging';

export enum InventoryEventType {
  Wastage = 'wastage',
  Production = 'production',
  Transfer = 'transfer',
}

export enum InventoryEventStateEnum {
  Archived = 'archived',
  Submitted = 'submitted',
  Draft = 'draft',
}

export interface InventoryEventState {
  readonly name: InventoryEventStateEnum;
  readonly sort: number;
}

export interface InventoryEventFilters extends EntityListDateRangeFilter {
  readonly name: string | null;
  readonly locations: string[];
  readonly archived: boolean;
}

export interface InventoryEventDetailsRequest {
  readonly eventDate?: Date;
  readonly remarks?: string | null;
  readonly attachments?: Attachment[];
  readonly localFiles?: LocalFile[];
}

export interface InventoryEventItemRequest {
  readonly id?: string;
  readonly quantity: number;
  readonly packagingUnit?: PackagingUnitRequest;
}

export interface InventoryEventItemProps {
  readonly locationId: string;
  readonly term: string;
  readonly eventDate: Date;
}

export interface InventoryEventRequestProps {
  readonly id: string;
  readonly name: string;
  readonly 'retailer.id': string;
  readonly 'location.id': string;
  readonly 'state.name': string;
  readonly 'state.sort': string;
  readonly eventDate: Date;
  readonly createdAt: Date;
  readonly updatedAt: Date;
}

export interface UploadInventoryEventAttachmentQueryParams {
  readonly retailerId: string;
}

export interface UploadInventoryEventAttachmentResponse {
  readonly path: string;
}
