import { first, startWith, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IANATimezone } from '@supy.api/dictionaries';

import { DialogOffset } from '../../dialog';
import { MaintenanceInfo, MaintenanceInfoService } from './maintenance-info.service';
import { MaintenanceOverlayService } from './maintenance-overlay.service';
import { ROUTES_MAP } from './routes-map.const';

export interface MaintenanceUserInfo {
  readonly userId: string;
  readonly retailerId: string;
}

@Injectable({
  providedIn: 'root',
})
export class MaintenanceCheckerService {
  private maintenanceCheckSubscription: Subscription;
  private userInfo: MaintenanceUserInfo = { userId: null, retailerId: null };

  constructor(
    private readonly router: Router,
    private readonly maintenanceService: MaintenanceInfoService,
    private readonly overlayService: MaintenanceOverlayService,
  ) {
    this.initMonitoring();
  }

  patchUserInfo(userInfo: Partial<MaintenanceUserInfo>): void {
    this.userInfo = {
      ...this.userInfo,
      ...userInfo,
    };
  }

  setTimezone(ianaTimeZone: IANATimezone) {
    this.overlayService.setTimezone(ianaTimeZone);
  }

  setOffset(offsetTop: DialogOffset) {
    this.overlayService.setOffset(offsetTop);
  }

  destroy(): void {
    if (this.maintenanceCheckSubscription) {
      this.maintenanceCheckSubscription.unsubscribe();
    }
  }

  private initMonitoring(): void {
    this.maintenanceCheckSubscription = this.maintenanceService
      .getMaintenanceInfo()
      .pipe(map(info => info.find(({ type }) => type === 'web')))
      .subscribe(maintenanceInfo => {
        if (!maintenanceInfo) {
          return this.overlayService.hideOverlay();
        }

        const now = new Date();
        const start = new Date(maintenanceInfo.startDate);
        const end = new Date(maintenanceInfo.endDate);
        const isMaintenanceTime = now >= start && now <= end;

        if (isMaintenanceTime) {
          this.showOverlayIfNeeded(maintenanceInfo);
        } else {
          this.overlayService.hideOverlay();
        }
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(new NavigationEnd(0, '/', '/')),
      )
      .subscribe(() => {
        this.maintenanceService
          .getMaintenanceInfo()
          .pipe(
            map(info => info.find(({ type }) => type === 'web')),
            first(Boolean),
          )
          .subscribe(maintenanceInfo => {
            this.showOverlayIfNeeded(maintenanceInfo);
          });
      });
  }

  private showOverlayIfNeeded(maintenanceInfo: MaintenanceInfo): void {
    if (this.canAccessFeature(maintenanceInfo)) {
      this.overlayService.hideOverlay();

      return;
    }

    const start = new Date(maintenanceInfo.startDate);
    const end = new Date(maintenanceInfo.endDate);
    const isMaintenanceTime = this.maintenanceService.isMaintenanceTime(maintenanceInfo);

    const isWholeAppDisabled = maintenanceInfo.features.some(feature => feature === '*' || feature === '/');

    if (isMaintenanceTime && isWholeAppDisabled) {
      return this.overlayService.showOverlay(start, end, maintenanceInfo.notificationMessage);
    }

    const { baseRoute, pathSegments } = this.getCurrentRouteOrFeature();
    const path = pathSegments.join('/');

    const currentRoutes = [...new Set([baseRoute, path])];

    const affectedFeatures = currentRoutes.reduce((acc, curr) => {
      const features = ROUTES_MAP.get(curr);

      if (features) {
        features.forEach(feature => {
          acc.add(feature);
          acc.add(feature.split('.')[0]);
        });
      }

      return acc;
    }, new Set<string>());

    const underMaintenance = maintenanceInfo.features.some(feature => affectedFeatures.has(feature));

    if (isMaintenanceTime && underMaintenance) {
      this.overlayService.showOverlay(start, end, maintenanceInfo.notificationMessage);
    } else {
      this.overlayService.hideOverlay();
    }
  }

  private getCurrentRouteOrFeature(): { baseRoute: string; pathSegments: string[] } {
    let currentRoute: ActivatedRoute = this.router.routerState.root;
    let fullPath = '';

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;

      const url = currentRoute.snapshot?.url ?? [];

      if (url.length) {
        fullPath += '/' + url.map(segment => segment.path).join('/');
      }
    }

    // Extract the base route, ignoring any language prefix
    const pathSegments = fullPath
      .split('/')
      .filter(path => path && !path.match(/^(en|fr|de|es)$/))
      .slice(0, 2);
    const baseRoute = pathSegments.length > 0 ? pathSegments[0] : '';

    return { baseRoute, pathSegments };
  }

  private canAccessFeature(maintenanceInfo: MaintenanceInfo): boolean {
    const {
      whitelist: { retailerIds, userIds },
    } = maintenanceInfo;
    const { retailerId, userId } = this.userInfo;

    return retailerIds?.includes(retailerId) || userIds?.includes(userId);
  }
}
