import { CreateSupplierBFFRequest, Query, Supplier, User } from '@supy/common';

import { CreateSupplierRequest, SearchSuppliersRequest, UpdateSupplierRequest } from '../../core';

export class GetSuppliers {
  static type = '[Suppliers] GetSuppliers';

  constructor(public query: Query<Supplier>) {}
}

export class GetSupplier {
  static type = '[Suppliers] GetSupplier';

  constructor(public id: string) {}
}

export class GetSupplierBff {
  static type = '[Suppliers] GetSupplierBff';

  constructor(public payload: { supplierId: string; retailerId: string }) {}
}

export class ResetSelectedSupplier {
  static type = '[Suppliers] ResetSelected';
}

export class GetSupplierUsers {
  static type = '[Suppliers] GetSupplierUsers';

  constructor(
    public id: string,
    public query: Query<User>,
  ) {}
}

export class GetUserPartners {
  static type = '[Suppliers] GetUserPartners';

  constructor(
    public id: string,
    public query: Query<Supplier>,
    public relativeTo: 'branch' | 'retailer',
  ) {}
}

export class GetCurrentUserPartners {
  static type = '[Suppliers] GetCurrentUserPartners';

  constructor(
    public relativeTo: 'branch' | 'retailer',
    public forceFetch = false,
  ) {}
}

export class CreateSupplier {
  static type = '[Suppliers] CreateSupplier';

  constructor(public body: CreateSupplierRequest) {}
}

export class UpdateSupplier {
  static type = '[Suppliers] UpdateSupplier';

  constructor(
    public id: string,
    public body: UpdateSupplierRequest,
  ) {}
}

export class DeleteSupplier {
  static type = '[Suppliers] DeleteSupplier';

  constructor(public id: string) {}
}

export class ManageSupplierUsers {
  static type = '[Suppliers] ManageSupplierUsers';

  constructor(
    public id: string,
    public users: string[],
    public type: 'add' | 'remove',
  ) {}
}

export class SearchSuppliers {
  static type = '[Suppliers] SearchSuppliers';

  constructor(public payload: SearchSuppliersRequest) {}
}

export class CreateSupplierBff {
  static type = '[Suppliers] CreateSupplierBff';

  constructor(public payload: CreateSupplierBFFRequest) {}
}

export class UpdateSupplierBff {
  static type = '[Suppliers] UpdateSupplierBff';

  constructor(
    public id: string,
    public payload: CreateSupplierBFFRequest,
  ) {}
}

export class ResetCreateUpdateSupplierResponse {
  static type = '[Suppliers] ResetCreateUpdateSupplierResponse';
}
