import { SaveVarianceReportRequest, SubmitVarianceReportRequest } from '../../core';
import { InventoryVarianceFilters, InventoryVarianceRequestMetadata } from '../state/inventory-variance.state';

export class InventoryVarianceGetMany {
  static readonly type = '[InventoryVariance] GetMany';
}

export class InventoryVarianceGetAll {
  static readonly type = '[InventoryVariance] GetAll';
}

export class InventoryVarianceInitFilters {
  static readonly type = '[InventoryVariance] InitFilters';
}

export class InventoryVariancePatchFilter {
  static readonly type = '[InventoryVariance] PatchFilter';
  constructor(readonly payload: Partial<InventoryVarianceFilters>) {}
}

export class InventoryVarianceResetFilter {
  static readonly type = '[InventoryVariance] ResetFilter';
}

export class InventoryVariancePatchRequestMeta {
  static readonly type = '[InventoryVariance] PatchRequestMeta';
  constructor(readonly payload: Partial<InventoryVarianceRequestMetadata>) {}
}

export class InventoryVarianceSubmitReport {
  static readonly type = '[InventoryVariance] SubmitReport';

  constructor(readonly payload: SubmitVarianceReportRequest) {}
}

export class InventoryVarianceSubmitReportDispatched {
  static readonly type = '[InventoryVariance] SubmitReportDispatched';
  constructor(
    readonly success: boolean,
    readonly message: string,
  ) {}
}

export class InventoryVarianceSaveReport {
  static readonly type = '[InventoryVariance] SaveReport';

  constructor(
    readonly stockCountId: string,
    readonly payload: SaveVarianceReportRequest,
  ) {}
}

export class InventoryVarianceGetTotals {
  static readonly type = '[InventoryVariance] GetTotals';

  constructor(readonly stockCountId: string) {}
}
