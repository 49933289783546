import * as d3 from 'd3';
import { Axis, BaseType, NumberValue, ScaleBand, ScaleOrdinal } from 'd3';
import { AxisScale } from 'd3-axis';
import { ScaleLinear } from 'd3-scale';
import { Selection } from 'd3-selection';
import { Subject, takeUntil } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';

import { SkeletonObjectType } from '@supy/common';

import { calculateNumberTick, ChartBaseDirective, getTooltipPosition } from '../../../chart-core';
import { StackedBarChartD3ReturnData, StackedBarGroupData, StackedChartData } from './stacked-bar-chart-d3.interfaces';
import { ChartColor, COLORS_MAP, GRADIENTS_MAP } from './stacked-bar-chart-d3-colors.map';

export interface HorizontalScaleConfig {
  // Percentage value
  readonly yAxisLabelWidth: number;
  // Percentage value
  readonly barWidth: number;
}

@Component({
  selector: 'supy-stacked-bar-chart-d3',
  templateUrl: './stacked-bar-chart-d3.component.html',
  styleUrls: ['./stacked-bar-chart-d3.component.scss'],
})
export class StackedBarChartD3Component
  extends ChartBaseDirective<StackedBarChartD3ReturnData>
  implements OnChanges, OnDestroy
{
  private static id = 0;
  private readonly id: number = 0;
  @Input() set data(value: StackedBarGroupData[] | StackedChartData[]) {
    if (value) {
      // FIXME: temporary code for backward compatibility
      const data: StackedBarGroupData[] = value[0].label
        ? (value as StackedBarGroupData[])
        : (value as StackedChartData[]).reduce<StackedBarGroupData[]>((acc, curr) => {
            const item: StackedBarGroupData = {
              label: curr.name,
              ...curr,
              datasets: [curr],
            };
            const index = acc.findIndex(({ id }) => id === item.id);

            if (index === -1) {
              acc.push(item);
            } else {
              acc[index].datasets.push(...item.datasets);
            }

            return acc;
          }, []);

      const sortedData = Array.from(data ?? [])
        .map(group => ({
          ...group,
          total: group.total ?? group.datasets.reduce((acc, curr) => acc + curr.total, 0),
        }))
        .sort((a, b) => b.total - a.total);

      this.#data =
        this.hasVerticalScroll || this.hasHorizontalScroll
          ? sortedData
          : sortedData.slice(0, this.maxBars ?? (value.length || 0));

      if (this.hasVerticalScroll || this.hasHorizontalScroll) {
        this.barsAmount = value.length || 0;
      }
    }
  }

  get data(): StackedBarGroupData[] {
    return this.#data;
  }

  #data: StackedBarGroupData[] = [];
  @Input() readonly maxBars: number;

  @HostBinding('class.supy-stacked-bar-chart__horizontal-scroll')
  @Input()
  protected set hasHorizontalScroll(scrollable: boolean) {
    if (scrollable) {
      this.barsAmount = this.#data.length || 0;
      this.createChart();
    }
    this.#hasHorizontalScroll = scrollable;
  }

  protected get hasHorizontalScroll(): boolean {
    return this.#hasHorizontalScroll;
  }

  @HostBinding('class.supy-stacked-bar-chart__vertical-scroll')
  @Input()
  protected set hasVerticalScroll(scrollable: boolean) {
    if (scrollable) {
      this.barsAmount = this.#data.length || 0;
      this.createChart();
    }
    this.#hasVerticalScroll = scrollable;
  }

  protected get hasVerticalScroll(): boolean {
    return this.#hasVerticalScroll;
  }

  #hasVerticalScroll = false;
  #hasHorizontalScroll = false;

  @Input() readonly colors: string[] = [];
  @Input() set horizontalScaleConfig(value: HorizontalScaleConfig) {
    if (value) {
      this.#horizontalScaleConfig = value;
      this.createChart();
    }
  }

  get horizontalScaleConfig(): HorizontalScaleConfig {
    return this.#horizontalScaleConfig;
  }

  #horizontalScaleConfig: HorizontalScaleConfig = {
    yAxisLabelWidth: 0.2,
    barWidth: 0.75,
  };

  /**
   * Sets predefined color shades list based on the color name.
   * This input has higher priority than `colors` -in case both are passed, this one is used.
   *
   * @param colorShades ChartColor One of Aroma color names.
   */
  @Input() readonly colorShades: ChartColor;

  @Input() readonly useGradient: boolean;

  @Input() readonly showSeparateLabels: boolean;

  @Input() readonly rotateTotals: boolean;

  /**
   * Sets keys that will be used to render stacks for each bar
   * Don't set it if each data object have different properties so component is responsible for correct colors &
   * keys calculations
   *
   * @param keys Array<String> list of object keys to use to get values for chart
   */
  @Input() set keys(keys: string[]) {
    this.#keys = keys ?? [];
    this.predefinedKeys = keys?.length > 0;
  }

  get keys(): string[] {
    return this.#keys;
  }

  /**
   * Sets the outer padding to the specified value which must be in the range [0, 1].
   * The outer padding determines the ratio of the range that is reserved for blank space before
   * the first band and after the last band.
   *
   * The default setting is 0.3.
   *
   * @param paddingOuter Value for outer padding in [0, 1] interval.
   */
  @Input() readonly paddingOuter: number = 0.3;

  /**
   * Sets the inner padding to the specified value which must be in the range [0, 1].
   * The inner padding determines the ratio of the range that is reserved for blank space between bands.
   *
   * The default setting is 0.5.
   *
   * @param paddingInner Value for inner padding in [0, 1] interval.
   */
  @Input() readonly paddingInner: number = 0.5;
  @Input() readonly dataAxisLabel: string = '';

  /**
   * Sets number ticks format according to https://github.com/d3/d3-format
   *
   * The default setting is ',.2r' - grouped thousands with two significant digits, "4,200".
   *
   * @param numberTickSpecifier An optional format specifier to customize how the tick values are formatted.
   */
  @Input() readonly horizontal: boolean;
  @Input() readonly isDisabled: boolean;
  @Input() readonly isBarClickable: boolean;
  @Input() readonly isLabelClickable: boolean;
  @Input() readonly isHoverable = true;
  @Input() readonly withNegativity: boolean;
  @Input() readonly fullBarHovering: boolean;
  @Input() readonly showLegend: boolean;
  @Input() readonly showTotals: boolean = true;
  @Input() readonly xAxisShow: boolean = true;
  @Input() readonly yAxisShow: boolean = true;

  @Output()
  readonly barClick = new EventEmitter<StackedBarChartD3ReturnData>();

  @Output()
  readonly labelClick = new EventEmitter<SkeletonObjectType>();

  private scaleBand: ScaleBand<string>;
  private scaleLinear: ScaleLinear<number, number>;
  private bottomLinearAxis: Axis<NumberValue>;
  private leftBandAxis: Axis<string>;
  private bottomBandAxis: Axis<string>;
  private leftLinearAxis: Axis<NumberValue>;
  private z: ScaleOrdinal<string, unknown>; // D3 color provider
  private g: Selection<SVGGElement, unknown, null, undefined>;
  private defs: Selection<SVGDefsElement, unknown, null, undefined>; // Top level SVG element
  private readonly keysMap = new Map<string, [number, number]>();
  private predefinedKeys = false;
  private readonly click$ = new Subject<MouseEvent>();
  #keys: string[] = [];

  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly ngZone: NgZone,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(elementRef, cdr);
    StackedBarChartD3Component.id += 1;
    this.id = StackedBarChartD3Component.id;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.removeExistingChartFromParent();
  }

  @HostListener('document:click', ['$event'])
  protected onClick(event: MouseEvent): void {
    this.click$.next(event);
  }

  createChart(): void {
    this.ngZone.runOutsideAngular(() => {
      this.initializeSVG();

      if (this.useGradient) {
        this.initializeGradient();
      }

      this.setScales();

      if (!this.predefinedKeys) {
        this.setDefaultDataKeys();
      }

      if (!this.data.length) {
        return;
      }

      this.setDomains();

      this.renderChart();
    });

    if (!this.isDisabled) {
      this.addEventListeners();
    }
  }

  protected renderChart() {
    if (this.horizontal) {
      this.renderHorizontalData();
      this.renderHorizontalAxes();
    } else {
      this.renderVerticalData();
      this.renderVerticalAxes();
    }
  }

  private removeExistingChartFromParent(): void {
    // !!!!Caution!!!
    // Make sure not to do;
    //     d3.select('svg').remove();
    // That will clear all other SVG elements in the DOM
    d3.select(this.elementRef.nativeElement).select('svg').remove();
  }

  private setScales(): void {
    this.horizontal ? this.setHorizontalScales() : this.setVerticalScales();
  }

  private setVerticalScales(): void {
    const margin = {
      top: 50,
      right: 0,
      bottom: 20,
      left: 0,
    };

    const heightOffset = 0 - margin.top - margin.bottom;

    this.setSizes(margin, heightOffset);

    this.scaleBand = (this.scaleBand ?? d3.scaleBand())
      .range([0, this.width])
      .paddingOuter(this.yAxisShow ? this.paddingOuter : 0)
      .paddingInner(this.paddingInner)
      .align(0.5);

    this.scaleLinear = (this.scaleLinear ?? d3.scaleLinear()).range([this.height, 0]);

    this.z = this.colorShades
      ? d3.scaleOrdinal().range(COLORS_MAP.get(this.colorShades))
      : this.colors?.length
      ? d3.scaleOrdinal().range(this.colors)
      : d3.scaleOrdinal(d3.schemePaired) /* 12 colors */;
  }

  private setHorizontalScales(): void {
    const margin = { top: 30, right: 10, bottom: 20, left: 0 };

    this.setSizes(margin);

    this.scaleBand = (this.scaleBand ?? d3.scaleBand())
      .range([0, this.height])
      .paddingOuter(this.paddingOuter)
      .paddingInner(this.paddingInner)
      .align(0.5);

    this.scaleLinear = (this.scaleLinear ?? d3.scaleLinear()).range([
      0,
      this.width * this.horizontalScaleConfig.barWidth,
    ]);

    this.z = this.colorShades
      ? d3.scaleOrdinal().range(COLORS_MAP.get(this.colorShades))
      : this.colors.length
      ? d3.scaleOrdinal().range(this.colors)
      : d3.scaleOrdinal(d3.schemePaired) /* 12 colors */;
  }

  protected setSizes(margin: { top: number; bottom: number; left: number; right: number }, heightOffset = 0): void {
    super.setSizes(margin, heightOffset);
    this.g = (this.g ?? this.svg.append('g')).attr('transform', `translate(${margin.left},${margin.top})`);
  }

  private setDefaultDataKeys(): void {
    this.#keys = [
      ...new Set(
        this.data.flatMap(item =>
          item.datasets.reduce<string[]>((acc, curr) => {
            Object.keys(curr)
              .filter(key => !['name', 'total', 'id', 'metadata', 'extraTotal'].includes(key))
              .forEach((key, index, array) => {
                if (!this.keysMap.has(key)) {
                  this.keysMap.set(key, [array.length, index]);
                }
                acc.push(key);
              });

            return acc;
          }, []),
        ),
      ),
    ];
  }

  private setDomains(): void {
    if (!scalesMatch(this.scaleBand, this.data, (d: StackedBarGroupData) => d.label)) {
      this.scaleBand.domain(this.data.map(({ label }) => label));
    }

    const maxTotal = d3.max(this.data, d => d3.max(d.datasets, dataset => dataset.total));

    this.scaleLinear.domain([0, maxTotal]).nice();

    this.z.domain(this.keys);
  }

  private renderVerticalData(): void {
    this.g.selectAll('.group').remove();

    // Create groups for each main category
    const groups = this.g
      .selectAll('.group')
      .data(this.data) // Assuming this.data is structured with grouped information
      .enter()
      .append('g')
      .attr('class', 'group')
      .attr('transform', d => `translate(${this.scaleBand(d.label)}, 0)`);

    // Iterate over groups to render stacked bars
    groups.each((groupData, i, nodes) => {
      const g = d3.select(nodes[i]);
      const groupScale = d3
        .scaleBand()
        .domain(groupData.datasets.map(d => d.name))
        .range([0, this.scaleBand.bandwidth()])
        .padding(0.1);

      const stacks = d3.stack().keys(this.keys)(groupData.datasets as unknown as { [key: string]: number }[]);

      // bars
      const bars = g
        .selectAll('.bar')
        .data(stacks)
        .enter()
        .append('g')
        .attr('fill', d => this.getColor(d.key))
        .selectAll('rect')
        .data(d => d.filter(isNotNaN))
        .enter()
        .append('rect')
        .attr('x', d => groupScale(String(d.data.name))) // Use groupScale for x position within the group
        .attr('y', d => this.scaleLinear(d[1]))
        .attr('height', d => this.scaleLinear(d[0]) - this.scaleLinear(d[1]))
        .attr('width', groupScale.bandwidth());

      if (this.showTotals && this.showSeparateLabels) {
        bars.each((d, index, nodes) => {
          const bar = d3.select(nodes[index]);
          const totalText =
            (this.withNegativity && d.data.total > 0 ? '-' : '') +
            `${this.dataAxisLabel} ${d3.format(calculateNumberTick(d.data.total))(d.data.total)}` +
            (d.data.extraTotal ? ` / ${d.data.extraTotal}` : '');

          if (d[0] === 0 && d[1] === 0) {
            return;
          }
          g.append('text')
            .attr('x', +bar.attr('x') + 10)
            .attr('y', +bar.attr('y') + +bar.attr('height') + 5)
            .attr('text-anchor', 'middle')
            .style('font-size', '10px') // Adjust font size as needed
            .text(totalText);
        });
      }
    });

    if (this.showTotals && !this.showSeparateLabels) {
      const totalsSelection = this.g.select('.bar-labels');
      const totalsRootElement: Selection<
        SVGGElement,
        d3.Series<{ [p: string]: number }, string>,
        null,
        undefined
      > = totalsSelection.empty()
        ? (this.g.append('g').classed('bar-labels', true) as Selection<
            SVGGElement,
            d3.Series<{ [p: string]: number }, string>,
            null,
            undefined
          >)
        : (totalsSelection as Selection<SVGGElement, d3.Series<{ [p: string]: number }, string>, null, undefined>);

      const texts = totalsRootElement.selectAll('text').data(this.data);

      // Enter
      const textsEnter = texts
        .enter()
        .append('text')
        .attr('dy', -10)
        .attr('fill', 'black')
        .attr('text-anchor', 'middle')
        .style('font-size', '13px');

      // Update
      const updatedTexts = texts
        .merge(textsEnter)
        .attr('x', d => this.scaleBand(d.label) + this.scaleBand.bandwidth() / 2)
        .attr('y', d => {
          const maxTotal = d3.max(d.datasets, dataset => dataset.total);

          return this.scaleLinear(maxTotal);
        })
        .text(
          d =>
            (this.withNegativity && d.total > 0 ? '-' : '') +
            `${this.dataAxisLabel} ${d3.format(calculateNumberTick(d.total))(d.total)}` +
            (d.extraTotal ? ` / ${d.extraTotal as number}` : ''),
        );

      if (this.rotateTotals) {
        updatedTexts
          .attr('transform', d => {
            const maxTotal = d3.max(d.datasets, dataset => dataset.total);

            const x = this.scaleBand(d.label) + this.scaleBand.bandwidth() / 2;
            const y = this.scaleLinear(maxTotal);

            return `rotate(-35 ${x} ${y - 10})`;
          })
          .attr('text-anchor', 'start');
      }

      // Exit
      texts.exit().remove();
    } else {
      const totalsSelection = this.g.select('.bar-labels');

      totalsSelection.remove();
    }
  }

  private renderHorizontalData(): void {
    this.g.selectAll('.group').remove();

    const selection = this.g.select('g');
    const rootElement: Selection<
      SVGGElement,
      d3.Series<{ [p: string]: number }, string>,
      null,
      undefined
    > = selection.empty()
      ? (this.g.append('g') as Selection<SVGGElement, d3.Series<{ [p: string]: number }, string>, null, undefined>)
      : (selection as Selection<SVGGElement, d3.Series<{ [p: string]: number }, string>, null, undefined>);

    // Create horizontal groups for each main category
    const groups = rootElement
      .selectAll('.group')
      .data(this.data) // This assumes `this.data` is structured accordingly
      .enter()
      .append('g')
      .attr('class', 'group')
      .attr(
        'transform',
        d => `translate(${this.width * this.horizontalScaleConfig.yAxisLabelWidth}, ${this.scaleBand(d.label)})`,
      );

    // Iterate over groups to render stacked bars
    groups.each((groupData, i, nodes) => {
      const g = d3.select(nodes[i]);
      const groupScale = d3
        .scaleBand()
        .domain(groupData.datasets.map(d => d.name))
        .range([0, this.scaleBand.bandwidth()])
        .padding(0);

      const stacks = d3.stack().keys(this.keys)(groupData.datasets as unknown as { [key: string]: number }[]);

      // bars
      const bars = g
        .selectAll('.bar')
        .data(stacks)
        .enter()
        .append('g')
        .attr('fill', d => this.getColor(d.key))
        .selectAll('rect')
        .data(d => d.filter(isNotNaN))
        .enter()
        .append('rect')
        .attr('y', d => groupScale(String(d.data.name))) // Use groupScale for y position within the group
        .attr('x', d => {
          const scaleValue = d[0] < 0 ? 0 : d[0];

          return this.scaleLinear(scaleValue);
        })
        .attr('width', d => {
          const width = this.scaleLinear(d[1]) - this.scaleLinear(d[0]);

          return width < 0 ? 5 : width;
        })
        .attr('height', groupScale.bandwidth());

      if (this.showTotals && this.showSeparateLabels) {
        bars.each((d, index, nodes) => {
          const bar = d3.select(nodes[index]);
          const totalText =
            (this.withNegativity && d.data.total > 0 ? '-' : '') +
            `${this.dataAxisLabel} ${d3.format(calculateNumberTick(d.data.total))(d.data.total)}` +
            (d.data.extraTotal ? ` / ${d.data.extraTotal}` : '');

          if (d[0] === 0 && d[1] === 0) {
            return;
          }
          g.append('text')
            .filter(() => +bar.attr('width') > 0)
            .attr('x', +bar.attr('x') + +bar.attr('width') + 5) // Offset to position the text outside the bar
            .attr('y', +bar.attr('height') / 2 + +bar.attr('y')) // Align accordingly to bar height
            .attr('dy', '.35em')
            .attr('text-anchor', 'start') // Adjust based on the direction of text you prefer
            .style('font-size', '10px') // Adjust font size as needed
            .text(totalText);
        });
      }
    });

    if (this.showTotals && !this.showSeparateLabels) {
      const totalsSelection = this.g.select('.bar-labels');
      const totalsRootElement: Selection<
        SVGGElement,
        d3.Series<{ [p: string]: number }, string>,
        null,
        undefined
      > = totalsSelection.empty()
        ? (this.g.append('g').classed('bar-labels', true) as Selection<
            SVGGElement,
            d3.Series<{ [p: string]: number }, string>,
            null,
            undefined
          >)
        : (totalsSelection as Selection<SVGGElement, d3.Series<{ [p: string]: number }, string>, null, undefined>);

      const texts = totalsRootElement.selectAll('text').data(this.data);

      // Enter
      const textsEnter = texts.enter().append('text').attr('dx', 10).attr('fill', 'black').attr('text-anchor', 'start');

      // Update
      texts
        .merge(textsEnter)
        .attr('x', d => {
          const maxTotal = d3.max(d.datasets, dataset => dataset.total);

          return this.scaleLinear(maxTotal) + this.width * this.horizontalScaleConfig.yAxisLabelWidth;
        })
        .attr('y', d => this.scaleBand(d.label))
        .attr('dy', (this.scaleBand.bandwidth() + 10) / 2)
        .style('font-size', '13px')
        .text(
          d =>
            (this.withNegativity && d.total > 0 ? '-' : '') +
            `${this.dataAxisLabel} ${d3.format(calculateNumberTick(d.total))(d.total)}` +
            (d.extraTotal ? ` / ${d.extraTotal as number}` : ''),
        );

      // Exit
      texts.exit().remove();
    } else {
      const totalsSelection = this.g.select('.bar-labels');

      totalsSelection.remove();
    }
  }

  private renderVerticalAxes(): void {
    const xAxisSelection = this.g.select('.x-axis') as Selection<SVGGElement, string, null, undefined>;

    const xAxis = xAxisSelection.empty()
      ? this.g.append('g').classed('axis', true).classed('x-axis', true)
      : xAxisSelection;

    xAxis.attr('transform', `translate(0,${this.height})`);

    if (this.xAxisShow) {
      const shouldCreate = !this.bottomBandAxis;

      if (shouldCreate) {
        this.bottomBandAxis = d3.axisBottom(this.scaleBand);
        xAxis.call(this.bottomBandAxis.tickFormat(() => '').tickSizeOuter(0));
      } else {
        this.bottomBandAxis.scale(this.scaleBand);
        xAxis.transition().duration(500).call(this.bottomBandAxis);
      }

      const ticks = xAxis.selectAll('.tick');

      const ticksArray = [...ticks];

      ticksArray.forEach((tick: SVGGElement) => {
        const d3Tick = d3.select(tick);
        const foreignObjectSelect: d3.Selection<SVGForeignObjectElement, unknown, null, undefined> =
          d3Tick.select('foreignObject');

        const foreignObject = foreignObjectSelect.empty() ? d3Tick.append('foreignObject') : foreignObjectSelect;

        const elem = foreignObject
          .attr('text-anchor', 'end')
          .attr('transform', 'rotate(-35)')
          .attr('x', -70)
          .attr('y', 0)
          .attr('height', 14)
          .attr('width', 67)
          .style('width', 67);

        const divSelect = elem.select('div');

        const div = divSelect.empty() ? elem.append('xhtml:div') : divSelect;

        div
          .text((domainValue: string) => domainValue)
          .classed('clickable', this.isLabelClickable)
          .style('overflow', 'hidden')
          .classed('custom-tick', true)
          .classed('custom-tick-right', true)
          .attr('title', (d: string) => d);
      });
    }

    if (this.yAxisShow) {
      const yAxisSelection = this.g.select('.y-axis') as Selection<SVGGElement, string, null, undefined>;

      const yAxis = yAxisSelection.empty()
        ? this.g.append('g').classed('axis', true).classed('y-axis', true)
        : yAxisSelection;

      const shouldCreate = !this.leftLinearAxis;

      if (shouldCreate) {
        this.leftLinearAxis = d3.axisLeft(this.scaleLinear);
        yAxis
          .call(this.leftLinearAxis.ticks(calculateNumberTick).tickSizeOuter(0))
          .append('text')
          .attr('x', -26)
          .attr('y', -16)
          .attr('dy', '0.32em')
          .attr('fill', '#000')
          .attr('text-anchor', 'start')
          .text(this.dataAxisLabel);
      } else {
        this.leftLinearAxis.scale(this.scaleLinear);
        yAxis.transition().duration(500).call(this.leftLinearAxis);
      }
    }

    this.g.selectAll('.axis path').style('stroke', '#EEEDF2');
  }

  private getColor(key: string): string {
    if (this.useGradient && this.colorShades) {
      return `url(#ColorGradient_${this.id})`;
    } else if (this.predefinedKeys) {
      return this.z(key) as string;
    } else {
      return this.getCustomColorScale(key);
    }
  }

  private renderHorizontalAxes(): void {
    if (this.xAxisShow) {
      const xAxisSelection = this.g.select('.x-axis') as Selection<SVGGElement, string, null, undefined>;

      const xAxis = xAxisSelection.empty()
        ? this.g.append('g').classed('axis', true).classed('x-axis', true)
        : xAxisSelection;

      xAxis.attr('transform', `translate(${this.width * this.horizontalScaleConfig.yAxisLabelWidth},${this.height})`);

      const shouldCreate = !this.bottomLinearAxis;

      if (shouldCreate) {
        this.bottomLinearAxis = d3.axisBottom(this.scaleLinear);
        xAxis
          .call(this.bottomLinearAxis.ticks(0).tickSizeOuter(0))
          .append('text')
          .attr('y', 2)
          .attr('x', this.scaleLinear(this.scaleLinear.ticks().shift()) + 5)
          .attr('dy', '0.32em')
          .attr('fill', '#000')
          .attr('text-anchor', 'start')
          .text(this.dataAxisLabel)
          .selectAll('text');
      } else {
        this.bottomLinearAxis.scale(this.scaleLinear);
        xAxis.transition().duration(500).call(this.bottomLinearAxis);
      }
    }

    const yAxisSelection = this.g.select('.y-axis') as Selection<SVGGElement, string, null, undefined>;

    const yAxis = yAxisSelection.empty()
      ? this.g.append('g').classed('axis', true).classed('y-axis', true)
      : yAxisSelection;

    yAxis.attr('transform', `translate(${this.width * this.horizontalScaleConfig.yAxisLabelWidth}, 0)`);

    if (this.yAxisShow) {
      const shouldCreate = !this.leftBandAxis;

      if (shouldCreate) {
        this.leftBandAxis = d3.axisLeft(this.scaleBand);
        yAxis.call(
          this.leftBandAxis
            .ticks(calculateNumberTick)
            .tickFormat(() => '')
            .tickSizeOuter(0),
        );
      } else {
        this.leftBandAxis.scale(this.scaleBand);
        yAxis.transition().duration(500).call(this.leftBandAxis);
      }

      const ticks = yAxis.selectAll('.tick');

      const ticksArray = [...ticks];

      ticksArray.forEach((tick: SVGGElement) => {
        const d3Tick = d3.select(tick);
        const foreignObjectSelect: d3.Selection<SVGForeignObjectElement, unknown, null, undefined> =
          d3Tick.select('foreignObject');

        const foreignObject = foreignObjectSelect.empty() ? d3Tick.append('foreignObject') : foreignObjectSelect;

        const elem = foreignObject
          .attr('text-anchor', 'start')
          .attr('x', -this.width * this.horizontalScaleConfig.yAxisLabelWidth)
          .attr('y', -6)
          .attr('height', 14)
          .attr('width', this.getLabelWidth()) // use .attr for Firefox
          .style('width', this.getLabelWidth());

        const divSelect = elem.select('div');

        const div = divSelect.empty() ? elem.append('xhtml:div') : divSelect;

        div
          .text((domainValue: string) => domainValue)
          .classed('clickable', this.isLabelClickable)
          .style('overflow', 'hidden')
          .classed('custom-tick', true)
          .attr('title', (d: string) => d);
      });
    }

    this.svg.selectAll('.axis path').style('opacity', 0);
  }

  private addBarClickListener(): void {
    const barClick$ = this.barClick;
    const outerClick$ = this.click$;
    const fillColor = '#413E4C';
    const destroyed$ = this.destroyed$;
    const bars = d3.select(this.elementRef.nativeElement).selectAll('rect');

    bars.on('mouseup', function (_, d: { data: Record<string, string> }) {
      const bar = this as SVGRectElement;
      const parentElement = bar.parentNode as SVGGElement;

      if (bar?.getAttribute('fill') === fillColor) {
        bar.removeAttribute('fill');
        barClick$.emit(null);
      } else {
        const subgroupName = d3.select<BaseType, Record<string, string>>(parentElement).datum().key;
        const subgroupValue = d.data[subgroupName];
        const normalizedName = subgroupName.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
        const data: StackedBarChartD3ReturnData = {
          title: normalizedName,
          value: subgroupValue,
          field: subgroupName,
          data: d.data as StackedChartData,
        };

        bar.setAttribute('fill', fillColor);

        barClick$.emit(data);

        const clickSubscription = outerClick$.pipe(takeUntil(destroyed$)).subscribe((event: MouseEvent) => {
          if (event.target === bar || event.target === parentElement) {
            return;
          }

          bar.removeAttribute('fill');
          clickSubscription.unsubscribe();
        });
      }
    });
  }

  protected addHoverEventListeners(): void {
    const fullBarHovering = this.fullBarHovering;
    const tooltipConfig = this.tooltipConfig;
    const bars = d3.select(this.elementRef.nativeElement).selectAll('rect');

    bars
      .on('mouseover', function (event: MouseEvent, d: { data: Record<string, string> }) {
        const bar = this as SVGRectElement;

        if (!fullBarHovering) {
          bar.style.strokeWidth = '2px';
          bar.style.stroke = '#413E4C';
        }

        // getting correct property name based on hovered element
        const subgroupName = d3.select<BaseType, Record<string, string>>(bar.parentNode as SVGGElement).datum().key;
        const subgroupValue = d.data[subgroupName];
        const normalizedName = subgroupName.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

        tooltipConfig.set({
          data: {
            title: normalizedName,
            value: subgroupValue,
            field: subgroupName,
            data: d.data as StackedChartData,
          },
          positioning: getTooltipPosition(bar, event),
        });
      })
      .on('mouseleave', function () {
        const bar = this as SVGRectElement;

        if (!fullBarHovering) {
          bar.style.strokeWidth = '';
          bar.style.stroke = '';
        }

        tooltipConfig.set({
          data: null,
          positioning: null,
        });
      });
  }

  private addLabelClickListener(): void {
    d3.select(this.elementRef.nativeElement)
      .selectAll('.axis')
      .selectAll('.custom-tick')
      .on('mouseup', (_, d: string) => {
        if (this.isLabelClickable) {
          const data = this.data.find(({ label }) => label === d);

          this.labelClick.emit(
            data && {
              name: data.label,
              id: data.datasets[0].id,
            },
          );
        }
      });
  }

  protected addEventListeners(): void {
    if (!this.isDisabled) {
      this.addHoverEventListeners();
    }

    if (this.isBarClickable) {
      this.addClickEventListeners();
    }
  }

  protected addClickEventListeners(): void {
    this.addBarClickListener();
    this.addLabelClickListener();
  }

  /**
   * Gets color from palette in case there are no predefined keys and each object could have own set own unique keys.
   * This method ensures that all stacks will have similar colors for consistency.
   *
   * The default setting is first color from colors array.
   *
   * @param key label key to get color by it's index in the object.
   */
  private getCustomColorScale(key: string): string {
    const [total, index] = this.keysMap.get(key) ?? [null, 0];

    const colors = this.colorShades ? [...COLORS_MAP.get(this.colorShades)] : [...this.colors];

    if (total < colors.length) {
      colors.splice(0, colors.length - total);
    }

    return colors[index % colors.length];
  }

  private getLabelWidth() {
    return this.width * this.horizontalScaleConfig.yAxisLabelWidth - 10;
  }

  private initializeGradient(): void {
    this.defs = this.defs ?? this.svg.append('defs');

    const linearGradientSelect: d3.Selection<SVGLinearGradientElement, unknown, null, undefined> =
      this.defs.select('linearGradient');
    const linearGradient = linearGradientSelect.empty() ? this.defs.append('linearGradient') : linearGradientSelect;

    if (linearGradientSelect.empty()) {
      linearGradient.append('stop').attr('offset', '0%');
      linearGradient.append('stop').attr('offset', '100%');
    }

    linearGradient
      .attr('id', `ColorGradient_${this.id}`)
      .attr('x1', 0)
      .attr('x2', this.horizontal ? 1 : 0)
      .attr('y1', this.horizontal ? 0 : 1)
      .attr('y2', 0);

    const stops = linearGradient.selectAll('stop');

    const colors = GRADIENTS_MAP.get(this.colorShades);

    if (colors) {
      stops.each((_, i, nodes) => {
        const stop = d3.select(nodes[i]);

        stop.attr('stop-color', colors[i]);
      });
    }
  }
}

function isNotNaN(d: d3.SeriesPoint<{ [p: string]: number }>) {
  return !Number.isNaN(d[0]) && !Number.isNaN(d[1]);
}

function scalesMatch(
  scale: AxisScale<d3.AxisDomain>,
  data: StackedBarGroupData[],
  accessor: (...args) => d3.AxisDomain,
): boolean {
  return scale && scale.domain().length === data.length && scale.domain().every((d, i) => d === data.map(accessor)[i]);
}
