import { ChangeDetectionStrategy, Component, EventEmitter, ViewChild } from '@angular/core';
import { IgxListComponent, IListItemClickEventArgs } from '@infragistics/igniteui-angular';

@Component({
  selector: 'supy-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  @ViewChild(IgxListComponent, { static: true })
  readonly list: IgxListComponent;

  get itemClicked(): EventEmitter<IListItemClickEventArgs> {
    return this.list.itemClicked;
  }
}
