import { cloneDeep } from 'lodash-es';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ArrayDataSource } from '@angular/cdk/collections';
import { CdkTreeModule } from '@angular/cdk/tree';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { fadeIn, fadeOut } from '@infragistics/igniteui-angular';

import { ButtonModule } from '../../../button';
import { CheckboxModule } from '../../../checkbox';
import { IconModule } from '../../../icon';
import { TreeDirective } from '../../directives';

export interface TreeNode {
  readonly id?: string;
  readonly name: string;
  readonly disabled?: boolean;
  readonly level?: string;
  readonly children?: TreeNode[];
  readonly dependencies?: string[];
  selected?: boolean;
  hidden?: boolean;
  forceHidden?: boolean;
}

export interface SelectNodeEvent {
  readonly node: TreeNode;
  readonly value: boolean;
}

@Component({
  selector: 'supy-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CdkTreeModule, IconModule, ButtonModule, CheckboxModule, NgIf, NgSwitch, NgSwitchCase],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [
        useAnimation(fadeIn, {
          params: {
            duration: '.35s',
            easing: 'ease-out',
          },
        }),
      ]),
      transition('* => void', [
        useAnimation(fadeOut, {
          params: {
            duration: '.35s',
            easing: 'ease-out',
          },
        }),
      ]),
    ]),
  ],
})
export class TreeComponent extends TreeDirective implements AfterViewInit {
  @Input() set data(data: TreeNode[]) {
    this.setDataNodes(data);
  }

  @Input() readonly expandAll: boolean;
  @Input() readonly isReadonly: boolean = false;

  @Output() readonly selectNode = new EventEmitter<SelectNodeEvent>();
  @Output() readonly selectParentNode = new EventEmitter<SelectNodeEvent>();

  constructor(readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  resetForceHiddenNodes(): void {
    this.treeControl.dataNodes.forEach(node => {
      node.forceHidden = false;

      if (node.children?.length) {
        node.children.forEach(child => {
          child.forceHidden = false;

          if (child.children?.length) {
            child.children.forEach(grandchild => {
              grandchild.forceHidden = false;
            });
          }
        });
      }
    });

    this.cdr.detectChanges();
  }

  forceHideNodes(nodeIds: string[]): void {
    this.treeControl.dataNodes.forEach(node => {
      if (nodeIds.includes(node.id)) {
        node.forceHidden = true;
        node.selected = false;
      }

      if (node.children?.length) {
        node.children.forEach(child => {
          if (nodeIds.includes(child.id)) {
            child.forceHidden = true;
            child.selected = false;
          }

          if (child.children?.length) {
            child.children.forEach(grandchild => {
              if (nodeIds.includes(grandchild.id)) {
                grandchild.forceHidden = true;
                grandchild.selected = false;
              }
            });
          }
        });
      }
    });

    this.cdr.detectChanges();
  }

  private setDataNodes(nodes: TreeNode[]): void {
    const clonedNodes = cloneDeep<TreeNode[]>(nodes);

    this.treeControl.dataNodes = clonedNodes;
    this.dataSource = new ArrayDataSource(clonedNodes);

    this.cdr.detectChanges();
  }

  onToggleSelectParentNode(node: TreeNode, value: boolean): void {
    this.toggleSelectNode(node, value, true);

    this.toggleSelectChildren(node, value);

    if (value) {
      this.collapseNodesRecursively(node);
    }

    this.selectParentNode.emit({ node, value });

    this.cdr.detectChanges();
  }

  protected onToggleSelectNode(node: TreeNode, value: boolean): void {
    this.toggleSelectNode(node, value);

    this.selectNode.emit({ node, value });

    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    if (this.expandAll) {
      this.expandAllNodes();
    }
  }
}
