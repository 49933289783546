import { first, map, Observable, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { ResourceEnum } from '@supy.api/permissions';

import { CheckPermissionAllowance, IsAllowedRequest, Principal, Resource } from '../core';
import { IsAllowed, PERMISSION_NOT_FOUND, PermissionsState } from '../store';

export abstract class PermissionStrategy {
  abstract request(resource?: Resource, principal?: Principal): Observable<IsAllowedRequest>;
  protected store: Store;

  constructor() {
    this.store = inject(Store);
  }

  isAllowed(resource?: Resource, principal?: Principal): Observable<boolean> {
    return this.store.select(PermissionsState.permissions).pipe(
      first(permissions => !!permissions && permissions.length > 0),
      switchMap(() => this.request(resource, principal).pipe(first())),
      switchMap(isAllowedRequest => {
        return this.checkForPermissionAllowance({
          resource: isAllowedRequest.resource.kind as ResourceEnum,
          action: isAllowedRequest.action,
        }).pipe(
          first(),
          map(isAllowed => ({ isAllowed, isAllowedRequest })),
        );
      }),
      switchMap(({ isAllowed, isAllowedRequest }) => {
        if (isAllowed !== PERMISSION_NOT_FOUND) {
          return of(isAllowed) as Observable<boolean>;
        }

        return this.store
          .dispatch(
            new IsAllowed({
              resource: isAllowedRequest.resource.kind as ResourceEnum,
              action: isAllowedRequest.action,
            }),
          )
          .pipe(
            switchMap(
              () =>
                this.checkForPermissionAllowance({
                  resource: isAllowedRequest.resource.kind as ResourceEnum,
                  action: isAllowedRequest.action,
                }).pipe(first()) as Observable<boolean>,
            ),
            first(),
          );
      }),
      first(),
    );
  }

  protected checkForPermissionAllowance(permissionCheck: CheckPermissionAllowance): Observable<boolean | string> {
    return this.store.select(PermissionsState.isPermissionAllowed(permissionCheck)) as Observable<boolean | string>;
  }
}

export class PermissionNotFoundStrategy extends PermissionStrategy {
  override request = (resource?: Resource, principal?: Principal): Observable<IsAllowedRequest> => {
    throw new Error('Permission not found');
  };
}
