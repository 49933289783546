import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { SUPPLIER_RETURN_URI } from '../../config';
import {
  CreateSupplierReturnRequest,
  DownloadSupplierReturnPdf,
  GenerateSupplierReturnDocumentNumberRequest,
  GenerateSupplierReturnDocumentNumberResponse,
  LockSupplierReturnsRequest,
  PostSupplierReturnsRequest,
  PostSupplierReturnsResponse,
  SupplierReturn,
  SupplierReturnQueryParams,
  SupplierReturnUploadResponse,
  UpdateSupplierReturnRequest,
} from '../../core';

@Injectable()
export class SupplierReturnService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(SUPPLIER_RETURN_URI) private readonly uriBff: string,
  ) {}

  getSupplierReturns(query: Query<SupplierReturnQueryParams>): Observable<IQueryResponse<SupplierReturn>> {
    return this.httpClient.get<IQueryResponse<SupplierReturn>>(this.uriBff, { params: query.toQueryParams() });
  }

  getSupplierReturn(supplierReturnId: string): Observable<SupplierReturn> {
    return this.httpClient.get<SupplierReturn>(`${this.uriBff}/${supplierReturnId}`);
  }

  createSupplierReturn(createSupplierReturn: CreateSupplierReturnRequest): Observable<void> {
    return this.httpClient.post<void>(this.uriBff, createSupplierReturn);
  }

  updateSupplierReturn(supplierReturnId: string, updateSupplierReturn: UpdateSupplierReturnRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uriBff}/${supplierReturnId}`, updateSupplierReturn);
  }

  postSupplierReturns(postSupplierReturnRequest: PostSupplierReturnsRequest): Observable<PostSupplierReturnsResponse> {
    return this.httpClient.post<PostSupplierReturnsResponse>(`${this.uriBff}/post`, postSupplierReturnRequest);
  }

  lockSupplierReturns(lockSupplierReturnsRequest: LockSupplierReturnsRequest): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/lock`, lockSupplierReturnsRequest);
  }

  postSingleSupplierReturn(supplierReturnId: string): Observable<void> {
    return this.httpClient.post<void>(`${this.uriBff}/${supplierReturnId}/post`, null);
  }

  uploadFile(file: FormData, retailerId: string): Observable<SupplierReturnUploadResponse> {
    return this.httpClient.post<SupplierReturnUploadResponse>(`${this.uriBff}/upload`, file, {
      params: {
        retailerId,
      },
    });
  }

  generateDocumentNumber(
    body: GenerateSupplierReturnDocumentNumberRequest,
  ): Observable<GenerateSupplierReturnDocumentNumberResponse> {
    return this.httpClient.post<GenerateSupplierReturnDocumentNumberResponse>(`${this.uriBff}/generate`, body);
  }

  downloadPDF(supplierReturnId: string): Observable<DownloadSupplierReturnPdf> {
    return this.httpClient.get<DownloadSupplierReturnPdf>(`${this.uriBff}/${supplierReturnId}/download`);
  }
}
