import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '@supy/auth';
import { Branch, IQueryResponse, Query, QueryPaging, SimpleUser } from '@supy/common';

import { BranchesService } from '../../services';

@Injectable()
export class UserBranchesResolver {
  constructor(
    private readonly authService: AuthService,
    private readonly branchesService: BranchesService,
  ) {}

  resolve(): Observable<IQueryResponse<Branch>> {
    const user = this.authService.user;
    const query = new Query<Branch>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'users', op: 'in', match: [user?.id as unknown as SimpleUser] }],
      ordering: [
        { by: 'name', dir: 'asc' },
        { by: 'id', dir: 'desc' },
      ],
    });

    return this.branchesService.getBranches(query);
  }
}
