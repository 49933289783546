import { filter, takeUntil, tap } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import { Destroyable, Query } from '@supy/common';
import { APP_CONFIG } from '@supy/core';
import { CurrentRetailerState } from '@supy/retailers';
import { getLocalizedName } from '@supy/settings';
import { CurrentUserState } from '@supy/users';

import { RetailerItemCategoriesConfig } from '../../config';
import { RetailerItemCategory } from '../../core';
import { RetailerItemCategoriesService } from '../../services';
import {
  RetailerItemCategoriesGetCategories,
  RetailerItemCategoryCreateCategory,
  RetailerItemCategoryGetMany,
  RetailerItemCategoryRemoveCategory,
  RetailerItemCategoryUpdateCategory,
} from '../actions';

export interface RetailerItemCategoriesStateModel {
  readonly categories: RetailerItemCategory[];
}

export const RETAILER_ITEM_CATEGORIES_STATE_TOKEN = new StateToken<RetailerItemCategoriesStateModel>(
  'retailerItemCategories',
);

@State<RetailerItemCategoriesStateModel>({
  name: RETAILER_ITEM_CATEGORIES_STATE_TOKEN,
  defaults: {
    categories: [],
  },
})
@Injectable()
export class RetailerItemCategoriesState extends Destroyable implements NgxsOnInit {
  constructor(
    private readonly retailerItemCategoriesService: RetailerItemCategoriesService,
    private readonly store: Store,
    @Inject(APP_CONFIG) protected readonly config: RetailerItemCategoriesConfig,
  ) {
    super();
  }

  static category(categoryId: string) {
    return createSelector([RETAILER_ITEM_CATEGORIES_STATE_TOKEN], (state: RetailerItemCategoriesStateModel) => {
      return state.categories?.find(({ id }) => id === categoryId);
    });
  }

  static categoryName(categoryName: string) {
    return createSelector([RETAILER_ITEM_CATEGORIES_STATE_TOKEN], (state: RetailerItemCategoriesStateModel) => {
      return state.categories?.find(({ name }) => name.en === categoryName);
    });
  }

  @Selector()
  static getRetailerItemCategories(state: RetailerItemCategoriesStateModel) {
    return state.categories;
  }

  @Selector()
  static getRetailerItemCategoriesChildren(state: RetailerItemCategoriesStateModel) {
    return state.categories.flatMap(({ children }) => children);
  }

  @Selector()
  static getRetailerItemCategoriesBase(state: RetailerItemCategoriesStateModel) {
    return state.categories?.map(({ id, name }) => ({ id, name: getLocalizedName(name) }));
  }

  @Selector()
  static getRetailerItemCategoriesTree(state: RetailerItemCategoriesStateModel) {
    return state?.categories
      ?.filter(cat => !!cat.children?.length)
      .map(({ id, name, children }) => ({
        id,
        name: getLocalizedName(name),
        children: children?.map(({ id, name }) => ({ id, name: getLocalizedName(name) })),
        unselectable: !!children?.length,
      }));
  }

  @Selector()
  static getRetailerItemCategoriesWithUnselectableParentTree(state: RetailerItemCategoriesStateModel) {
    return state.categories
      ?.filter(cat => !!cat.children?.length)
      .map(({ id, name, children }) => ({
        id,
        name: getLocalizedName(name),
        children: children?.map(({ id, name }) => ({ id, name: getLocalizedName(name) })),
        unselectable: true,
      }));
  }

  ngxsOnInit(ctx: StateContext<RetailerItemCategoriesStateModel>) {
    this.store
      .select(CurrentUserState.getCurrentUser)
      .pipe(
        takeUntil(this.destroyed$),
        filter(user => !!user),
        tap(() => this.getCategories(ctx)),
      )
      .subscribe();
  }

  @Action(RetailerItemCategoryGetMany)
  getCategories(ctx: StateContext<RetailerItemCategoriesStateModel>) {
    //FIXME: (store) make sure all BFFs are returning correct data or avoid using this store in other apps
    if (!this.config.apiUrlBff.includes('retailer')) {
      return;
    }

    const selectedRetailer = this.store.selectSnapshot(CurrentRetailerState.get);
    const query = new Query<RetailerItemCategory>({
      filtering: [
        { by: 'retailerId', op: 'eq', match: selectedRetailer },
        { by: 'state', op: 'eq', match: 'active' },
      ],
    });

    ctx.dispatch(new RetailerItemCategoriesGetCategories(query));
  }

  @Action(RetailerItemCategoriesGetCategories)
  getRetailerItemCategories(
    ctx: StateContext<RetailerItemCategoriesStateModel>,
    { query }: RetailerItemCategoriesGetCategories,
  ) {
    return this.retailerItemCategoriesService.getCategories(query).pipe(
      tap(({ data: categories }) => {
        ctx.patchState({ categories });
      }),
    );
  }

  @Action(RetailerItemCategoryCreateCategory)
  createCategory(ctx: StateContext<RetailerItemCategoriesStateModel>, { payload }: RetailerItemCategoryCreateCategory) {
    return this.retailerItemCategoriesService.createCategory(payload).pipe(tap(() => this.getCategories(ctx)));
  }

  @Action(RetailerItemCategoryRemoveCategory)
  removeCategory(
    ctx: StateContext<RetailerItemCategoriesStateModel>,
    { categoryId }: RetailerItemCategoryRemoveCategory,
  ) {
    return this.retailerItemCategoriesService.removeCategory(categoryId).pipe(tap(() => this.getCategories(ctx)));
  }

  @Action(RetailerItemCategoryUpdateCategory)
  updateRetailerItemCategory(
    ctx: StateContext<RetailerItemCategoriesStateModel>,
    { categoryId, payload }: RetailerItemCategoryUpdateCategory,
  ) {
    return this.retailerItemCategoriesService
      .updateRetailerItemCategory(categoryId, payload)
      .pipe(tap(() => this.getCategories(ctx)));
  }
}
