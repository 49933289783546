import { inject, InjectionToken } from '@angular/core';

import { CommonConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export interface RetailersConfig extends CommonConfig {}

export const RETAILERS_URI = new InjectionToken<string>('Outlets BFF URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrl}${config.apiUrlPrefix}/retailers`;
  },
});

export const RETAILERS_BFF_URI = new InjectionToken<string>('Outlets BFF URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/retailers`;
  },
});
