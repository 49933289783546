import { capital } from 'case';

import { Uom } from '@supy/common';

import { InventoryTransferMode } from '../../event';
import { EventSourceTypesEnum, ItemStockMovement } from '../core';

export const EventSourceTypeMap = Object.values(EventSourceTypesEnum).reduce(
  (acc, cur) => ({ ...acc, [cur]: capital(cur) }),
  {} as Record<EventSourceTypesEnum, string>,
);

export function getComputedQuantity(stockMovement: ItemStockMovement, baseUnit: Uom): number {
  switch (stockMovement.event) {
    case EventSourceTypesEnum.ItemVariance:
      return stockMovement.quantity.after / baseUnit.conversionToAtom;
    default:
      return stockMovement.quantity.change / baseUnit.conversionToAtom;
  }
}

export function getComputedCost(stockMovement: ItemStockMovement, baseUnit: Uom): number {
  let res: number;

  switch (stockMovement.event) {
    case EventSourceTypesEnum.ItemVariance:
      res = stockMovement.cost.after * stockMovement.quantity.after;
      break;
    case EventSourceTypesEnum.Init:
      res = stockMovement.cost.after * baseUnit.conversionToAtom;
      break;
    default:
      res = (stockMovement.metadata?.atomPrice ?? 0) * stockMovement.quantity.change;
  }

  return Math.abs(res);
}

export function getComputedItemCost(stockMovement: ItemStockMovement, baseUnit: Uom): number {
  return stockMovement.cost.after * baseUnit.conversionToAtom;
}

export function getComputedTotal(stockMovement: ItemStockMovement, baseUnit: Uom): number {
  return stockMovement.quantity.after / baseUnit.conversionToAtom;
}

export function getEventType(stockMovement: ItemStockMovement): string {
  switch (stockMovement.event) {
    case EventSourceTypesEnum.Init:
      return $localize`:@@create:Create`;
    case EventSourceTypesEnum.Grn:
      return $localize`:@@inventory.stocks.purchase:Purchase`;
    case EventSourceTypesEnum.ItemVariance:
      return $localize`:@@inventory.stocks.count:Count`;
    case EventSourceTypesEnum.ShippedOut:
      return $localize`:@@statusShipped:Shipped`;
    default:
      return EventSourceTypeMap[stockMovement.event];
  }
}

export function getEventLink(stockMovement: ItemStockMovement): string | null {
  switch (stockMovement.event) {
    case EventSourceTypesEnum.Grn:
      return `/accounting/grns/${stockMovement.reference.id}/details`;
    case EventSourceTypesEnum.ItemVariance:
      return `/inventory/stock-counts/details/${stockMovement.reference.id}`;
    case EventSourceTypesEnum.Wastage:
      return `/inventory/wastages/${stockMovement.reference.id}`;
    case EventSourceTypesEnum.ProductionIn:
    case EventSourceTypesEnum.ProductionOut:
      return `/inventory/productions/${stockMovement.reference.id}`;
    case EventSourceTypesEnum.TransferIn:
      return `/inventory/transfers/${InventoryTransferMode.Incoming}/${stockMovement.reference.id}`;
    case EventSourceTypesEnum.TransferOut:
      return `/inventory/transfers/${InventoryTransferMode.Outgoing}/${stockMovement.reference.id}`;
    case EventSourceTypesEnum.Return:
      return `/accounting/supplier-return/${stockMovement.reference.id}/details`;
    case EventSourceTypesEnum.Sales:
      return `/inventory/recipes/${stockMovement.reference.id}/details`;
    case EventSourceTypesEnum.ShippedOut:
      return `/central-kitchen/incoming-orders/by-order/${stockMovement.reference.id}?status=shipped`;

    default:
      return null;
  }
}
