import { Workbook } from 'exceljs';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import { createWorksheet, DataColumn, DEFAULT_IANA_TIMEZONE, saveWorkbook, Uom } from '@supy/common';
import { DropdownTreeNode } from '@supy/components';
import { getLocalizedName } from '@supy/settings';

import { BaseItemInventory, InventoryItemType } from '../../../core';

export function downloadInventoryStocksList(
  items: BaseItemInventory[],
  currency: Currency,
  locationId: string,
  branches: DropdownTreeNode<string>[],
  categoriesTree: DropdownTreeNode<string>[],
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): void {
  const columns: DataColumn[] = [
    { header: 'Base Item Name', key: 'name' },
    { header: 'Code', key: 'code' },
    { header: 'SubCategory', key: 'subCategory' },
    { header: 'Category', key: 'category' },
    { header: `Cost (${currency})`, key: 'cost' },
    { header: `Base Unit`, key: 'baseUnit' },
    { header: 'On Hand', key: 'onHand' },
    { header: 'Stock Value', key: 'stockValue' },
    // { header: 'Last Count Qty', key: 'lastStockCount' },
    { header: 'Scope', key: 'scope' },
  ];

  const currencyCols = new Set(['cost', 'stockValue']);
  const qtyCols = new Set(['onHand']);

  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      name: getLocalizedName(item.retailerItem.name),
      category: item.retailerItem.category?.id && getParentCategoryName(item.retailerItem.category.id, categoriesTree),
      subCategory: getLocalizedName(item.retailerItem.category?.name),
      cost: getComputedCost(item),
      baseUnit: getBaseUnitName(item),
      onHand:
        getComputedOnHand(item) / (item.packagings.find(packaging => packaging.customId === item.id)?.toBaseUom ?? 1),
      stockValue: getComputedCost(item) * getComputedOnHand(item),
      // lastStockCount: item.lastStockCount?.countedQuantity,
      code: item.retailerItem.code,
      scope: [InventoryItemType.SemiFinishedItem, InventoryItemType.SemiFinished].includes(item.retailerItem.scope.type)
        ? 'Semi-Finished'
        : 'Item',
    };
  });

  createWorksheet(workbook, `Stock Items`, { data, columns, currencyCols, qtyCols });

  const fileName = `stocks-${getName(locationId, branches)}-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}

function getName(locationId: string, branches: DropdownTreeNode<string>[]): string {
  return branches.reduce(
    (name: string, storage) => {
      if (name) {
        return name;
      }

      const location = storage.children?.find(child => child.id === locationId);

      if (location) {
        return `${storage.name} - ${location.name}`;
      }

      return name;
    },
    null as unknown as string,
  );
}

function getStorageUnit(item: BaseItemInventory): Uom {
  return item.retailerItem.baseUnit;
}

function getComputedCost(item: BaseItemInventory): number {
  const conversionToAtom = getStorageUnit(item).conversionToAtom;

  return conversionToAtom ? item.cost * conversionToAtom : 0;
}

function getComputedOnHand(item: BaseItemInventory): number {
  const conversionToAtom = getStorageUnit(item).conversionToAtom;

  return conversionToAtom ? item.onHand / conversionToAtom : 0;
}

function getBaseUnitName(item: BaseItemInventory): string {
  return item?.retailerItem?.baseUnit?.name;
}

function getParentCategoryName(subCategoryId: string, categories: DropdownTreeNode<string>[]): string {
  return categories.find(category => category.children?.some(child => child.id === subCategoryId))?.name ?? '';
}
