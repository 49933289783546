import { IAuthUserRequest, IAuthUserResponse } from '../../../common';
import { IAuthCallback } from '../auth-callback.interface';
import { IAuthHandler } from '../auth-handler.interface';
import { BaseUserAuthHandler } from './base-user-auth.handler';

export class UserAuthSimpleHandler
  extends BaseUserAuthHandler<IAuthUserRequest, IAuthUserResponse>
  implements IAuthHandler<IAuthUserRequest, IAuthCallback<IAuthUserResponse>>
{
  async auth(params: IAuthUserRequest, callback: IAuthCallback<IAuthUserResponse>): Promise<void> {
    try {
      const response = await this.execute(params);

      return callback(null, response);
    } catch (error) {
      return callback(error as Error, null);
    }
  }
}
