import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { InventoryEventType } from '../../core';

@Component({
  selector: 'supy-inventory-event-empty-details',
  templateUrl: './inventory-event-empty-details.component.html',
  styleUrls: ['./inventory-event-empty-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEventEmptyDetailsComponent {
  readonly inventoryEventType = InventoryEventType;

  @Input() readonly eventType: InventoryEventType;
}
