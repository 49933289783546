import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IgxStepperModule } from '@infragistics/igniteui-angular';

import { StepComponent, StepperComponent } from './components';

@NgModule({
  declarations: [StepperComponent, StepComponent],
  imports: [CommonModule, IgxStepperModule, RouterModule],
  exports: [StepperComponent, StepComponent],
})
export class StepperModule {}
