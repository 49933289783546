import { IgxLegendModule } from 'igniteui-angular-charts';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartLegendComponent } from './components';

@NgModule({
  declarations: [ChartLegendComponent],
  imports: [CommonModule, IgxLegendModule],
  exports: [ChartLegendComponent],
})
export class ChartLegendModule {}
