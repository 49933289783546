import { BaseType, format, scaleOrdinal, select } from 'd3';
import {
  sankey,
  SankeyGraph,
  SankeyLayout,
  SankeyLink as D3SankeyLink,
  sankeyLinkHorizontal,
  SankeyNode as D3SankeyNode,
} from 'd3-sankey';
import { Selection } from 'd3-selection';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';

import { calculateNumberTick, ChartBaseDirective, getTooltipPosition } from '../../../chart-core';
import { SankeyChartTooltipType, SankeyLink, SankeyNode } from './sankey-chart.interfaces';
import { SANKEY_COLORS } from './sankey-chart-colors.map';

@Component({
  selector: 'supy-sankey-chart',
  templateUrl: './sankey-chart.component.html',
  styleUrls: ['./sankey-chart.component.scss'],
})
export class SankeyChartComponent
  extends ChartBaseDirective<(SankeyLink | SankeyNode) & { type: SankeyChartTooltipType }>
  implements OnChanges, OnDestroy
{
  @Input() protected readonly transfers: SankeyLink[];
  @Input() protected readonly locations: SankeyNode[];

  @Output() readonly linkClick = new EventEmitter<SankeyLink>();
  @Output() readonly nodeClick = new EventEmitter<SankeyNode>();

  protected readonly color = scaleOrdinal(SANKEY_COLORS);
  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(elementRef, cdr);
  }

  protected renderChart() {
    const baseLinks = this.getLinks();
    const baseNodes = this.getNodes();

    this.svg = this.createSVGElement();

    const sankeyLayout: SankeyLayout<
      SankeyGraph<SankeyNode, SankeyLink>,
      SankeyNode,
      SankeyLink
    > = this.getSankeyLayout();

    const { nodes, links } = sankeyLayout({
      nodes: baseNodes,
      links: baseLinks,
    });

    const sankeyGroup = this.getSankeyGroup();

    this.renderLinks(sankeyGroup, links);
    this.renderNodes(sankeyGroup, nodes);
    this.renderNodeValues(nodes);
    this.renderNodeLabels(nodes);
  }

  private getSankeyLayout(): SankeyLayout<SankeyGraph<SankeyNode, SankeyLink>, SankeyNode, SankeyLink> {
    return (
      sankey<SankeyNode, SankeyLink>()
        // TODO: decide who handles nodes (FE or BE).
        //  If we have links with few same node ids,
        //  there will be multi-level sankey chart by default.
        //  We should have different ids for same location
        //  if it could be on both sides of chart and we want only 2 sides,
        //  like `${locationId}_in` and `${locationId}_out`
        .nodeId(d => d.nodeId)
        .nodeWidth(15)
        .nodePadding(10)
        .extent([
          [1, 5],
          [this.width - 1, this.height - 5],
        ])
    );
  }

  private getSankeyGroup(): Selection<SVGGElement, unknown, null, undefined> {
    const sankeyGroupSelection: Selection<SVGGElement, unknown, null, undefined> = this.svg.select('.sankey-group');
    const sankeyGroup = sankeyGroupSelection.empty()
      ? this.svg.append('g').classed('sankey-group', true)
      : sankeyGroupSelection;

    sankeyGroup.attr('transform', `translate(${this.marginLeft},5)`);

    return sankeyGroup;
  }

  private renderLinks(
    sankeyGroup: Selection<SVGGElement, unknown, null, undefined>,
    links: D3SankeyLink<SankeyNode, SankeyLink>[],
  ) {
    const path = sankeyGroup.selectAll('path').data(links);

    const pathEnter = path.enter().append('path');

    path
      .merge(pathEnter)
      .attr('d', sankeyLinkHorizontal())
      .attr('fill', 'none')
      .attr('opacity', '0.3')
      .attr('id', d => `link-${d.index}`)
      .attr('stroke', d => this.color((d.source as SankeyNode).name))
      .attr('stroke-width', d => Math.max(1, d.width));

    path.exit().remove();
  }

  private renderNodes(
    sankeyGroup: Selection<SVGGElement, unknown, null, undefined>,
    nodes: D3SankeyNode<SankeyNode, SankeyLink>[],
  ) {
    const nodeGroupSelection: Selection<SVGGElement, unknown, null, undefined> = this.svg.select('.node-group');
    const nodeGroup = nodeGroupSelection.empty()
      ? sankeyGroup.append('g').classed('node-group', true)
      : nodeGroupSelection;

    const g = nodeGroup.selectAll('g').data(nodes);

    const gEnter = g.enter().append('g');

    const mergedG = g.merge(gEnter).attr('transform', d => {
      const x = d.x0 < this.width / 2 ? d.x1 - 25 : d.x0 + 2;

      return `translate(${x}, ${d.y0})`;
    });

    const rects = mergedG.selectAll<SVGRectElement, D3SankeyNode<SankeyNode, SankeyLink>>('rect').data(d => [d]);

    const rectsEnter = rects.enter().append('rect');

    rects
      .merge(rectsEnter)
      .attr('fill', d => this.color(d.name))
      .attr('height', d => d.y1 - d.y0)
      .attr('width', d => d.x1 - d.x0);

    // Exit
    rects.exit().remove();

    // Exit
    g.exit().remove();
  }

  private renderNodeValues(nodes: (D3SankeyNode<SankeyNode, SankeyLink> & { nodeValue?: number })[]) {
    const nodeValuesSelection: Selection<SVGGElement, unknown, null, undefined> = this.svg.select('.node-values');
    const nodeValues = nodeValuesSelection.empty()
      ? this.svg.append('g').classed('node-values', true)
      : nodeValuesSelection;

    const text = nodeValues
      .attr('transform', `translate(${this.marginLeft},5)`)
      .selectAll('text')
      .data(nodes.filter(node => node.nodeValue));

    const textEnter = text.enter().append('text');

    text
      .merge(textEnter)
      .style('font-size', '0.75rem')
      .attr('x', d => (d.x0 < this.width / 2 ? d.x1 + 6 : d.x0 - 6))
      .attr('y', d => (d.y1 + d.y0) / 2)
      .attr('dy', '0.35em')
      .attr('text-anchor', d => (d.x0 < this.width / 2 ? 'start' : 'end'))
      .text(d => d.nodeValue && format(calculateNumberTick(d.nodeValue))(d.nodeValue));

    // Exit
    text.exit().remove();
  }

  private renderNodeLabels(nodes: (D3SankeyNode<SankeyNode, SankeyLink> & { nodeValue?: number })[]) {
    const nodeLabelsSelection: Selection<SVGGElement, unknown, null, undefined> = this.svg.select('.node-labels');
    const nodeLabels = nodeLabelsSelection.empty()
      ? this.svg.append('g').classed('node-labels', true)
      : nodeLabelsSelection;

    nodeLabels.attr('transform', `translate(${this.marginLeft},5)`);

    const foreignObject = nodeLabels.selectAll('foreignObject').data(nodes.filter(node => node.nodeValue));

    const foreignObjectEnter = foreignObject.enter().append('foreignObject');

    const mergedForeignObjects = foreignObject
      .merge(foreignObjectEnter)
      .attr('transform', `translate(${this.marginLeft},5)`)
      .attr('x', d => (d.x0 < this.width / 2 ? -this.marginLeft * 2 : d.x0 + 30 - this.marginLeft))
      .attr('y', d => (d.y1 + d.y0) / 2 - 7)
      .attr('dy', '0.35em')
      .attr('text-anchor', d => (d.x0 < this.width / 2 ? 'end' : 'start'))
      .attr('height', 14)
      .attr('width', this.marginLeft - 30)
      .style('width', this.marginLeft - 30);

    const divs = mergedForeignObjects
      .selectAll<BaseType, D3SankeyNode<SankeyNode, SankeyLink>>('.custom-tick')
      .data(d => {
        return [d];
      });

    const divsEnter = divs.enter().append('xhtml:div');

    divs
      .merge(divsEnter)
      .text(d => d.name)
      .classed('end-aligned', d => d.x0 < this.width / 2)
      .style('overflow', 'hidden')
      .classed('custom-tick', true)
      .attr('title', d => d.name);

    foreignObject.exit().remove();
    divs.exit().remove();
  }

  private getNodes() {
    return this.locations;
  }

  private getLinks() {
    return this.transfers;
  }

  protected addHoverEventListeners() {
    this.addLinkHoverEventListeners();
    this.addNodeHoverEventListeners();
    this.addLabelHoverEventListeners();
  }

  private addLabelHoverEventListeners(): void {
    const tooltipConfig = this.tooltipConfig;
    const cdr = this.cdr;
    const highlightNodeLinks = (path: SVGPathElement, node: D3SankeyNode<SankeyNode, SankeyLink>) =>
      this.highlightNodeLinks(path, node);

    select(this.hostElement)
      .selectAll('.custom-tick')
      .on('mouseover', function (event: MouseEvent, d: D3SankeyNode<SankeyNode, SankeyLink>) {
        const hoveredElement = this as SVGPathElement;

        highlightNodeLinks(hoveredElement, d);

        tooltipConfig.set({
          data: {
            ...d,
            type: d.sourceLinks?.length ? SankeyChartTooltipType.From : SankeyChartTooltipType.To,
          },
          positioning: getTooltipPosition(hoveredElement, event),
        });

        cdr.detectChanges();
      })
      .on('mouseleave', function (event: MouseEvent, d: D3SankeyNode<SankeyNode, SankeyLink>) {
        const hoveredElement = this as SVGPathElement;

        highlightNodeLinks(hoveredElement, d);

        tooltipConfig.update(config => ({ ...config, data: null }));
        cdr.detectChanges();
      });
  }

  protected addClickEventListeners(): void {
    this.addLinkClickEventListeners();
    this.addNodeClickEventListeners();
  }

  private addLinkHoverEventListeners(): void {
    const tooltipConfig = this.tooltipConfig;
    const cdr = this.cdr;
    const highlightNodeLinks = (path: SVGPathElement, node: D3SankeyNode<SankeyNode, SankeyLink>) =>
      this.highlightNodeLinks(path, node);

    select(this.hostElement)
      .selectAll('path')
      .on('mouseover', function (event: MouseEvent, d: D3SankeyLink<SankeyNode, SankeyLink>) {
        const hoveredElement = this as SVGPathElement;

        highlightNodeLinks(hoveredElement, d.source as D3SankeyNode<SankeyNode, SankeyLink>);

        tooltipConfig.set({
          data: {
            ...d,
            type: SankeyChartTooltipType.Link,
          },
          positioning: getTooltipPosition(hoveredElement, event),
        });

        cdr.detectChanges();
      })
      .on('mouseleave', function (event: MouseEvent, d: D3SankeyLink<SankeyNode, SankeyLink>) {
        const hoveredElement = this as SVGPathElement;

        highlightNodeLinks(hoveredElement, d.source as D3SankeyNode<SankeyNode, SankeyLink>);

        tooltipConfig.update(config => ({ ...config, data: null }));
        cdr.detectChanges();
      });
  }

  private addNodeHoverEventListeners(): void {
    const tooltipConfig = this.tooltipConfig;
    const cdr = this.cdr;

    select(this.hostElement)
      .selectAll('rect')
      .on('mouseover', function (event: MouseEvent, d: D3SankeyNode<SankeyNode, SankeyLink>) {
        const hoveredElement = this as SVGPathElement;

        tooltipConfig.set({
          data: {
            ...d,
            type: d.sourceLinks?.length ? SankeyChartTooltipType.From : SankeyChartTooltipType.To,
          },
          positioning: getTooltipPosition(hoveredElement, event),
        });

        cdr.detectChanges();
      })
      .on('mouseleave', function () {
        tooltipConfig.update(config => ({ ...config, data: null }));
        cdr.detectChanges();
      });
  }

  private addLinkClickEventListeners(): void {
    select(this.hostElement)
      .selectAll('path')
      .classed('clickable', this.isClickable)
      .on('mouseup', (_, d: D3SankeyLink<SankeyNode, SankeyLink>) => {
        this.tooltipConfig.update(config => ({ ...config, data: null }));
        this.linkClick.emit(d);
      });
  }

  private addNodeClickEventListeners(): void {
    select(this.hostElement)
      .selectAll('rect')
      .classed('clickable', this.isClickable)
      .on('mouseup', (_, d: D3SankeyNode<SankeyNode, SankeyLink>) => {
        this.tooltipConfig.update(config => ({ ...config, data: null }));
        this.nodeClick.emit(d);
      });
  }

  private highlightNodeLinks(path: SVGPathElement, node: D3SankeyNode<SankeyNode, SankeyLink>) {
    let remainingNodes: D3SankeyNode<SankeyNode, SankeyLink>[] = [];
    let nextNodes: D3SankeyNode<SankeyNode, SankeyLink>[] = [];
    let opacity = 0;

    if (select(path).attr('data-hovered') == '1') {
      select(path).attr('data-hovered', '0');
      opacity = 0.3;
    } else {
      select(path).attr('data-hovered', '1');
      opacity = 0.9;
    }

    const traverse = [
      {
        linkType: 'sourceLinks',
        nodeType: 'target',
      },
      {
        linkType: 'targetLinks',
        nodeType: 'source',
      },
    ];

    traverse.forEach(step => {
      (node[step.linkType] as D3SankeyLink<SankeyNode, SankeyLink>[]).forEach(link => {
        remainingNodes.push(link[step.nodeType] as D3SankeyNode<SankeyNode, SankeyLink>);
        this.highlightLink(link.index, opacity);
      });

      while (remainingNodes.length) {
        nextNodes = [];
        remainingNodes.forEach(node => {
          (node[step.linkType] as D3SankeyLink<SankeyNode, SankeyLink>[]).forEach(link => {
            nextNodes.push(link[step.nodeType] as D3SankeyNode<SankeyNode, SankeyLink>);
            this.highlightLink(link.index, opacity);
          });
        });
        remainingNodes = nextNodes;
      }
    });
  }

  private highlightLink(id: number, opacity: number) {
    select(`#link-${id}`).style('opacity', opacity).raise();
  }
}
