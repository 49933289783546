import { BaseChannel } from './channel.base';
import { ChannelType } from './channel.type';

type ChannelName = `${ChannelType.Presence}-${string}`;

export class PresenceChannel extends BaseChannel<ChannelName> {
  get type(): ChannelType {
    return ChannelType.Presence;
  }

  static for(name: string): ChannelName {
    return `${ChannelType.Presence}-${name}`;
  }
}
