import { Observable, takeUntil } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { ActionsExecuting } from '@ngxs-labs/actions-executing';

import { Destroyable, SkeletonObjectType, Uom } from '@supy/common';
import { DialogComponent, DialogService, IDialogComponent } from '@supy/components';
import { getLocalizedName } from '@supy/settings';

import {
  CreatePackagingRequest,
  Packaging,
  PackagingGroup,
  PackagingLevel,
  PackagingQuantityRequest,
  PackagingScopeEnum,
  UpdatePackagingRequest,
} from '../../core';

@Component({
  selector: 'supy-create-package-item-dialog',
  styleUrls: ['create-package-item-dialog.component.scss'],
  templateUrl: 'create-package-item-dialog.component.html',
  providers: [DialogService],
})
export class CreatePackageItemDialogComponent extends Destroyable implements OnInit, IDialogComponent {
  @Input() readonly item: SkeletonObjectType;
  @Input() readonly packages: PackagingGroup[];
  @Input() readonly isDeletable: boolean;
  @Input() readonly packageItem?: Packaging;
  @Input() readonly basePackageItem?: Packaging;
  @Input() set units(value: Uom[]) {
    this.#units = value;
  }

  get units(): Uom[] {
    return this.#units;
  }

  @Input() readonly baseUom: Uom | null;
  @Input() readonly scope: PackagingScopeEnum;
  @Input() readonly isItemUsedAsPiece: boolean;
  @Input() readonly isLoading$: Observable<ActionsExecuting>;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly createClicked = new EventEmitter<CreatePackagingRequest>();
  @Output() readonly saveClicked = new EventEmitter<UpdatePackagingRequest>();
  @Output() readonly deleteClicked = new EventEmitter<void>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  form: UntypedFormGroup;
  #units: Uom[] = [];

  get selectedBaseUom(): Uom | null {
    const formValue = this.form.getRawValue() as Packaging;

    return this.units.find(u => u.id === (formValue.baseUnit as unknown as string[])?.[0]) ?? null;
  }

  get itemName(): string {
    return getLocalizedName(this.packageItem?.itemName) || this.item.name;
  }

  ngOnInit() {
    this.initData();
    this.setupFormListeners();
    this.setItemNameControlValue(this.form.getRawValue() as Packaging);
    this.checkQuantity();
  }

  initData(): void {
    this.form = new UntypedFormGroup({
      packageName: new UntypedFormControl(this.packageItem?.unitName ?? null),
      baseUnit: new UntypedFormControl(
        {
          value:
            this.baseUom && !this.basePackageItem
              ? [this.units.find(({ id }) => this.baseUom?.id === id)?.id]
              : this.basePackageItem?.packageName,
          disabled:
            this.baseUom?.isPiece ||
            (this.packageItem
              ? this.packageItem.level !== 0
              : (!this.basePackageItem || this.basePackageItem.level === 0) && this.baseUom),
        },
        [Validators.required],
      ),
      amount: new UntypedFormControl(this.getPackagingAmount(this.packageItem), [
        Validators.required,
        Validators.min(Number.MIN_VALUE),
      ]),
      itemName: new UntypedFormControl({ value: null, disabled: true }),
      usedAsPiece: new UntypedFormControl(false),
      isPreferred: new UntypedFormControl(this.packageItem?.isPreferred ?? false),
    });

    if (this.packageItem && !this.baseUom?.isPiece) {
      this.#units = this.#units.filter(({ isPiece }) => !isPiece);
    }
  }

  setupFormListeners(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.setItemNameControlValue(this.form.getRawValue() as Packaging);
    });

    this.form
      .get('baseUnit')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.form.get('amount')?.setValue(null);
        this.checkQuantity();
      });
  }

  checkQuantity(): void {
    if (this.selectedBaseUom?.isPiece && (!this.packages.length || !this.basePackageItem)) {
      this.form.get('amount')?.setValue(1);
      this.form.get('amount')?.disable();
    } else {
      this.form.get('amount')?.enable();
    }
  }

  getPackagingAmount(packaging: Packaging | undefined): number | null {
    if (!packaging) {
      return null;
    }

    return packaging.level === 0 ? packaging.quantity.baseUnit : packaging.quantity?.parent ?? null;
  }

  setItemNameControlValue(value: Packaging) {
    this.form.get('itemName')?.setValue(this.getPackageItemName(value), {
      emitEvent: false,
    });
  }

  getPackageItemName(value: Packaging): string | undefined {
    return `${this.itemName} ${this.getPackageName(value)}`;
  }

  getPackageName(value: Packaging): string {
    const basePackageName = this.basePackageItem?.packageName;
    const currentPackageNamePart =
      basePackageName && this.basePackageItem?.level !== 0
        ? basePackageName?.slice(basePackageName.indexOf('(') + 1, basePackageName.indexOf(')'))
        : basePackageName ?? '';

    return `${
      !this.basePackageItem || this.packageItem?.level === 0
        ? `${value.amount ?? ''}${this.selectedBaseUom?.name ?? ''}${value.packageName ? ` ${value.packageName}` : ''}`
        : `${value.packageName ? `${value.packageName} ` : ''}(${
            value.amount ? `${value.amount}x` : ''
          }${currentPackageNamePart})`
    }`;
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCreateClick(): void {
    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.getRawValue() as Packaging;

    let quantity = {} as PackagingQuantityRequest;

    if (this.basePackageItem) {
      quantity = { parent: formValue.amount || 0 };
    } else {
      quantity = { baseUnit: formValue.amount || 0 };
    }

    let payload: CreatePackagingRequest = {
      unitName: formValue.packageName ?? '',
      packageName: this.getPackageName(formValue),
      quantity,
      source: { id: this.item.id, scope: this.scope },
      usedAsPiece: !this.basePackageItem && this.selectedBaseUom?.isPiece ? true : formValue.usedAsPiece,
      isPreferred: formValue.isPreferred,
      level: (this.basePackageItem ? this.basePackageItem.level + 1 : 0) as PackagingLevel,
    };

    if (this.basePackageItem) {
      payload = { ...payload, parent: { id: this.basePackageItem.id } };
    } else {
      payload = { ...payload, baseUnit: { id: (formValue.baseUnit as unknown as string[])[0] } };
    }

    this.createClicked.emit(payload);
  }

  onSaveClick(): void {
    if (this.form.valid) {
      const formValue = this.form.getRawValue() as Packaging;

      let quantity = {} as PackagingQuantityRequest;

      if (this.basePackageItem) {
        quantity = { parent: formValue.amount || 0 };
      } else {
        quantity = { baseUnit: formValue.amount || 0 };
      }

      let payload: UpdatePackagingRequest = {
        unitName: formValue.packageName ?? '',
        packageName: this.getPackageName(formValue),
        quantity,
        applyQuantityUpdates: true,
        isPreferred: formValue.isPreferred,
      };

      if (!this.basePackageItem) {
        payload = { ...payload, baseUnit: { id: (formValue.baseUnit as unknown as string[])[0] } };
      }

      this.saveClicked.emit(payload);
    }
  }
}
