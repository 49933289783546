import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IgxRadioModule } from '@infragistics/igniteui-angular';

import { RadioComponent } from './components';

@NgModule({
  declarations: [RadioComponent],
  imports: [CommonModule, IgxRadioModule, FormsModule],
  exports: [RadioComponent],
})
export class RadioModule {}
