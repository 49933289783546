import { switchMap } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { getCurrentScope } from '@sentry/browser';

import { QueryBuilder, QueryPaging, Supplier, SupplierState, SupplierType, ViewBranch } from '@supy/common';
import { DropdownTreeNode } from '@supy/components';
import { getLocalizedName } from '@supy/settings';
import { CurrentUserState, CurrentUserStateModel, UsersService } from '@supy/users';

import { RetailersService } from '../../services';
import {
  CurrentRetailerCreateUser,
  CurrentRetailerSet,
  CurrentRetailerSuppliersGet,
  CurrentRetailerSuppliersSet,
  CurrentRetailerUpdateUser,
} from '../actions';

export interface CurrentRetailerStateModel {
  readonly retailerId?: string | null;
  readonly suppliers: Supplier[];
}

export const CURRENT_RETAILER_STATE_TOKEN = new StateToken<CurrentRetailerStateModel>('currentRetailer');

@State<CurrentRetailerStateModel>({
  name: CURRENT_RETAILER_STATE_TOKEN,
})
@Injectable()
export class CurrentRetailerState {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly retailersService: RetailersService,
    private readonly usersService: UsersService,
    private readonly router: Router,
  ) {}

  @Selector()
  static get(state: CurrentRetailerStateModel) {
    return state.retailerId;
  }

  @Selector()
  static suppliers(state: CurrentRetailerStateModel) {
    return state.suppliers;
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static userCentralKitchens(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    return CurrentUserState.isSuperadmin(currentUserState)
      ? this.relatedCentralKitchens(state)
      : currentUserState.user?.centralKitchens?.filter(({ retailerId }) => state.retailerId === retailerId);
  }

  @Selector()
  static relatedCentralKitchens(state: CurrentRetailerStateModel) {
    return state.suppliers?.filter(supp => supp.type === SupplierType.centralKitchen);
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static retailer(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    return currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  /**
   * Branches grouped by region list for dropdowns (filters, selects, etc.).
   * @returns {DropdownTreeNode<string>[]} - list of branches grouped by region (if any)
   */
  static locationsWithRegionsBranches(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailer = currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);

    return [...(selectedRetailer?.outlets ?? [])]
      .sort(a => (a.region ? -1 : 1))
      .reduce((list, outlet) => {
        if (outlet.region) {
          const existingRegion = list.find(({ id }) => id === outlet.region?.id);

          if (existingRegion) {
            existingRegion.children?.push({
              id: outlet.id,
              name: getLocalizedName(outlet.name),
              children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
              unselectable: true,
            });
          } else {
            list.push({
              id: outlet.region.id,
              name: outlet.region.name,
              unselectable: true,
              children: [
                {
                  id: outlet.id,
                  name: getLocalizedName(outlet.name),
                  children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
                  unselectable: true,
                },
              ],
            });
          }
        } else {
          list.push({
            id: outlet.id,
            name: getLocalizedName(outlet.name),
            children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
            unselectable: true,
          });
        }

        return list;
      }, [] as DropdownTreeNode<string>[]);
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  /**
   * Simple branches list for displaying. Not grouped by regions.
   * @returns {DropdownTreeNode<string>[]} - list of branches without grouping by regions
   */
  static locationsWithBranches(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailerOutlets =
      currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId)?.outlets ?? [];

    return selectedRetailerOutlets.map(outlet => ({
      id: outlet.id,
      name: getLocalizedName(outlet.name),
      children: outlet.branches?.map(branch => ({ id: branch.id, name: branch.name })),
      unselectable: true,
    }));
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static outlets(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailer = currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);

    return selectedRetailer?.outlets ?? [];
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static branches(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailer = currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);

    return (
      selectedRetailer?.outlets?.flatMap<ViewBranch>(outlet =>
        outlet.branches.map(branch => ({ ...branch, name: `${getLocalizedName(outlet.name)} - ${branch.name}` })),
      ) ?? []
    );
  }

  @Selector([CurrentRetailerState, CurrentUserState])
  static idToLocation(state: CurrentRetailerStateModel, currentUserState: CurrentUserStateModel) {
    const selectedRetailer = currentUserState.user?.retailers?.find(retailer => retailer.id === state.retailerId);

    return new Map<string, ViewBranch>(
      (
        selectedRetailer?.outlets?.flatMap<ViewBranch>(outlet =>
          outlet.branches.map(branch => ({ ...branch, name: `${getLocalizedName(outlet.name)} - ${branch.name}` })),
        ) ?? []
      ).map(location => [location.id, location]),
    );
  }

  @Action(CurrentRetailerSet, { cancelUncompleted: true })
  set(ctx: StateContext<CurrentRetailerStateModel>, action: CurrentRetailerSet) {
    const { retailerId } = ctx.getState();

    const scope = getCurrentScope();

    scope.setTag('retailerId', retailerId);

    if (action.payload.id !== retailerId) {
      ctx.patchState({ retailerId: action.payload.id });

      if (retailerId && action.payload.id && !action.payload.stopRedirect) {
        const routes = this.router.url.split('/');

        this.document.location.replace(`/${routes?.length ? routes[1] : ''}`);
      }
    }
  }

  @Action(CurrentRetailerSuppliersGet, { cancelUncompleted: true })
  getRetailerSuppliers(ctx: StateContext<CurrentRetailerStateModel>) {
    const qb = new QueryBuilder<Supplier>({
      paging: QueryPaging.NoLimit,
      filtering: {
        filtering: [
          { by: 'state', op: 'eq', match: SupplierState.Active },
          { by: 'state', op: 'eq', match: SupplierState.Pending },
        ],
        groups: [],
        condition: 'or',
      },
    });

    return this.retailersService
      .getRetailersRelatedSuppliers(ctx.getState().retailerId as string, qb.build())
      .pipe(switchMap(({ data }) => ctx.dispatch(new CurrentRetailerSuppliersSet({ suppliers: data }))));
  }

  @Action(CurrentRetailerSuppliersSet)
  setRetailerSuppliers(
    ctx: StateContext<CurrentRetailerStateModel>,
    { payload: { suppliers } }: CurrentRetailerSuppliersSet,
  ) {
    ctx.patchState({ suppliers });
  }

  @Action(CurrentRetailerCreateUser)
  createRetailerUser(ctx: StateContext<CurrentRetailerStateModel>, { body }: CurrentRetailerCreateUser) {
    return this.usersService.createRetailerUserBFF(body);
  }

  @Action(CurrentRetailerUpdateUser)
  updateRetailerUser(ctx: StateContext<CurrentRetailerStateModel>, { id, body }: CurrentRetailerUpdateUser) {
    return this.usersService.updateRetailerUserBFF(id, body);
  }
}
