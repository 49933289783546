import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';

import { IWebSocketClientOptions, WebSocketClientRegistry } from '../client';

export const WEBSOCKET_CLIENT_REGISTRY_OPTIONS = new InjectionToken<IWebSocketClientRegistryOptions>(
  'WEBSOCKET_CLIENT_REGISTRY_OPTIONS',
);

export enum WebSocketApp {
  Default = 'default',
}

export type IWebSocketClientRegistryOptions = Record<WebSocketApp, IWebSocketClientOptions>;

@Injectable()
export class WebSocketClientRegistryService extends WebSocketClientRegistry<WebSocketApp> implements OnDestroy {
  constructor(
    @Inject(WEBSOCKET_CLIENT_REGISTRY_OPTIONS)
    private readonly options: IWebSocketClientRegistryOptions,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}
