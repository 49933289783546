<supy-dialog
  class="supy-create-item-dialog"
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  initialState="open"
  position="center"
  width="40vw"
>
  <supy-dialog-header>
    <supy-icon name="arrow-left" size="small" (click)="onGoBackClicked()" *ngIf="isLastForm"></supy-icon>
    <h4>{{ title }}</h4>
  </supy-dialog-header>
  <supy-dialog-content>
    <div class="supy-create-item-dialog__content">
      <supy-loading-overlay
        *ngIf="(isItemLoading$ | async) || (isCreateItemLoading$ | async) || (isPackageCheckLoading$ | async)"
      ></supy-loading-overlay>
      <form [formGroup]="form">
        <ng-container *ngIf="isFirstForm">
          <div class="supy-create-item-dialog__form" @fadeIn>
            <div class="supy-create-item-dialog__row-full">
              <div class="supy-create-item-dialog__input">
                <label class="supy-create-item-dialog__label">Select Base Item</label>
                <supy-combo-box
                  formControlName="retailerItem"
                  placeholder="Select"
                  (searchInputUpdated)="searchBaseItems.emit($event)"
                  density="medium"
                  [list]="baseItems$ | async"
                  valueKey="id"
                  displayKey="name.en"
                  titleKey="name.en"
                  [isLoading]="isItemSearchLoading$ | async"
                  (changed)="onRetailerItemChanged($event)"
                ></supy-combo-box>
              </div>
            </div>

            <div
              class="supy-create-item-dialog__row supy-create-item-dialog__row-start"
              *ngIf="selectedItem?.id && (selectedItemPackages$ | async)?.length > 0 && !isItemLocked()"
              @swingIn
            >
              <div class="supy-create-item-dialog__input">
                <label class="supy-create-item-dialog__label">Select an existing package:</label>
                <igx-radio-group
                  class="supy-create-item-dialog__radio-group"
                  formControlName="selectBasePackage"
                  (change)="onPackageChange($event)"
                >
                  <igx-radio *ngFor="let package of selectedItemPackages$ | async" [value]="package?.id">{{
                    package?.packageName
                  }}</igx-radio>
                </igx-radio-group>
              </div>
              <div class="supy-create-item-dialog__input">
                <label class="supy-create-item-dialog__label">Enter Price ({{ currency$ | async }})<sup>*</sup></label>
                <supy-input
                  #priceInput
                  placeholder="Enter Price"
                  density="medium"
                  [numeric]="true"
                  formControlName="price"
                ></supy-input>
              </div>
            </div>
            <ng-container *supyContext="packageExists$ | async as packageExists">
              <div class="supy-create-item-dialog__banner" *ngIf="packageExists || isItemLocked()" @swingIn>
                <supy-icon name="info" color="warn" size="small"></supy-icon>
                <span *ngIf="isItemLocked()" class="supy-create-item-dialog__banner--text">
                  Oops! It looks like this item is currently locked. To update it, simply unlock it first.
                </span>
                <span *ngIf="packageExists" class="supy-create-item-dialog__banner--text">
                  The selected package is already assigned to this supplier.
                </span>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="isLastForm">
          <div class="supy-create-item-dialog__form" @fadeIn>
            <div class="supy-create-item-dialog__row-grid" formGroupName="basePackage">
              <div class="supy-create-item-dialog__input">
                <label class="supy-create-item-dialog__label">Base Package Name</label>
                <supy-input
                  [placeholder]="isPiece ? '' : 'Enter Name'"
                  density="medium"
                  formControlName="unitName"
                ></supy-input>
              </div>
              <div class="supy-create-item-dialog__input">
                <label class="supy-create-item-dialog__label">Base Package Quantity <sup>*</sup></label>
                <supy-counter-input
                  density="medium"
                  [disabled]="isPiece"
                  (valueChange)="basePackageQuantityControl.patchValue($event)"
                  [value]="basePackageQuantityControl.value"
                  [min]="0"
                ></supy-counter-input>
              </div>
              <div class="supy-create-item-dialog__input">
                <label class="supy-create-item-dialog__label">Base Unit</label>
                <supy-input density="medium" formControlName="baseUnit"></supy-input>
              </div>
            </div>
            <div *ngIf="!multiLevelPackage">
              <supy-button color="secondary" (buttonClick)="onAddPackageLevel()">
                <supy-icon name="plus-flat" size="small"></supy-icon>
                <span>Add packaging level</span>
              </supy-button>
            </div>
            <ng-container *ngIf="multiLevelPackage">
              <div class="supy-create-item-dialog__row-grid" @swingIn formGroupName="packPackage">
                <div class="supy-create-item-dialog__input">
                  <label class="supy-create-item-dialog__label">Pack Package Name</label>
                  <supy-input placeholder="Enter Name" density="medium" formControlName="unitName"></supy-input>
                </div>
                <div class="supy-create-item-dialog__input">
                  <label class="supy-create-item-dialog__label">Pack Package Quantity <sup>*</sup></label>
                  <supy-counter-input
                    density="medium"
                    [min]="0"
                    [disabled]="packPackageQuantityControl.disabled"
                    (valueChange)="packPackageQuantityControl.patchValue($event)"
                    [value]="packPackageQuantityControl.value"
                  ></supy-counter-input>
                </div>
                <div class="supy-create-item-dialog__input" *ngIf="!isPiece">
                  <label class="supy-create-item-dialog__label supy-create-item-dialog__label-hidden">remove</label>
                  <supy-icon
                    name="delete"
                    color="error"
                    class="supy-create-item-dialog__remove"
                    (click)="onRemovePackageLevel()"
                  ></supy-icon>
                </div>
              </div>
            </ng-container>

            <div class="supy-create-item-dialog__row-grid supy-create-item-dialog__row-grid--secondary">
              <div class="supy-create-item-dialog__input">
                <label class="supy-create-item-dialog__label">Package Item Name</label>
                <supy-input [value]="fullPackageName" [disabled]="true" density="medium"></supy-input>
              </div>
              <div class="supy-create-item-dialog__input">
                <label class="supy-create-item-dialog__label">Enter Price ({{ currency$ | async }}) <sup>*</sup></label>
                <supy-input placeholder="Enter Price" formControlName="price" density="medium"></supy-input>
              </div>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </supy-dialog-content>
  <supy-dialog-footer>
    <div class="supy-create-item-dialog__footer">
      <div *ngIf="!isItemLocked()">
        <div class="supy-create-item-dialog__footer--left" *ngIf="selectedItem?.id && isFirstForm" @fadeIn>
          <span class="supy-create-item-dialog__footer-label">Can't find your package?</span>
          <span class="supy-create-item-dialog__footer-action" (click)="onCreateNewPackageClicked()">
            <supy-icon name="plus-flat" size="small" color="primary"></supy-icon>
            <span class="supy-create-item-dialog__footer-action--text">Create New Package</span>
          </span>
        </div>
      </div>
      <div class="supy-create-item-dialog__footer--right">
        <supy-button color="default" name="cancel" (buttonClick)="closeDialog()">{{ closeText }}</supy-button>
        <supy-button
          color="secondary"
          name="create-item"
          [disabled]="!canCreateItem || (isCreateItemLoading$ | async)"
          (buttonClick)="onSubmit()"
          *ngIf="isFirstForm"
        >
          <supy-loader *ngIf="isCreateItemLoading$ | async"></supy-loader>
          <span>Create Item </span>
        </supy-button>
        <supy-button
          color="secondary"
          name="add-item"
          [disabled]="!canCreatePackage || (isCreateItemLoading$ | async)"
          (buttonClick)="onSubmit()"
          *ngIf="isLastForm"
        >
          <supy-loader *ngIf="isCreateItemLoading$ | async"></supy-loader>
          <span>Add </span>
        </supy-button>
      </div>
    </div>
  </supy-dialog-footer>
</supy-dialog>
