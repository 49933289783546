import { filter, finalize, first, Observable, of, switchMap, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';

import {
  CreateUpdateSupplierResponse,
  IQueryResponse,
  Query,
  QueryPaging,
  Supplier,
  SupplierState,
  SupplierType,
  User,
} from '@supy/common';
import { DetailedRetailerSupplier } from '@supy/retailers';
import { CurrentUserState } from '@supy/users';

import { SuppliersBffService, SuppliersService } from '../../services';
import {
  CreateSupplier,
  CreateSupplierBff,
  DeleteSupplier,
  GetCurrentUserPartners,
  GetSupplierBff,
  GetSuppliers,
  GetSupplierUsers,
  GetUserPartners,
  ManageSupplierUsers,
  ResetCreateUpdateSupplierResponse,
  ResetSelectedSupplier,
  SearchSuppliers,
  UpdateSupplier,
  UpdateSupplierBff,
} from '../actions';

interface SuppliersStoreState {
  readonly selectedSupplier?: DetailedRetailerSupplier | null;
  readonly usersMap: Record<string, User[]>;
  readonly suppliers: Supplier[];
  readonly partners: Supplier[];
  readonly isLoading?: boolean;
  readonly searchSuppliers?: Supplier[];
  readonly createUpdateSupplierResponse?: CreateUpdateSupplierResponse;
}

const SUPPLIERS_STATE_TOKEN = new StateToken<SuppliersStoreState>('suppliers');

@State<SuppliersStoreState>({
  name: SUPPLIERS_STATE_TOKEN,
  defaults: {
    usersMap: {},
    partners: [],
    suppliers: [],
    selectedSupplier: undefined,
    isLoading: false,
    searchSuppliers: [],
  },
})
@Injectable()
export class SuppliersState {
  constructor(
    private readonly suppliersService: SuppliersService,
    private readonly suppliersBffService: SuppliersBffService,
    private readonly store: Store,
  ) {}

  @Selector()
  static isLoading(state: SuppliersStoreState) {
    return state.isLoading;
  }

  @Selector()
  static suppliers(state: SuppliersStoreState) {
    return state.suppliers;
  }

  @Selector()
  static searchSuppliers(state: SuppliersStoreState) {
    return state.searchSuppliers;
  }

  @Selector()
  static selectedSupplier(state: SuppliersStoreState) {
    return state.selectedSupplier;
  }

  @Selector()
  static isCKSupplierV1(state: SuppliersStoreState) {
    return state.selectedSupplier?.type === SupplierType.centralKitchen && !state.selectedSupplier?.isExposed;
  }

  @Selector()
  static isCKSupplierV2(state: SuppliersStoreState) {
    return state.selectedSupplier?.type === SupplierType.centralKitchen && state.selectedSupplier?.isExposed;
  }

  @Selector()
  static createUpdateSupplierResponse(state: SuppliersStoreState) {
    return state.createUpdateSupplierResponse;
  }

  @Selector()
  static suppliersMap(state: SuppliersStoreState) {
    return new Map(state.suppliers.map(supplier => [supplier.id, supplier]));
  }

  @Selector()
  static usersMap(state: SuppliersStoreState) {
    return state.usersMap;
  }

  @Selector()
  static partners(state: SuppliersStoreState) {
    return state.partners;
  }

  @Selector()
  static partnersMap(state: SuppliersStoreState) {
    return new Map(state.partners.map(supplier => [supplier.id, supplier]));
  }

  @Action(GetSuppliers)
  getSuppliers(ctx: StateContext<SuppliersStoreState>, { query }: GetSuppliers) {
    ctx.patchState({ isLoading: true });

    return this.suppliersService.getSuppliers(query).pipe(
      tap(({ data }) => ctx.patchState({ suppliers: data })),
      finalize(() => ctx.patchState({ isLoading: false })),
    );
  }

  @Action(SearchSuppliers)
  searchSuppliers(ctx: StateContext<SuppliersStoreState>, { payload }: SearchSuppliers) {
    return this.suppliersBffService.searchSuppliers(payload).pipe(
      tap(({ data }) => {
        ctx.patchState({ searchSuppliers: data });
      }),
    );
  }

  @Action(CreateSupplierBff)
  createSupplierBff(ctx: StateContext<SuppliersStoreState>, { payload }: CreateSupplierBff) {
    return this.suppliersBffService
      .createSupplier(payload)
      .pipe(tap(createUpdateSupplierResponse => ctx.patchState({ createUpdateSupplierResponse })));
  }

  @Action(UpdateSupplierBff)
  updateSupplierBff(ctx: StateContext<SuppliersStoreState>, { id, payload }: UpdateSupplierBff) {
    return this.suppliersBffService
      .updateSupplier(id, payload)
      .pipe(tap(createUpdateSupplierResponse => ctx.patchState({ createUpdateSupplierResponse })));
  }

  @Action(GetUserPartners)
  getUserPartners(ctx: StateContext<SuppliersStoreState>, { id, query, relativeTo }: GetUserPartners) {
    return this.getUserPartnersRequest(id, query, relativeTo).pipe(
      tap(({ data }) => ctx.patchState({ partners: data })),
    );
  }

  @Action(GetCurrentUserPartners)
  getCurrentUserPartners(ctx: StateContext<SuppliersStoreState>, { relativeTo, forceFetch }: GetCurrentUserPartners) {
    const partners = ctx.getState().partners;

    if (partners.length > 0 && !forceFetch) {
      return of(partners);
    } else {
      const user$ = this.store.select(CurrentUserState.getCurrentUser);

      const query = new Query<Supplier>({
        filtering: [{ by: 'state', op: 'eq', match: SupplierState.Active }],
        paging: QueryPaging.NoLimit,
      });

      return user$.pipe(
        filter(Boolean),
        first(),
        switchMap(user => this.getUserPartnersRequest(user.id, query, relativeTo)),
        tap(({ data }) => ctx.patchState({ partners: data })),
      );
    }
  }

  @Action(GetSupplierBff)
  getSupplierBff(ctx: StateContext<SuppliersStoreState>, { payload }: GetSupplierBff) {
    return this.suppliersBffService
      .getSupplier(payload)
      .pipe(tap(supplier => ctx.patchState({ selectedSupplier: supplier })));
  }

  @Action(ResetSelectedSupplier)
  resetSelected(ctx: StateContext<SuppliersStoreState>) {
    ctx.patchState({ selectedSupplier: null });
  }

  @Action(GetSupplierUsers)
  getSupplierUsers(ctx: StateContext<SuppliersStoreState>, { id, query }: GetSupplierUsers) {
    return this.suppliersService.getSupplierUsers(id, query).pipe(
      tap(({ data: users }) => {
        const state = ctx.getState();

        ctx.patchState({ usersMap: { ...state.usersMap, [id]: users } });
      }),
    );
  }

  @Action(ManageSupplierUsers)
  manageSupplierUsers(_: StateContext<SuppliersStoreState>, { id, users, type }: ManageSupplierUsers) {
    return this.suppliersService.manageSupplierUsers(id, users, type === 'add');
  }

  @Action(CreateSupplier)
  createSupplier(_: StateContext<SuppliersStoreState>, { body }: CreateSupplier) {
    return this.suppliersService.createSupplier(body);
  }

  @Action(UpdateSupplier)
  updateSupplier(_: StateContext<SuppliersStoreState>, { id, body }: UpdateSupplier) {
    return this.suppliersService.editSupplier(id, body);
  }

  @Action(DeleteSupplier)
  deleteSupplier(_: StateContext<SuppliersStoreState>, { id }: DeleteSupplier) {
    return this.suppliersService.deleteSupplier(id);
  }

  @Action(ResetCreateUpdateSupplierResponse)
  resetCreateUpdateSupplierResponse(ctx: StateContext<SuppliersStoreState>) {
    ctx.patchState({ createUpdateSupplierResponse: undefined });
  }

  private getUserPartnersRequest(id: string, query: Query<Supplier>, relativeTo: 'branch' | 'retailer') {
    let request$: Observable<IQueryResponse<Supplier>>;

    switch (relativeTo) {
      case 'branch': {
        request$ = this.suppliersService.getUserRelatedSuppliers(id, query);
        break;
      }

      case 'retailer': {
        request$ = this.suppliersService.getUserRetailersRelatedSuppliers(id, query);
        break;
      }

      default:
        throw Error('Invalid value for `relativeTo`');
    }

    return request$;
  }
}
