import { BaseRequestMetadata, IdType } from '@supy/common';

import {
  DeleteSalesTransactionsRequest,
  ManualSalesImportSyncRequest,
  MapSalesTransactionsRequest,
  ProviderSyncSalesImportRequest,
  SalesTransaction,
} from '../../models';
import { SalesImportFilters, SalesImportRequestMetadata } from '../state';

export class SalesImportGetMany {
  static readonly type = '[SalesImport] GetMany';
}

export class SalesImportGet {
  static readonly type = '[SalesImport] Get';

  constructor(readonly payload: { id: string }) {}
}

export class SalesImportSubmit {
  static readonly type = '[SalesImport] Submit';

  constructor(readonly payload: { id: string }) {}
}

export class SalesImportUploadSheet {
  static readonly type = '[SalesImport] UploadSheet';

  constructor(
    readonly uploadPayload: FormData,
    readonly createPayload: ManualSalesImportSyncRequest,
  ) {}
}

export class SalesImportManualSync {
  static readonly type = '[SalesImport] ManualSync';

  constructor(readonly payload: ManualSalesImportSyncRequest) {}
}

export class SalesImportDelete {
  static readonly type = '[SalesImport] Delete';

  constructor(readonly payload: IdType) {}
}

export class SalesImportInitFilters {
  static readonly type = '[SalesImport] InitFilters';
}

export class SalesImportPatchRequestMetadata {
  static readonly type = '[SalesImport] PatchRequestMetadata';
  constructor(readonly payload: Partial<SalesImportRequestMetadata>) {}
}

export class SalesImportPatchFilters {
  static readonly type = '[SalesImport] PatchFilters';
  constructor(readonly payload: Partial<SalesImportFilters>) {}
}

export class SalesImportResetFilters {
  static readonly type = '[SalesImport] ResetFilters';
}

export class SalesImportPatchTransactionFilters {
  static readonly type = '[SalesImport] PatchTransactionFilters';
  constructor(readonly payload: Partial<SalesImportFilters>) {}
}

export class SalesImportResetTransactionFilters {
  static readonly type = '[SalesImport] ResetTransactionFilters';
}

export class SalesImportGetTransactions {
  static readonly type = '[SalesImport] GetTransactions';
}

export class SalesImportPatchTransaction {
  static readonly type = '[SalesImport] PatchTransaction';

  constructor(readonly transaction: SalesTransaction) {}
}

export class SalesImportDeleteTransactions {
  static readonly type = '[SalesImport] DeleteTransactions';

  constructor(
    readonly importId: string,
    readonly body: DeleteSalesTransactionsRequest,
  ) {}
}

export class SalesImportMapTransactions {
  static readonly type = '[SalesImport] MapTransactions';

  constructor(
    readonly importId: string,
    readonly body: MapSalesTransactionsRequest,
  ) {}
}

export class SalesImportCancelTransactions {
  static readonly type = '[SalesImport] CancelTransactions';

  constructor(readonly importId: string) {}
}

export class SalesImportPatchTransactionsRequestMetadata {
  static readonly type = '[SalesImport] PatchTransactionsRequestMetadata';

  constructor(readonly payload: Partial<BaseRequestMetadata>) {}
}

export class SalesImportResetTransactions {
  static readonly type = '[SalesImport] ResetTransactions';
}

export class SalesImportExportTransactions {
  static readonly type = '[SalesImport] ExportTransactions';

  constructor(readonly payload: { importId: string; branchName: string }) {}
}

export class SalesImportProviderSync {
  static type = '[SalesImport] ProviderSync';

  constructor(readonly payload: ProviderSyncSalesImportRequest) {}
}

export class SalesImportSetProviderSyncStatusListener {
  static readonly type = '[SalesImport] SetProviderSyncStatusListener';

  constructor(
    readonly branchId: string,
    readonly tenantId: string,
  ) {}
}

export class SalesImportDisposeProviderSyncStatusListener {
  static readonly type = '[SalesImport] DisposeSyncStatusListener';
}

export class SalesImportResetProviderSyncStatus {
  static readonly type = '[SalesImport] ResetProviderSyncStatus';
}
