import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { IQueryResponse, Query, QueryPaging, User, UserState } from '@supy/common';

import { BranchesService } from '../../services';

@Injectable()
export class BranchUsersResolver {
  constructor(private readonly branchesService: BranchesService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<IQueryResponse<User>> {
    const query = new Query<User>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'state', op: 'eq', match: UserState.active }],
    });

    return firstValueFrom(this.branchesService.getBranchUsers(route.params.branchId as string, query));
  }
}
