import { BaseRequestMetadata, Query } from '@supy/common';

import {
  CatalogChannelItemApiProps,
  ChannelItem,
  ChannelItemsProps,
  SupplierChannelItem,
  UpdateChannelItemRequest,
} from '../../core';
import { ChannelItemsFilters } from '../state';

export class ChannelItemsGetMany {
  static readonly type = '[ChannelItems] GetMany';
}

export class ChannelItemsInitFilters {
  static readonly type = '[ChannelItems] InitFilters';
}

export class ChannelItemsSetFilters {
  static readonly type = '[ChannelItems] SetFilter';
  constructor(readonly payload: Partial<ChannelItemsFilters>) {}
}

export class ChannelItemsPatchFilters {
  static readonly type = '[ChannelItems] PatchFilter';
  constructor(readonly payload: Partial<ChannelItemsFilters>) {}
}

export class ChannelItemsResetFilters {
  static readonly type = '[ChannelItems] ResetFilter';
}

export class ChannelItemsPatchRequestMeta {
  static readonly type = '[ChannelItems] PatchRequestMeta';
  constructor(readonly payload: Partial<BaseRequestMetadata>) {}
}

export class ChannelItemsUpdateMany {
  static readonly type = '[ChannelItems] UpdateMany';

  constructor(readonly channelItems: UpdateChannelItemRequest[]) {}
}

export class ChannelItemsChangeSelectedItem {
  static readonly type = '[ChannelItems] ChangeSelectedItem';
  constructor(
    readonly packagingId: string,
    readonly item: SupplierChannelItem,
  ) {}
}

export class ChannelItemsGetGroupedItems {
  static readonly type = '[ChannelItems] GetGroupedItems';
  constructor(readonly query: Query<ChannelItemsProps>) {}
}

export class ChannelItemsDeleteBatch {
  static readonly type = '[ChannelItems] DeleteBatch';
  constructor(readonly payload: string[]) {}
}

export class ChannelItemsGetFillableItems {
  static readonly type = '[ChannelItems] GetFillableItems';
  constructor(readonly query: Query<ChannelItem & CatalogChannelItemApiProps>) {}
}
