import { Currency } from '@supy.api/dictionaries';

import {
  getApiDetailsDecorator,
  IdType,
  OutletData,
  removeEmpty,
  SimpleBranch,
  SimpleDocument,
  Supplier,
} from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { Packaging } from '@supy/packaging';

import { GrnChannelItem } from './grn-channel-item.entity';
import {
  BaseCreditNoteResponse,
  CreditNoteResponse,
  CreditNoteStatus,
  GrnCreditNoteResponse,
  GrnCreditNoteType,
} from './grn-credit-note.model';
import { GrnItem } from './grn-item.entity';
import { GrnItemTaxRate } from './grn-item-tax-rate.entity';

const ApiProperty = getApiDetailsDecorator<CreditNoteResponse | GrnCreditNoteResponse>();

export abstract class BaseCreditNote {
  protected constructor(args: NonFunctionProperties<BaseCreditNote>) {
    this.id = args.id;
    this.item = args.item;
    this.quantity = args.quantity;
    this.price = args.price;
    this.total = args.total;
    this.comment = args.comment;
    this.creditType = args.creditType;
    this.status = args.status;
    this.appliedTax = args.appliedTax;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly item?: GrnChannelItem;
  @ApiProperty() readonly quantity: number;
  @ApiProperty() readonly price: number;
  @ApiProperty() readonly total: number;
  @ApiProperty() readonly comment?: string;
  @ApiProperty() readonly creditType: GrnCreditNoteType;
  @ApiProperty() readonly status: CreditNoteStatus;
  @ApiProperty() readonly appliedTax?: GrnItemTaxRate;

  static deserialize(data: BaseCreditNoteResponse): BaseCreditNote {
    return {
      id: data.id,
      item: GrnChannelItem.deserialize(data.item),
      quantity: data.quantity,
      price: data.price,
      total: data.total,
      comment: data.comment,
      creditType: data.creditType,
      status: data.status,
      appliedTax: data.appliedTax && GrnItemTaxRate.deserialize(data.appliedTax),
    };
  }
}

export class GrnCreditNote extends BaseCreditNote {
  private constructor(args: NonFunctionProperties<GrnCreditNote>) {
    super(args);

    this.allocatedCreditNote = args.allocatedCreditNote;
  }

  @ApiProperty() readonly allocatedCreditNote?: IdType;

  static deserialize(data: GrnCreditNoteResponse): GrnCreditNote {
    return new GrnCreditNote({
      ...super.deserialize(data),
      allocatedCreditNote: data.allocatedCreditNote,
    });
  }

  static fromItems({
    items,
    currency,
    adjustmentRate,
    existingCreditNotes,
  }: GrnCreditNoteFromItemsArgs): GrnCreditNote[] {
    const creditNotes: GrnCreditNote[] = [];
    const existingCreditNotesMap = new Map<string, GrnCreditNote>();

    existingCreditNotes.forEach(creditNote => {
      existingCreditNotesMap.set(creditNote.item.id, creditNote);
    });

    items.forEach(item => {
      const { createdAt, updatedAt, createdBy, updatedBy, ...props } = item.channelItem.packaging;
      const itemId = item.channelItem.id;
      const existingCreditNote = existingCreditNotesMap.get(itemId);
      const baseCreditNote = {
        item: {
          id: itemId,
          itemCode: item.channelItem.itemCode,
          name: item.channelItem.name,
          packaging: removeEmpty<Packaging>(props),
        },
      };

      if (item?.quantities?.isCreditCreated) {
        const quantity = item.quantities.document - item.quantities.received;
        const price = item.prices.invoice - (item.prices.invoice * adjustmentRate) / 100;
        const taxAmount = this.calculateAppliedTaxAmount({
          price,
          quantity: item.quantities.received,
          appliedTax: item.appliedTax,
        });

        creditNotes.push({
          ...baseCreditNote,
          ...(existingCreditNote && existingCreditNote.creditType === GrnCreditNoteType.Quantity
            ? existingCreditNote
            : {}),
          quantity,
          price,
          comment: `Qty Credit (Doc ${item.quantities.document}, Received ${item.quantities.received})`,
          creditType: GrnCreditNoteType.Quantity,
          total: price * quantity + taxAmount,
          appliedTax: {
            ...item.appliedTax,
            amount: taxAmount,
          },
        } as GrnCreditNote);
      }

      if (item?.prices?.isCreditCreated) {
        let price = item.prices.invoice - item.prices.item;

        price = price - (price * adjustmentRate) / 100;

        creditNotes.push({
          ...baseCreditNote,
          ...(existingCreditNote && existingCreditNote.creditType === GrnCreditNoteType.Price
            ? existingCreditNote
            : {}),
          quantity: item.quantities.received,
          price,
          comment: `Price Credit (Expected ${item.prices.item} ${currency}, Doc ${item.prices.invoice} ${currency})`,
          creditType: GrnCreditNoteType.Price,
          total: price * item.quantities.received,
          appliedTax: {
            ...item.appliedTax,
            amount: this.calculateAppliedTaxAmount({
              price,
              quantity: item.quantities.received,
              appliedTax: item.appliedTax,
            }),
          },
        } as GrnCreditNote);
      }
    });

    return creditNotes;
  }

  static calculateAppliedTaxAmount({
    quantity,
    price,
    appliedTax,
  }: Pick<GrnCreditNote, 'quantity' | 'price' | 'appliedTax'>): number {
    return ((appliedTax?.rate ?? 0) / 100) * price * quantity;
  }
}

export class CreditNote extends BaseCreditNote {
  private constructor(args: NonFunctionProperties<CreditNote>) {
    super(args);

    this.supplier = args.supplier;
    this.location = args.location;
    this.allocatedGrn = args.allocatedGrn;
    this.issuerGrn = args.issuerGrn;
    this.outlet = args.outlet;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
  }

  @ApiProperty() readonly supplier?: Supplier;
  @ApiProperty() readonly location: SimpleBranch;
  @ApiProperty() readonly allocatedGrn?: SimpleDocument;
  @ApiProperty() readonly issuerGrn: SimpleDocument;
  @ApiProperty() readonly outlet?: OutletData;
  @ApiProperty() readonly createdAt?: string | Date;
  @ApiProperty() readonly updatedAt?: string | Date;

  static deserialize(data: CreditNoteResponse): CreditNote {
    return new CreditNote({
      ...super.deserialize(data),
      supplier: data.supplier,
      location: data.location,
      allocatedGrn: data.allocatedGrn,
      issuerGrn: data.issuerGrn,
      outlet: data.outlet,
      createdAt: data.createdAt && new Date(data.createdAt),
      updatedAt: data.updatedAt && new Date(data.updatedAt),
    });
  }
}

interface GrnCreditNoteFromItemsArgs {
  readonly items: GrnItem[];
  readonly currency: Currency;
  readonly adjustmentRate: number;
  readonly existingCreditNotes: GrnCreditNote[];
}
