import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GridPagingMode, IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { Select, Store } from '@ngxs/store';
import { Currency } from '@supy.api/dictionaries';

import { Destroyable, PartyInfo } from '@supy/common';
import { DialogComponent, GridComponent, IDialogComponent } from '@supy/components';
import { SettingsState } from '@supy/settings';

import { OrderItem } from '../../../../core';

@Component({
  selector: 'supy-add-order-items-dialog',
  templateUrl: './add-order-items-dialog.component.html',
  styleUrls: ['./add-order-items-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddOrderItemsDialogComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;
  @ViewChild(GridComponent, { static: false }) private readonly orderItemsGrid: GridComponent;
  @Select(SettingsState.currency) protected readonly currency$: Observable<Currency>;
  protected readonly currencyPrecision = toSignal(this.store.select(SettingsState.currencyPrecision));

  @Input() set orderItems(orderItems: OrderItem[]) {
    this.#orderItems = [];

    if (orderItems) {
      orderItems.forEach(orderItem => {
        this.#orderItems.push(orderItem);

        if (orderItem.quantity > 0) {
          this.selectedOrderItems.set(orderItem.productId, orderItem);
        }
      });
    }
  }

  get orderItems(): OrderItem[] {
    return this.#orderItems;
  }

  @Input() partyInfo: PartyInfo;

  @Input() readonly limit: number;
  @Input() readonly page$: Observable<number>;
  @Input() readonly isLoading$: Observable<boolean>;
  @Input() readonly submitButtonText: string = $localize`:@@common.actions.addItems:Add Items`;
  @Input() readonly hideAddNewItemButton: boolean;

  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly orderItemsAdded = new EventEmitter<OrderItem[]>();
  @Output() readonly pageChange = new EventEmitter<number>();
  @Output() readonly searchItems = new EventEmitter<string>();
  @Output() readonly createItem = new EventEmitter<void>();

  paginationMode = GridPagingMode.Remote;

  readonly selectedOrderItems = new Map<string, OrderItem>();
  #orderItems: OrderItem[] = [];

  constructor(private readonly store: Store) {
    super();
  }

  onSearch(term: string): void {
    this.orderItemsGrid.filterColumnByString(term, 'name');
  }

  onAdd(): void {
    this.orderItemsGrid.endEdit();
    this.orderItemsAdded.emit(Array.from(this.selectedOrderItems.values()));
  }

  onQuantityChanges(orderItem: OrderItem, quantity: number): void {
    if (quantity > 0) {
      this.selectedOrderItems.set(orderItem.productId, {
        ...orderItem,
        quantity,
      });
    } else {
      this.selectedOrderItems.delete(orderItem.productId);
    }
  }

  getQuantity(orderItem: OrderItem): number {
    return this.selectedOrderItems.get(orderItem.productId)?.quantity ?? 0;
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
