import { TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { UnleashModule } from '@supy/common';

import { OrderResolver, OrdersResolver, SubmitOrderResolver } from './resolvers';
import { GrnService, OrdersService, SupplierReturnService } from './services';
import { CategoryOrdersState, CreditNotesState, GrnsState, OrdersState, SupplierReturnState } from './store';

@NgModule({
  providers: [
    TitleCasePipe,
    OrderResolver,
    OrdersResolver,
    SubmitOrderResolver,
    OrdersService,
    GrnService,
    SupplierReturnService,
  ],
  imports: [
    UnleashModule,
    NgxsModule.forFeature([OrdersState, CategoryOrdersState, GrnsState, CreditNotesState, SupplierReturnState]),
  ],
})
export class OrdersModule {}
