import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ToggleButton } from '../../../button';

export interface ButtonGroupCellEditorContext {
  readonly buttons: ToggleButton[];
}

export type ButtonGroupCellEditorParams = ICellEditorParams<unknown, string, ButtonGroupCellEditorContext>;

@Component({
  selector: 'supy-button-group-cell-editor',
  template: `<div class="supy-button-group-cell-editor">
    <supy-button-group
      [buttons]="context.buttons"
      [value]="value"
      (valueChange)="onValueChange($event)"
    ></supy-button-group>
  </div>`,
  styleUrls: ['./button-group-cell-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupCellEditorComponent implements ICellEditorAngularComp {
  protected params: ButtonGroupCellEditorParams;
  protected context: ButtonGroupCellEditorContext;
  protected value: string;

  agInit(params: ButtonGroupCellEditorParams): void {
    this.params = params;
    this.context = this.params.context;
    this.value = this.params.value;
  }

  getValue(): string {
    return this.value;
  }

  onValueChange(value: string): void {
    this.value = value;
    this.params.stopEditing();
  }
}
