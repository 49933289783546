import { IdType, LocalizedData, PreferredType } from '@supy/common';
import { Packaging } from '@supy/packaging';

export class CalculatedField {
  readonly value: number;
  readonly percentage: number;
}

export enum ReceiveOrderType {
  Save = 'save',
  Close = 'close',
}

export class StatefulOrderEntity {
  readonly ordered: number;
  readonly received?: number;
  readonly modified?: number;
  readonly returned?: number;
  readonly shipped?: number;
  readonly confirmed?: number;
}

export class OrderItemQuantity extends StatefulOrderEntity {
  readonly document?: number;
}

export class SimpleEntity {
  readonly id: string;
  readonly name: string | LocalizedData;
}

export interface ProductRetailerItem {
  readonly id: string;
  readonly code?: string;
  readonly taxCode?: string;
}

export class SimpleOrder {
  readonly id: string;
  readonly channel: SimpleEntity;
  readonly number: string;
  readonly deliveryDate: number;
  readonly submittedDate?: number;
  readonly receivedDate?: number;
  readonly orderedTotal?: number;
  readonly status: OrderStatus;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly branch: SimpleEntity;
  readonly supplier: SimpleEntity;
  readonly retailer: SimpleEntity;
  readonly total: StatefulOrderEntity;
  readonly closed?: boolean;
  readonly vat?: number;
}

export enum OrderStatus {
  Draft = 'draft',
  Submitted = 'submitted',
  Confirmed = 'confirmed',
  Received = 'received',
  PartialReceived = 'partial-received',
  Discarded = 'discarded',
  Pending = 'pending',
  Rejected = 'rejected',
  Modified = 'modified',
  Processed = 'processed',
  Shipped = 'shipped',
}

export enum PercentageValueType {
  Percentage = 'percentage',
  Amount = 'amount',
}

export interface PercentageValue {
  readonly value: number;
  readonly type: PercentageValueType;
}

export class InvoiceItem {
  readonly id: string;
  readonly unit: string;
  readonly packaging: Packaging;
  readonly itemCode?: string;
  readonly quantity: number;
  readonly price: number;
  readonly isDiscounted?: boolean;
  readonly retailerItem: IdType;
  readonly supplierItem?: IdType;
  readonly name: LocalizedData;
  readonly preferredType?: PreferredType;
  readonly inventoryItem?: IdType;
  readonly comment?: string;
}
