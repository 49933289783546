import { IdType, LocalizedData, SimpleUser, Uom } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

export interface PackagingGroup {
  name: string | null;
  items: Packaging[];
}

export class PackagingQuantity {
  parent?: number;
  base: number;
  baseUnit: number;

  static default(): PackagingQuantity {
    return { parent: -1, base: 0, baseUnit: 0 };
  }
}

export type PackagingLevel = 0 | 1 | 2;

export interface Packaging {
  id: string;
  level: PackagingLevel;
  unitName: string;
  packageName: string;
  itemName?: LocalizedData;
  sources: PackagingSource[];
  quantity: PackagingQuantity;
  amount?: number;
  parent?: IdType;
  retailer?: IdType;
  usedAsPiece?: boolean;
  isPreferred?: boolean;
  baseUnit: Uom;
  fullPackageName: string;
  state?: PackagingState;
  updatedBy?: SimpleUser;
  createdBy?: SimpleUser;
  createdAt?: Date;
  updatedAt?: Date;
  inMemory?: 'create' | 'update' | null;
}

export class PackagingSource {
  scope: PackagingScopeEnum;
  id: string;
}

export enum PackagingScopeEnum {
  Recipe = 'recipe',
  Item = 'item',
  Packaging = 'packaging',
  Uom = 'uom',
}

export enum PackagingState {
  Active = 'active',
  Deleted = 'deleted',
  Draft = 'draft',
}

export enum PackagingUnitScope {
  Packaging = 'packaging',
  Uom = 'uom',
}

export class PackagingUnit {
  readonly uomId: string;
  readonly packagingId?: string;
  readonly customId?: string;
  readonly name: string;
  readonly toAtomUom?: number;
  readonly toBaseUom?: number;
  readonly isPiece?: boolean;
  readonly isPreferred?: boolean;

  static default(): PackagingUnit {
    const uomId = crypto.randomUUID();

    return { customId: uomId, name: '', uomId };
  }

  constructor(args: NonFunctionProperties<PackagingUnit>) {
    this.uomId = args.uomId;
    this.packagingId = args.packagingId;
    this.customId = `${args.uomId}${args.packagingId ?? ''}`;
    this.name = args.name;
    this.toAtomUom = args.toAtomUom;
    this.toBaseUom = args.toBaseUom;
    this.isPiece = args.isPiece;
    this.isPreferred = args.isPreferred;
  }

  static deserialize(args: NonFunctionProperties<PackagingUnit>): PackagingUnit {
    return new PackagingUnit({ ...args });
  }

  static deserializeList(data: NonFunctionProperties<PackagingUnit>[]): PackagingUnit[] {
    return data.map(packagingUnit => PackagingUnit.deserialize(packagingUnit));
  }

  static fromBaseUom(uom: Uom): PackagingUnit {
    return new PackagingUnit({
      name: uom.name,
      uomId: uom.id,
      toAtomUom: uom.conversionToAtom,
      toBaseUom: 1,
      isPiece: uom.isPiece,
    });
  }

  static fromBaseUoms(uoms: Uom[]): PackagingUnit[] {
    return uoms.map(uom => PackagingUnit.fromBaseUom(uom));
  }

  static fromPackagingGroups(packagingGroups: PackagingGroup[]): PackagingUnit[] {
    return packagingGroups
      .flatMap(({ items }) => items)
      .map(packaging =>
        PackagingUnit.deserialize({
          uomId: packaging.baseUnit.id,
          packagingId: packaging.id,
          name: packaging.packageName,
          toAtomUom: packaging.quantity.baseUnit * (packaging.baseUnit.conversionToAtom ?? 0),
          toBaseUom: packaging.quantity.baseUnit,
          isPiece: packaging.usedAsPiece,
          isPreferred: packaging.isPreferred,
        }),
      );
  }
}
