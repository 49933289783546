<div class="supy-multiple-image-uploader">
  <div *ngIf="isInitialLoading" class="supy-multiple-image-uploader--initial-loading"></div>
  <input type="file" multiple [accept]="acceptedFormats" #fileInput (change)="onFileChange($event)" hidden />
  <h6 class="supy-multiple-image-uploader__title">
    {{ titleText }}
    <supy-icon class="supy-multiple-image-uploader__picture-icon" name="picture" size="small"></supy-icon>
  </h6>
  <div class="supy-multiple-image-uploader__main-container" *ngIf="imageUrlsCopy?.length; else noImages">
    <span class="supy-multiple-image-uploader__image-container" *ngFor="let imageUrl of imageUrlsCopy; let i = index">
      <supy-icon
        (click)="onRemoveImage(i)"
        class="supy-multiple-image-uploader__remove-icon"
        name="round-x"
        size="medium"
        [class.supy-multiple-image-uploader__remove-icon--hidden]="disabled"
      ></supy-icon>
      <img [src]="imageUrl" (click)="onClickImage(imageUrl)" class="supy-multiple-image-uploader__image" />
    </span>
  </div>
  <ng-template #noImages>
    <div class="supy-multiple-image-uploader__empty-template">
      <supy-icon name="exclamation-hex" size="large"></supy-icon>
      <span>No invoice images</span>
    </div>
  </ng-template>
  <div
    (click)="disabled ? (fileInput.disabled = true) : fileInput.click()"
    class="supy-multiple-image-uploader__main-container"
    [class.supy-multiple-image-uploader__main-container--hidden]="disabled"
  >
    <span class="supy-multiple-image-uploader__upload-input">
      <span class="supy-multiple-image-uploader__input-text">
        <supy-icon class="supy-multiple-image-uploader__camera-icon" name="camera-alt" size="small"></supy-icon>
        <ng-content></ng-content>
        {{ actionText }}
      </span>
      <supy-icon name="right-caret" size="small"></supy-icon>
    </span>
  </div>
  <igx-input-group class="supy-multiple-image-uploader__hints">
    <input type="hidden" igxInput />
    <igx-hint class="supy-multiple-image-uploader__hint-placeholder">
      <supy-input-hint-container class="supy-multiple-image-uploader__hint-container">
        <ng-content select="supy-input-error"></ng-content>
        <ng-content select="supy-input-hint"></ng-content>
      </supy-input-hint-container>
    </igx-hint>
  </igx-input-group>
</div>
