import { BehaviorSubject, filter, Observable, takeUntil } from 'rxjs';
import {
  ColDef,
  ICellRendererParams,
  IsFullWidthRowParams,
  NewValueParams,
  RowHeightParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Currency } from '@supy.api/dictionaries';

import { DEFAULT_QUANTITY_PRECISION, Destroyable, Uom } from '@supy/common';
import {
  ActionsCellRendererComponent,
  ActionsCellRendererContext,
  AutocompleteCellEditorComponent,
  AutocompleteCellEditorContext,
  AutocompleteCellRendererComponent,
  GridPocComponent,
  HeaderWithIconComponent,
  HeaderWithIconParams,
  IconType,
  InputCellEditorComponent,
  SelectCellEditorComponent,
  SelectCellEditorContext,
  SelectComponent,
} from '@supy/components';
import { getUsedAsPiecePackagingUnit, PackagingUnit } from '@supy/packaging';
import { getLocalizedName } from '@supy/settings';

import { InventoryIngredientNormalized, InventoryRecipeType, RecipeIngredientItem } from '../../core';
import { averageIngredientCost, lastPurchaseIngredientCost, quantityGross } from '../../core/formulas';
import { InventoryItemType } from './../../../../core';
import { FullWidthRowComponent } from './components';
import { RecipeIngredientsGrid } from './recipe-ingredients-grid.interface';

export interface RecipeIngredientsForm {
  ingredients: InventoryIngredientNormalized[];
}
export interface SemiFinishedRecipeIngredientsForm {
  ingredients: InventoryIngredientNormalized[];
}

export interface FinishedRecipeIngredientsForm {
  ingredients: [{ salesTypeId: string; ingredients: InventoryIngredientNormalized[] }];
}

@Component({
  selector: 'supy-recipe-ingredients-grid',
  templateUrl: './recipe-ingredients-grid.component.html',
  styleUrls: ['./recipe-ingredients-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipeIngredientsGridComponent),
      multi: true,
    },
  ],
})
export class RecipeIngredientsGridComponent<T>
  extends Destroyable
  implements OnInit, OnChanges, ControlValueAccessor, RecipeIngredientsGrid
{
  @ViewChild(GridPocComponent, { static: false }) private readonly gridPoc: GridPocComponent;
  @ViewChild('customOptionsTemplate') private readonly customOptionsTemplate: TemplateRef<unknown>;
  @ViewChild('noMatchOption') private readonly noMatchOption: TemplateRef<unknown>;
  @ViewChildren('itemSelect') private readonly itemSelects: QueryList<SelectComponent<string>>;

  @Input() set ingredientItems(value: RecipeIngredientItem[]) {
    this.#searchableItemListChanges.next(value);
  }

  @Input() readonly ingredientItemsFetched: Observable<boolean>;
  @Input() readonly ingredientItemsLoading: boolean;
  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() set units(value: Uom[]) {
    this.#units = value;
    this.totalUnits = value.filter(unit => !unit.isPiece);
    this.totalUnitId = value.find(unit => unit.name === 'Kg')?.id;
    this.setAvailableUoms(this.ingredients);
  }

  @Input() readonly isReadonly: boolean;
  @Input() readonly costTitle: string;
  @Input() readonly hideCosts: boolean;

  @Output() readonly searchValueChange = new EventEmitter<string>();

  protected readonly inventoryRecipeType = InventoryRecipeType;
  protected readonly quantityGross = quantityGross;
  protected readonly averageIngredientCost = averageIngredientCost;
  protected readonly lastPurchaseIngredientCost = lastPurchaseIngredientCost;
  protected readonly form = new FormGroup({
    ingredients: new FormControl<InventoryIngredientNormalized[]>([]),
  });

  readonly #searchableItemListChanges = new BehaviorSubject<RecipeIngredientItem[]>([]);
  readonly searchableItemList$ = this.#searchableItemListChanges.asObservable();

  readonly uomsMap = new Map<string | undefined, PackagingUnit[]>();

  protected currentAutoCompleteId: string | null = null;
  protected colDefs: ColDef[] = [];
  protected readonly fullWidthCellRenderer = FullWidthRowComponent;
  isFullWidthRow: (params: IsFullWidthRowParams) => boolean = (
    params: IsFullWidthRowParams<{ fullWidth: boolean }>,
  ) => {
    return params.rowNode.data?.fullWidth ?? false;
  };

  onTouched: () => void;
  onChange: (value: T) => void;

  get ingredients(): InventoryIngredientNormalized[] {
    return this.form.getRawValue().ingredients ?? [];
  }

  get ingredientsTotal(): { net: number; gross: number; avgCost: number } {
    const totalUnit = this.totalUnits.find(unit => unit.id === this.totalUnitId);
    const total = this.ingredients?.reduce(
      (prev, curr) => {
        const toAtomUom = curr.packagingUnit?.isPiece
          ? getUsedAsPiecePackagingUnit(curr.item?.packagings ?? [])?.toAtomUom
          : curr.packagingUnit?.toAtomUom;

        return {
          net: ((toAtomUom ?? 0) * (curr.quantityNet ?? 0)) / (totalUnit?.conversionToAtom ?? 0) + prev.net,
          gross: ((toAtomUom ?? 0) * (quantityGross(curr) ?? 0)) / (totalUnit?.conversionToAtom ?? 0) + prev.gross,
          avgCost: averageIngredientCost(curr) + prev.avgCost,
        };
      },
      { net: 0, gross: 0, avgCost: 0 },
    );

    return total;
  }

  #units: Uom[];
  totalUnits: Uom[];
  totalUnitId: string;
  pinnedBottomRowData: (InventoryIngredientNormalized & { fullWidth: boolean })[] = [
    {
      item: null,
      modifier: false,
      fullWidth: true,
    },
  ];

  protected fullWidthCellRendererParams: { grid: unknown } = {
    grid: this,
  };

  protected readonly defaultColDefs: ColDef = {};

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.onChange?.(value as T);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.costTitle || changes.currency || changes.isReadonly) {
      this.setColDefs();
    }
  }

  isCurrentAutocomplete(id: string): boolean {
    return this.currentAutoCompleteId === id;
  }

  getAutocompleteOptions() {
    return this.searchableItemList$;
  }

  getAutocompleteFetchDone(id: string): Observable<boolean> {
    return this.ingredientItemsFetched.pipe(filter(() => this.isCurrentAutocomplete(id)));
  }

  onSearchValueChange(rowData: InventoryIngredientNormalized, term: string): void {
    this.currentAutoCompleteId = rowData.id ?? null;
    this.#searchableItemListChanges.next([]);
    this.searchValueChange.emit(term);
  }

  getUom(rowData?: InventoryIngredientNormalized | null, rowPackagingUnit?: PackagingUnit): PackagingUnit {
    let res: PackagingUnit | null;

    if (this.uomsMap.has(rowData?.id)) {
      if (rowPackagingUnit) {
        const findPieceUom = (packagingUnit: PackagingUnit) => !packagingUnit.packagingId && packagingUnit.isPiece;
        const findUom = (packagingUnit: PackagingUnit) =>
          !packagingUnit.packagingId && packagingUnit.uomId === rowPackagingUnit.uomId;
        const predicate = rowPackagingUnit.packagingId ? findPieceUom : findUom;

        res = this.uomsMap.get(rowData?.id)?.find(predicate);
      } else {
        const baseUnitId = rowData?.item?.packagingUnit?.uomId;

        if (baseUnitId) {
          res = this.uomsMap.get(rowData.id)?.find(unit => unit.uomId === baseUnitId);
        }
      }
    }

    return res ?? PackagingUnit.default();
  }

  setAvailableUoms(items: InventoryIngredientNormalized[]): void {
    const baseUoms: PackagingUnit[] = PackagingUnit.deserializeList(
      this.#units.map(({ id, name, conversionToAtom, isPiece }) => ({
        uomId: id,
        name,
        toAtomUom: conversionToAtom,
        isPiece,
      })),
    );

    const pieceUom = this.#units.find(({ isPiece }) => isPiece);

    items?.forEach(({ id, item }) => {
      if (item) {
        if (item.packagingUnit?.uomId === pieceUom?.id) {
          this.uomsMap.set(id, [PackagingUnit.fromBaseUom(pieceUom)]);
        } else if (item.packagings?.find(({ isPiece }) => !!isPiece)) {
          this.uomsMap.set(id, PackagingUnit.deserializeList(baseUoms));
        } else {
          this.uomsMap.set(id, PackagingUnit.deserializeList(baseUoms.filter(({ isPiece }) => !isPiece)));
        }
      }
    });
    this.gridPoc?.api?.refreshCells({ force: true });
  }

  onCellEditDone(): void {
    this.form.patchValue({ ingredients: structuredClone(this.ingredients) });
  }

  onRowAdding(): void {
    if (this.isGridValid()) {
      this.form.patchValue({ ingredients: [...this.ingredients, InventoryIngredientNormalized.default()] });

      setTimeout(() => {
        this.gridPoc?.agGrid?.api.startEditingCell({ rowIndex: this.ingredients.length - 1, colKey: 'item' });
        setTimeout(() => this.itemSelects.last?.select.input.focus(), 0);
      }, 0);
    }
  }

  getIngredientIcon(type: InventoryItemType | undefined): IconType | null {
    switch (type) {
      case InventoryItemType.Item:
        return 'salt';
      case InventoryItemType.Finished:
      case InventoryItemType.FinishedItem:
        return 'food';
      case InventoryItemType.SemiFinished:
      case InventoryItemType.SemiFinishedItem:
        return 'sub-recipe';
      default:
        return null;
    }
  }

  markForCheck(): void {
    this.gridPoc?.markForCheck();
  }

  itemDisplayValueFn(value: RecipeIngredientItem): string {
    return getLocalizedName(value?.name);
  }

  itemValidatorFn(value: string, items: RecipeIngredientItem[]): RecipeIngredientItem {
    return items?.find(item => getLocalizedName(item.name).toLowerCase() === value?.toLowerCase());
  }

  onItemSelected(rowData: InventoryIngredientNormalized, $event: RecipeIngredientItem): void {
    const filledRowData: InventoryIngredientNormalized = { ...rowData, item: $event };

    this.setAvailableUoms([filledRowData]);

    const updatedIngredient = $event
      ? { item: $event, packagingUnit: this.getUom(filledRowData) }
      : { item: null, packagingUnit: null, prepWastage: null };

    this.form.patchValue({
      ingredients: this.ingredients.map(ingredient =>
        ingredient.id === filledRowData.id
          ? ({ ...ingredient, ...updatedIngredient } as InventoryIngredientNormalized)
          : ingredient,
      ),
    });
  }

  writeValue(value: { ingredients: InventoryIngredientNormalized[] }): void {
    if (value) {
      this.setAvailableUoms(value.ingredients);
      this.form.patchValue(structuredClone(value));
    }
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  isGridValid(): boolean {
    return this.ingredients.every(({ item, packagingUnit, quantityNet }) => item && packagingUnit && quantityNet);
  }

  getRowHeight: (params: RowHeightParams) => number | undefined | null = (
    params: RowHeightParams<{ fullWidth: boolean }>,
  ) => {
    const isBodyRow = params.node.rowPinned !== undefined;
    const isFullWidth: boolean = params.node.data?.fullWidth;

    if (isBodyRow && isFullWidth && (params.api.getRenderedNodes().length >= 1 || this.ingredients?.length >= 1)) {
      return 72;
    }

    return 40;
  };

  setColDefs() {
    const colDefs: ColDef[] = [
      {
        field: 'item',
        headerName: $localize`:@@items:Items`,
        flex: 3,
        cellRenderer: AutocompleteCellRendererComponent,
        cellEditor: AutocompleteCellEditorComponent,
        editable: !this.isReadonly,
        cellEditorParams: {
          context: this.getItemsContext(),
        },
        cellRendererParams: {
          context: {
            showIcon: true,
            icon: (rowData: InventoryIngredientNormalized) => this.getIngredientIcon(rowData.item?.type),
            displayFn: (rowData: InventoryIngredientNormalized) => getLocalizedName(rowData?.item?.name),
          },
        },
        onCellValueChanged: ({ data }: NewValueParams<InventoryIngredientNormalized>) => {
          if (data.item) {
            this.onItemSelected(data, data.item);
          }
        },
      },
      {
        field: 'quantityNet',
        headerName: $localize`:@@grid.headers.netQty.label:Net Qty.`,
        editable: !this.isReadonly,
        cellDataType: 'number',
        cellRenderer: (params: ICellRendererParams<InventoryIngredientNormalized>) => {
          const data: InventoryIngredientNormalized = params.data;

          return (
            data.quantityNet?.toFixed(DEFAULT_QUANTITY_PRECISION) ??
            '<span class="supy-create-recipe__ingredients-grid-cell-placeholder">Net Qty.</span>'
          );
        },
        flex: this.hideCosts ? 2 : 1,
        cellEditor: InputCellEditorComponent,
        cellEditorParams: {
          context: {
            numeric: true,
            placeholder: '0.000',
            precision: 3,
          },
        },
      },
      {
        field: 'packagingUnit',
        cellRenderer: (params: ICellRendererParams<InventoryIngredientNormalized>) => {
          const data: InventoryIngredientNormalized = params.data;

          if (data?.packagingUnit || data?.item) {
            return this.getUom(data, data.packagingUnit)?.name;
          }

          return '<span class="supy-create-recipe__ingredients-grid-cell-placeholder">UOM</span>';
        },
        headerName: $localize`:@@uom:UOM`,
        editable: !this.isReadonly,
        cellEditor: SelectCellEditorComponent,
        cellEditorParams: {
          context: this.getSelectUomContext(),
        },
        flex: this.hideCosts ? 2 : 1,
      },
      {
        field: 'prepWastage',
        editable: !this.isReadonly,
        cellDataType: 'number',
        cellRenderer: (params: ICellRendererParams<InventoryIngredientNormalized>) => {
          const data: InventoryIngredientNormalized = params.data;

          const dataToDisplay: number | null =
            (Number.isFinite(data?.prepWastage) ? data.prepWastage : data?.item?.wastagePercentage) ?? null;

          if (dataToDisplay !== null) {
            return `${dataToDisplay}%`;
          }

          return '<span class="supy-create-recipe__ingredients-grid-cell-placeholder">0%</span>';
        },
        headerName: $localize`:@@grid.headers.prepWastagePercent.label:Prep Wastage (%)`,
        cellEditor: InputCellEditorComponent,
        cellEditorParams: {
          context: {
            numeric: true,
            placeholder: '0.000',
            precision: 3,
          },
        },
        flex: 1,
      },
      {
        field: 'quantityGross',
        headerName: $localize`:@@grid.headers.grossQty.label:Gross Qty.`,
        valueGetter: (params: ValueGetterParams<InventoryIngredientNormalized>) => {
          const data: InventoryIngredientNormalized = params.data;

          return Number(this.quantityGross(data)).toFixed(DEFAULT_QUANTITY_PRECISION);
        },
        cellRenderer: (params: ICellRendererParams<InventoryIngredientNormalized>) => params.value as string,
        suppressNavigable: !this.isReadonly,
        flex: 1,
      },
      {
        field: 'modifier',
        editable: !this.isReadonly,
        headerComponent: HeaderWithIconComponent,
        headerComponentParams: {
          header: $localize`:@@inventory.recipe.grid.modifier:Modifier`,
          tooltip: $localize`:@@inventory.recipe.grid.modifierTooltip:If it's true, the ingredient will not be depleted when a sales event happens`,
        } as HeaderWithIconParams,
        flex: 1,
      },
      {
        field: 'includedInCost',
        editable: !this.isReadonly,
        headerName: $localize`:@@grid.headers.inclInCost.label:Incl. In Cost`,
        flex: 1,
      },
      {
        headerName: ``,
        cellRenderer: ActionsCellRendererComponent<InventoryIngredientNormalized>,
        cellRendererParams: {
          context: this.getActionsContext(),
        },
        flex: 0.6,
      },
    ];

    const costColDefs: ColDef[] = [
      {
        field: 'itemCost',
        cellRenderer: (params: ICellRendererParams<InventoryIngredientNormalized>) => {
          const data: InventoryIngredientNormalized = params.data;

          return Number(this.averageIngredientCost(data)).toFixed(this.currencyPrecision);
        },
        headerName: `${this.costTitle} (${this.currency})`,
        flex: 1,
      },
      {
        field: 'itemCost',
        cellRenderer: (params: ICellRendererParams<InventoryIngredientNormalized>) => {
          const data: InventoryIngredientNormalized = params.data;

          return Number(this.lastPurchaseIngredientCost(data)).toFixed(this.currencyPrecision);
        },
        headerName: $localize`:@@grid.headers.lastPurchaseCostCurrency.label:Last Purchase Cost (${this.currency})`,
        flex: 1,
      },
    ];

    if (!this.hideCosts) {
      colDefs.splice(5, 0, ...costColDefs);
    }

    this.colDefs = colDefs;

    this.gridPoc?.setColumnDefs(this.colDefs);
  }

  getActionsContext(): ActionsCellRendererContext<InventoryIngredientNormalized> {
    return {
      actions: [
        {
          icon: 'delete',
          iconColor: 'error',
          callback: (data: InventoryIngredientNormalized) => {
            const rowIndex = this.ingredients.findIndex(({ id }) => id === data.id);

            this.ingredients.splice(rowIndex, 1);
            this.onCellEditDone();
          },
          hidden: this.isReadonly,
        },
      ],
    };
  }

  getSelectUomContext(): SelectCellEditorContext<InventoryIngredientNormalized, PackagingUnit> {
    return {
      options: (rowData: InventoryIngredientNormalized) => this.uomsMap.get(rowData?.id),
      value: (rowData: InventoryIngredientNormalized) => this.getUom(rowData, rowData?.packagingUnit),
      disabled: (rowData: InventoryIngredientNormalized) => this.uomsMap.get(rowData?.id)?.length === 1,
      displayFn: (uom: PackagingUnit) => uom.name,
    };
  }

  getItemsContext(): AutocompleteCellEditorContext<InventoryIngredientNormalized, RecipeIngredientItem> {
    return {
      options: () => this.getAutocompleteOptions(),
      displayFn: (item: RecipeIngredientItem) => this.itemDisplayValueFn(item),
      validatorFn: (term: string, options: RecipeIngredientItem[]) => this.itemValidatorFn(term, options),
      prefixIcon: (rowData: InventoryIngredientNormalized) => this.getIngredientIcon(rowData.item?.type),
      onItemSelected: (rowData: InventoryIngredientNormalized, value: RecipeIngredientItem) =>
        this.onItemSelected(rowData, value),
      noMatchOption: this.noMatchOption,
      customOptionsTemplate: this.customOptionsTemplate,
      isLoading: (rowData: InventoryIngredientNormalized) =>
        this.ingredientItemsLoading && this.isCurrentAutocomplete(rowData?.id ?? ''),
      fetchDone: (rowData: InventoryIngredientNormalized) => this.getAutocompleteFetchDone(rowData?.id ?? ''),
      searchValueChange: (rowData: InventoryIngredientNormalized, search: string) =>
        this.onSearchValueChange(rowData, search),
    };
  }
}
