import {
  CreateSupplierReturnRequest,
  LockSupplierReturnsRequest,
  PostSupplierReturnsRequest,
  UpdateSupplierReturnRequest,
} from '../../core';
import { SupplierReturnFilters, SupplierReturnRequestMetadata } from '../state';

export class SupplierReturnGetMany {
  static readonly type = '[SupplierReturn] Get Many';
}

export class SupplierReturnListExport {
  static readonly type = '[SupplierReturn] ListExport';
}

export class SupplierReturnGetOne {
  static readonly type = '[SupplierReturn] Get One';

  constructor(readonly payload: { id: string; fromCache?: boolean }) {}
}

export class SupplierReturnCreate {
  static readonly type = '[SupplierReturn] Create';

  constructor(readonly payload: { body: CreateSupplierReturnRequest }) {}
}

export class SupplierReturnUpdate {
  static readonly type = '[SupplierReturn] Update';

  constructor(readonly payload: { id: string; readonly body: UpdateSupplierReturnRequest }) {}
}

export class SupplierReturnPost {
  static readonly type = '[SupplierReturn] Post';

  constructor(readonly payload: string) {}
}

export class SupplierReturnLockMany {
  static readonly type = '[SupplierReturn] Lock Many';

  constructor(readonly payload: LockSupplierReturnsRequest) {}
}

export class SupplierReturnPostMany {
  static readonly type = '[SupplierReturn] Post Many';

  constructor(readonly payload: PostSupplierReturnsRequest) {}
}

export class ClearSupplierReturnPostMany {
  static readonly type = '[SupplierReturn] ClearPostMany';
}

export class SupplierReturnInitFilters {
  static readonly type = '[SupplierReturn] InitFilters';
}

export class SupplierReturnSetFilter {
  static readonly type = '[SupplierReturn] SetFilter';

  constructor(readonly payload: SupplierReturnFilters) {}
}

export class SupplierReturnPatchFilter {
  static readonly type = '[SupplierReturn] PatchFilter';

  constructor(readonly payload: Partial<SupplierReturnFilters>) {}
}

export class SupplierReturnResetFilter {
  static readonly type = '[SupplierReturn] ResetFilter';
}

export class SupplierReturnPatchRequestMetadata {
  static readonly type = '[SupplierReturn] PatchRequestMetadata';

  constructor(readonly payload: Partial<SupplierReturnRequestMetadata>) {}
}

export class SupplierReturnGenerateDocumentNumber {
  static readonly type = '[SupplierReturn] GenerateDocumentNumber';
}
