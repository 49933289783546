<main class="supy-page">
  <header #headerElement class="supy-page__header-content">
    <div class="supy-page__header-top-content">
      <supy-breadcrumb
        *ngIf="withBreadcrumbs; else customBreadcrumbs"
        [showBackButton]="showBackButton"
        [mergeParams]="mergeParams"
        [aliases]="aliases"
        [breadcrumbs]="breadcrumbs"
        [skipFirstRoute]="skipFirstRoute"
        [type]="breadcrumbs?.length ? 'semi-static' : 'dynamic'"
      ></supy-breadcrumb>
      <ng-template #customBreadcrumbs>
        <ng-content select="[pageBreadcrumb]"></ng-content>
      </ng-template>

      <div class="supy-page__header-content--section">
        <supy-button
          color="default-outline"
          variant="icon"
          [round]="true"
          name="notification"
          class="supy-page__notifications"
          height="full"
          width="full"
          [class.supy-page__new-notification]="hasNewNotifications()"
          (buttonClick)="showNotificationsDrawer()"
        >
          <supy-icon name="notification" size="small"></supy-icon>
        </supy-button>
        <ng-container *ngIf="isSuperAdmin; else regularHeader">
          <supy-button
            *ngIf="currentRetailer"
            color="warn"
            class="supy-page__retailers-select"
            (buttonClick)="onInputClick($event)"
          >
            {{ currentRetailerName }}&nbsp;
            <supy-loader *ngIf="appendQueryParamsLoading$ | async; else sidebarIcon"></supy-loader>
            <ng-template #sidebarIcon>
              <supy-icon name="sidebar-left"></supy-icon>
            </ng-template>
          </supy-button>
        </ng-container>
        <ng-template #regularHeader>
          <supy-button class="supy-page__refer-earn" (buttonClick)="referAndEarn()" *ngIf="hasReferAndEarnFeature()">
            <supy-loader *ngIf="appendQueryParamsLoading$ | async; else walletIcon" color="warn"></supy-loader>
            <ng-template #walletIcon>
              <supy-icon #walletIcon name="wallet-money" color="warn"></supy-icon>
            </ng-template>
            <span class="supy-page__refer-earn-text">Refer & Earn</span>
          </supy-button>
          <supy-combo-box
            *ngIf="retailers?.length && currentRetailer"
            class="supy-page__retailers"
            name="retailer"
            displayKey="name"
            valueKey="id"
            titleKey="name"
            [value]="[currentRetailer]"
            [list]="retailers"
            [localSearch]="true"
            [itemHeight]="40"
            (changed)="onRetailerChange($event[0])"
          ></supy-combo-box>
        </ng-template>
        <supy-dropdown-toggle
          *ngIf="user"
          [dropdownRef]="supyDropdown"
          position="bottom-left"
          class="supy-page__dropdown"
          action
        >
          <supy-avatar [initials]="user.fullName" [src]="user.photoUrl" [roundShape]="false" size="small"></supy-avatar>
        </supy-dropdown-toggle>
        <supy-dropdown #supyDropdown action>
          <ng-container *supyToggle="toggleFeature.Localize">
            <supy-dropdown-item (click)="showUserSettingsDrawer()">
              <span>Preferences</span>
            </supy-dropdown-item>
          </ng-container>
          <supy-dropdown-item (click)="logout()">
            <span>Logout</span>
          </supy-dropdown-item>
        </supy-dropdown>
      </div>
    </div>

    <h1 *ngIf="header; else pageHeader" class="supy-page__header">{{ header }}</h1>
    <ng-template #pageHeader>
      <ng-content select="[pageHeader]"></ng-content>
    </ng-template>
    <h2 *ngIf="subHeader; else pageSubHeader" class="supy-page__sub-header">{{ subHeader }}</h2>
    <ng-template #pageSubHeader>
      <ng-content select="[pageSubHeader]"></ng-content>
    </ng-template>

    <p *ngIf="description; else pageDescription" class="supy-page__description">
      {{ description }}
    </p>
    <ng-template #pageDescription>
      <ng-content select="[pageDescription]"></ng-content>
    </ng-template>
  </header>

  <supy-separator *ngIf="withSeparator" type="solid"></supy-separator>
  <div class="supy-page__content" [class.supy-page__content--no-padding]="noPadding">
    <ng-content></ng-content>
    <div class="supy-page__maintenance-overlay"></div>
  </div>
  <div class="supy-page__sticky-bottom">
    <ng-content select="[pageStickyBottom]"></ng-content>
  </div>
</main>
