<div class="channels-list">
  <div class="channels-list__header">
    <div class="channels-list__header-location">
      <span class="channels-list__header-label" i18n="@@channels.list.chooseLocation">Choose Location</span>

      <supy-combo-box
        placeholder="Choose Location"
        i18n-placeholder="@@channels.list.chooseLocation"
        [list]="branchesList()"
        [value]="[selectedBranch?.id]"
        [localSearch]="true"
        name="select-branch"
        titleKey="name"
        displayKey="name"
        valueKey="id"
        [clearable]="false"
        [itemHeight]="40"
        [disabled]="!selectedBranch?.id"
        (changed)="onBranchChanged($event)"
      ></supy-combo-box>
    </div>
  </div>

  <div class="channels-list__container">
    <supy-search-input
      name="channels-search"
      class="channels-list__search"
      i18n-placeholder="@@suppliers.search"
      placeholder="Search Suppliers"
      [latency]="300"
      suffixColor="primary"
      [value]="channelsSearchValue()"
      (valueChange)="channelsSearchValue.set($event)"
    ></supy-search-input>

    <div class="channels-list__content">
      <supy-list class="channels-list__list">
        <supy-loading-overlay *ngIf="isLoadingChannels"></supy-loading-overlay>
        <supy-list-item
          class="channels-list__item"
          *ngFor="let channel of filteredChannels(); trackBy: trackByChannelId"
          [routerLink]="channel.id"
          [routerLinkActiveOptions]="{ exact: false }"
          routerLinkActive="channels-list__item--active"
          (clicked)="channelClicked.emit(channel)"
        >
          <supy-avatar
            item-thumbnail
            [src]="channel.logoUrl"
            [initials]="channel.name.slice(0, 2)"
            size="small"
          ></supy-avatar>
          <h6 item-line-title class="channels-list__item-title">{{ channel.displayName?.supplier || channel.name }}</h6>
          <supy-icon name="arrow-circle-right" color="gray" item-action></supy-icon>
        </supy-list-item>
      </supy-list>
    </div>
  </div>
</div>
