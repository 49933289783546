import {
  extent,
  Force,
  forceCollide as D3ForceCollide,
  forceSimulation as D3ForceSimulation,
  forceX,
  forceY,
  ScalePower,
  scaleSqrt,
  Simulation,
  SimulationNodeDatum,
} from 'd3';
import forceBoundary from 'd3-force-boundary';
import { Selection } from 'd3-selection';
import { Subject, takeUntil } from 'rxjs';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { HexColor } from '@supy/common';

import { ChartBaseDirective, getTooltipPosition } from '../../../chart-core';
import { BubbleChartData } from './bubble-chart.interfaces';

interface ForceWithStrength extends Force<SimulationNodeDatum, undefined> {
  strength(n: number): this;
}

@Component({
  selector: 'supy-bubble-chart',
  templateUrl: './bubble-chart.component.html',
  styleUrls: ['./bubble-chart.component.scss'],
})
export class BubbleChartComponent extends ChartBaseDirective {
  @Input() protected readonly data: BubbleChartData[];
  @Input() protected readonly colorsMap: Map<string, HexColor> = new Map();

  @Output() readonly circleClick = new EventEmitter<BubbleChartData | null>();

  protected readonly marginTop: number = 0; // top margin, in pixels
  protected readonly marginRight: number = 0; // right margin, in pixels
  protected readonly marginBottom: number = 0; // bottom margin, in pixels
  protected readonly marginLeft: number = 0; // left margin, in pixels
  private readonly click$ = new Subject<MouseEvent>();
  private circleRadiusScale: ScalePower<number, number>;
  private circles: Selection<SVGGElement, BubbleChartData, SVGGElement, unknown>;
  private forceSimulationNodes: Simulation<SimulationNodeDatum, undefined>;

  @HostListener('document:click', ['$event'])
  protected onClick(event: MouseEvent): void {
    this.click$.next(event);
  }

  @Input() protected readonly valueFn: (d: BubbleChartData) => number = (d: BubbleChartData) => d.value;
  @Input() protected readonly categoryFn = (d: BubbleChartData) => d.categoryName;
  @Input() protected readonly labelFn = (d: BubbleChartData) => d.name;
  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(elementRef, cdr);
  }

  protected renderChart() {
    this.svg = this.createSVGElement();

    const entities: BubbleChartData[] = this.data;

    const values = entities.map(d => this.valueFn(d));
    const populationExtent = extent(values);
    const isMoreThanFifty = entities.length > 50;
    const minSizeForMoreThanFifty = 6;
    const minSizeForLessThanFifty = 12;
    const maxSizeForMoreThanFifty = 40;
    const maxSizeForLessThanFifty = 80;

    const circleSize = {
      min: isMoreThanFifty ? minSizeForMoreThanFifty : minSizeForLessThanFifty,
      max: isMoreThanFifty ? maxSizeForMoreThanFifty : maxSizeForLessThanFifty,
    };

    this.circleRadiusScale = (this.circleRadiusScale ?? scaleSqrt())
      .domain(populationExtent)
      .range([circleSize.min, circleSize.max]);

    this.createCircles(entities);
    this.createForceSimulation(entities);
  }

  private createCircles(entities: BubbleChartData[]) {
    const element: Selection<SVGGElement, BubbleChartData, SVGGElement, unknown> = this.svg
      .selectAll('.circle-group')
      .data(entities) as unknown as Selection<SVGGElement, BubbleChartData, SVGGElement, unknown>;

    const elementEnter = element.enter().append('g').classed('circle-group', true);

    elementEnter
      .append('circle')
      .classed('clickable', this.isClickable)
      .attr('r', d => this.circleRadiusScale(this.valueFn(d)))
      .attr('fill', d => {
        return this.colorsMap?.get(this.categoryFn(d)) ?? '#BFBBCC';
      });

    elementEnter
      .append('foreignObject')
      .attr('text-anchor', 'left')
      .style('transform', d => {
        const r = this.circleRadiusScale(this.valueFn(d));

        const y = -r / ((r * 25) / 100);
        const x = -r;

        return `translate(${x}px, ${y}px)`;
      })
      .attr('width', d => {
        const r = this.circleRadiusScale(this.valueFn(d));

        return r * 2;
      })
      .style('width', d => {
        const r = this.circleRadiusScale(this.valueFn(d));

        return r * 2;
      })
      .attr('height', d => {
        const r = this.circleRadiusScale(this.valueFn(d));

        return r / ((r * 10) / 100);
      })
      .style('height', d => {
        const r = this.circleRadiusScale(this.valueFn(d));

        return r / ((r * 10) / 100);
      })
      .style('font-size', d => {
        const r = this.circleRadiusScale(this.valueFn(d));

        return `${r / ((r * 10) / 100)}px`;
      })
      .style('line-height', d => {
        const r = this.circleRadiusScale(this.valueFn(d));

        return `${r / ((r * 10) / 100)}px`;
      })
      .style('pointer-events', 'none')
      .append('xhtml:div')
      .classed('bubble-label', true)
      .text(d => this.labelFn(d).toUpperCase());

    this.circles = element.merge(elementEnter);

    element.exit().remove();
  }

  private getForces() {
    const forceStrength = 0.05;

    return {
      x: forceX(this.width / 2).strength(forceStrength),
      y: forceY(this.height / 2).strength(forceStrength),
    };
  }

  private createForceSimulation(entities: BubbleChartData[]) {
    const forces = this.getForces();

    if (!this.forceSimulationNodes) {
      this.forceSimulationNodes = D3ForceSimulation()
        .force(
          'boundary',
          (forceBoundary as (...args) => ForceWithStrength)(0, 0, this.width, this.height).strength(0.05),
        )
        .force('x', forces.x)
        .force('y', forces.y)
        .force('collide', D3ForceCollide(this.forceCollide as unknown as number))
        .nodes(entities as unknown as SimulationNodeDatum[]);
    }

    this.forceSimulationNodes.on('tick', () => {
      this.circles.attr('transform', function (d) {
        const { x, y } = d as SimulationNodeDatum;

        return `translate(${x}, ${y})`;
      });
    });
  }

  private readonly forceCollide = (d: BubbleChartData) => {
    return this.circleRadiusScale(this.valueFn(d)) + 1;
  };

  protected addClickEventListeners(): void {
    const circles = this.svg.selectAll('circle');
    const circleClick$ = this.circleClick;
    const outerClick$ = this.click$;
    const cdr = this.cdr;
    const destroyed$ = this.destroyed$;
    const colorsMap = this.colorsMap;
    const categoryFn = this.categoryFn;

    circles.on('mouseup', function (_, data: BubbleChartData) {
      const filledColor = '#413E4C';
      const circle = this as SVGCircleElement;
      const isSelected = circle?.getAttribute('fill') === filledColor;

      if (isSelected) {
        circle.setAttribute('fill', colorsMap?.get(categoryFn(data)) ?? '#BFBBCC');
        circleClick$.emit(null);
      } else {
        circle.setAttribute('fill', filledColor);
        circleClick$.emit(data);
        cdr.detectChanges();

        const clickSubscription = outerClick$.pipe(takeUntil(destroyed$)).subscribe((event: MouseEvent) => {
          if (event.target === circle) {
            return;
          }

          circle.setAttribute('fill', colorsMap?.get(categoryFn(data)) ?? '#BFBBCC');
          clickSubscription.unsubscribe();
        });
      }
    });
  }

  protected addHoverEventListeners(): void {
    const circles = this.svg.selectAll('circle');
    const tooltipConfig = this.tooltipConfig;
    const cdr = this.cdr;

    circles
      .on('mouseover', function (event: MouseEvent, d: BubbleChartData) {
        const circle = this as SVGCircleElement;

        circle.style.strokeWidth = '2px';
        circle.style.stroke = '#413E4C';

        tooltipConfig.set({
          data: d,
          positioning: getTooltipPosition(circle, event),
        });

        cdr.detectChanges();
      })
      .on('mouseleave', function () {
        const circle = this as SVGCircleElement;

        circle.style.strokeWidth = '';
        circle.style.stroke = '';
        tooltipConfig.update(config => ({ ...config, data: null }));
        cdr.detectChanges();
      });
  }
}
