<header class="inventory-event-details-header">
  <div class="inventory-event-details-header__flex-space-between">
    <supy-icon name="close-circle" color="gray" routerLink="../" queryParamsHandling="preserve"></supy-icon>

    <div *ngIf="selectedEvent" class="inventory-event-details-header__actions">
      <ng-container *ngIf="canDelete">
        <supy-dropdown-toggle [dropdownRef]="detailsActions">
          <supy-icon name="dots" color="gray"></supy-icon>
        </supy-dropdown-toggle>
        <supy-dropdown #detailsActions action>
          <supy-dropdown-item (click)="deleteClicked.emit(selectedEvent.id)">
            <supy-icon name="delete" color="error"></supy-icon>
            <span i18n="@@delete">Delete</span>
          </supy-dropdown-item>
        </supy-dropdown>
      </ng-container>

      <supy-button
        variant="icon"
        color="primary-blend"
        name="previous"
        title="Previous"
        [disabled]="previousDisabled"
        (buttonClick)="navigationClicked.emit(false)"
      >
        <supy-icon name="long-arrow-left-circle" size="medium"></supy-icon>
      </supy-button>
      <supy-button
        variant="icon"
        color="primary-blend"
        name="next"
        title="Next"
        [disabled]="nextDisabled"
        (buttonClick)="navigationClicked.emit(true)"
      >
        <supy-icon name="long-arrow-right-circle" size="medium"></supy-icon>
      </supy-button>
    </div>
  </div>
  <div class="inventory-event-details-header__flex-space-between">
    <h4>
      {{ eventTitle }}
    </h4>
    <supy-inventory-event-status
      *ngIf="selectedEvent"
      [eventType]="eventType"
      [status]="eventStatus"
    ></supy-inventory-event-status>
  </div>
</header>
