import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, Injectable, LOCALE_ID } from '@angular/core';
import { loadTranslations, MessageId, TargetMessage } from '@angular/localize';

export type Translations = Record<MessageId, TargetMessage>;
@Injectable({
  providedIn: 'root',
})
class I18n {
  locale = 'en';

  async setLocale() {
    const userLocale = localStorage.getItem('supy.locale');

    // If the user has a preferred language stored in localStorage, use it.
    if (userLocale) {
      this.locale = userLocale;

      const htmlTag = globalThis.document.querySelector('html');

      if (htmlTag) {
        htmlTag.dir = userLocale === 'ar' ? 'rtl' : 'ltr';
        htmlTag.lang = userLocale;
      }
    }

    // Use web pack magic string to only include required locale data
    const localeModule = (await import(
      /* webpackInclude: /(ar|en|es)\.mjs$/ */
      `../../../../../../node_modules/@angular/common/locales/${this.locale}.mjs`
    )) as { default: Record<string, string> };

    // TODO: use direct imports instead:
    // import ar from '@angular/common/locales/ar';
    // import es from '@angular/common/locales/es';
    //
    // registerLocaleData(ar, 'ar');
    // registerLocaleData(es, 'es');

    // Set locale for built in pipes, etc.
    registerLocaleData(localeModule.default);

    // Load translation file
    const translations = await getTranslationsByUrl(`assets/i18n/${this.locale}.json`);

    if (translations) {
      // Load translations for the current locale at run-time
      loadTranslations(translations);
    }
  }
}

// Load locale data at app start-up
function setLocale() {
  return {
    provide: APP_INITIALIZER,
    useFactory: (i18n: I18n) => () => i18n.setLocale(),
    deps: [I18n],
    multi: true,
  };
}

// Set the runtime locale for the app
function setLocaleId() {
  return {
    provide: LOCALE_ID,
    useFactory: (i18n: I18n) => i18n.locale,
    deps: [I18n],
  };
}

export const I18nModule = {
  setLocale: setLocale,
  setLocaleId: setLocaleId,
};

async function getTranslationsByUrl(url: string): Promise<Translations> {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      return null;
    }

    const data = await response.text();

    return parseTranslations(data);
  } catch (error) {
    return null;
  }
}

function parseTranslations(fileData: string): Translations {
  return (JSON.parse(fileData) as { translations: Translations }).translations;
}
