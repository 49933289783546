import produce from 'immer';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import {
  BaseRequestMetadata,
  BaseResponseMetadata,
  EntityListState,
  EntityListStateModel,
  Query,
  QueryBuilder,
} from '@supy/common';

import {
  ChannelItem,
  ChannelItemsPerPackaging,
  ChannelItemsProps,
  ChannelItemState,
  InventoryChannelItem,
} from '../../core';
import { ChannelItemsService } from '../../services';
import {
  ChannelItemsChangeSelectedItem,
  ChannelItemsDeleteBatch,
  ChannelItemsGetFillableItems,
  ChannelItemsGetGroupedItems,
  ChannelItemsGetMany,
  ChannelItemsInitFilters,
  ChannelItemsPatchFilters,
  ChannelItemsPatchRequestMeta,
  ChannelItemsResetFilters,
  ChannelItemsSetFilters,
  ChannelItemsUpdateMany,
} from '../actions';

export interface ChannelItemsStateModel extends EntityListStateModel<ChannelItem> {
  readonly groupedItems: ChannelItemsPerPackaging[];
  readonly fillableItems: InventoryChannelItem[];
  readonly filters: ChannelItemsFilters;
  readonly requestMetadata: BaseRequestMetadata;
  readonly responseMetadata: BaseResponseMetadata;
}

export const CHANNEL_ITEMS_STATE_TOKEN = new StateToken<ChannelItemsStateModel>('channelItems');

export interface ChannelItemsFilters {
  readonly status: string | null;
  readonly channelId: string | null;
  readonly name: string | null;
}

const DEFAULT_FILTERS: ChannelItemsFilters = {
  status: null,
  name: null,
  channelId: null,
};

const DEFAULT_REQUEST_METADATA = { page: 0, limit: 25 };
const DEFAULT_RESPONSE_METADATA = { count: 0, total: 0 };

@State<ChannelItemsStateModel>({
  name: CHANNEL_ITEMS_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    groupedItems: [],
    fillableItems: [],
    filters: DEFAULT_FILTERS,
    requestMetadata: DEFAULT_REQUEST_METADATA,
    responseMetadata: DEFAULT_RESPONSE_METADATA,
  },
})
@Injectable()
export class ChannelItemsState extends EntityListState {
  constructor(
    private readonly channelItemsService: ChannelItemsService,
    protected readonly router: Router,
  ) {
    super(router);
  }

  @Selector()
  static groupedItems(state: ChannelItemsStateModel) {
    return state.groupedItems;
  }

  @Selector()
  static items(state: ChannelItemsStateModel) {
    return state.entities;
  }

  @Selector()
  static requestMetadata(state: ChannelItemsStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: ChannelItemsStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static fillableItems(state: ChannelItemsStateModel) {
    return state.fillableItems ?? [];
  }

  @Action(ChannelItemsInitFilters)
  initFilters(ctx: StateContext<ChannelItemsStateModel>) {
    super.initFilter(ctx, DEFAULT_FILTERS);
  }

  @Action(ChannelItemsSetFilters)
  setFilters(ctx: StateContext<ChannelItemsStateModel>, action: ChannelItemsSetFilters) {
    super.setFilter(ctx, action.payload, DEFAULT_FILTERS);
    ctx.dispatch([new ChannelItemsPatchRequestMeta({ page: 0 }), new ChannelItemsGetMany()]);
  }

  @Action(ChannelItemsPatchFilters)
  patchFilters(ctx: StateContext<ChannelItemsStateModel>, action: ChannelItemsPatchFilters) {
    super.patchFilter(ctx, action.payload, DEFAULT_FILTERS);
    ctx.dispatch([new ChannelItemsPatchRequestMeta({ page: 0 }), new ChannelItemsGetMany()]);
  }

  @Action(ChannelItemsPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<ChannelItemsStateModel>, { payload }: ChannelItemsPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(ChannelItemsResetFilters)
  resetFilters(ctx: StateContext<ChannelItemsStateModel>) {
    super.resetFilter(ctx, DEFAULT_FILTERS);
    ctx.dispatch([new ChannelItemsPatchRequestMeta({ page: 0 }), new ChannelItemsGetMany()]);
  }

  @Action(ChannelItemsGetMany)
  getItems(ctx: StateContext<ChannelItemsStateModel>) {
    return this.channelItemsService.getChannelItems(this.getQuery(ctx.getState())).pipe(
      tap(({ data, metadata }) => {
        ctx.patchState({ responseMetadata: metadata });
        super.setMany(ctx, data);
      }),
    );
  }

  @Action(ChannelItemsUpdateMany)
  updateMany(_: StateContext<ChannelItemsStateModel>, { channelItems }: ChannelItemsUpdateMany) {
    return this.channelItemsService.updateChannelItems({ channelItems });
  }

  @Action(ChannelItemsDeleteBatch)
  delete(_: StateContext<ChannelItemsStateModel>, { payload }: ChannelItemsDeleteBatch) {
    return this.channelItemsService.deleteChannelItemsBff(payload);
  }

  @Action(ChannelItemsGetGroupedItems)
  getGroupedItems(ctx: StateContext<ChannelItemsStateModel>, { query }: ChannelItemsGetGroupedItems) {
    return this.channelItemsService
      .getGroupedChannelItemsBff(query)
      .pipe(tap(({ data, metadata }) => ctx.patchState({ groupedItems: data, responseMetadata: metadata })));
  }

  @Action(ChannelItemsChangeSelectedItem)
  changeSelectedItem(ctx: StateContext<ChannelItemsStateModel>, { packagingId, item }: ChannelItemsChangeSelectedItem) {
    const packagingIndex = ctx.getState().groupedItems.findIndex(({ packaging }) => packaging.id === packagingId);

    if (packagingIndex > -1) {
      ctx.setState(
        produce(draft => {
          draft.groupedItems[packagingIndex].selectedChannelItem = item;
        }),
      );
    }
  }

  @Action(ChannelItemsGetFillableItems)
  getFillableChannelItems(ctx: StateContext<ChannelItemsStateModel>, { query }: ChannelItemsGetFillableItems) {
    return this.channelItemsService.getFillableChannelItemsBff(query).pipe(
      tap(({ data }) => {
        ctx.patchState({ fillableItems: data });
      }),
    );
  }

  private getQuery(state: ChannelItemsStateModel): Query<ChannelItem & ChannelItemsProps> {
    const { channelId, name, status } = state.filters;

    const qb = new QueryBuilder<ChannelItem & ChannelItemsProps>({
      filtering: [
        {
          by: 'state',
          op: 'eq',
          match: status ?? ChannelItemState.Available,
        },
      ],
      paging: { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: [{ by: 'id', dir: 'desc' }],
    });

    if (channelId) {
      qb.filtering.setFilter({ by: 'channelId', op: 'eq', match: channelId });
    }

    if (name) {
      qb.filtering.withGroup('or', [
        {
          by: 'name.ar',
          op: 'like',
          match: name,
        },
        {
          by: 'name.en',
          op: 'like',
          match: name,
        },
      ]);
    }

    return qb.build();
  }
}
