import Pusher from 'pusher-js';

import {
  BaseChannel,
  ChannelName,
  IAuthChannelRequest,
  IAuthChannelResponse,
  IAuthUserRequest,
  IAuthUserResponse,
  WebSocketChannelFactory,
} from '../common';
import { AuthHandler, IAuthCallback, IAuthHandler } from './auth';
import { WebSocketConnection } from './connection';
import { WebSocketSubscriptionScope } from './scope';
import { WebSocketUser } from './user';

export enum WebSocketProtocol {
  WS = 'ws',
  WSS = 'wss',
}

export type WebSocketClientEventType = 'pusher:error' | 'error';

export interface WebSocketAuthConfig {
  readonly user: IAuthHandler<IAuthUserRequest, IAuthCallback<IAuthUserResponse>>;
  readonly channel: IAuthHandler<IAuthChannelRequest, IAuthCallback<IAuthChannelResponse>>;
}

export interface IWebSocketClientBaseOptions {
  readonly appKey: string;
  readonly host: string;
  readonly port?: string;
  readonly cluster?: string;
  readonly secure?: boolean;
}

export interface IWebSocketClientAuthOptions extends IWebSocketClientBaseOptions {
  readonly auth: WebSocketAuthConfig;
}

export interface IWebSocketClientOptions extends IWebSocketClientBaseOptions, IWebSocketClientAuthOptions {}

export class WebSocketClientRegistry<TKey> {
  readonly #clients: Map<TKey, WebSocketClient> = new Map();

  get(key: TKey): WebSocketClient | undefined {
    return this.#clients.get(key);
  }

  has(key: TKey): boolean {
    return this.#clients.has(key);
  }

  connect(key: TKey, options: IWebSocketClientOptions): WebSocketClient {
    const client = this.get(key) ?? new WebSocketClient(options);

    this.#clients.set(key, client);

    return client;
  }

  disconnect(key: TKey): void {
    if (this.has(key)) {
      const client = this.get(key);

      client?.disconnect();
    }
  }

  destroy(): void {
    this.#clients.forEach(client => {
      client.disconnect();
    });
    this.#clients.clear();
  }
}

export class WebSocketClient {
  readonly #pusher: Pusher;
  readonly #factory: WebSocketChannelFactory;
  readonly #connection: WebSocketConnection;
  readonly #user: WebSocketUser;
  readonly #scopes: Set<WebSocketSubscriptionScope> = new Set();

  constructor(options: IWebSocketClientOptions) {
    this.#pusher = new Pusher(options.appKey, {
      wsHost: options.host,
      cluster: options.cluster ?? '',
      userAuthentication: {
        endpoint: '',
        transport: 'ajax',
        customHandler: AuthHandler.create(options.auth.user),
      },
      channelAuthorization: {
        endpoint: '',
        transport: 'ajax',
        customHandler: AuthHandler.create(options.auth.channel),
      },
      enabledTransports: [WebSocketProtocol.WS, WebSocketProtocol.WSS],
      forceTLS: options.secure ?? true,
    });

    this.#factory = new WebSocketChannelFactory();
    this.#connection = new WebSocketConnection(this.#pusher);
    this.#user = new WebSocketUser(this.#pusher);
  }

  get channels(): BaseChannel[] {
    return this.#pusher.allChannels().map(x => this.#factory.create(x));
  }

  get connection(): WebSocketConnection {
    return this.#connection;
  }

  get scoped(): WebSocketSubscriptionScope {
    const scope = new WebSocketSubscriptionScope(this.#pusher);

    this.#scopes.add(scope);

    return scope;
  }

  get user(): WebSocketUser {
    return this.#user;
  }

  channel<TChannel extends BaseChannel>(name: ChannelName): TChannel | undefined {
    return this.channels.find(x => x.name === name) as TChannel;
  }

  subscribe<TChannel extends BaseChannel>(name: ChannelName): TChannel {
    const channel = this.#pusher.subscribe(name);

    return this.#factory.create<TChannel>(channel);
  }

  connect(): void {
    this.#pusher.signin();
  }

  disconnect(): void {
    for (const channel of this.channels) {
      channel.dispose();
    }

    this.#pusher.disconnect();
  }

  on<TEvent extends WebSocketClientEventType, TData>(event: TEvent, callback: (data: TData) => void): void {
    this.#pusher.bind(event, callback);
  }

  off<TEvent extends WebSocketClientEventType>(event: TEvent): void {
    this.#pusher.unbind(event);
  }
}
