import { delay, firstValueFrom, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { IAuthExecutor, IAuthExecutorOptions } from '../../client';

export class HttpClientAuthExecutor<TRequest, TResponse> implements IAuthExecutor<TRequest, TResponse> {
  constructor(
    private readonly http: HttpClient,
    private readonly options: IAuthExecutorOptions,
  ) {}

  execute(request: TRequest): Promise<TResponse> {
    return firstValueFrom(
      of(null).pipe(
        delay(5000),
        switchMap(user => this.http.post<TResponse>(this.options.endpoint, request)),
      ),
    );
  }
}
