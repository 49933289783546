import { BaseChannel } from './channel.base';
import { ChannelType } from './channel.type';

type ChannelName = `${ChannelType.Private}-${string}`;

export class PrivateChannel extends BaseChannel<ChannelName> {
  get type(): ChannelType {
    return ChannelType.Private;
  }

  static for(name: string): ChannelName {
    return `${ChannelType.Private}-${name}`;
  }
}
