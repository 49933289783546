import { select } from 'd3';
import { Selection } from 'd3-selection';
import {
  ChangeDetectorRef,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  signal,
  WritableSignal,
} from '@angular/core';

import { Destroyable } from '@supy/common';

import { ChartTooltipDirective } from './chart.directives';
import { TooltipConfig, TooltipPosition } from './chart.interfaces';

export const MIN_TOOLTIP_WIDTH = 300;

const SCROLLABLE_BAR_SIZE = 24;

@Directive()
export abstract class ChartBaseDirective<T = unknown> extends Destroyable implements OnChanges, OnDestroy {
  @Input() protected readonly isDisabled: boolean;
  @Input() protected readonly isClickable: boolean;
  @Input() readonly maxBars: number;
  @Input() protected set hasHorizontalScroll(scrollable: boolean) {
    this.#hasHorizontalScroll = scrollable;
  }

  protected get hasHorizontalScroll(): boolean {
    return this.#hasHorizontalScroll;
  }

  @Input() protected set hasVerticalScroll(scrollable: boolean) {
    this.#hasVerticalScroll = scrollable;
  }

  protected get hasVerticalScroll(): boolean {
    return this.#hasVerticalScroll;
  }

  #hasVerticalScroll = false;
  #hasHorizontalScroll = false;

  @Input() @HostBinding('style.height.px') protected readonly svgHeight: number;
  @Input() @HostBinding('style.width.px') protected readonly svgWidth: number;

  @ContentChild(ChartTooltipDirective)
  protected readonly tooltipTemplate: ChartTooltipDirective;

  protected readonly tooltipConfig: WritableSignal<TooltipConfig<T>> = signal({
    data: null,
    positioning: {},
  });

  protected width = 640;
  protected height: number;
  protected readonly marginTop: number = 50; // top margin, in pixels
  protected readonly marginRight: number = 100; // right margin, in pixels
  protected readonly marginBottom: number = 0; // bottom margin, in pixels
  protected readonly marginLeft: number = 100; // left margin, in pixels
  protected barsAmount = 0; // amount of bars to calculate height
  protected readonly hostElement: HTMLElement; // Native element hosting the SVG container
  protected svg: Selection<SVGSVGElement, unknown, null, undefined>; // Top level SVG element

  protected constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super();
    this.hostElement = this.elementRef.nativeElement;
  }

  ngOnChanges() {
    this.createChart();
  }

  createChart(): void {
    this.initializeSVG();
    this.setSizes({ top: this.marginTop, left: this.marginLeft, right: this.marginRight, bottom: this.marginBottom });
    this.renderChart();
    this.addEventListeners();
  }

  @HostListener('scroll')
  protected onScroll(): void {
    if (this.tooltipConfig().data) {
      this.tooltipConfig.update(config => ({
        ...config,
        data: null,
      }));
      this.cdr.detectChanges();
    }
  }

  protected initializeSVG(): void {
    this.svg = (this.svg ?? select(this.hostElement).classed('svg-container', true).append('svg'))
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .classed('svg-content-responsive', true)
      .attr('width', this.hasHorizontalScroll ? this.getSvgSize() : '100%')
      .attr('height', this.hasVerticalScroll ? this.getSvgSize() : '100%');
  }

  protected setSizes(margin: { top: number; bottom: number; left: number; right: number }, heightOffset = 0): void {
    const definedWidth =
      (this.hasHorizontalScroll && this.calculateCustomSize()) || (this.svgWidth && Number(this.svgWidth));
    const definedHeight =
      (this.hasVerticalScroll && this.calculateCustomSize()) || (this.svgHeight && Number(this.svgHeight));

    this.width = (definedWidth ?? +this.svg.node().getBoundingClientRect().width) - margin.left - margin.right;
    this.height = (definedHeight ?? +this.svg.node().getBoundingClientRect().height) - margin.bottom + heightOffset;
  }

  protected getSvgSize(): string {
    const size = this.calculateCustomSize();

    return (size && `${size}px`) || '100%';
  }

  protected calculateCustomSize(): number {
    return this.maxBars !== undefined && this.maxBars < this.barsAmount ? SCROLLABLE_BAR_SIZE * this.barsAmount : 0;
  }

  protected abstract renderChart(): void;

  protected createSVGElement(): Selection<SVGSVGElement, unknown, null, undefined> {
    return (
      this.svg ??
      select(this.hostElement)
        .classed('svg-container', true)
        .append('svg')
        .attr('width', this.width)
        .attr('height', this.height)
        .attr('viewBox', [0, 0, this.width, this.height])
        .attr('style', 'max-width: 100%; height: auto; height: intrinsic;')
    );
  }

  protected addEventListeners(): void {
    if (!this.isDisabled) {
      this.addHoverEventListeners();

      if (this.isClickable) {
        this.addClickEventListeners();
      }
    }
  }

  protected abstract addHoverEventListeners(): void;

  protected abstract addClickEventListeners(): void;
}

export function calculateNumberTick(value: number, showDecimals?: boolean): string {
  /**
   *  Method to calculate d3 number format - https://github.com/d3/d3-format#api-reference
   *  ',.2f' => comma-separated, with 2dp. Eg: 12,805.75
   *  ',.2d' => comma-separated integer value. Eg: 12,805
   *  '.3s'  => shorthand-enabled, with 3 significant digits. Eg: 1.06M or 12.5M
   */
  if (showDecimals) {
    return value.toFixed(0).length > 6 ? '.3s' : ',.2f';
  } else {
    return value.toFixed(0).length > 6 ? '.2s' : ',.2d';
  }
}

export function getTooltipPosition(hoveredElement: SVGElement, event: MouseEvent): TooltipPosition {
  // TODO: improve positioning,
  //  investigate Angular CDK Overlays how to make it work with <rect> elements correctly

  const sizes = hoveredElement.getBoundingClientRect();
  const windowSizes = document.body.getBoundingClientRect();

  const haveSpaceOnRight = windowSizes.right - event.pageX > MIN_TOOLTIP_WIDTH;

  return haveSpaceOnRight
    ? {
        ['bottom.px']: windowSizes.bottom - sizes.top + 20,
        ['left.px']: sizes.left,
      }
    : {
        ['bottom.px']: windowSizes.bottom - sizes.top + 20,
        ['right.px']: windowSizes.right - sizes.right,
      };
}
