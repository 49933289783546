import { IAuthHandler } from './auth-handler.interface';

export abstract class AuthHandler<TRequest, TResponse> {
  static create<TRequest, TCallback>(handler: IAuthHandler<TRequest, TCallback>) {
    return async (request: TRequest, callback: TCallback): Promise<void> => {
      return await handler.auth(request, callback);
    };
  }

  protected abstract execute(request: TRequest): Promise<TResponse>;
}
