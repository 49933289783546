export enum ChannelType {
  Public = 'public',
  Private = 'private',
  PrivateEncrypted = 'private-encrypted',
  Presence = 'presence',
  Cache = 'cache',
  PrivateCache = 'private-cache',
  PresenceCache = 'presence-cache',
  PrivateEncryptedCache = 'private-encrypted-cache',
}
