import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconModule } from '../icon';
import { StarRatingComponent } from './components';

@NgModule({
  declarations: [StarRatingComponent],
  imports: [CommonModule, IconModule],
  exports: [StarRatingComponent],
})
export class RatingModule {}
