import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Retailer } from '@supy/common';

import { RetailersService } from '../../services';

@Injectable()
export class RetailerResolver {
  constructor(private readonly retailersService: RetailersService) {}
  resolve(route: ActivatedRouteSnapshot): Promise<Retailer> {
    return firstValueFrom(this.retailersService.getRetailer(route.params.retailerId as string));
  }
}
