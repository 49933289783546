export type PdfViewerZoomLevel = 'auto' | 'page-actual' | 'page-fit' | 'page-width' | number;

export const PdfViewerZoomLevels: PdfViewerZoomLevel[] = [
  'auto',
  'page-actual',
  'page-fit',
  'page-width',
  0.25,
  0.5,
  0.75,
  1,
];
