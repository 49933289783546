import Pusher from 'pusher-js';

export class WebSocketUser {
  readonly #pusher: Pusher;

  constructor(pusher: Pusher) {
    this.#pusher = pusher;
  }

  on<TEvent extends string, TData>(event: TEvent, callback: (data: TData) => void): void {
    this.#pusher.user.bind(event, callback);
  }

  off<TEvent extends string, TData>(event: TEvent, callback?: (data: TData) => void): void {
    this.#pusher.user.unbind(event, callback);
  }
}
