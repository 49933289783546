import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { IAfterGuiAttachedParams, ICellEditorParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import { ButtonColor } from '../../../button';
import { EditableOptionsComponent } from '../../../editable-options/components';
import { IconType } from '../../../icon';

export interface EditableOptionsCellEditorContext {
  readonly buttonColor?: ButtonColor;
  readonly disabled?: boolean;
  readonly iconSuffix: IconType;
  readonly inputSuffix: IconType;
  readonly placeholder?: string;
  readonly validators?: ValidatorFn[];
}

type EditableOptionsCellEditorParams = ICellEditorParams<unknown, string[], EditableOptionsCellEditorContext>;

@Component({
  selector: 'supy-editable-options-cell-editor',
  template: `
    <div class="supy-editable-options-cell-editor">
      <supy-editable-options
        #component
        [buttonColor]="context?.buttonColor ?? 'secondary'"
        [disabled]="context?.disabled"
        [iconSuffix]="context?.iconSuffix"
        [inputSuffix]="context?.inputSuffix"
        [options]="value"
        [placeholder]="context?.placeholder"
        [validators]="context?.validators ?? []"
        (add)="onAdd($event)"
        (remove)="onRemove($event)"
      ></supy-editable-options>
    </div>
  `,
  styleUrls: ['./editable-options-cell-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableOptionsCellEditorComponent implements ICellEditorAngularComp {
  @ViewChild(EditableOptionsComponent) private readonly component: EditableOptionsComponent;
  protected value: string[];
  protected params: EditableOptionsCellEditorParams;
  protected context: EditableOptionsCellEditorContext;

  agInit(params: EditableOptionsCellEditorParams): void {
    this.params = params;
    this.context = params.context;
    this.value = params.value;
  }

  getValue(): string[] {
    return this.value;
  }

  afterGuiAttached(_?: IAfterGuiAttachedParams): void {
    this.onStartEditing();
  }

  refresh(_: EditableOptionsCellEditorParams): boolean {
    return true;
  }

  protected onAdd(value: string): void {
    this.value = [...this.value, value];
    this.params.stopEditing();
    this.params.api.refreshCells();
  }

  protected onRemove(value: string): void {
    const data = this.value.slice();
    const targetIndex = this.value.findIndex(val => val === value);

    data.splice(targetIndex, 1);
    this.value = data;
    this.params.stopEditing();
    this.params.api.refreshCells();
  }

  private onStartEditing(): void {
    if (this.component) {
      this.component.toggle();
    }
  }
}
