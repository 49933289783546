import { Currency } from '@supy.api/dictionaries';

import {
  IdType,
  InvoiceUnit,
  LocalizedData,
  LocalizedSkeletonObjectType,
  PreferredType,
  SkeletonObjectType,
} from '@supy/common';
import { Packaging } from '@supy/packaging';

import { CatalogChannelItemApi, ChannelItemRetailerItem, InventoryChannelItemApi } from './channel-item.model';

export enum ChannelItemState {
  Available = 'available',
  Deleted = 'deleted',
  Hidden = 'hidden',
}

export class ChannelItem {
  readonly id: string;
  readonly supplierId: string;
  readonly retailerId: string;
  readonly branchId: string;
  readonly retailerItemId: string;
  readonly channelId: string;
  readonly name?: LocalizedData;
  readonly packaging?: Packaging;
  readonly price?: number;
  readonly itemCode?: string;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly currency?: Currency;
  readonly unit?: string;
  readonly retailerItem?: LocalizedSkeletonObjectType;
  readonly state: ChannelItemState;
  readonly version?: string;
  readonly preferredType?: PreferredType;
  readonly ckStock: number;
  readonly category?: LocalizedSkeletonObjectType;

  constructor(channelItem: ChannelItem) {
    Object.assign(this, channelItem);
  }
}

export class ExistingChannelItem extends ChannelItem {
  readonly _id?: string;

  constructor(channelItem: ExistingChannelItem) {
    super(channelItem);
    Object.assign(this, channelItem);
  }
}

export class CatalogChannelItem {
  readonly id: string;
  readonly name: LocalizedData;
  readonly price: number;
  readonly itemCode?: string;
  readonly preferredType?: PreferredType;
  readonly retailer: IdType;
  readonly branch: IdType;
  readonly channel: IdType;
  readonly supplier: IdType;
  readonly retailerItem?: ChannelItemRetailerItem;
  readonly supplierItem?: LocalizedSkeletonObjectType & {
    readonly invoiceUnit: InvoiceUnit;
  };

  readonly inventoryItem?: IdType;
  readonly category?: LocalizedSkeletonObjectType;
  readonly packaging?: Packaging;
  readonly priority?: boolean;
  readonly state: ChannelItemState;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(args: CatalogChannelItemApi) {
    Object.assign(this, args);
  }

  static deserialize(data: CatalogChannelItemApi): CatalogChannelItem {
    return new CatalogChannelItem({ ...data });
  }

  static deserializeList(data: CatalogChannelItemApi[]): CatalogChannelItem[] {
    return data.map(channelItem => CatalogChannelItem.deserialize(channelItem));
  }
}

export interface ChannelItemQuantity {
  readonly stock: number;
  readonly par: number;
}
export class InventoryChannelItem extends CatalogChannelItem {
  readonly quantityLevels: ChannelItemQuantity;
  readonly quantity: number;
  readonly orderable: boolean;
  readonly ckStock: number;

  constructor(args: InventoryChannelItemApi) {
    super(args);
    Object.assign(this, args);
  }

  static deserialize(data: InventoryChannelItemApi): InventoryChannelItem {
    return new InventoryChannelItem({ ...data, quantity: data.quantity ?? 0 });
  }

  static deserializeList(data: InventoryChannelItemApi[]): InventoryChannelItem[] {
    return data.map(channelItem => InventoryChannelItem.deserialize(channelItem));
  }
}

export type SupplierChannelItem = Omit<CatalogChannelItem, 'supplier'> & { readonly supplier: SkeletonObjectType };

export class ChannelItemsPerPackaging {
  readonly packaging: Packaging;
  readonly channelItems: SupplierChannelItem[];
  readonly selectedChannelItem: SupplierChannelItem;
}
