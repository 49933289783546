import { IAuthChannelRequest, IAuthChannelResponse } from '../../../common';
import { IAuthCallback } from '../auth-callback.interface';
import { IAuthHandler } from '../auth-handler.interface';
import { BaseChannelAuthHandler } from './base-channel-auth.handler';

export class ChannelAuthSimpleHandler
  extends BaseChannelAuthHandler<IAuthChannelRequest, IAuthChannelResponse>
  implements IAuthHandler<IAuthChannelRequest, IAuthCallback<IAuthChannelResponse>>
{
  async auth(params: IAuthChannelRequest, callback: IAuthCallback<IAuthChannelResponse>): Promise<void> {
    try {
      const response = await this.execute(params);

      return callback(null, response);
    } catch (error) {
      return callback(error as Error, null);
    }
  }
}
