import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'supy-multiple-image-uploader',
  templateUrl: './multiple-image-uploader.component.html',
  styleUrls: ['./multiple-image-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleImageUploaderComponent<T> implements ControlValueAccessor {
  #value: T[];

  @Input() readonly useCropper: boolean = false;
  @Input() readonly disabled: boolean = false;
  @Input() set imageUrls(value: string[]) {
    this.imageUrlsCopy = [...(value ?? [])];
  }

  @Input() protected readonly isInitialLoading: boolean;
  @Input() readonly titleText: string = 'Uploaded Pictures';
  @Input() readonly actionText: string = 'Upload Pictures';
  @Output() readonly imageCropped = new EventEmitter<Blob>();
  @ViewChild('fileInput') readonly input: ElementRef<HTMLInputElement>;
  @Output() readonly fileUploaded: EventEmitter<Blob[]> = new EventEmitter<Blob[]>();
  @Output() readonly fileRemoved: EventEmitter<number> = new EventEmitter<number>();
  cropperImageEvent: Event;
  cropperImageBlob: Blob;
  cropperImageLoaded: boolean;
  imageUrl: string;
  touched = false;
  acceptedFormats = ['image/png', 'image/jpeg', 'image/bmp', 'image/ico', 'image/webp', 'application/pdf'];
  imageUrlsCopy: string[];
  onChange: (value: Blob[]) => void;

  onTouched: () => void;

  constructor(
    private readonly domSanitizer: DomSanitizer,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  onRemoveImage(index: number): void {
    if (!this.disabled) {
      this.imageUrlsCopy.splice(index, 1);
      this.input.nativeElement.value = '';
      this.fileRemoved.emit(index);
    }
  }

  onClickImage(imageUrl: string): void {
    window.open(imageUrl, '_blank');
  }

  onFileChange(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    const images: Blob[] = [];

    for (let i = 0; i < files.length; i++) {
      const image = files[i] as Blob;
      const format = image.type;
      const isImage = this.acceptedFormats.includes(format);

      if (isImage) {
        const formattedBlob = image.slice(0, image.size, image.type);
        const url = URL.createObjectURL(formattedBlob);
        const sanitized = this.domSanitizer.bypassSecurityTrustUrl(url);

        this.imageUrlsCopy.push(sanitized as string);

        images.push(image);
      }
    }
    this.fileUploaded.emit(images);
  }

  writeValue(value: T[]): void {
    this.#value = value;
  }

  registerOnChange(onChange: (value: Blob[]) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
