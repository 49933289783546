import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SwitchSize } from '../../../switch';

export interface SwitchCellEditorContext {
  readonly disabled?: boolean;
  readonly size?: SwitchSize;
}

@Component({
  selector: 'supy-switch-cell-editor',
  template: `<div class="supy-switch-cell-editor">
    <supy-switch
      [value]="value"
      [size]="context?.size ?? 'small'"
      [disabled]="context?.disabled"
      (valueChange)="valueChangeHandler($event)"
    ></supy-switch>
  </div>`,
  styleUrls: ['./switch-cell-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchCellEditorComponent implements ICellEditorAngularComp {
  protected value: boolean;
  protected params: ICellEditorParams<unknown, boolean, SwitchCellEditorContext>;
  protected context: SwitchCellEditorContext;

  agInit(params: ICellEditorParams<unknown, boolean, SwitchCellEditorContext>): void {
    this.params = params;
    this.context = params.context;
    this.value = params.value;
  }

  getValue(): boolean {
    return this.value;
  }

  protected valueChangeHandler(value: boolean): void {
    this.value = value;
  }
}
