import { BranchId, IdType, OutletData, PartyInfo, PreferredType, SupplierId, User } from '@supy/common';
import { Country } from '@supy/countries';

import { OrderStatus, StatefulOrderEntity } from './common-order.entity';
import { CreationSourceEnum } from './order.model';
import { OrderItem } from './order-item.entity';
import { OrderProduct } from './order-product.entity';
import { OrderUpdate, OrderUpdateStatus } from './order-update.entity';

export enum LanguageKeys {
  en = 'name',
  ar = 'name_ar',
}

export class OrderInvoice {
  readonly numbers: string[];
  readonly images: string[];
}

export class BaseOrder {
  readonly id: string;
  readonly status: OrderStatus;
  readonly number: string;
  readonly country: Country;
  readonly comment?: string;
  readonly deliveryDate: Date;
  readonly createdAt: Date;
  readonly outlet?: OutletData;
  readonly invoice: OrderInvoice;
  readonly creationSource?: CreationSourceEnum;

  constructor(args: Partial<BaseOrder>) {
    this.id = args.id;
    this.status = args.status;
    this.number = args.number;
    this.country = args.country;
    this.comment = args.comment;
    this.deliveryDate = new Date(args.deliveryDate);
    this.createdAt = new Date(args.createdAt);
    this.outlet = args.outlet;
    this.invoice = args.invoice;
    this.creationSource = args.creationSource;
  }
}

export class Order extends BaseOrder {
  readonly brandId?: string;
  readonly channelId: string;
  readonly countryId: string;
  readonly channelOrderIndex?: number;
  readonly channelPreferredType?: PreferredType;
  readonly linkedGrns: IdType[];
  readonly modified: OrderItem[];
  readonly modifiedTotal?: number;
  readonly ordered: OrderItem[];
  readonly parties: string[];
  readonly partyInfo: PartyInfo;
  readonly preferredType?: PreferredType;
  readonly productsList: string[];
  readonly items?: OrderProduct[];
  readonly received: OrderItem[];
  readonly receivedDate?: Date;
  readonly receivedTotal: number;
  readonly retailerId: BranchId;
  readonly returned: OrderItem[];
  readonly orderedTotal: number;
  readonly submittedBy?: User;
  readonly draftedBy?: User;
  readonly submittedDate?: Date;
  readonly supplierId: SupplierId;
  readonly total: StatefulOrderEntity;
  readonly updates: OrderUpdate[];
  readonly vat: number;
  readonly orderedCKItems: OrderItem[];
  readonly itemCount: number;
  readonly customer?: {
    readonly id: string;
    readonly displayName: string;
  };

  constructor(args: Partial<Order>) {
    super(args);
    this.channelId = args.channelId;
    this.retailerId = args.retailerId;
    this.supplierId = args.supplierId;
    this.parties = args.parties ?? [args.retailerId, args.supplierId];
    this.channelOrderIndex = args?.channelOrderIndex ?? 0;
    this.ordered = args.ordered ?? [];
    this.received = args.received ?? [];
    this.returned = args.returned ?? [];
    this.receivedTotal = args.receivedTotal;
    this.productsList = args.productsList ?? this.ordered.map(item => item.productId);
    this.updates = args.updates ?? [];
    this.partyInfo = args.partyInfo;
    this.modified = args.modified ?? [];
    this.modifiedTotal = args.modifiedTotal;
    this.orderedTotal = this.calculateTotal(args);
    this.vat = args.vat;
    this.channelPreferredType = args.channelPreferredType ?? PreferredType.Regular;
    this.brandId = args.brandId;
    this.orderedCKItems = args.orderedCKItems;
    this.submittedDate = this.getSubmittedDate();
    this.receivedDate = this.getReceivedDate();
    this.submittedBy = this.getSubmittedBy();
    this.draftedBy = this.getDraftedBy();
    this.items = args.items;
    this.customer = args.customer;
  }

  private calculateTotal?(order: Partial<Order>): number {
    return order.ordered?.length
      ? order.ordered.reduce((accumulator: number, item: OrderItem) => {
          const modifiedItem = order.modified?.find(({ productId }) => productId == item.productId);

          return accumulator + item.price * (modifiedItem ? modifiedItem.quantity : item.quantity);
        }, 0)
      : order.orderedTotal;
  }

  private getSubmittedDate?(): Date {
    return this.updates.find(({ status }) => status == OrderUpdateStatus.submitted)?.createdAt ?? this.createdAt;
  }

  private getReceivedDate?(): Date {
    return this.updates.find(({ status }) => status == OrderUpdateStatus.received)?.createdAt;
  }

  private getSubmittedBy?(): User {
    return this.updates.find(({ status }) => status == OrderUpdateStatus.submitted)?.user;
  }

  private getDraftedBy?(): User {
    return this.updates.find(({ status }) => status === OrderUpdateStatus.draft)?.user;
  }
}
