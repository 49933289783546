export interface DivergingChartData {
  id: string;
  displayNames: DivergingChartDisplayNames;
  category: string;
  value: number;
  metadata: DivergingChartMetadata;
}

export interface DivergingChartMetadata {
  [key: string]: DivergingChartMetadata | string | number;
}

export interface DivergingChartDisplayNames<T = string> extends Record<string, T> {
  parentName?: T;
  name?: T;
}

export enum DivergingChartTooltipType {
  Label = 'label',
  Bar = 'bar',
}
