<igx-list-item (click)="onClick($event)" [isHeader]="isHeader" class="supy-list-item">
  <div class="supy-list-item__thumbnail" igxListThumbnail><ng-content select="[item-thumbnail]"></ng-content></div>
  <div class="supy-list-item__line" igxListLine>
    <div class="supy-list-item__line-title" igxListLineTitle><ng-content select="[item-line-title]"></ng-content></div>
    <div class="supy-list-item__line-subtitle" igxListLineSubTitle>
      <ng-content select="[item-line-subtitle]"></ng-content>
    </div>
  </div>
  <div class="supy-list-item__action" igxListAction><ng-content select="[item-action]"></ng-content></div>
  <ng-content></ng-content>
</igx-list-item>
