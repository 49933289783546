import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

export interface CheckboxCellRendererContext {
  readonly disabled: boolean;
  readonly title: string;
  readonly indeterminate: boolean;
  readonly rowSelection: boolean;
  readonly isRowSelectionHeader: boolean;
}

@Component({
  selector: 'supy-checkbox-cell-renderer',
  template: `
    <div class="supy-checkbox-cell-renderer__cell">
      <supy-checkbox
        [checked]="checked"
        [disabled]="params.context?.disabled"
        (checkedChange)="onCheckedChange($event)"
        [indeterminate]="params.context?.indeterminate"
      >
        <span class="supy-checkbox-cell-renderer__cell-title">{{ params.context?.title }}</span>
      </supy-checkbox>
    </div>
  `,
  styleUrls: ['./checkbox-cell-renderer.component.scss'],
})
export class CheckboxCellRendererComponent implements ICellRendererAngularComp {
  protected params: ICellRendererParams<unknown, boolean, CheckboxCellRendererContext>;

  get checked(): boolean {
    return this.params.value;
  }

  agInit(params: ICellRendererParams<unknown, boolean, CheckboxCellRendererContext>): void {
    this.params = params;
  }

  onCheckedChange(value: boolean): void {
    this.params.setValue(value);
  }

  refresh(): boolean {
    return false;
  }
}
