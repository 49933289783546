import {
  ProviderEnum,
  ResourceTypeEnum,
  SetTenantRegistryRequest,
  TenantActivateRequest,
  TenantCategoryEnum,
  TenantLinkData,
  TenantUpdateRequest,
} from '../../models';

export class TenantGetMany {
  static readonly type = '[Tenant] GetMany';

  constructor(
    readonly retailerId: string,
    readonly categories: TenantCategoryEnum[],
  ) {}
}

export class TenantGet {
  static readonly type = '[Tenant] Get';

  constructor(readonly payload: { tenantId: string; fromCache?: boolean }) {}
}

export class TenantSetReconnecting {
  static readonly type = '[Tenant] SetReconnecting';

  constructor(readonly reconnectingTenantId: string) {}
}

export class TenantResetReconnecting {
  static readonly type = '[Tenant] ResetReconnecting';
}

export class TenantGetRegistries {
  static readonly type = '[Tenant] GetRegistries';

  constructor(
    readonly tenantId: string,
    readonly payload: { retailerId: string },
  ) {}
}

export class TenantSetRegistries {
  static readonly type = '[Tenant] SetRegistries';

  constructor(
    readonly tenantId: string,
    readonly payload: SetTenantRegistryRequest,
  ) {}
}

export class TenantDelete {
  static readonly type = '[Tenant] Delete';

  constructor(readonly tenantId: string) {}
}

export class TenantGetMappedBranchesCount {
  static readonly type = '[Tenant] GetMappedBranchesCount';

  constructor(readonly tenantIds: string[]) {}
}

export class TenantUpdate {
  static readonly type = '[Tenant] Update';

  constructor(readonly payload: { tenantId: string; body: TenantUpdateRequest }) {}
}

export class TenantActivate {
  static readonly type = '[Tenant] Activate';

  constructor(readonly payload: { tenantId: string; body: TenantActivateRequest }) {}
}

export class TenantLink {
  static readonly type = '[Tenant] Link';

  constructor(
    readonly provider: ProviderEnum,
    readonly payload: TenantLinkData,
  ) {}
}

export class TenantReconnect {
  static readonly type = '[Tenant] Reconnect';
}

export class TenantGetProviderTenants {
  static readonly type = '[Tenant] GetProviderTenants';

  constructor(readonly tenantId: string) {}
}

export class TenantGetProviderBranches {
  static readonly type = '[Tenant] GetProviderBranches';

  constructor(
    readonly tenantId: string,
    readonly forAccounting?: boolean,
  ) {}
}

export class TenantResetProviderBranches {
  static readonly type = '[Tenant] ResetProviderBranches';
}

export class TenantGetProviderLocations {
  static readonly type = '[Tenant] GetProviderLocations';

  constructor(readonly tenantId: string) {}
}

export class TenantGetProviderSuppliers {
  static readonly type = '[Tenant] GetProviderSuppliers';

  constructor(readonly tenantId: string) {}
}

export class TenantGetProviderItems {
  static readonly type = '[Tenant] GetProviderItems';

  constructor(readonly tenantId: string) {}
}

export class TenantGetProviderCategories {
  static readonly type = '[Tenant] GetProviderCategories';

  constructor(readonly tenantId: string) {}
}

export class TenantGetProviderTaxRates {
  static readonly type = '[Tenant] GetProviderTaxRates';

  constructor(readonly tenantId: string) {}
}

export class TenantGetProviderSalesTypes {
  static readonly type = '[Tenant] GetProviderSalesTypes';

  constructor(
    readonly tenantId: string,
    readonly providerBranchId?: string,
  ) {}
}

export class TenantGetProviderResources {
  static readonly type = '[Tenant] GetProviderResources';

  constructor(
    readonly tenantId: string,
    readonly type: ResourceTypeEnum,
    readonly forAccounting?: boolean,
    readonly providerBranchId?: string,
  ) {}
}
