import {
  axisBottom,
  axisLeft,
  BaseType,
  EnterElement,
  extent,
  scaleLinear,
  select,
  selectAll,
  Transition,
  transition,
} from 'd3';
import { ScaleLinear } from 'd3-scale';
import { Selection } from 'd3-selection';
import { Subject } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { HexColor } from '@supy/common';

import { ChartBaseDirective, getTooltipPosition } from '../../../chart-core';
import { PlotPoint, RenderedPlotPoint } from './scatter-plot-chart.interfaces';

const MINIMUM_AXIS_OFFSET = 0; // 1 stands for 100%, for example 15% should be passed as 0.15

@Component({
  selector: 'supy-scatter-plot-chart',
  templateUrl: './scatter-plot-chart.component.html',
  styleUrls: ['./scatter-plot-chart.component.scss'],
})
export class ScatterPlotChartComponent extends ChartBaseDirective<PlotPoint> implements OnDestroy {
  @Input() readonly data: PlotPoint[] = [];
  @Input() title: (d: PlotPoint) => string; // given d in data, returns the title text
  @Input() label: (d: PlotPoint) => string; // items list that has same id to get label text
  @Input() readonly showLabelTooltip: boolean;
  @Input() readonly yAxisLabel: string;
  @Input() readonly xAxisLabel: string;
  @Input() readonly yAxisDomain: [number, number];
  @Input() readonly xAxisDomain: [number, number];
  @Input() readonly yAxisAverage: number;
  @Input() readonly xAxisAverage: number;

  @Input() readonly keys: [string, string]; // array of z-values (keys to define x & y)
  @Input() readonly colorsMap: Map<string, HexColor> = new Map<string, HexColor>();
  @Output() readonly circleClick: EventEmitter<PlotPoint> = new EventEmitter<PlotPoint>();

  private readonly click$ = new Subject<MouseEvent>();

  private readonly radius: number = 6; // left margin, in pixels
  private xRange: [number, number] = [this.marginLeft, this.width - this.marginRight]; // [left, right]
  private yRange: [number, number]; // [bottom, top]
  private verticalLine: Selection<SVGLineElement, unknown, null, undefined>; // Line element to display on hover
  private horizontalLine: Selection<SVGLineElement, unknown, null, undefined>; // Line element to display on hover

  private xAxis: ScaleLinear<number, number>;
  private yAxis: ScaleLinear<number, number>;
  private privateKeys: [string, string];
  /**
   * returns the (quantitative) x-value
   *
   * @param d PlotPoint one bar from data list.
   */
  @Input() readonly xValueFn = (d: PlotPoint): number => Number(d.position[this.privateKeys[0]]);

  /**
   * returns the (ordinal) y-value
   *
   * @param d PlotPoint one bar from data list.
   */
  @Input() readonly yValueFn = (d: PlotPoint): number => Number(d.position[this.privateKeys[1]]);

  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(elementRef, cdr);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.removeExistingChartFromParent();
  }

  @HostListener('document:click', ['$event'])
  protected onClick(event: MouseEvent): void {
    this.click$.next(event);
  }

  private removeExistingChartFromParent(): void {
    select(this.hostElement).select('svg').remove();
  }

  protected renderChart() {
    if (!this.data?.length) {
      return;
    }

    const svg = this.createSVGElement();

    this.svg = svg;

    this.setPrivateKeys();

    const x = this.createXScale();
    const y = this.createYScale();

    this.xAxis = x;
    this.yAxis = y;

    const marks = this.createMarks(x, y);
    const t = this.createTransition();

    this.renderCornersText(svg);
    this.renderYAxis(svg, y, t);
    this.renderXAxis(svg, x, t);
    this.renderYAxisAverages(svg, y, t);
    this.renderXAxisAverages(svg, x, t);
    this.renderCircles(svg, marks, t);
    this.renderHoverLines(svg);
    // render 2 circles (bigger and smaller) on top in separate `g` elem in order to have nice hover result
    this.renderHoverCircle(svg, marks, t);
  }

  private setPrivateKeys() {
    if (!this.keys?.length) {
      this.privateKeys = Object.keys(this.data[0].position) as [string, string];
    } else {
      this.privateKeys = [...this.keys];
    }
  }

  private createXScale() {
    return scaleLinear()
      .domain(this.xAxisDomain ?? (extent(this.data, this.xValueFn) as [number, number]))
      .range(this.xRange)
      .clamp(true);
  }

  private createYScale() {
    return scaleLinear()
      .domain(this.yAxisDomain ?? (extent(this.data, this.yValueFn) as [number, number]))
      .range(this.yRange)
      .clamp(true);
  }

  private createMarks(
    x: ScaleLinear<number, number, never>,
    y: ScaleLinear<number, number, never>,
  ): RenderedPlotPoint[] {
    return this.data.map(d => ({
      x: x(this.xValueFn(d)),
      y: y(this.yValueFn(d)),
      data: d,
    }));
  }

  private createTransition(): Transition<BaseType, unknown, null, undefined> {
    return transition().duration(1000);
  }

  private renderCornersText(svg: Selection<SVGSVGElement, unknown, null, undefined>) {
    const topLeftText = `
  <p class="chart-section__description">Low Margins</p>
  <p class="chart-section__description">High Sales</p>
  <span class="chart-section__nickname">Plow Horses</span>
`;
    const topRightText = `
  <p class="chart-section__description">High Margins</p>
  <p class="chart-section__description">High Sales</p>
  <span class="chart-section__nickname">Stars</span>
`;
    const bottomLeftText = `
  <span class="chart-section__nickname">Dogs</span>
  <p class="chart-section__description">Low Margins</p>
  <p class="chart-section__description">Low Sales</p>
`;
    const bottomRightText = `
  <span class="chart-section__nickname">Puzzles</span>
  <p class="chart-section__description">High Margins</p>
  <p class="chart-section__description">Low Sales</p>
`;

    const textContainerWidth = 100;
    const textContainerHeight = 50;

    const topLeftSelection: Selection<SVGForeignObjectElement, unknown, null, undefined> =
      svg.select('foreignObject.top-left');
    const topLeftForeignObject = topLeftSelection.empty()
      ? svg.append('foreignObject').classed('top-left', true)
      : topLeftSelection;

    topLeftForeignObject
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', textContainerWidth)
      .attr('height', textContainerHeight)
      .style('text-align', 'left');

    const topRightSelection: Selection<SVGForeignObjectElement, unknown, null, undefined> =
      svg.select('foreignObject.top-right');
    const topRightForeignObject = topRightSelection.empty()
      ? svg.append('foreignObject').classed('top-right', true)
      : topRightSelection;

    topRightForeignObject
      .attr('x', this.width - textContainerWidth + this.marginLeft + this.marginRight)
      .attr('y', 0)
      .attr('width', textContainerWidth)
      .attr('height', textContainerHeight)
      .style('text-align', 'right');

    const bottomLeftSelection: Selection<SVGForeignObjectElement, unknown, null, undefined> =
      svg.select('foreignObject.bottom-left');
    const bottomLeftForeignObject = bottomLeftSelection.empty()
      ? svg.append('foreignObject').classed('bottom-left', true)
      : bottomLeftSelection;

    bottomLeftForeignObject
      .attr('x', 0)
      .attr('y', this.height - textContainerHeight)
      .attr('width', textContainerWidth)
      .attr('height', textContainerHeight)
      .style('text-align', 'left');

    const bottomRightSelection: Selection<SVGForeignObjectElement, unknown, null, undefined> =
      svg.select('foreignObject.bottom-right');
    const bottomRightForeignObject = bottomRightSelection.empty()
      ? svg.append('foreignObject').classed('bottom-right', true)
      : bottomRightSelection;

    bottomRightForeignObject
      .attr('x', this.width - textContainerWidth + this.marginLeft + this.marginRight)
      .attr('y', this.height - textContainerHeight)
      .attr('width', textContainerWidth)
      .attr('height', textContainerHeight)
      .style('text-align', 'right');

    this.appendTextToContainer(topLeftForeignObject, topLeftText);
    this.appendTextToContainer(topRightForeignObject, topRightText);
    this.appendTextToContainer(bottomLeftForeignObject, bottomLeftText);
    this.appendTextToContainer(bottomRightForeignObject, bottomRightText);
  }

  private appendTextToContainer(
    foreignObject: Selection<SVGForeignObjectElement, unknown, null, undefined>,
    textContent: string,
  ): void {
    const divSelect = foreignObject.select('div');

    const div = divSelect.empty() ? foreignObject.append('xhtml:div') : divSelect;

    div
      .style('width', '100%')
      .style('height', '100%')
      .style('display', 'flex')
      .style('flex-direction', 'column')
      .html(textContent);
  }

  private renderHoverLines(svg: Selection<SVGSVGElement, unknown, null, undefined>): void {
    this.verticalLine = (this.verticalLine ?? svg.append('line')) // Initialize the vertical line element
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 0)
      .attr('stroke', '#DDD7F1')
      .classed('hidden', true); // Add a CSS class to hide the line initially

    this.horizontalLine = (this.horizontalLine ?? svg.append('line')) // Initialize the horizontal line element
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', 0)
      .attr('stroke', '#DDD7F1')
      .classed('hidden', true); // Add a CSS class to hide the line initially
  }

  private renderHoverCircle(
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    marks: RenderedPlotPoint[],
    t: Transition<BaseType, unknown, null, undefined>,
  ): void {
    const circleSelection = svg.select('.hover-circle') as Selection<SVGGElement, unknown, null, unknown>;

    const circle = circleSelection.empty() ? svg.append('g').classed('hover-circle', true) : circleSelection;

    circle
      .selectAll('circle')
      .data([marks[0]])
      .join(
        (enter: Selection<EnterElement, RenderedPlotPoint, SVGSVGElement, unknown>) => {
          enter
            .append('circle')
            .call(selection => this.positionCircles(selection, t))
            .call(selection => selection.classed('hover-circle', true).classed('hidden', true))
            .call(selection => this.growBackgroundCircle(selection));

          return enter
            .append('circle')
            .call(selection => this.positionCircles(selection, t))
            .call(selection => this.initializeCircleRadius(selection))
            .call(selection => selection.classed('hover-circle hover-circle-inner', true).classed('hidden', true))
            .call(selection => this.growCircle(selection, t));
        },
        (update: Selection<EnterElement, RenderedPlotPoint, SVGSVGElement, unknown>) => {
          return update.call((u: Selection<BaseType, unknown, BaseType, unknown>) =>
            u
              .transition(t)
              .call(selection =>
                this.positionCircles(
                  selection as unknown as Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>,
                  t,
                ),
              ),
          );
        },
        (exit: Selection<EnterElement, RenderedPlotPoint, SVGSVGElement, unknown>) => {
          return exit.remove();
        },
      );
  }

  private renderCircles(
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    marks: RenderedPlotPoint[],
    t: Transition<BaseType, unknown, null, undefined>,
  ): void {
    const circleSelection = svg.select('.view-circle') as Selection<SVGGElement, unknown, null, unknown>;

    const circle = circleSelection.empty() ? svg.append('g').classed('view-circle', true) : circleSelection;

    circle
      .selectAll('circle')
      .data(marks)
      .join(
        (enter: Selection<EnterElement, RenderedPlotPoint, SVGSVGElement, unknown>) => {
          return enter
            .append('circle')
            .call(selection => selection.classed('data-circle', true))
            .call(selection => selection.classed('data-circle__clickable', this.isClickable))
            .call(selection => this.positionCircles(selection, t))
            .call(selection => this.initializeCircleRadius(selection))
            .call(selection => this.mapColors(selection))
            .call(selection => this.growCircle(selection, t));
        },
        (update: Selection<EnterElement, RenderedPlotPoint, SVGSVGElement, unknown>) => {
          return update.call((u: Selection<BaseType, unknown, BaseType, unknown>) =>
            u
              .transition(t)
              .delay((d: RenderedPlotPoint, i: number) => i * 10)
              .call(selection =>
                this.mapColors(
                  selection as unknown as Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>,
                ),
              )
              .call(selection =>
                this.positionCircles(
                  selection as unknown as Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>,
                  t,
                ),
              ),
          );
        },
        (exit: Selection<EnterElement, RenderedPlotPoint, SVGSVGElement, unknown>) => {
          return exit.remove();
        },
      );
  }

  private initializeCircleRadius(
    circles: Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>,
  ): void {
    circles.attr('r', 0);
  }

  private mapColors(
    circles: Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>,
    data?: RenderedPlotPoint,
  ): void {
    circles
      .style('color', (d: RenderedPlotPoint) => this.colorsMap.get((data ?? d).data.categoryId) ?? 'inherit')
      .attr('fill', 'currentColor');
  }

  private growCircle(
    circles: Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>,
    t: Transition<BaseType, unknown, null, undefined>,
  ): void {
    circles.transition(t).attr('r', this.radius);
  }

  private growBackgroundCircle(circles: Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>): void {
    circles.attr('r', this.radius + 3);
  }

  private positionCircles(
    circles: Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>,
    t: Transition<BaseType, unknown, null, undefined>,
  ): void {
    circles
      .attr('cx', (d: RenderedPlotPoint) => d.x)
      .attr('cy', (d: RenderedPlotPoint) => d.y)
      .transition(t);
  }

  private positionCircleSimple(
    circle: Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>,
    d: RenderedPlotPoint,
  ): void {
    circle.attr('cx', () => d.x).attr('cy', () => d.y);
  }

  private renderYAxisAverages(
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    y: ScaleLinear<number, number>,
    t: Transition<BaseType, unknown, null, undefined>,
  ): void {
    svg
      .selectAll('.y-axis-average')
      .data([null])
      .join('g')
      .attr('class', 'y-axis-average')
      .attr('transform', `translate(${this.getYAxisAveragePosition()},0)`)
      .transition(t)
      .call(axisLeft(y).ticks(0).tickSizeOuter(0) as (...args) => void);
  }

  private renderXAxisAverages(
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    x: ScaleLinear<number, number>,
    t: Transition<BaseType, unknown, null, undefined>,
  ): void {
    svg
      .selectAll('.x-axis-average')
      .data([null])
      .join('g')
      .attr('class', 'x-axis-average')
      .attr('transform', `translate(0,${this.getXAxisAveragePosition()})`)
      .transition(t)
      .call(axisBottom(x).ticks(0).tickSizeOuter(0) as (...args) => void);
  }

  private renderYAxis(
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    y: ScaleLinear<number, number>,
    t: Transition<BaseType, unknown, null, undefined>,
  ): void {
    svg
      .selectAll('.y-axis')
      .data([null])
      .join('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.getYAxisPosition()},0)`)
      .transition(t)
      .call(axisLeft(y).ticks(20) as (...args) => void);

    const axisLabelSelection: Selection<SVGTextElement, unknown, null, undefined> = svg.select('.y-axis-label');

    const axisLabel = axisLabelSelection.empty()
      ? svg.select('.y-axis').append('text').classed('y-axis-label', true)
      : axisLabelSelection;

    axisLabel
      .attr('x', -23)
      .attr('y', 36)
      .attr('dy', '1rem')
      .attr('fill', '#000')
      .attr('text-anchor', 'end')
      .text(this.yAxisLabel);
  }

  private renderXAxis(
    svg: Selection<SVGSVGElement, unknown, null, undefined>,
    x: ScaleLinear<number, number>,
    t: Transition<BaseType, unknown, null, undefined>,
  ): void {
    svg
      .selectAll('.x-axis')
      .data([null])
      .join('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${this.getXAxisPosition()})`)
      .transition(t)
      .call(axisBottom(x).ticks(20) as (...args) => void);

    const axisLabelSelection: Selection<SVGTextElement, unknown, null, undefined> = svg.select('.x-axis-label');

    const axisLabel = axisLabelSelection.empty()
      ? svg.select('.x-axis').append('text').classed('x-axis-label', true)
      : axisLabelSelection;

    axisLabel
      .attr('x', x(x.ticks().pop()) + 5)
      .attr('y', 32)
      .attr('dy', '0.32em')
      .attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .text(this.xAxisLabel);
  }

  protected addHoverEventListeners(): void {
    const isDisabled = this.isDisabled;
    const tooltipConfig = this.tooltipConfig;
    const cdr = this.cdr;
    const verticalLine = this.verticalLine;
    const horizontalLine = this.horizontalLine;
    const getXAxisPosition = () => this.getXAxisPosition();
    const getYAxisPosition = () => this.getYAxisPosition();
    const positionCircleSimple = (selection: unknown, d: RenderedPlotPoint) =>
      this.positionCircleSimple(selection as Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>, d);
    const mapColors = (selection: unknown, d: RenderedPlotPoint) =>
      this.mapColors(selection as Selection<SVGCircleElement, RenderedPlotPoint, SVGSVGElement, unknown>, d);
    const svg = this.svg;

    select(this.hostElement)
      .selectAll('.data-circle')
      .on('mouseover', function (event: MouseEvent, d: RenderedPlotPoint) {
        if (isDisabled) {
          return;
        }

        const hoveredElement = this as SVGCircleElement;
        const circle = select(hoveredElement);
        const circleX = +circle.attr('cx');
        const circleY = +circle.attr('cy');

        verticalLine
          .attr('x1', circleX)
          .attr('y1', circleY)
          .attr('x2', circleX)
          .attr('y2', getXAxisPosition)
          .classed('hidden', false);

        horizontalLine
          .attr('x1', circleX)
          .attr('y1', circleY)
          .attr('x2', getYAxisPosition)
          .attr('y2', circleY)
          .classed('hidden', false);

        svg
          .selectAll('.hover-circle')
          .classed('hidden', false)
          .call(selection => positionCircleSimple(selection, d));

        svg.selectAll('.hover-circle-inner').call(selection => mapColors(selection, d));

        tooltipConfig.set({
          data: d.data,
          positioning: getTooltipPosition(hoveredElement, event),
        });

        cdr.detectChanges();
      })
      .on('mouseleave', function () {
        if (isDisabled) {
          return;
        }

        selectAll('.hover-circle').classed('hidden', true);
        verticalLine.classed('hidden', true);
        horizontalLine.classed('hidden', true);

        tooltipConfig.update(config => ({ ...config, data: null }));
        cdr.detectChanges();
      });
  }

  protected addClickEventListeners(): void {
    select(this.hostElement)
      .selectAll('.data-circle')
      .on('mouseup', (event: MouseEvent, d: RenderedPlotPoint) => {
        this.tooltipConfig.update(config => ({ ...config, data: null }));
        this.circleClick.emit(d.data);
      });
  }

  protected setSizes(margin: { top: number; bottom: number; left: number; right: number }, heightOffset = 0): void {
    super.setSizes(margin, heightOffset);
    this.xRange = [this.marginLeft, this.width];
    this.yRange = [this.height - this.marginBottom, this.marginTop];
  }

  private getXAxisPosition(): number {
    return this.height - this.marginBottom;
  }

  private getYAxisPosition(): number {
    return this.marginLeft;
  }

  private getXAxisAveragePosition(): number {
    const [min, max] = this.yAxisDomain ?? (extent(this.data, this.yValueFn) as [number, number]);

    return (
      (this.height - this.marginBottom - this.marginTop) * (1 - findPosition(min, max, this.yAxisAverage)) +
      this.marginTop
    );
  }

  private getYAxisAveragePosition(): number {
    const [min, max] = this.xAxisDomain ?? (extent(this.data, this.xValueFn) as [number, number]);

    return (this.width - this.marginLeft) * findPosition(min, max, this.xAxisAverage) + this.marginLeft;
  }
}

function findPosition(min: number, max: number, num: number): number {
  if (min > max || num < min || num > max) {
    // position in the middle by default
    return 0.5;
  }

  const range = max - min;
  const difference = num - min;

  const position = Number.isNaN(difference) || Number.isNaN(range) || range === 0 ? 0.5 : difference / range;

  return position >= 1 - MINIMUM_AXIS_OFFSET
    ? 1 - MINIMUM_AXIS_OFFSET
    : position <= MINIMUM_AXIS_OFFSET
    ? MINIMUM_AXIS_OFFSET
    : position;
}
