import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';

import { GetIsAllowedRequestOptions, IsAllowedRequest } from '../core';

export abstract class PermissionHelper {
  protected store: Store;

  constructor() {
    this.store = inject(Store);
  }

  abstract checkIsAllowed(options: GetIsAllowedRequestOptions): Observable<IsAllowedRequest>;
}
