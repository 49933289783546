import { pdfDefaultOptions, ResponsiveVisibility } from 'ngx-extended-pdf-viewer';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';

import { PdfViewerZoomLevel, PdfViewerZoomLevels } from './zoom-levels';

@Component({
  selector: 'supy-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent {
  @Input() readonly headerTitle: string = $localize`:@@pdfViewer.attachment:Attachment`;
  @Input() protected readonly src: string;
  @Input() protected readonly initialPageNumber: number = 1;
  @Input() protected readonly scale: number = 1.5;
  @Input() protected readonly showToolbar: boolean = true;
  @Input() protected readonly showSidebarButton: ResponsiveVisibility = false;
  @Input() protected readonly showFindButton: ResponsiveVisibility = false;
  @Input() protected readonly showPagingButtons: ResponsiveVisibility = 'always-visible';
  @Input() protected readonly showDrawEditor: ResponsiveVisibility = false;
  @Input() protected readonly showStampEditor: ResponsiveVisibility = false;
  @Input() protected readonly showTextEditor: ResponsiveVisibility = false;
  @Input() protected readonly showZoomButtons: ResponsiveVisibility = 'always-visible';
  @Input() protected readonly showPresentationModeButton: ResponsiveVisibility = 'always-visible';
  @Input() protected readonly showOpenFileButton: ResponsiveVisibility = false;
  @Input() protected readonly showPrintButton: ResponsiveVisibility = 'always-visible';
  @Input() protected readonly showDownloadButton: ResponsiveVisibility = 'always-visible';
  @Input() protected readonly showSecondaryToolbarButton: ResponsiveVisibility = false;
  @Input() protected readonly showRotateButton: ResponsiveVisibility = 'always-visible';
  @Input() protected readonly showRotateCwButton: ResponsiveVisibility = 'always-visible';
  @Input() protected readonly showRotateCcwButton: ResponsiveVisibility = false;
  @Input() protected readonly showHandToolButton: ResponsiveVisibility = false;
  @Input() protected readonly showScrollingButton: ResponsiveVisibility = 'always-visible';
  @Input() protected readonly showSpreadButton: ResponsiveVisibility = false;
  @Input() protected readonly showPropertiesButton: ResponsiveVisibility = false;
  @Input() protected readonly showHighlightEditor: ResponsiveVisibility = false;
  @Input() protected readonly initialZoom: PdfViewerZoomLevel = 'page-width';
  @Input() protected readonly isCollapsable: boolean;
  @Input() set isCollapsed(value: boolean) {
    this.isCollapsedChanges.set(value);
  }

  @Input() protected set isInitialCollapsed(value: boolean) {
    this.isCollapsedChanges.set(value);
  }

  protected readonly zoomLevels = PdfViewerZoomLevels;
  protected readonly isLoading = signal<boolean>(true);
  readonly isCollapsedChanges = signal<boolean>(false);

  constructor() {
    pdfDefaultOptions.enableScripting = false;
  }

  protected onPageRendered(): void {
    this.isLoading.set(false);
  }

  protected onToggleCollapse(): void {
    this.isCollapsedChanges.update(value => !value);
  }
}
