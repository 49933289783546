import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { GridModule, IconModule, SwitchModule } from '@supy/components';

import { ActiveLocationsGridComponent } from './components/active-locations-grid';
import { RetailerSettingsService, SettingsService, UserSettingsService } from './services';

@NgModule({
  declarations: [ActiveLocationsGridComponent],
  imports: [CommonModule, ReactiveFormsModule, GridModule, SwitchModule, IconModule],
  providers: [SettingsService, RetailerSettingsService, UserSettingsService],
  exports: [ActiveLocationsGridComponent],
})
export class SettingsModule {}
