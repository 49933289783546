import { Branch, getApiDetailsDecorator, IdType, LocalizedSkeletonObjectType, Supplier } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { GrnItem } from '../grn';
import { AggregatedLinkedOrder } from './aggregated-order-link.entity';
import { AggregatedOrdersResponse } from './aggregated-orders.model';

const ApiProperty = getApiDetailsDecorator<AggregatedOrdersResponse>();

export class AggregatedOrders {
  private constructor(args: NonFunctionProperties<AggregatedOrders>) {
    this.supplier = args.supplier;
    this.location = args.location;
    this.channel = args.channel;
    this.linkedOrders = args.linkedOrders ?? [];
    this.items = args.items ?? [];
    this.outlet = args.outlet;
  }

  @ApiProperty() readonly supplier: Supplier;
  @ApiProperty() readonly location: Branch;
  @ApiProperty() readonly channel: IdType;
  @ApiProperty() readonly linkedOrders: AggregatedLinkedOrder[];
  @ApiProperty() readonly items: GrnItem[];
  @ApiProperty() readonly outlet: LocalizedSkeletonObjectType;

  static deserialize(data: AggregatedOrdersResponse): AggregatedOrders {
    return new AggregatedOrders({
      supplier: data.supplier,
      location: data.location,
      channel: data.channel,
      linkedOrders: data.linkedOrders && AggregatedLinkedOrder.deserializeList(data.linkedOrders),
      items: data.items && GrnItem.deserializeList(data.items),
      outlet: data.outlet,
    });
  }
}
