import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseHttpService, IQueryResponse, Query } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

import { RetailerItemCategoriesConfig } from '../config';
import { CreateRetailerItemCategoryRequest, RetailerItemCategory, UpdateRetailerItemCategoryRequest } from '../core';

@Injectable({
  providedIn: 'root',
})
export class RetailerItemCategoriesService extends BaseHttpService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(APP_CONFIG) protected readonly config: RetailerItemCategoriesConfig,
  ) {
    super(`${config.apiUrlBff}${config.apiUrlPrefix}/catalog/retailer-item-categories`);
  }

  getCategories(query: Query<RetailerItemCategory>): Observable<IQueryResponse<RetailerItemCategory>> {
    return this.get('', query.toQueryParams());
  }

  createCategory(body: CreateRetailerItemCategoryRequest): Observable<void> {
    return this.post<CreateRetailerItemCategoryRequest, void>(body, '');
  }

  removeCategory(categoryId: string): Observable<void> {
    return this.delete(`${categoryId}`);
  }

  updateRetailerItemCategory(categoryId: string, body: UpdateRetailerItemCategoryRequest): Observable<void> {
    return this.patch<UpdateRetailerItemCategoryRequest, void>(body, `${categoryId}`);
  }
}
