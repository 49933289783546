<form class="supy-create-recipe__details-form" [formGroup]="form">
  <div class="supy-create-recipe__details-form-container">
    <div class="supy-create-recipe__details-form-row">
      <div class="supy-create-recipe__details-form-column">
        <div class="supy-create-recipe__details-form-row">
          <div>
            <label i18n="@@recipeType">Recipe Type</label>
            <supy-button-group
              style="width: fit-content"
              formControlName="type"
              [buttons]="types"
              (valueChange)="onTypeChange($event)"
            ></supy-button-group>
          </div>
          <div *ngIf="activeSalesTypesCount">
            <ng-container *ngTemplateOutlet="recipeCodeTemplate"></ng-container>
          </div>
        </div>

        <ng-container formGroupName="name">
          <div class="supy-create-recipe__details-form-control">
            <label i18n="@@inventory.recipe.nameEn">Recipe Name (EN)*</label>
            <supy-input
              placeholder="Recipe En. Name"
              i18n-placeholder="@@inventory.recipe.nameEnPlaceholder"
              name="en"
              formControlName="en"
            >
              <supy-input-error
                *ngIf="!nameControl?.valid && (formSubmitted || nameControl?.touched)"
                i18n="@@common.nameEnRequired"
                >English name is required</supy-input-error
              >
            </supy-input>
          </div>
        </ng-container>

        <div class="supy-create-recipe__details-form-control">
          <label i18n="@@common.menuCategory">Menu Category</label>

          <supy-dropdown-tree
            name="category"
            formControlName="category"
            [disabled]="isReadonly"
            placeholder="Category"
            selection="single"
            [data]="categories"
            [autoExpanded]="true"
          ></supy-dropdown-tree>
        </div>
      </div>

      <div class="supy-create-recipe__details-form-column">
        <div *ngIf="withLocation" class="supy-create-recipe__details-form-control">
          <label i18n="@@location">Location</label>
          <supy-dropdown-tree
            name="location"
            formControlName="location"
            placeholder="Location"
            i18n-placeholder="@@location"
            selection="single"
            displayStrategy="path"
            [disabled]="editMode"
            [clearable]="false"
            [data]="locations"
            (valueChange)="locationChange.emit()"
          ></supy-dropdown-tree>
        </div>
        <div *ngIf="!withLocation"></div>

        <ng-container formGroupName="name">
          <div class="supy-create-recipe__details-form-control">
            <label i18n="@@inventory.recipe.nameAlt">Recipe Name (Alt)</label>
            <supy-input placeholder="Recipe Alt. Name" name="ar" formControlName="ar"> </supy-input>
          </div>
        </ng-container>

        <div *ngIf="activeSalesTypesCount" class="supy-create-recipe__details-form-control">
          <label i18n="@@inventory.recipe.salesTypesLabel">Sales Types (Applicable)</label>

          <supy-combo-box
            formControlName="activeSalesTypes"
            i18n-placeholder="@@inventory.recipe.salesTypesPlaceholder"
            placeholder="Select Sales Types"
            density="medium"
            [list]="salesTypes"
            [multiple]="true"
            [selectAllAllowed]="false"
            valueKey="id"
            [disabledValues]="[disabledSalesType]"
            [clearable]="false"
            displayKey="name"
            titleKey="name"
            (changed)="onSalesTypeChange($event)"
          ></supy-combo-box>
        </div>

        <div *ngIf="!activeSalesTypesCount">
          <ng-container *ngTemplateOutlet="recipeCodeTemplate"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="supy-create-recipe__details-form-control-picture">
    <label i18n="@@inventory.recipe.itemPicture">Item Picture</label>
    <supy-file-upload
      responseUrlKey="photoUrl"
      [imgUrl]="form.get('imageUrl')?.value"
      [singleImage]="true"
      [fileTypes]="[mimeType.jpeg, mimeType.png]"
      [maxFileSize]="5000000"
      [token]="token"
      [uploadUrl]="uploadImageUrl"
      (changeFiles)="onFileChange($event)"
    ></supy-file-upload>
  </div>

  <ng-template #recipeCodeTemplate>
    <div class="supy-create-recipe__details-form-control-column-justify-end-full-width">
      <label i18n="@@common.recipeCode">Recipe Code</label>
      <supy-input placeholder="Code" name="recipe-code" formControlName="code"></supy-input>
    </div>
  </ng-template>
</form>
