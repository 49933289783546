import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AuthzDirective } from './directives';
import { AuthzGuard } from './guards';
import { PermissionsState } from './store';

@NgModule({
  declarations: [AuthzDirective],
  imports: [CommonModule, NgxsModule.forFeature([PermissionsState])],
  exports: [AuthzDirective],
  providers: [AuthzGuard],
})
export class AuthzModule {}
