import { Query } from '@supy/common';

import {
  CreateInventoryStockCountInput,
  CreateInventorySubStockCountInput,
  InventoryStockCount,
  InventoryStockCountRequestProps,
} from '../../core';
import {
  InventoryStockCountsFilters,
  InventoryStockCountsRequestMetadata,
  InventoryStockCountsStateModel,
} from '../state';

export class InventoryStockCountsGetMany {
  static readonly type = '[InventoryStockCounts] GetMany';

  constructor(readonly query?: Query<InventoryStockCount & InventoryStockCountRequestProps>) {}
}

export class InventoryStockCountsListExport {
  static readonly type = '[InventoryStockCounts] ListExport';
}

export class InventoryStockCountsInitFilters {
  static readonly type = '[InventoryStockCounts] InitFilters';
}

export class InventoryStockCountsSetFilter {
  static readonly type = '[InventoryStockCounts] SetFilter';
  constructor(readonly payload: InventoryStockCountsFilters) {}
}

export class InventoryStockCountsPatchFilter {
  static readonly type = '[InventoryStockCounts] PatchFilter';
  constructor(readonly payload: Partial<InventoryStockCountsFilters>) {}
}

export class InventoryStockCountsResetFilter {
  static readonly type = '[InventoryStockCounts] ResetFilter';
}

export class InventoryStockCountsPatchRequestMeta {
  static readonly type = '[InventoryStockCounts] PatchRequestMeta';
  constructor(readonly payload: Partial<InventoryStockCountsRequestMetadata>) {}
}

export class InventoryStockCountsCreate {
  static readonly type = '[InventoryStockCounts] Create';
  constructor(readonly payload: CreateInventoryStockCountInput) {}
}

export class InventoryStockCountsCreateSub {
  static readonly type = '[InventoryStockCounts] CreateSub';
  constructor(
    readonly id: string,
    public payload: CreateInventorySubStockCountInput,
  ) {}
}

export class InventoryStockCountsGetLastDate {
  static readonly type = '[InventoryStockCounts] GetLastDate';

  constructor(
    readonly locationIds: string[],
    readonly options?: { skipTimeCheck: boolean },
  ) {}
}

export class InventoryStockCountsPatchState {
  static readonly type = '[InventoryStockCounts] PatchState';

  constructor(readonly state: Partial<InventoryStockCountsStateModel>) {}
}

export class InventoryStockCountsGetLastOpeningCount {
  static readonly type = '[InventoryStockCounts] GetLastOpeningCount';

  constructor(readonly locationIds: string[]) {}
}

export class InventoryStockCountsGetFirstEventDate {
  static readonly type = '[InventoryStockCounts] GetFirstEventDate';

  constructor(readonly locationId: string) {}
}
