import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { SALES_IMPORTS_URI } from '../config';
import {
  DeleteSalesTransactionsRequest,
  ManualSalesImportSyncRequest,
  MapSalesTransactionsRequest,
  ProviderSyncSalesImportRequest,
  ProviderSyncSalesImportResponse,
  SalesImport,
  SalesImportsRequestProps,
  SalesTransaction,
  SalesTransactionQueryProps,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class SalesImportService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(SALES_IMPORTS_URI) private readonly uri: string,
  ) {}

  get(id: string): Observable<SalesImport> {
    return this.httpClient.get<SalesImport>(`${this.uri}/${id}`);
  }

  getMany(query: Query<SalesImport & SalesImportsRequestProps>): Observable<IQueryResponse<SalesImport>> {
    return this.httpClient.get<IQueryResponse<SalesImport>>(`${this.uri}`, {
      params: query.toQueryParams(),
    });
  }

  submit(id: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${id}/submit`, null);
  }

  uploadSheet(file: FormData): Observable<{ fileUrl: string }> {
    return this.httpClient.post<{ fileUrl: string }>(`${this.uri}/upload`, file);
  }

  manualSync(body: ManualSalesImportSyncRequest): Observable<SalesImport> {
    return this.httpClient.post<SalesImport>(`${this.uri}/manual-sync`, body);
  }

  providerSync(body: ProviderSyncSalesImportRequest): Observable<ProviderSyncSalesImportResponse> {
    return this.httpClient.post<ProviderSyncSalesImportResponse>(`${this.uri}/provider-sync`, body);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${id}`);
  }

  mapTransactions(importId: string, body: MapSalesTransactionsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${importId}/map-transactions`, body);
  }

  cancelTransactions(importId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${importId}/canceled-transactions-to-wastage`, null);
  }

  deleteTransactions(importId: string, body: DeleteSalesTransactionsRequest): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${importId}/delete-transactions`, { body });
  }

  getTransactions(
    salesImportId: string,
    query: Query<SalesTransactionQueryProps>,
  ): Observable<IQueryResponse<SalesTransaction>> {
    return this.httpClient.get<IQueryResponse<SalesTransaction>>(`${this.uri}/${salesImportId}/grouped-transactions`, {
      params: query.toQueryParams(),
    });
  }
}
