import { capital } from 'case';
import { Workbook } from 'exceljs';
import { IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  saveWorkbook,
} from '@supy/common';
import { getLocalizedName } from '@supy/settings';

import { FinishedRecipe, InventoryRecipe, InventoryRecipeType, SemiFinishedRecipe } from '../core';

export function downloadInventoryRecipesList(
  items: InventoryRecipe[],
  options: { locationId?: string; currency: string; getCategory: (id: string) => string },
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): void {
  const columns: DataColumn[] = [
    { header: 'Recipe Code', key: 'code' },
    { header: 'Recipe Name', key: 'name' },
    { header: `Category`, key: 'category' },
    { header: 'Type', key: 'type' },
    { header: 'Stockable', key: 'isStockable' },
    { header: 'Locations', key: 'locationCount' },
    { header: 'Ingredients', key: 'ingredientCount' },
    { header: 'Revenue Tracking Only', key: 'isForRevenueTracking' },
    { header: 'Portion Size', key: 'portionSize' },
    { header: 'Portion Unit', key: 'portionUnit' },
    { header: 'Status', key: 'state' },
    { header: 'Created By', key: 'createdBy' },
    { header: 'Created On', key: 'createdAt' },
  ];

  if (options.locationId) {
    columns.splice(
      7,
      0,
      ...[
        { header: `Cost (${options.currency})`, key: 'cost' },
        { header: 'Food Cost %', key: 'foodCost' },
        { header: 'Selling Price', key: 'sellingPrice' },
      ],
    );
  }

  const currencyCols = new Set(['cost', 'sellingPrice']);
  const percentCols = new Set(['foodCost']);
  const qtyCols = new Set(['portionSize']);
  const dateCols = new Set(['createdAt']);

  const workbook = new Workbook();

  const data = items.map(item => {
    const ingredientCount = item.isForRevenueTracking
      ? 0
      : item.type === InventoryRecipeType.Finished && 'ingredients' in item
      ? (item as unknown as FinishedRecipe).ingredients[0].ingredients.length
      : (item as SemiFinishedRecipe).ingredients.length;

    return {
      ...item,
      code: item.code,
      name: getLocalizedName(item.name),
      category: options.getCategory(item.category?.id),
      type: item.type === InventoryRecipeType.Finished ? 'Finished' : 'Semi-Finished',
      locationCount: item.locations.length,
      ingredientCount,
      portionSize: item.portionSize?.size,
      portionUnit: item.portionSize?.uom.name,
      state: capital(item.state ?? ''),
      createdAt: item.createdAt && getUTCOverriddenDateTime(new Date(item.createdAt), ianaTimeZone),
      createdBy: `${item.createdBy?.firstName} ${item.createdBy?.lastName}`,
      cost: (options.locationId && getRecipeCost(item, options.locationId)) || 0,
      foodCost: getSellingPrice(item, options.locationId ?? '')
        ? item.cost / getSellingPrice(item, options.locationId ?? '')
        : 0,
      sellingPrice: getSellingPrice(item, options.locationId ?? ''),
    };
  });

  createWorksheet(workbook, `Recipes`, { data, columns, qtyCols, dateCols, currencyCols, percentCols });

  const fileName = `recipes-${Date.now()}.xlsx`;

  saveWorkbook(workbook, fileName);
}

function getRecipeCost(recipe: InventoryRecipe, locationId: string): number {
  const recipeLocation = recipe.locations.find(location => location.locationId === locationId);

  return (recipeLocation?.cost ?? 0) * (recipe?.baseUnit?.conversionToAtom ?? 1) * (recipe?.portionSize?.size ?? 1);
}

function getSellingPrice(recipe: InventoryRecipe, locationId: string): number {
  return recipe.costCenters?.find(({ location }) => location.id === locationId)?.sellingPrice ?? 0;
}
