import { filter, first, Observable, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '@supy/auth';
import { IQueryResponse, Query, QueryPaging, Supplier, SupplierState } from '@supy/common';

import { SuppliersService } from '../../services';

@Injectable()
export class UserRetailersRelatedSuppliersResolver {
  constructor(
    private readonly suppliersService: SuppliersService,
    private readonly authService: AuthService,
  ) {}

  resolve(): Observable<IQueryResponse<Supplier>> {
    const query = new Query<Supplier>({
      filtering: [{ by: 'state', op: 'eq', match: SupplierState.Active }],
      paging: QueryPaging.NoLimit,
    });

    return this.authService.user$.pipe(
      filter(Boolean),
      first(),
      switchMap(user => this.suppliersService.getUserRetailersRelatedSuppliers(user.id, query)),
    );
  }
}
