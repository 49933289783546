import { Injectable } from '@angular/core';

import { SimpleTypeofValues, SimpleValueTypes } from '../../models';

export interface SheetColumnValidationOptions {
  readonly index: number;
  readonly name: string;
  readonly required?: boolean;
  readonly type?: SimpleTypeofValues;
}

export interface SheetValidationOptions {
  readonly rowOffset?: number;
  readonly totalColumns?: number;
  readonly columnsOptions?: SheetColumnValidationOptions[];
}

const DEFAULT_SHEET_VALIDATION_OPTIONS: SheetValidationOptions = {
  rowOffset: 0,
  totalColumns: 0,
  columnsOptions: [],
};

@Injectable({ providedIn: 'root' })
export class SheetValidatorService<T = SimpleValueTypes> {
  validate(data: T[][], options: SheetValidationOptions = DEFAULT_SHEET_VALIDATION_OPTIONS): void {
    if (!options.columnsOptions.length) {
      return;
    }

    if (!data.length) {
      throw new Error('Sheet is empty');
    }

    data.forEach((row, index) => this.#validateRow(row, options, index));
  }

  #validateRow(row: T[], options: SheetValidationOptions, rowIndex: number): void {
    for (let i = 0; i < options.totalColumns; i++) {
      const columnOptions = options.columnsOptions.find(column => column.index === i);

      if (columnOptions) {
        this.#validateCell(row[i], columnOptions, rowIndex + 1 + options.rowOffset);
      }
    }
  }

  #validateCell(cell: T, columnOptions: SheetColumnValidationOptions, rowIndex: number): void {
    const A = 'A'.charCodeAt(0);
    const columnLetter = String.fromCharCode(A + columnOptions.index);
    const cellIndex = `${columnLetter}${rowIndex}`;

    if (columnOptions.required && !cell) {
      throw new Error(`Column ${columnOptions.name} is required at ${cellIndex}`);
    }

    if (columnOptions.type && !(typeof cell === columnOptions.type)) {
      throw new Error(`Column ${columnOptions.name} type should be ${columnOptions.type} at ${cellIndex}`);
    }
  }
}
