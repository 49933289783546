import { ApplicationRef, ComponentRef, EmbeddedViewRef, Injectable } from '@angular/core';
import { IANATimezone } from '@supy.api/dictionaries';

import { LocalizedData } from '@supy/common';

import { DialogOffset } from '../../dialog';
import { MaintenanceOverlayComponent } from '../components';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceOverlayService {
  private overlayComponentRef: ComponentRef<MaintenanceOverlayComponent> | null = null;
  private ianaTimeZone: IANATimezone;
  private offsetTop: DialogOffset;
  constructor(private readonly appRef: ApplicationRef) {}

  setTimezone(ianaTimeZone: IANATimezone): void {
    this.ianaTimeZone = ianaTimeZone;

    if (this.overlayComponentRef) {
      this.overlayComponentRef.instance.ianaTimeZone = this.ianaTimeZone;
    }
  }

  setOffset(offsetTop: DialogOffset): void {
    this.offsetTop = offsetTop;

    if (this.overlayComponentRef) {
      this.overlayComponentRef.instance.offsetTop = this.offsetTop;
    }
  }

  showOverlay(startDate: Date, endDate: Date, message?: LocalizedData): void {
    if (this.overlayComponentRef?.instance) {
      this.overlayComponentRef.instance.startDate = startDate;
      this.overlayComponentRef.instance.endDate = endDate;
      this.overlayComponentRef.instance.message = message;
      this.overlayComponentRef.instance.ianaTimeZone = this.ianaTimeZone;
      this.overlayComponentRef.instance.offsetTop = this.offsetTop;

      return;
    }

    // wait for element to bootstrap appears on the page first;
    setTimeout(() => {
      const overlayComponentRef = this.appRef.bootstrap(MaintenanceOverlayComponent, '.supy-page__maintenance-overlay');

      overlayComponentRef.instance.startDate = startDate;
      overlayComponentRef.instance.endDate = endDate;
      overlayComponentRef.instance.message = message;
      overlayComponentRef.instance.ianaTimeZone = this.ianaTimeZone;
      overlayComponentRef.instance.offsetTop = this.offsetTop;

      const domElem = (overlayComponentRef.hostView as EmbeddedViewRef<HTMLElement>).rootNodes[0] as HTMLElement;

      document.body.appendChild(domElem);

      this.overlayComponentRef = overlayComponentRef;
    });
  }

  hideOverlay(): void {
    if (!this.overlayComponentRef) {
      return;
    }

    this.appRef.detachView(this.overlayComponentRef.hostView);
    this.overlayComponentRef.destroy();
    this.overlayComponentRef = null;
  }
}
