import { BehaviorSubject, combineLatest, ReplaySubject, startWith, takeUntil } from 'rxjs';
import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { Destroyable } from '../lifecycle';
import { ToggleFeature } from './toggle-features';
import { UnleashService } from './unleash.service';

interface ToggleContext<T> {
  readonly $implicit: T;
  readonly supyToggle: ToggleFeature;
}

/**
 * @description
 * Conditionally shows/hides templates based on the current user having the specified toggle features.
 *
 * @caution: `UnleashModule` needs to be imported into the feature module.
 *
 * Basic usage
 * @example
 * ```html
 *  <button *supyToggle='toggleFeature.CreateInventoryItem'>Create</button>
 * ```
 *
 * It also supports multiple features
 * @example
 * ```html
 *  <button *supyToggle="[toggleFeature.CreateInventoryItem, toggleFeature.EditInventoryItem]">
 *    Create
 *  </button>
 * ```
 * It also supports else templates
 * @example
 * ```html
 *  <button *supyToggle="toggleFeature.CreateInventoryItem; else expired">Create</button>
 *
 * <ng-template #expired>Your subscription has expired, please call our support team.</ng-template>
 * ```
 */
@Directive({ selector: '[supyToggle]' })
export class UnleashDirective<T> extends Destroyable implements OnInit {
  static ngTemplateGuard_supyToggle: 'binding';

  @Input() set supyToggle(value: ToggleFeature | ToggleFeature[]) {
    this.#togglesChanges.next(value);
  }

  readonly #togglesChanges = new ReplaySubject<ToggleFeature | ToggleFeature[]>(1);

  readonly #thenTemplateChanges = new BehaviorSubject<TemplateRef<ToggleContext<T>> | null>(null);

  @Input() set supyToggleElse(value: TemplateRef<void>) {
    this.#elseTemplateChanges.next(value);
  }

  readonly #elseTemplateChanges = new BehaviorSubject<TemplateRef<void> | null>(null);
  #thenViewContainerRef: EmbeddedViewRef<ToggleContext<T>> | null = null;
  #elseViewContainerRef: EmbeddedViewRef<void> | null = null;

  static ngTemplateContextGuard<T>(_dir: UnleashDirective<T>, _ctx: ToggleContext<T>): _ctx is ToggleContext<T> {
    return true;
  }

  constructor(
    private readonly unleashService: UnleashService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<ToggleContext<T>>,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.#togglesChanges,
      this.#thenTemplateChanges,
      this.#elseTemplateChanges,
      this.unleashService.enabledTogglesChanges$.pipe(startWith(this.unleashService.enabledToggles)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([toggles, thenTemplate, elseTemplate, enabledToggles]) => {
        let hasToggles = false;

        if (Array.isArray(toggles)) {
          toggles.forEach(toggle => {
            if (!hasToggles) {
              hasToggles = enabledToggles.includes(toggle);
            }
          });
        } else {
          hasToggles = enabledToggles.includes(toggles);
        }

        if (hasToggles || !toggles) {
          this.showThen(thenTemplate);
        } else {
          this.showElse(elseTemplate);
        }

        this.cdr.markForCheck();
      });
  }

  private showThen(thenTemplateRef: TemplateRef<ToggleContext<T>> | null): void {
    if (!this.#thenViewContainerRef) {
      this.viewContainerRef.clear();
      this.#elseViewContainerRef = null;
      this.#thenViewContainerRef = this.viewContainerRef.createEmbeddedView(thenTemplateRef ?? this.templateRef);
    }
  }

  private showElse(elseTemplateRef: TemplateRef<void> | null): void {
    if (!this.#elseViewContainerRef) {
      this.viewContainerRef.clear();
      this.#thenViewContainerRef = null;

      if (elseTemplateRef) {
        this.#elseViewContainerRef = this.viewContainerRef.createEmbeddedView(elseTemplateRef);
      }
    }
  }
}
