<div class="supy-date-time-editor-container">
  <igx-input-group displayDensity="compact" class="supy-date-time-editor" theme="material" [type]="inputType">
    <input
      #input
      type="text"
      igxInput
      [igxDateTimeEditor]="dateTimeEditor"
      [displayFormat]="displayFormat"
      [igxTextSelection]="textSelection"
      [id]="id"
      [name]="name"
      [disabled]="disabled"
      [minValue]="minValue"
      [maxValue]="maxValue"
      [value]="value"
      [placeholder]="placeholder"
      (valueChange)="onValueChange($event)"
      (focusout)="onFocusOut($event)"
      (focusin)="onFocusIn($event)"
      (validationFailed)="input.blur()"
    />

    <igx-hint class="supy-date-time-editor__hint-placeholder">
      <supy-input-hint-container>
        <ng-content select="supy-input-error"></ng-content>
        <ng-content select="supy-input-hint"></ng-content>
      </supy-input-hint-container>
    </igx-hint>
  </igx-input-group>
</div>
