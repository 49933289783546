import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { MixpanelService } from '@supy/mixpanel';

@Component({
  selector: 'supy-retailer',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(private readonly mixpanelService: MixpanelService) {
    this.mixpanelService.init();
  }

  ngOnInit() {
    this.mixpanelService.trackRouterEvents();
  }
}
