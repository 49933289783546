import { first, forkJoin, map, Observable } from 'rxjs';
import { IsAllowedRequest, Resource } from '@cerbos/core';

import { AUTHZ_DEFAULT_ID, GetIsAllowedRequestOptions, PermissionHelper } from '@supy/authz';
import { CurrentRetailerState } from '@supy/retailers';
import { CurrentUserState } from '@supy/users';

export class RetailerPermissionHelper extends PermissionHelper {
  checkIsAllowed({
    store,
    kind,
    action,
    principal: p,
    resource: r,
  }: GetIsAllowedRequestOptions): Observable<IsAllowedRequest> {
    return forkJoin([
      store.select(CurrentUserState.getCurrentUser).pipe(first(Boolean)),
      store.select(CurrentRetailerState.get).pipe(first(Boolean)),
    ]).pipe(
      map(([user, selectedRetailerId]) => {
        const principal = p ?? {
          id: user.id,
          roles: user.roles.map(({ identifier }) => identifier),
          attributes: { retailerIds: user.retailerIds },
        };

        const resource: Resource = r ?? {
          id: AUTHZ_DEFAULT_ID,
          kind,
          attributes: { retailerId: selectedRetailerId },
        };

        return { principal, resource, action };
      }),
    );
  }
}
