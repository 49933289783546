import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { UserWithBranches } from '@supy/common';

import { UsersWithBranchesService } from '../../services';

@Injectable()
export class UserWithBranchesResolver {
  constructor(private readonly usersWithBranchesService: UsersWithBranchesService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UserWithBranches> {
    return this.usersWithBranchesService.getUserWithBranches(route.params.id as string).pipe(
      map(user => {
        const roleId = user.roles?.length ? user.roles[0].id : '';

        return { ...user, roleId };
      }),
    );
  }
}
