import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ColumnChartEntry } from '@supy/components';

import { computedFoodCost, InventoryRecipeType } from '../../core';

@Component({
  selector: 'supy-recipe-price-simulator-chart',
  templateUrl: './recipe-price-simulator-chart.component.html',
  styleUrls: ['./recipe-price-simulator-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipePriceSimulatorChartComponent {
  @Input() set averageFoodCost(value: number) {
    this.averageFoodCostValue = value;
    this.init();
  }

  @Input() set lastPurchaseFoodCost(value: number) {
    this.lastPurchaseFoodCostValue = value;
    this.init();
  }

  @Input() set sellingPrice(value: number) {
    this.sellingPriceValue = value;
    this.init();
  }

  @Input() set recipeCostingTotalTaxes(value: number) {
    this.recipeCostingTotalTaxesValue = value ?? 0;
    this.init();
  }

  @Input() set targetCost(value: number) {
    if (typeof value === 'number') {
      this.targetCostValue = value;
      this.init();
    }
  }

  get chartYAxisMaxValue(): number {
    return (
      Math.max(
        this.chartData[0]?.targetCost as number,
        this.chartData[1]?.averageCost as number,
        this.chartData[2]?.lastPurchaseCost as number,
      ) + 10
    );
  }

  protected chartData: ColumnChartEntry[] = [];

  protected recipeTypeValue: InventoryRecipeType;
  protected readonly inventoryRecipeType = InventoryRecipeType;

  private sellingPriceValue: number;
  private averageFoodCostValue: number;
  private lastPurchaseFoodCostValue: number;
  private targetCostValue: number;
  private recipeCostingTotalTaxesValue: number;

  private init(): void {
    const targetCost =
      this.targetCostValue ??
      computedFoodCost(this.averageFoodCostValue, this.recipeCostingTotalTaxesValue, this.sellingPriceValue);

    const averageCost = computedFoodCost(
      this.averageFoodCostValue,
      this.recipeCostingTotalTaxesValue,
      this.sellingPriceValue,
    );

    const lastPurchaseCost = computedFoodCost(
      this.lastPurchaseFoodCostValue,
      this.recipeCostingTotalTaxesValue,
      this.sellingPriceValue,
    );

    this.chartData = [
      this.getTargetCostEntry(targetCost),
      this.getAverageCostEntry(averageCost),
      this.getLastPurchaseCostEntry(lastPurchaseCost),
    ];
  }

  private getTargetCostEntry(value: number): ColumnChartEntry {
    return {
      categoryLabel: $localize`:@@inventory.recipe.costing.targetCostPercent:Target Cost: ${value}%`,
      targetCost: value,
      averageCost: 0,
      lastPurchaseCost: 0,
    };
  }

  private getAverageCostEntry(value: number): ColumnChartEntry {
    return {
      categoryLabel: $localize`:@@inventory.recipe.costing.averageCostPercent:Average Cost: ${value}%`,
      targetCost: 0,
      averageCost: value,
      lastPurchaseCost: 0,
    };
  }

  private getLastPurchaseCostEntry(value: number): ColumnChartEntry {
    return {
      categoryLabel: $localize`:@@inventory.recipe.costing.lastPurchaseCostPercent:Last Purchase Cost: ${value}%`,
      targetCost: 0,
      averageCost: 0,
      lastPurchaseCost: value,
    };
  }
}
