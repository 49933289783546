import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IgxGridModule } from '@infragistics/igniteui-angular';
import { NgxsModule } from '@ngxs/store';

import { ButtonModule, CheckboxModule, DialogModule, IconModule, InputModule, SeparatorModule } from '@supy/components';

import { RetailerItemCategoriesState } from './store';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    ButtonModule,
    InputModule,
    SeparatorModule,
    IgxGridModule,
    IconModule,
    CheckboxModule,
    RouterModule,
    NgxsModule.forFeature([RetailerItemCategoriesState]),
  ],
})
export class RetailerItemCategoriesModule {}
