import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SupyCommonModule } from '@supy/common';

import { IconModule } from '../icon';
import { StatisticsCardComponent } from './statistics-card.component';

@NgModule({
  declarations: [StatisticsCardComponent],
  imports: [CommonModule, SupyCommonModule, IconModule],
  exports: [StatisticsCardComponent],
})
export class StatisticsCardModule {}
