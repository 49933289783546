import produce from 'immer';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import {
  Branch,
  BranchState,
  EntityListState,
  EntityListStateModel,
  Query,
  QueryBuilder,
  Supplier,
} from '@supy/common';

import { DetailedRetailerSupplier } from '../../core';
import { BranchesService, RetailersService } from '../../services';
import {
  RetailerSuppliersDelete,
  RetailerSuppliersGetDetailed,
  RetailerSuppliersGetMany,
  RetailerSuppliersGetRetailerBranches,
  RetailerSuppliersInitFilters,
  RetailerSuppliersPatchFilter,
  RetailerSuppliersPatchRequestMeta,
  RetailerSuppliersResetCurrent,
  RetailerSuppliersResetFilter,
  RetailerSuppliersSetFilter,
} from '../actions';

export interface RetailerSuppliersStateModel extends EntityListStateModel<Supplier> {
  filters: RetailerSuppliersFilters;
  requestMetadata: RetailerSuppliersRequestMetadata;
  detailedSupplier: DetailedRetailerSupplier | null;
  searchSuppliers: Supplier[] | null;
  retailerBranches: Branch[] | null;
}

export interface RetailerSuppliersFilters {
  name: string | null;
}

export interface RetailerSuppliersRequestMetadata {
  readonly page: number;
  readonly limit: number;
  readonly retailerId: string | null;
}

const FILTERS_DEFAULT = { name: null };

const REQUEST_METADATA_DEFAULT = { page: 0, limit: 10, retailerId: null };

export const RETAILER_SUPPLIERS_TOKEN = new StateToken<RetailerSuppliersStateModel>('retailerSuppliers');

@State<RetailerSuppliersStateModel>({
  name: RETAILER_SUPPLIERS_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: REQUEST_METADATA_DEFAULT,
    detailedSupplier: null,
    searchSuppliers: null,
    retailerBranches: null,
  },
})
@Injectable()
export class RetailerSuppliersState extends EntityListState {
  constructor(
    protected readonly router: Router,
    private readonly retailersService: RetailersService,
    private readonly branchesService: BranchesService,
  ) {
    super(router);
  }

  @Selector()
  static retailerSuppliers(state: RetailerSuppliersStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static detailedSupplier(state: RetailerSuppliersStateModel) {
    return state.detailedSupplier;
  }

  @Selector()
  static searchSuppliers(state: RetailerSuppliersStateModel) {
    return state.searchSuppliers;
  }

  @Selector()
  static retailerBranches(state: RetailerSuppliersStateModel) {
    return state.retailerBranches;
  }

  @Selector()
  static requestMetadata(state: RetailerSuppliersStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static filters(state: RetailerSuppliersStateModel) {
    return EntityListState.currentFilters<RetailerSuppliersFilters>(state);
  }

  @Selector()
  static appliedFiltersCount(state: RetailerSuppliersStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static isFirst(state: RetailerSuppliersStateModel) {
    return EntityListState.isFirst(state);
  }

  @Selector()
  static isLast(state: RetailerSuppliersStateModel) {
    return EntityListState.isLast(state);
  }

  @Action(RetailerSuppliersInitFilters)
  retailerSuppliersInitFilter(ctx: StateContext<RetailerSuppliersStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(RetailerSuppliersSetFilter)
  setRetailerSuppliersFilter(ctx: StateContext<RetailerSuppliersStateModel>, action: RetailerSuppliersSetFilter) {
    super.setFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new RetailerSuppliersPatchRequestMeta({ page: 0 }), new RetailerSuppliersGetMany()]);
  }

  @Action(RetailerSuppliersPatchFilter)
  patchRetailerSuppliersFilter(ctx: StateContext<RetailerSuppliersStateModel>, action: RetailerSuppliersPatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
    ctx.dispatch([new RetailerSuppliersPatchRequestMeta({ page: 0 }), new RetailerSuppliersGetMany()]);
  }

  @Action(RetailerSuppliersResetFilter)
  resetRetailerSuppliersFilter(ctx: StateContext<RetailerSuppliersStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
    ctx.dispatch([new RetailerSuppliersPatchRequestMeta({ page: 0 }), new RetailerSuppliersGetMany()]);
  }

  @Action(RetailerSuppliersPatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<RetailerSuppliersStateModel>, { payload }: RetailerSuppliersPatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(RetailerSuppliersGetMany, { cancelUncompleted: true })
  getRetailerSuppliers(ctx: StateContext<RetailerSuppliersStateModel>) {
    return this.retailersService
      .getRetailerUserSuppliersBff(ctx.getState().requestMetadata.retailerId as string, this.getQuery(ctx.getState()))
      .pipe(
        tap(({ data }) => {
          super.setMany(ctx, data);
        }),
      );
  }

  @Action(RetailerSuppliersGetRetailerBranches)
  getRetailerBranches(
    ctx: StateContext<RetailerSuppliersStateModel>,
    { payload }: RetailerSuppliersGetRetailerBranches,
  ) {
    const qb = new QueryBuilder<Branch>({
      filtering: [
        {
          by: 'retailer',
          match: payload.retailerId,
          op: 'eq',
        },
        {
          by: 'state',
          op: 'neq',
          match: BranchState.deleted,
        },
      ],
    });

    return this.branchesService.getBranchesBff(qb.build()).pipe(
      tap(({ data }) => {
        ctx.patchState({ retailerBranches: data });
      }),
    );
  }

  @Action(RetailerSuppliersGetDetailed)
  getRetailerSuppliersDetailed(
    ctx: StateContext<RetailerSuppliersStateModel>,
    { payload }: RetailerSuppliersGetDetailed,
  ) {
    return this.retailersService.getDetailedSupplier(payload).pipe(
      tap(response => {
        ctx.patchState({ detailedSupplier: response });
      }),
    );
  }

  @Action(RetailerSuppliersResetCurrent)
  resetCurrent(ctx: StateContext<RetailerSuppliersStateModel>) {
    ctx.patchState({ detailedSupplier: null });
  }

  @Action(RetailerSuppliersDelete)
  unlinkSupplier(ctx: StateContext<RetailerSuppliersStateModel>, { payload }: RetailerSuppliersGetDetailed) {
    return this.retailersService.unlinkSupplier(payload);
  }

  private getQuery(state: RetailerSuppliersStateModel): Query<Supplier> {
    const { name } = state.filters;

    const qb = new QueryBuilder<Supplier>({
      filtering: [],
      paging: { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
    });

    if (name?.trim()?.length) {
      qb.filtering.setFilter({
        by: 'name',
        match: name,
        op: 'like',
      });
    }

    return qb.build();
  }
}
