import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChartTooltipDirective } from './chart.directives';

@NgModule({
  declarations: [ChartTooltipDirective],
  imports: [CommonModule],
  exports: [ChartTooltipDirective],
})
export class ChartCoreModule {}
