import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';
import { IANATimezone } from '@supy.api/dictionaries';

import { Destroyable, LocalizedData, SupyCommonModule } from '@supy/common';

import { ButtonModule } from '../../../button';
import { DialogComponent, DialogModule, DialogOffset, DialogWidth, IDialogComponent } from '../../../dialog';
import { IconModule } from '../../../icon';
import { IntercomService } from '../../../intercom';

@Component({
  selector: 'supy-maintenance-overlay',
  templateUrl: './maintenance-overlay.component.html',
  styleUrls: ['./maintenance-overlay.component.scss'],
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonModule, IconModule, SupyCommonModule],
})
export class MaintenanceOverlayComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent, { static: true }) private readonly dialog: DialogComponent;

  @Input() readonly width: DialogWidth = '32rem';

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() message?: LocalizedData;
  @Input() ianaTimeZone: IANATimezone;
  @Input() offsetTop: DialogOffset;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();

  constructor(private readonly intercomService: IntercomService) {
    super();
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onContactSupport() {
    this.intercomService.show();
  }
}
