import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { DropdownModule } from '../dropdown';
import { IconModule } from '../icon';
import { TooltipModule } from '../tooltip';
import { TagComponent, TagsComponent } from './components';

const COMPONENTS = [TagComponent, TagsComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, IconModule, DropdownModule, TooltipModule],
  providers: [TitleCasePipe],
  exports: [...COMPONENTS],
})
export class TagsModule {}
