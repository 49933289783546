import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IgxInputGroupType } from '@infragistics/igniteui-angular';

import { Destroyable } from '@supy/common';

@Component({
  selector: 'supy-date-time-editor',
  templateUrl: './date-time-editor.component.html',
  styleUrls: ['./date-time-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeEditorComponent extends Destroyable implements ControlValueAccessor {
  @Input() readonly dateTimeEditor: string = 'dd/MM/yyyy hh:mm tt';
  @Input() readonly displayFormat: string = 'dd/MM/yyyy hh:mm tt';
  @Input() readonly inputType?: IgxInputGroupType = 'border';
  @Input() @HostBinding('attr.name') readonly name: string;
  @Input() readonly placeholder: string = 'Example: 20-04-2020 12:30 PM';
  @Input() readonly id?: string;
  @Input() readonly minValue?: string | Date;
  @Input() readonly maxValue?: string | Date;
  @Input() readonly textSelection?: boolean;
  @Input() set value(value: string | Date) {
    this.#value = value;
  }

  get value(): string | Date {
    return this.#value;
  }

  @Output() readonly valueChange = new EventEmitter<string | Date>();
  @Output() readonly focusIn = new EventEmitter<FocusEvent>();
  @Output() readonly focusOut = new EventEmitter<FocusEvent>();

  @Input() set disabled(value: boolean) {
    this.#disabled = value;
  }

  get disabled(): boolean {
    return this.#disabled;
  }

  onChange: (value: string | Date) => void;
  onTouched: () => void;

  touched = false;
  #value: string | Date;
  #disabled: boolean;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(NgControl)
    private readonly control?: NgControl,
  ) {
    super();

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  onValueChange(event: string | Date): void {
    if (!this.disabled) {
      this.#value = event;
      this.onChange?.(event);
      this.valueChange.emit(event);
    }
  }

  onFocusOut(e: FocusEvent): void {
    this.focusOut.emit(e);
    this.markAsTouched();
  }

  onFocusIn(e: FocusEvent): void {
    this.focusIn.emit(e);
    this.markAsTouched();
  }

  writeValue(value: string | Date): void {
    this.#value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (value: string | Date) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  setDisabledState(disabled: boolean): void {
    this.#disabled = disabled;
    this.cdr.markForCheck();
  }

  private emitTouchStatusChanged(): void {
    if (!this.control) {
      return;
    }

    const statusChanges = this.control.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
