import { BaseChannel } from './channel.base';
import { ChannelType } from './channel.type';

type ChannelName = `${ChannelType.Public}-${string}`;

export class PublicChannel extends BaseChannel<ChannelName> {
  get type(): ChannelType {
    return ChannelType.Public;
  }

  static for(name: string): ChannelName {
    return `${ChannelType.Public}-${name}`;
  }
}
