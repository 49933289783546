import {
  MixpanelService,
  TrackConfirmOrder,
  TrackCreateDraftOrder,
  TrackReceiveOrder,
  TrackRejectOrder,
  TrackSubmitOrder,
} from '@supy/mixpanel';

import { Order, OrderItem, OrderStatus, OrderUpdateStatus, UpdateOrderRequest } from '../core';
import { compareModifiedWithOrdered } from './orders.helper';

export interface TrackAnalyticsOptions {
  readonly mixpanelService: MixpanelService;
  readonly order: Order;
  readonly body: UpdateOrderRequest;
  readonly success?: boolean;
  readonly restaurantName: string;
}

export function trackOrderAnalytics({
  mixpanelService,
  order,
  body,
  success = true,
  restaurantName,
}: TrackAnalyticsOptions): void {
  switch (body.type) {
    case OrderUpdateStatus.draft:
      mixpanelService.trackCreateDraftOrder({ ...getTrackDraftOrder(order, body, restaurantName), success });
      break;
    case OrderUpdateStatus.submitted:
      mixpanelService.trackSubmitOrder({ ...getTrackSubmitOrder(order, body, restaurantName), success });
      break;
    case OrderUpdateStatus.confirmed:
      mixpanelService.trackConfirmOrder({ ...getTrackConfirmOrder(order, restaurantName), success });
      break;
    case OrderUpdateStatus.received:
      mixpanelService.trackReceiveOrder({ ...getTrackReceiveOrder(order, body, restaurantName), success });
      break;
    case OrderUpdateStatus.rejected:
      mixpanelService.trackRejectOrder({ ...getTrackRejectOrder(order, restaurantName), success });
      break;
    case OrderUpdateStatus.discarded: {
      const { productCategories, customDeliveryDate, addedSupplierNote, ...discardedOrderData } = getTrackDraftOrder(
        order,
        body,
        restaurantName,
      );

      mixpanelService.trackDiscardDraftOrder({ ...discardedOrderData, success });
      break;
    }
  }
}

export function getTrackSubmitOrder(
  order: Order,
  request: UpdateOrderRequest,
  restaurantName: string,
): TrackSubmitOrder {
  const totalPrice = order.ordered.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);

  return {
    orderId: order.id,
    restaurantName,
    branchName: order.partyInfo.retailer.name,
    supplierName: order.partyInfo.supplier.name,
    numberOfUniqueProducts: request.ordered?.length ?? order.ordered.length,
    uniqueProducts: order.ordered.map(({ name }) => name),
    success: true,
    currency: order.ordered[0].currency,
    gmvOrdering: totalPrice,
    repeatOrder: false,
    draftOrder: order.status === OrderStatus.Draft,
    addedSupplierNote: Boolean(request.comment ?? order.comment),
    customDeliveryDate: hasCustomDeliveryDate(request.deliveryDate ?? order.deliveryDate),
  };
}

export function getTrackDraftOrder(
  order: Order,
  request: UpdateOrderRequest,
  restaurantName: string,
): TrackCreateDraftOrder {
  const totalPrice = order.ordered.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);

  return {
    orderId: order.id,
    restaurantName,
    branchName: order.partyInfo.retailer.name,
    supplierName: order.partyInfo.supplier.name,
    numberOfUniqueProducts: request.ordered?.length ?? order.ordered.length,
    uniqueProducts: order.ordered.map(({ name }) => name),
    success: true,
    currency: order.ordered[0].currency,
    gmv: totalPrice,
    addedSupplierNote: Boolean(order.comment),
    customDeliveryDate: hasCustomDeliveryDate(order.deliveryDate),
  };
}

export function getTrackConfirmOrder(order: Order, restaurantName: string): TrackConfirmOrder {
  const orderChangedSupplier = order.modified.reduce(
    (acc, currentItem) => {
      const orderedItem = order.ordered.find(({ productId }) => productId === currentItem.productId);

      if (orderedItem?.price !== currentItem.price) {
        acc.price++;
      }

      if (orderedItem?.quantity !== currentItem.quantity) {
        currentItem.quantity ? acc.quantity++ : acc.outOfStock++;
      }

      return acc;
    },
    {
      quantity: 0,
      outOfStock: 0,
      price: 0,
    },
  );

  const gmvConfirming: number = compareModifiedWithOrdered(order).reduce(
    (acc, currentItem) => acc + currentItem.price * currentItem.quantity,
    0,
  );

  return {
    orderId: order.id,
    restaurantName,
    branchName: order.partyInfo.retailer.name,
    supplierName: order.partyInfo.supplier.name,
    numberOfUniqueProducts: order.ordered.length,
    uniqueProducts: order.ordered.map(({ name }) => name),
    success: true,
    currency: order.ordered[0].currency,
    gmvOrdering: order.orderedTotal,
    gmvConfirming,
    qtyChangedSupplier: orderChangedSupplier.quantity,
    priceChangedSupplier: orderChangedSupplier.price,
    outOfStocksSupplier: orderChangedSupplier.outOfStock,
  };
}

export function getTrackReceiveOrder(
  order: Order,
  request: UpdateOrderRequest,
  restaurantName: string,
): TrackReceiveOrder {
  const orderChangedRetailer = (request.received || order.received).reduce(
    (acc, currentItem) => {
      const orderedItem = order.ordered.find(({ productId }) => productId === currentItem.productId);

      if ((currentItem as OrderItem).price != null && orderedItem?.price !== (currentItem as OrderItem).price) {
        acc.price++;
      }

      if (orderedItem?.quantity !== currentItem.quantity) {
        acc.quantity++;
      }

      return acc;
    },
    {
      quantity: 0,
      product: 0,
      price: 0,
    },
  );

  const gmvReceiving: number = (request.received || order.received).reduce((acc, currentItem) => {
    const price =
      (currentItem as OrderItem).price ??
      order.ordered.find(({ productId }) => productId === currentItem.productId)?.price ??
      0;

    return acc + price * currentItem.quantity;
  }, 0);

  return {
    ...getTrackConfirmOrder(order, restaurantName),
    numberOfUniqueProducts: request.ordered?.length ?? order.ordered.length,
    gmvReceiving,
    qtyChangedRetailer: orderChangedRetailer.quantity,
    productAddedRetailer: Math.abs(request.received?.length - order.ordered?.length),
    priceChangedRetailer: orderChangedRetailer.price,
    orderInvoices: order.invoice?.images?.length,
    invoiceNumbers: order.invoice?.numbers?.length,
    onDeliveryDate: isToday(new Date(order.deliveryDate)),
  };
}

export function getTrackRejectOrder(order: Order, restaurantName: string): TrackRejectOrder {
  const totalPrice = order.ordered.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);

  return {
    orderId: order.id,
    restaurantName,
    branchName: order.partyInfo.retailer.name,
    supplierName: order.partyInfo.supplier.name,
    numberOfUniqueProducts: order.ordered.length,
    uniqueProducts: order.ordered.map(({ name }) => name),
    success: true,
    currency: order.ordered[0].currency,
    gmvOrdering: totalPrice,
  };
}

export function isToday(date: Date | string): boolean {
  const today = new Date();
  const someDate = new Date(date);

  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}

export function hasCustomDeliveryDate(date: Date | string): boolean {
  const defaultDate = new Date();

  // default delivery date is tomorrow
  defaultDate.setDate(defaultDate.getDate() + 1);
  defaultDate.setHours(0, 0, 0, 0);

  const orderDate = new Date(date);

  orderDate.setHours(0, 0, 0, 0);

  return defaultDate.getTime() !== orderDate.getTime();
}
