import { FilterActionsOptions } from '@supy/common';

import { InventoryStocksFilters, InventoryStocksRequestMetadata } from '../state';

export class InventoryStocksGet {
  static readonly type = '[InventoryStocks] Get';
  constructor(
    readonly payload: { readonly id: string; readonly fromCache?: boolean; readonly skipListChanges?: boolean },
  ) {}
}

export class InventoryStocksGetMany {
  static readonly type = '[InventoryStocks] GetMany';
}

export class InventoryStocksListExport {
  static readonly type = '[InventoryStocks] ListExport';
}

export class InventoryStocksInitFilters {
  static readonly type = '[InventoryStocks] InitFilters';
}

export class InventoryStocksResetFilter {
  static readonly type = '[InventoryStocks] ResetFilter';
}

export class InventoryStocksPatchFilter {
  static readonly type = '[InventoryStocks] PatchFilter';
  constructor(
    readonly payload: Partial<InventoryStocksFilters>,
    readonly options?: FilterActionsOptions,
  ) {}
}

export class InventoryStocksPatchRequestMeta {
  static readonly type = '[InventoryStocks] PatchRequestMeta';
  constructor(readonly payload: Partial<InventoryStocksRequestMetadata>) {}
}

export class InventoryStocksGetStatistics {
  static readonly type = '[InventoryStocks] GetStatistics';
}
