<div
  class="d3-tooltip"
  (scroll)="onScroll()"
  *ngIf="tooltipTemplate && tooltipConfig().data && !isDisabled"
  [ngStyle]="tooltipConfig().positioning"
>
  <ng-container
    *ngTemplateOutlet="
      tooltipTemplate.template;
      context: { $implicit: tooltipConfig().data, metadata: tooltipConfig().metadata }
    "
  ></ng-container>
</div>
