import { LocalizedSkeletonObjectType, SimpleUser } from '@supy/common';

export interface StockStatistic {
  readonly value: number;
}
export interface StockStatisticsResponse {
  readonly abovePar: StockStatistic;
  readonly belowMinimum: StockStatistic;
  readonly negative: StockStatistic;
  readonly notLinked: StockStatistic;
  readonly onHand: StockStatistic;
}

export enum StockStatisticsCardType {
  AbovePar = 'above-par',
  BelowMinimum = 'below-minimum',
  Negative = 'negative',
  NotLinked = 'not-linked',
  OnHand = 'on-hand',
}

export enum EventSourceTypesEnum {
  Init = 'init',
  Grn = 'grn',
  Return = 'return',
  ProductionIn = 'production-in',
  ProductionOut = 'production-out',
  Wastage = 'wastage',
  TransferIn = 'transfer-in',
  TransferOut = 'transfer-out',
  ItemVariance = 'item-variance',
  ShippedOut = 'shipped-out',
  Sales = 'sales',
  Merge = 'merge',
}

export interface LedgerChange {
  readonly before: number;
  readonly after: number;
  readonly change: number;
}

export interface ItemStockMovement {
  readonly id: string;
  readonly quantity: LedgerChange;
  readonly cost: LedgerChange;
  readonly event: EventSourceTypesEnum;
  readonly createdBy: SimpleUser;
  readonly updatedBy: SimpleUser;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly eventDate: Date;
  readonly metadata: GrnMetadata & LedgerMetadata & VarianceMetadata;
  readonly reference: LocalizedSkeletonObjectType;
}

export interface StockMovementRequestProps {
  readonly id: string;
  readonly 'item.id': string;
  readonly 'location.id': string;
  readonly eventDate: Date;
  readonly 'issuer.type': string;
}

export interface LedgerMetadata {
  readonly requiresRecalculation: boolean;
  readonly purchaseCost: number;
  readonly atomPrice: number;
}

export interface GrnPriceMetadata {
  readonly isCreditCreated: boolean;
  readonly isDiscounted: boolean;
  readonly invoice: number;
  readonly item: number;
  readonly actual: number;
  readonly itemHandlingFee?: number;
}

export interface GrnQuantityMetadata {
  readonly isCreditCreated: boolean;
  readonly received: number;
  readonly document: number;
}

export interface GrnMetadata extends LedgerMetadata {
  readonly price: GrnPriceMetadata;
  readonly quantity: GrnQuantityMetadata;
}

export interface VarianceMetadata extends LedgerMetadata {
  readonly cost: number;
  readonly countedQuantity: number;
  readonly onHandQuantity: number;
}
