import Pusher from 'pusher-js';

import { BaseChannel, ChannelName, WebSocketChannelFactory } from '../../common';

export class WebSocketSubscriptionScope {
  readonly #pusher: Pusher;
  readonly #channels: Set<BaseChannel> = new Set();
  readonly #factory: WebSocketChannelFactory;

  constructor(pusher: Pusher) {
    this.#pusher = pusher;
    this.#factory = new WebSocketChannelFactory();
  }

  subscribe<TChannel extends BaseChannel>(name: ChannelName): TChannel {
    const pusher = this.#pusher.subscribe(name);
    const channel = this.#factory.create<TChannel>(pusher);

    this.#channels.add(channel);

    return channel;
  }

  dispose(): void {
    for (const channel of this.#channels) {
      channel.dispose();
    }

    this.#channels.clear();
  }
}
