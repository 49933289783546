import { IdType, LocalizedSkeletonObjectType, SimpleUser, SkeletonObjectType, Uom } from '@supy/common';
import { PackagingUnit, PackagingUnitScope } from '@supy/packaging';

import { CostingMethod } from './base-item.entity';
import {
  BaseItemInventoryCostingMethod,
  BaseItemInventoryQuantityLevel,
  BaseItemInventoryState,
  RetailerItemSnapshot,
} from './base-item-inventory.entity';

export interface BaseItemInventoryLastStockCountApi {
  readonly onHandQuantity: number;
  readonly countedQuantity: number;
  readonly eventDate: string;
}

export interface BaseItemInventoryApi {
  readonly id: string;
  readonly retailer: SkeletonObjectType;
  readonly retailerItem: RetailerItemSnapshot;
  readonly state: BaseItemInventoryState;
  readonly costingMethod: BaseItemInventoryCostingMethod;
  readonly notAffectCogs: boolean;
  readonly cost: number;
  readonly onHand: number;
  readonly wastagePercentage?: number;
  readonly baseUnit: Uom;
  readonly locations: BaseItemInventoryLocationApi[];
  readonly storages: LocalizedSkeletonObjectType[];
  readonly lastStockCount: BaseItemInventoryLastStockCountApi;
  readonly updatedBy: SimpleUser;
  readonly createdBy: SimpleUser;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly lastPurchaseCost?: number;
  readonly packagings: PackagingUnit[];
  readonly suppliers: SkeletonObjectType[];
}

export interface BaseItemInventoryLocationApi {
  readonly id: string;
  readonly location: IdType;
  readonly cost: number;
  readonly quantity: number;
  readonly packagingUnit: PackagingUnit;
  readonly quantityLevel: BaseItemInventoryQuantityLevel;
}

export interface BaseItemInventoryPackagingUnitRequest {
  readonly id: string;
  readonly type: PackagingUnitScope;
}

export interface BaseItemInventoryLocationRequest {
  readonly id: string;
  readonly packagingUnit: BaseItemInventoryPackagingUnitRequest | null;
  readonly quantityLevel: BaseItemInventoryQuantityLevel | null;
}

export interface BaseItemInventoryStorageRequest {
  readonly id: string;
  readonly locationId: string;
}

export interface BaseItemInventoryRequest {
  readonly costingMethod?: CostingMethod;
  readonly fixedCost?: number;
  readonly wastagePercentage?: number;
  readonly notAffectCogs?: boolean;
  readonly locations: BaseItemInventoryLocationRequest[];
  readonly effectiveDate?: Date;
}

export interface UpdateBaseItemInventoryRequest extends BaseItemInventoryRequest {
  readonly storages?: BaseItemInventoryStorageRequest[];
}

export interface BaseItemInventoryRequestProps {
  readonly code: string;
  readonly 'retailer.name': string;
  readonly 'retailer.id': string;
  readonly 'storages.id': string;
  readonly 'locations.id': string;
  readonly 'retailerItem.name.en': string;
  readonly 'retailerItem.category.id': string;
  readonly 'retailerItem.scope.type': string;
  readonly 'retailerItem.baseUnit.type': string;
  readonly 'retailerItem.code': string;
  readonly tileType: string;
}

export enum InventoryItemType {
  Finished = 'finished',
  SemiFinished = 'semi-finished',
  FinishedItem = 'finished-item',
  SemiFinishedItem = 'semi-finished-item',
  Item = 'item',
}

export interface MinimumEffectiveDateResponse {
  readonly minEffectiveDate: string;
}
