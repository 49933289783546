import { Observable } from 'rxjs';

import { Supplier } from '@supy/common';
import { DropdownTreeNode, FilterConfig, SelectType } from '@supy/components';

export interface OrdersMappedFilters {
  readonly search: string;
  readonly dateRange: { readonly start: Date; readonly end: Date };
  readonly branches: string[];
  readonly suppliers: string[];
}

interface OrdersFiltersArgs {
  readonly branches$: Observable<DropdownTreeNode<string>[]>;
  readonly suppliers$: Observable<Supplier[]>;
}

export function getOrdersFiltersConfig({ branches$, suppliers$ }: OrdersFiltersArgs): FilterConfig {
  return {
    search: {
      placeholder: $localize`:@@orders.retailer.filter.search:Search by PO `,
      value: null,
    },
    dropdowns: [
      {
        value: null,
        selectType: SelectType.DateRange,
        placeholder: $localize`:@@common.date:Date`,
        name: 'dateRange',
        canSelectSingleDate: true,
        clearable: true,
      },
      {
        multiple: true,
        multipleSelectionStrategy: 'children',
        name: 'branches',
        options: branches$,
        placeholder: $localize`:@@common.branchLocations:Branch & Locations`,
        returnStrategy: 'key',
        selectType: SelectType.DropdownTree,
        value: [],
        width: '12rem',
      },
      {
        displayKey: 'name',
        localSearch: true,
        multiple: true,
        name: 'suppliers',
        options: suppliers$,
        pipeKey: 'email',
        placeholder: $localize`:@@suppliers:Suppliers`,
        selectType: SelectType.Combo,
        subtitleKey: 'phone',
        titleKey: 'name',
        value: [],
        valueKey: 'id',
      },
    ],
  };
}
