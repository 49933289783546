import { Workbook } from 'exceljs';
import { Currency, IANATimezone } from '@supy.api/dictionaries';

import {
  createWorksheet,
  DataColumn,
  DEFAULT_IANA_TIMEZONE,
  getUTCOverriddenDateTime,
  saveWorkbook,
  Uom,
} from '@supy/common';

import { ItemStockMovement } from '../core';
import {
  getComputedCost,
  getComputedItemCost,
  getComputedQuantity,
  getComputedTotal,
  getEventType,
} from './stock-movement.helper';

export function downloadStockMovementItems(
  items: ItemStockMovement[],
  currency: Currency,
  baseUnit: Uom,
  itemName: string,
  outletName: string,
  locationName: string,
  ianaTimeZone: IANATimezone = DEFAULT_IANA_TIMEZONE,
): void {
  const columns: DataColumn[] = [
    { header: 'Date', key: 'eventDate' },
    { header: 'Event Type', key: 'event' },
    { header: `Event Cost (${currency})`, key: 'cost' },
    { header: `Event Quantity (${baseUnit.name})`, key: 'quantity' },
    { header: `Item Cost (${currency})`, key: 'itemCost' },
    { header: `Item Quantity (${baseUnit.name})`, key: 'itemQuantity' },
    { header: 'User', key: 'createdBy' },
  ];

  const currencyCols = new Set(['cost', 'itemCost']);
  const qtyCols = new Set(['quantity', 'itemQuantity']);
  const dateCols = new Set(['eventDate']);

  const workbook = new Workbook();

  const data = items.map(item => {
    return {
      ...item,
      eventDate: getUTCOverriddenDateTime(new Date(item.eventDate), ianaTimeZone),
      event: getEventType(item),
      cost: getComputedCost(item, baseUnit),
      quantity: getComputedQuantity(item, baseUnit),
      itemCost: getComputedItemCost(item, baseUnit),
      itemQuantity: `${getComputedTotal(item, baseUnit)} ${baseUnit.name}`,
      createdBy: `${item.createdBy?.firstName} ${item.createdBy?.lastName?.charAt(0)}.`,
    };
  });

  createWorksheet(workbook, `Stock Movement`, { data, columns, currencyCols, qtyCols, dateCols });

  const fileName = `${itemName}_${outletName}_${locationName}_stock-movement-${new Date().toISOString()}.xlsx`;

  saveWorkbook(workbook, fileName);
}
