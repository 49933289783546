export const ROUTES_MAP = new Map<string, string[]>([
  ['analytics', ['dashboard']],
  ['analytics/procurement', ['dashboard.procurement']],
  ['analytics/actual-cost', ['dashboard.actual-cost']],
  ['analytics/theoretical-cost', ['dashboard.theoretical-cost']],
  ['analytics/variance-analysis', ['dashboard.variance']],
  ['analytics/wastage-analytics', ['dashboard.wastage']],
  ['analytics/sales-analytics', ['dashboard.sales']],
  ['analytics/transfers', ['dashboard.transfers']],

  ['channels', ['procurement.orders']],
  ['orders', ['procurement.orders']],
  ['category-orders', ['procurement.orders']],

  ['central-kitchen', ['central-kitchen.orders']],

  ['suppliers', ['repository.suppliers']],
  ['items', ['repository.items']],
  ['repository/recipes', ['repository.recipes']],

  ['accounting/grns', ['accounting.grns']],
  ['accounting/credit-notes', ['accounting.credit-notes']],
  ['accounting/supplier-return', ['accounting.supplier-returns']],

  ['inventory/stocks', ['inventory.stock']],
  ['inventory/recipes', ['inventory.recipes']],
  ['inventory/wastages', ['inventory.wastage']],
  ['inventory/stock-counts', ['inventory.stock-counts']],
  ['inventory/productions', ['inventory.production']],
  ['inventory/transfers', ['inventory.transfers']],

  ['integrations/pos-items-mapping', ['integrations.pos-items-mapping']],
  ['integrations/sales-imports', ['integrations.sales-imports']],
  ['integrations/accounting', ['integrations.accounting']],

  ['reports/all', ['reports.generate']],
  ['reports/activity', ['reports.activity-log']],

  ['users', ['settings.users']],
  ['settings/general', ['settings.general']],
  ['settings/wastage-types', ['settings.wastage-types']],
  ['settings/costing-method', ['settings.costing']],
  ['settings/inventory', ['settings.inventory']],
  ['settings/users', ['settings.users']],
  ['settings/branches', ['settings.branches']],
  ['settings/item-categories', ['settings.item-categories']],
  ['settings/recipe-categories', ['settings.recipe-categories']],
  ['settings/accounting-categories', ['settings.accounting-categories']],
  ['settings/receiving', ['settings.receiving']],
  ['settings/integrations', ['settings.integrations']],
]);
