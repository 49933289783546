import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { WindowStateManagerStorageService } from './window-state-manager-storage.service';

const RECHECK_WINDOW_DELAY_MS = 1000;

@Injectable({
  providedIn: 'root',
})
export class WindowStateManagerService {
  get isMainWindow() {
    return this.#isMainWindow;
  }

  private readonly onDocumentVisibilityChange = () => {
    this.determineWindowState();
  };

  #initialized = false;
  #isMainWindow = false;
  #unloaded = false;
  #onTabsUpdate: (event: StorageEvent) => void;
  #windowArray: string[] = [];
  #windowId: string;
  #storageKeysToTrack: Set<string>;

  constructor(
    private readonly storageService: WindowStateManagerStorageService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  initialize(onTabsUpdate: (event: StorageEvent) => void, storageKeysToTrack?: Set<string>) {
    if (this.#initialized) {
      return;
    }

    this.#onTabsUpdate = onTabsUpdate;
    this.#windowId = crypto.randomUUID?.() ?? Date.now().toString();
    this.#storageKeysToTrack = storageKeysToTrack ?? new Set<string>();

    this.bindUnload();
    this.trackStorageEvents();
    this.determineWindowState();

    this.#initialized = true;

    this.document.addEventListener('visibilitychange', this.onDocumentVisibilityChange);
  }

  unregister() {
    this.removeWindow();
    this.document.removeEventListener('visibilitychange', this.onDocumentVisibilityChange);
  }

  resetWindows() {
    this.storageService.resetWindows();
  }

  private determineWindowState() {
    this.#windowArray = this.storageService.getActiveWindows();

    this.#isMainWindow = !this.document.hidden;

    if (!this.#initialized) {
      this.#isMainWindow = !this.document.hidden;
      this.#windowArray.push(this.#windowId);
      this.storageService.setActiveWindows(this.#windowArray);
    }

    setTimeout(() => {
      this.determineWindowState();
    }, RECHECK_WINDOW_DELAY_MS);
  }

  private removeWindow() {
    const windowArray: string[] = this.storageService.getActiveWindows();
    const index = windowArray.indexOf(this.#windowId);

    if (index !== -1) {
      windowArray.splice(index, 1);
      this.storageService.setActiveWindows(windowArray);
      this.#unloaded = true;
    }
  }

  private bindUnload() {
    window.addEventListener('beforeunload', () => {
      if (!this.#unloaded) {
        this.removeWindow();
      }
    });
    window.addEventListener('unload', () => {
      if (!this.#unloaded) {
        this.removeWindow();
      }
    });
  }

  private trackStorageEvents() {
    globalThis.addEventListener('storage', (event: StorageEvent) => {
      if (this.#storageKeysToTrack.has(event.key)) {
        this.#onTabsUpdate(event);
      }
    });
  }
}
