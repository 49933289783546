import { IdType, OutletData, SimpleBranch, SimpleUser, SupplierSnapshot } from '@supy/common';

import {
  SupplierReturnActivity,
  SupplierReturnDocument,
  SupplierReturnItem,
  SupplierReturnTotals,
} from './supplier-return.model';

export enum SupplierReturnStatusEnum {
  Saved = 'saved',
  Posted = 'posted',
}

export enum SupplierReturnActivityTypeEnum {
  Saved = 'saved',
  Posted = 'posted',
  Pushed = 'pushed',
  Transferred = 'transferred',
}

export interface SupplierReturnMetadata {
  readonly isLocked?: boolean;
  readonly isSynced?: boolean;
}

export class SupplierReturn {
  readonly id: string;
  readonly comment?: string;
  readonly supplier?: SupplierSnapshot;
  readonly retailer?: IdType; // FIXME:
  readonly location?: SimpleBranch;
  readonly outlet?: OutletData;
  readonly channel: IdType;
  readonly status?: SupplierReturnStatusEnum;
  readonly document?: SupplierReturnDocument;
  readonly activities?: SupplierReturnActivity[];
  readonly items?: SupplierReturnItem[];
  readonly totals?: SupplierReturnTotals;
  readonly createdBy?: SimpleUser;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
  readonly closedOn?: Date;
  readonly metadata?: SupplierReturnMetadata;

  constructor(supplierReturn: Partial<SupplierReturn>) {
    Object.assign(this, supplierReturn);
    this.closedOn = this.getClosedOnDate();
  }

  private getClosedOnDate?(): Date {
    return (
      this.activities?.find(activity => activity.type === SupplierReturnActivityTypeEnum.Posted)?.createdAt ?? null
    );
  }
}

export type ListSupplierReturnRow = Pick<
  SupplierReturn,
  'id' | 'comment' | 'supplier' | 'retailer' | 'location' | 'outlet' | 'channel' | 'status' | 'document' | 'totals'
>;

export class SupplierReturnQueryParams extends SupplierReturn {
  readonly 'retailer.id': string;
  readonly 'supplier.id': string;
  readonly 'location.id': string;
  readonly 'document.number': string;
  readonly 'document.documentDate': number;
}
