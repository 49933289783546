import { UpdateInventorySubStockCountInput } from '../../core';
import { InventoryStockCountFilters, InventoryStockCountRequestMetadata } from '../state';

export class InventoryStockCountGet {
  static readonly type = '[InventoryStockCount] Get';
  constructor(readonly payload?: { id?: string; fromCache?: boolean; noLimit?: boolean }) {}
}

export class InventoryStockCountGetAllItems {
  static readonly type = '[InventoryStockCount] GetAll';
}

export class InventoryStockCountInitFilters {
  static readonly type = '[InventoryStockCount] InitFilters';
}

export class InventoryStockCountSetFilter {
  static readonly type = '[InventoryStockCount] SetFilter';
  constructor(readonly payload: InventoryStockCountFilters) {}
}

export class InventoryStockCountPatchFilter {
  static readonly type = '[InventoryStockCount] PatchFilter';
  constructor(readonly payload: Partial<InventoryStockCountFilters>) {}
}

export class InventoryStockCountResetFilter {
  static readonly type = '[InventoryStockCount] ResetFilter';
}

export class InventoryStockCountPatchRequestMeta {
  static readonly type = '[InventoryStockCount] PatchRequestMeta';
  constructor(readonly payload: Partial<InventoryStockCountRequestMetadata>) {}
}

export class InventoryStockCountSetType {
  static readonly type = '[InventoryStockCount] SetType';
  constructor(readonly subCount: boolean) {}
}

export class InventoryStockSubCountUpdate {
  static readonly type = '[InventoryStockCount] SubCountUpdate';
  constructor(
    readonly payload: {
      readonly stockCountId: string;
      readonly subStockCountId: string;
      readonly body: UpdateInventorySubStockCountInput;
    },
  ) {}
}

export class InventoryStockSubCountDiscard {
  static readonly type = '[InventoryStockCount] SubCountDiscard';
  constructor(
    readonly id: string,
    readonly subCountId: string,
  ) {}
}

export class InventoryStockCountResetDetails {
  static readonly type = '[InventoryStockCount] ResetDetails';
}

export class InventoryStockCountToggleZeroingUncounted {
  static readonly type = '[InventoryStockCount] ToggleZeroingUncounted';

  constructor(readonly id: string) {}
}
