import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxInputGroupModule } from '@infragistics/igniteui-angular';

import { InputHintsModule } from '../input-hints';
import { ColorPickerComponent } from './components';

@NgModule({
  declarations: [ColorPickerComponent],
  imports: [CommonModule, IgxInputGroupModule, InputHintsModule],
  exports: [ColorPickerComponent, InputHintsModule],
})
export class ColorPickerModule {}
