import { BaseChannel } from './channel.base';
import { ChannelType } from './channel.type';

type ChannelName = `${ChannelType.Cache}-${string}`;

export class CacheChannel extends BaseChannel<ChannelName> {
  get type(): ChannelType {
    return ChannelType.Cache;
  }

  static for(name: string): ChannelName {
    return `${ChannelType.Cache}-${name}`;
  }
}
