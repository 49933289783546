import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { OutletData } from '@supy/common';

import { OutletsService } from '../../services';

@Injectable()
export class OutletResolver {
  constructor(private readonly outletsService: OutletsService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<OutletData> {
    return firstValueFrom(this.outletsService.getOutletBff(route.params.outletId as string));
  }
}
