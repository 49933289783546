import produce from 'immer';
import { catchError, tap, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { EntityListState, EntityListStateModel, Query, QueryBuilder, QueryPaging } from '@supy/common';

import { EventState, InventoryEventDate } from '../../../stock-count';
import { InventoryVariance, InventoryVarianceRequestProps, VarianceReportTotals } from '../../core';
import { InventoryVarianceService } from '../../services';
import {
  InventoryVarianceGetAll,
  InventoryVarianceGetMany,
  InventoryVarianceGetTotals,
  InventoryVarianceInitFilters,
  InventoryVariancePatchFilter,
  InventoryVariancePatchRequestMeta,
  InventoryVarianceResetFilter,
  InventoryVarianceSaveReport,
  InventoryVarianceSubmitReport,
  InventoryVarianceSubmitReportDispatched,
} from '../actions';

export interface InventoryVarianceStateModel extends EntityListStateModel<InventoryVariance> {
  readonly filters: InventoryVarianceFilters;
  readonly requestMetadata: InventoryVarianceRequestMetadata;
  readonly responseMetadata: InventoryVarianceResponseMetadata;
  readonly location: string | null;
  readonly state: EventState | null;
  readonly allItems: InventoryVariance[];
  readonly eventDate: InventoryEventDate | null;
  readonly uncountedIsZero: boolean;
  readonly isOpening: boolean;
  readonly totals: VarianceReportTotals | null;
}

export interface InventoryVarianceFilters {
  readonly name: string | null;
  readonly categoryIds: string[];
}

export interface InventoryVarianceRequestMetadata {
  readonly limit: number;
  readonly page: number;
  readonly retailerId: string | null;
  readonly stockCountId: string | null;
}

export interface InventoryVarianceResponseMetadata {
  readonly count: number;
  readonly total: number;
}

const INVENTORY_VARIANCE_STATE_TOKEN = new StateToken<InventoryVarianceStateModel[]>('inventoryVariance');

const FILTERS_DEFAULT: InventoryVarianceFilters = {
  name: null,
  categoryIds: [],
};

const REQUEST_META_DEFAULT: InventoryVarianceRequestMetadata = {
  page: 0,
  limit: 50,
  retailerId: null,
  stockCountId: null,
};
const RESPONSE_META_DEFAULT: InventoryVarianceResponseMetadata = { count: 0, total: 0 };

@State<InventoryVarianceStateModel>({
  name: INVENTORY_VARIANCE_STATE_TOKEN,
  defaults: {
    ...EntityListState.default(),
    filters: FILTERS_DEFAULT,
    requestMetadata: REQUEST_META_DEFAULT,
    responseMetadata: RESPONSE_META_DEFAULT,
    location: null,
    state: null,
    allItems: [],
    eventDate: null,
    uncountedIsZero: false,
    isOpening: false,
    totals: null,
  },
})
@Injectable()
export class InventoryVarianceState extends EntityListState {
  constructor(
    protected readonly router: Router,
    private readonly varianceService: InventoryVarianceService,
  ) {
    super(router);
  }

  @Selector()
  static items(state: InventoryVarianceStateModel) {
    return EntityListState.all(state);
  }

  @Selector()
  static allItems(state: InventoryVarianceStateModel) {
    return state.allItems;
  }

  @Selector()
  static appliedFiltersCount(state: InventoryVarianceStateModel) {
    return EntityListState.appliedFiltersCount(state, FILTERS_DEFAULT);
  }

  @Selector()
  static filters(state: InventoryVarianceStateModel) {
    return EntityListState.currentFilters<InventoryVarianceFilters>(state);
  }

  @Selector()
  static requestMetadata(state: InventoryVarianceStateModel) {
    return state.requestMetadata;
  }

  @Selector()
  static responseMetadata(state: InventoryVarianceStateModel) {
    return state.responseMetadata;
  }

  @Selector()
  static location(state: InventoryVarianceStateModel) {
    return state.location;
  }

  @Selector()
  static reportState(state: InventoryVarianceStateModel) {
    return state.state;
  }

  @Selector()
  static eventDate(state: InventoryVarianceStateModel) {
    return state.eventDate;
  }

  @Selector()
  static uncountedIsZero(state: InventoryVarianceStateModel) {
    return state.uncountedIsZero;
  }

  @Selector()
  static isOpening(state: InventoryVarianceStateModel) {
    return state.isOpening;
  }

  @Selector()
  static totals(state: InventoryVarianceStateModel) {
    return state.totals;
  }

  @Action(InventoryVarianceInitFilters)
  inventoryVarianceInitFilter(ctx: StateContext<InventoryVarianceStateModel>) {
    super.initFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryVariancePatchFilter)
  patchInventoryVarianceFilter(ctx: StateContext<InventoryVarianceStateModel>, action: InventoryVariancePatchFilter) {
    super.patchFilter(ctx, action.payload, FILTERS_DEFAULT);
  }

  @Action(InventoryVarianceResetFilter)
  resetInventoryVarianceFilter(ctx: StateContext<InventoryVarianceStateModel>) {
    super.resetFilter(ctx, FILTERS_DEFAULT);
  }

  @Action(InventoryVarianceGetMany, { cancelUncompleted: true })
  getInventoryVarianceReport(ctx: StateContext<InventoryVarianceStateModel>) {
    return this.varianceService.getReport(this.getQuery(ctx.getState())).pipe(
      tap(report => {
        ctx.patchState({
          state: report.state,
          location: report.location?.id,
          responseMetadata: report.items.metadata,
          eventDate: report.eventDate,
          uncountedIsZero: report?.uncountedIsZero ?? false,
          isOpening: report.isOpening,
        });
        super.setMany(ctx, []);
        super.setMany(ctx, report.items.data);
      }),
    );
  }

  @Action(InventoryVarianceGetAll, { cancelUncompleted: true })
  getAllInventoryVarianceReport(ctx: StateContext<InventoryVarianceStateModel>) {
    const stockCountId = ctx.getState().requestMetadata.stockCountId;
    const query = new Query<InventoryVariance & InventoryVarianceRequestProps>({
      paging: QueryPaging.NoLimit,
      filtering: [
        {
          by: 'stockCount.id',
          op: 'eq',
          match: stockCountId,
        },
      ],
      ordering: [{ by: 'id', dir: 'desc' }],
    });

    return this.varianceService.getReport(query).pipe(
      tap(report => {
        ctx.patchState({
          allItems: report.items.data,
        });
      }),
    );
  }

  @Action(InventoryVarianceSubmitReport)
  inventoryVarianceSubmitReport(
    ctx: StateContext<InventoryVarianceStateModel>,
    { payload }: InventoryVarianceSubmitReport,
  ) {
    return this.varianceService.submitReport(ctx.getState().requestMetadata.stockCountId, payload).pipe(
      tap(() => {
        ctx.dispatch(
          new InventoryVarianceSubmitReportDispatched(
            true,
            'The variance report has been submitted successfully, the items quantity will be reflected on the system within 30mins.',
          ),
        );
        ctx.patchState({
          state: EventState.Submitted,
        });
      }),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 400) {
          ctx.dispatch(new InventoryVarianceSubmitReportDispatched(false, (e.error as { message: string }).message));
        }

        return throwError(() => e);
      }),
    );
  }

  @Action(InventoryVarianceSaveReport)
  saveReport(ctx: StateContext<InventoryVarianceStateModel>, { stockCountId, payload }: InventoryVarianceSaveReport) {
    return this.varianceService.saveReport(stockCountId, payload);
  }

  @Action(InventoryVariancePatchRequestMeta)
  patchRequestMetadata(ctx: StateContext<InventoryVarianceStateModel>, { payload }: InventoryVariancePatchRequestMeta) {
    ctx.setState(
      produce(draft => {
        draft.requestMetadata = {
          ...ctx.getState().requestMetadata,
          ...payload,
        };
      }),
    );
  }

  @Action(InventoryVarianceGetTotals)
  getVarianceReportTotals(
    ctx: StateContext<InventoryVarianceStateModel>,
    { stockCountId }: InventoryVarianceGetTotals,
  ) {
    return this.varianceService.getTotals(stockCountId).pipe(tap(totals => ctx.patchState({ totals })));
  }

  private getQuery(state: InventoryVarianceStateModel): Query<InventoryVariance & InventoryVarianceRequestProps> {
    const { categoryIds, name } = state.filters;

    const qb = new QueryBuilder<InventoryVariance & InventoryVarianceRequestProps>({
      filtering: [],
      paging: { offset: state.requestMetadata.page * state.requestMetadata.limit, limit: state.requestMetadata.limit },
      ordering: [{ by: 'id', dir: 'desc' }],
    });

    if (name) {
      qb.filtering.setFilter({
        by: 'name.en',
        match: name,
        op: 'like',
      });
    }

    if (categoryIds?.length) {
      qb.filtering.setFilter({
        by: 'categories.id',
        match: categoryIds,
        op: 'in',
      });
    }

    if (state.requestMetadata.retailerId) {
      qb.filtering.setFilter({
        by: 'retailer.id',
        match: state.requestMetadata.retailerId,
        op: 'eq',
      });
    }

    if (state.requestMetadata.stockCountId) {
      qb.filtering.setFilter({
        by: 'stockCount.id',
        match: state.requestMetadata.stockCountId,
        op: 'eq',
      });
    }

    return qb.build();
  }
}
