import Pusher from 'pusher-js';

export class WebSocketConnection {
  readonly #pusher: Pusher;

  constructor(pusher: Pusher) {
    this.#pusher = pusher;
  }

  on<TEvent extends string, TCallback extends () => void>(event: TEvent, callback: TCallback): void {
    this.#pusher.bind(event, callback);
  }

  off<TEvent extends string, TCallback extends () => void>(event: TEvent, callback: TCallback): void {
    this.#pusher.unbind(event, callback);
  }
}
