import { Channel } from 'pusher-js';

import {
  BaseChannel,
  CacheChannel,
  ChannelType,
  PresenceCacheChannel,
  PresenceChannel,
  PrivateCacheChannel,
  PrivateChannel,
  PrivateEncryptedCacheChannel,
  PrivateEncryptedChannel,
  PublicChannel,
} from '../channels';

export class WebSocketChannelFactory {
  create<TChannel extends BaseChannel>(channel: Channel): TChannel {
    switch (true) {
      case channel.name.startsWith(ChannelType.PrivateEncryptedCache):
        return new PrivateEncryptedCacheChannel(channel) as TChannel;
      case channel.name.startsWith(ChannelType.PrivateEncrypted):
        return new PrivateEncryptedChannel(channel) as TChannel;
      case channel.name.startsWith(ChannelType.PrivateCache):
        return new PrivateCacheChannel(channel) as TChannel;

      case channel.name.startsWith(ChannelType.PresenceCache):
        return new PresenceCacheChannel(channel) as TChannel;
      case channel.name.startsWith(ChannelType.Presence):
        return new PresenceChannel(channel) as TChannel;

      case channel.name.startsWith(ChannelType.Private):
        return new PrivateChannel(channel) as TChannel;
      case channel.name.startsWith(ChannelType.Public):
        return new PublicChannel(channel) as TChannel;
      case channel.name.startsWith(ChannelType.Cache):
        return new CacheChannel(channel) as TChannel;
      default:
        throw new Error(`Channel ${channel.name} type not supported`);
    }
  }
}
