// eslint-disable-next-line @typescript-eslint/ban-types
export function encodeParams<T extends object>(obj: T): Record<string, string> {
  const params: Record<string, string> = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      params[key] = encodeURIComponent(typeof value === 'string' ? value : JSON.stringify(value));
    }
  }

  return params;
}
