<div class="supy-retailer-supplier-items">
  <div class="supy-retailer-supplier-items__header-container">
    <div class="supy-retailer-supplier-items__header" *ngIf="isIntegratedSupplier">
      <div class="supy-retailer-supplier-items__warning">
        <p class="supy-retailer-supplier-items__warning-item">
          <supy-icon name="info" color="error" size="small"></supy-icon>
          <span class="supy-retailer-supplier-items__warning-label">
            This is an Integrated Supplier. Kindly contact Supy or
            <a
              class="supy-retailer-supplier-items__warning-link"
              href="mailto:{{ integratedSupplierContact }}"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ supplierName }} Support
            </a>
            to create or manage items for this Supplier.
          </span>
        </p>
      </div>
    </div>

    <div class="supy-retailer-supplier-items__header">
      <supy-filter-group
        [filterGroup]="filtersGroup"
        [latency]="400"
        (filtersChange)="onApplyFilter($event)"
        (filtersReset)="resetFilters.emit()"
      ></supy-filter-group>

      <div class="supy-retailer-supplier-items__actions">
        <supy-dropdown-toggle
          [dropdownRef]="supyDropdown"
          position="bottom-left"
          class="supy-retailer-supplier-items__dropdown"
          action
        >
          <supy-button
            class="supy-retailer-supplier-items__action"
            type="button"
            color="secondary"
            density="large"
            name="actions"
          >
            <span i18n="@@actions">Actions</span>
            <supy-icon name="arrow-down"></supy-icon>
          </supy-button>
        </supy-dropdown-toggle>
        <supy-dropdown #supyDropdown action>
          <supy-dropdown-item (click)="onExportAll()">
            <div class="supy-retailer-supplier-items__actions">
              <supy-icon name="document-download"></supy-icon>
              <span i18n="@@common.actions.exportAll">Export All</span>
            </div>
          </supy-dropdown-item>
          <supy-dropdown-item (click)="onExportSelected()" [disabled]="!isSelectedRows">
            <div class="supy-retailer-supplier-items__actions">
              <supy-icon name="document-download"></supy-icon>
              <span i18n="@@common.actions.exportSelected">Export Selected</span>
            </div>
          </supy-dropdown-item>
          <supy-dropdown-item (click)="onDelete()" [disabled]="!isSelectedRows">
            <div class="supy-retailer-supplier-items__actions">
              <supy-icon name="delete"></supy-icon>
              <span i18n="@@deleteSelected">Delete Selected</span>
            </div>
          </supy-dropdown-item>
        </supy-dropdown>

        <supy-button
          *ngIf="!isPriceHidden"
          name="save"
          color="primary"
          [disabled]="isSaveDisabled"
          (buttonClick)="onUpdate()"
          i18n="@@save"
          >Save</supy-button
        >
      </div>
    </div>
  </div>

  <supy-retailer-supplier-items-grid
    class="supy-retailer-supplier-items__grid"
    [data]="supplierItems()"
    [isLoading]="isLoading"
    [currency]="currency"
    [requestMetadata]="requestMetadata"
    [responseMetadata]="responseMetadata"
    [isPriceHidden]="isPriceHidden"
    [isPriceEditable]="!isIntegratedSupplier && !isCKSupplierV1 && !isCKSupplierV2"
    [currencyPrecision]="currencyPrecision"
    (pageChange)="onPageChange($event)"
  ></supy-retailer-supplier-items-grid>
</div>
