import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { fadeOut } from '@infragistics/igniteui-angular';
import { Currency } from '@supy.api/dictionaries';

import {
  ChannelState,
  CreateSupplierBFFRequest,
  FormComponent,
  InputValidators,
  PaymentMethod,
  PreferredType,
  SnackbarService,
  Supplier,
  SupplierChannelRequest,
  trackByProperty,
} from '@supy/common';
import { ComboboxComponent, InputComponent } from '@supy/components';
import { Country } from '@supy/countries';
import { DetailedRetailerSupplier } from '@supy/retailers';
import { TaxRate } from '@supy/settings';

import { BranchesWithActiveStatus } from '../supplier-branches-grid';
import { SupplierContactType } from '../supplier-contacts-grid';

export interface RetailerSupplierDetailsForm {
  readonly name: string;
  readonly displayName: string;
  readonly adHoc: boolean;
  readonly taxRegistrationNumber: string;
  readonly contactNumbers: string[];
  readonly emails: string[];
  readonly country: string;
  readonly supplier: string[];
  readonly comments: string;
  readonly paymentMethod: PaymentMethod;
  readonly cutOffTime: number;
  readonly creditPeriod: number;
  readonly defaultHandlingFeePercentage: number;
  readonly defaultTaxId: string;
  readonly enableWhatsApp: boolean;
  readonly enableEmail: boolean;
  readonly minimumOrderLimit: number;
  readonly branches: BranchesWithActiveStatus[];
}

@Component({
  selector: 'supy-retailer-supplier-details',
  templateUrl: './retailer-supplier-details.component.html',
  styleUrls: ['./retailer-supplier-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeOut', [
      transition('* => void', [
        useAnimation(fadeOut, {
          params: {
            duration: '.35s',
            easing: 'ease-out',
          },
        }),
      ]),
    ]),
  ],
})
export class RetailerSupplierDetailsComponent extends FormComponent<RetailerSupplierDetailsForm> implements OnInit {
  @ViewChild('supplierNameInput') private readonly supplierNameInput: InputComponent<string>;
  @ViewChild('supplierNameCombo') private readonly supplierNameCombo: ComboboxComponent<string>;

  readonly #snackbarService = inject(SnackbarService);
  readonly #injector = inject(Injector);

  @Input() readonly isLoading: boolean;
  @Input() readonly initialLoading: boolean;
  @Input() readonly taxes: TaxRate[];
  @Input() readonly countries: Country[];
  @Input() readonly currency: Currency;
  @Input() readonly currencyPrecision: number;
  @Input() readonly isLoadingSuppliers: boolean;
  @Input() readonly retailerId: string;
  @Input() readonly canCreateNewSupplier: boolean;
  @Input() readonly canUpdateChannel: boolean;
  @Input() readonly isAddingContactDisabled: boolean;
  @Input() readonly isSelectingBranchDisabled: boolean;
  @Input() readonly showPreferredColumn: boolean;

  @Input() set isCKSupplierV1(value: boolean) {
    this.isCKSupplierV1Enabled.set(value);
  }

  @Input() set isCKSupplierV2(value: boolean) {
    this.isCKSupplierV2Enabled.set(value);
  }

  @Input() set supplierId(value: string) {
    this.#supplierId.set(value);
  }

  @Input() set supplier(value: DetailedRetailerSupplier) {
    if (value) {
      this.supplierObject.set(value);

      this.setupSupplierData();
    }
  }

  @Input() set mappedBranches(value: BranchesWithActiveStatus[]) {
    if (value?.length && !this.getValue('branches')?.length) {
      this.form.patchValue({ branches: value });
    }
  }

  @Input() set supplierSearchResults(value: Supplier[]) {
    this.supplierList.set(value);
  }

  @Input() set hideTaxes(value: boolean) {
    if (value) {
      this.form.removeControl('defaultTaxId');
      this.isTaxesHidden.set(true);
    }
  }

  @Output() readonly searchSuppliersChange = new EventEmitter<string>();
  @Output() readonly createSupplier = new EventEmitter<CreateSupplierBFFRequest>();
  @Output() readonly updateSupplier = new EventEmitter<CreateSupplierBFFRequest>();
  @Output() readonly deleteSupplier = new EventEmitter<string>();

  protected readonly supplierSearchTerm = signal<string | null>(null);
  protected readonly isNewSupplier = signal<boolean>(false);
  protected readonly supplierList = signal<Supplier[]>([]);
  protected readonly supplierObject = signal<DetailedRetailerSupplier | null>(null);
  protected readonly isCKSupplierV1Enabled = signal<boolean>(false);
  protected readonly isCKSupplierV2Enabled = signal<boolean>(false);
  protected readonly isTaxesHidden = signal<boolean>(false);

  readonly #supplierId = signal<string | null>(null);

  protected readonly isExistingSupplier = computed(() => !!this.#supplierId());

  readonly #supplierListMap = computed(() => new Map(this.supplierList().map(supplier => [supplier.id, supplier])));

  protected readonly phoneValidators = [InputValidators.phone];
  protected readonly emailValidators = [InputValidators.email];

  protected readonly paymentMethods = PaymentMethod;
  protected readonly contactType = SupplierContactType;

  protected readonly trackTaxesById = trackByProperty<TaxRate>('id');

  get activeBranches(): BranchesWithActiveStatus[] {
    return this.getValue('branches')?.filter(branch => branch.active) || [];
  }

  get isFormValid(): boolean {
    return this.form.valid && this.activeBranches.length > 0;
  }

  get canSelectAllBranchesDisabled(): boolean {
    return this.activeBranches.length === this.getValue('branches')?.length;
  }

  get canDeselectAllBranchesDisabled(): boolean {
    return this.activeBranches.length === 0;
  }

  constructor() {
    super({
      supplier: [null, [Validators.required]],
      name: [null],
      displayName: [null, [Validators.required]],
      country: [
        {
          value: null,
          disabled: true,
        },
        [Validators.required],
      ],
      comments: [null],
      taxRegistrationNumber: [
        {
          value: null,
          disabled: true,
        },
      ],
      adHoc: [null],
      contactNumbers: [[]],
      emails: [[]],
      minimumOrderLimit: [null],
      paymentMethod: [null],
      cutOffTime: [null],
      creditPeriod: [0, [Validators.minLength(0), Validators.maxLength(3)]],
      defaultHandlingFeePercentage: [null],
      defaultTaxId: [null],
      enableEmail: [false],
      enableWhatsApp: [false],
      branches: [[]],
    });
  }

  ngOnInit(): void {
    this.listenForDefaultPreferencesChanges();

    effect(
      () => {
        if (!this.isExistingSupplier()) {
          this.subscribeToSupplierNameChange();
        }
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  protected onBranchesChange(): void {
    const branches = this.getValue('branches');

    if (branches?.length) {
      const uniqueContacts = [...new Set([...branches.flatMap(branch => branch.contactNumbers)])] as string[];

      this.reconfigure('contactNumbers', { value: uniqueContacts });

      const uniqueEmails = [...new Set([...branches.flatMap(branch => branch.emails)])] as string[];

      this.reconfigure('emails', { value: uniqueEmails });
    }
  }

  protected createNewSupplierClicked(focusNameInput = true): void {
    if (this.supplierSearchTerm()?.length) {
      this.reconfigure('name', { value: this.supplierSearchTerm() });
    }

    this.isNewSupplier.set(true);

    this.getControl('country').enable();
    this.getControl('taxRegistrationNumber').enable();

    this.reconfigure('name', {
      validators: [Validators.required],
    });

    this.form.patchValue({
      displayName: null,
      country: null,
      adHoc: null,
      taxRegistrationNumber: null,
    });

    setTimeout(() => {
      if (focusNameInput) {
        this.supplierNameInput?.focus();
      }

      this.getControl('supplier').removeValidators([Validators.required]);
      this.getControl('supplier').updateValueAndValidity();
    }, 100);
  }

  protected searchSupplierClicked(): void {
    this.getControl('name').reset();
    this.getControl('name').removeValidators([Validators.required]);
    this.getControl('name').updateValueAndValidity();

    this.getControl('country').disable();
    this.getControl('taxRegistrationNumber').disable();

    this.supplierSearchTerm.set(null);

    this.isNewSupplier.set(false);

    setTimeout(() => {
      this.getControl('supplier').addValidators([Validators.required]);
      this.getControl('supplier').updateValueAndValidity();

      this.supplierNameCombo?.open();
    }, 100);
  }

  protected onSupplierChanged(supplierId: string[]): void {
    const supplier = this.#supplierListMap().get(supplierId[0]);

    if (supplier?.isExposed && supplier?.metadata?.retailerId !== this.retailerId) {
      this.#snackbarService.open(
        `${supplier.name} is utilizing Supy's central kitchen. Please ensure you contact them to establish your customer account before creating any items.`,
        { variant: 'error' },
      );
    }

    this.form.patchValue({
      country: supplier?.countryId ?? null,
      displayName: supplier?.name ?? null,
      taxRegistrationNumber: supplier?.metadata?.taxRegistrationNumber ?? null,
      adHoc: supplier?.metadata?.adHoc ?? null,
    });

    this.form.updateValueAndValidity();
  }

  protected onCreditPeriodChange(value: number): void {
    this.reconfigure('creditPeriod', { value });
  }

  protected onSearchSuppliers(term: string): void {
    if (!term.trim().length) {
      return;
    }

    this.searchSuppliersChange.emit(term);
  }

  protected onAddContact(contact: string, type: SupplierContactType): void {
    const controlName = type === SupplierContactType.WhatsApp ? 'contactNumbers' : 'emails';

    const updateBranches = this.getValue('branches')?.map(branch => ({
      ...branch,
      [controlName]: [...new Set([...(branch[controlName] ?? []), contact])],
    }));

    this.reconfigure('branches', { value: updateBranches });

    this.reconfigure(controlName, { value: [...new Set([...(this.getValue(controlName) ?? []), contact])] });
  }

  protected onRemoveContact(contact: string, type: SupplierContactType): void {
    const controlName = type === SupplierContactType.WhatsApp ? 'contactNumbers' : 'emails';

    const updateBranches = this.getValue('branches')?.map(branch => ({
      ...branch,
      [controlName]: branch[controlName]?.filter(c => c !== contact),
    }));

    this.reconfigure('branches', { value: updateBranches });

    this.reconfigure(controlName, { value: this.getValue(controlName)?.filter(c => c !== contact) });
  }

  protected onSubmit(): void {
    const { comments, supplier, adHoc, country, name, taxRegistrationNumber, displayName } =
      this.form.getRawValue() as RetailerSupplierDetailsForm;

    const searchSupplier = this.#supplierListMap().get(supplier?.at(0) as string);
    const supplierName = this.isNewSupplier() || this.isExistingSupplier() ? name : searchSupplier?.name;

    let requestPayload: CreateSupplierBFFRequest = {
      name: {
        en: supplierName as string,
      },

      country: { id: country.toString() },
      displayName: displayName ?? supplierName,
      comments,
      channels: [],
      metadata: {
        taxRegistrationNumber,
        adHoc,
      },
    };

    if (!this.isNewSupplier() && !this.isExistingSupplier()) {
      requestPayload = {
        ...requestPayload,
        supplier: {
          id: supplier?.toString() ?? null,
        },
      };
    }

    if (this.isExistingSupplier()) {
      const channels = this.getValue('branches')?.reduce((acc, branch) => {
        if (branch.id || branch.active) {
          const { name, active, retailerId, preferred, state, ...rest } = branch;

          const newState = active ? ChannelState.active : ChannelState.deleted;

          acc.push({ ...rest, state: newState });
        }

        return acc;
      }, [] as SupplierChannelRequest[]) as SupplierChannelRequest[];

      requestPayload = {
        ...requestPayload,
        channels,
      };

      this.updateSupplier.emit(requestPayload);
    } else {
      const channels = this.activeBranches.map(({ active, name, retailerId, state, id, preferred, ...rest }) => ({
        ...rest,
      })) as SupplierChannelRequest[];

      requestPayload = {
        ...requestPayload,
        channels,
        retailer: { id: this.retailerId },
      };

      this.createSupplier.emit(requestPayload);
    }
  }

  protected onDeleteSupplier(): void {
    this.deleteSupplier.emit(this.getValue('displayName') as string);
  }

  protected setAllBranches(active: boolean): void {
    const updatedBranches = this.getValue('branches')?.map(branch => ({
      ...branch,
      active,
      state: active ? ChannelState.active : ChannelState.deleted,
    }));

    this.reconfigure('branches', { value: updatedBranches });
  }

  private listenForDefaultPreferencesChanges() {
    let arrayOfDefaultPreferences = [
      'minimumOrderLimit',
      'paymentMethod',
      'cutOffTime',
      'creditPeriod',
      'defaultHandlingFeePercentage',
    ];

    if (!this.isTaxesHidden()) {
      arrayOfDefaultPreferences = [...arrayOfDefaultPreferences, 'defaultTaxId'];
    }

    arrayOfDefaultPreferences.forEach(preference => {
      this.getValueChanges(preference as keyof RetailerSupplierDetailsForm)
        .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
        .subscribe(value => this.updateBranchesValue(preference, value as string | number));
    });
  }

  private updateBranchesValue(field: string, value: string | number | null): void {
    const updatedBranches = this.getValue('branches')?.map(branch => {
      let updatedBranch = { ...branch } as BranchesWithActiveStatus;

      updatedBranch = {
        ...updatedBranch,
        [field]: value,
      };

      return updatedBranch;
    });

    this.reconfigure('branches', { value: updatedBranches });
  }

  private subscribeToSupplierNameChange() {
    this.getValueChanges('name')
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this.destroyed$))
      .subscribe(value => {
        this.reconfigure('displayName', { value });
      });
  }

  private mapContactNumbers(): void {
    const uniqueContactNumbers = [
      ...new Set([
        ...(this.getValue('contactNumbers') ?? []),
        ...(this.getValue('branches') ?? []).flatMap(branch => branch.contactNumbers ?? []),
      ]),
    ];

    this.reconfigure('contactNumbers', { value: uniqueContactNumbers });
  }

  private mapEmails(): void {
    const uniqueEmails = [
      ...new Set([
        ...(this.getValue('branches') ?? []).flatMap(branch => branch.emails ?? []),
        ...(this.getValue('emails') ?? []),
      ]),
    ];

    this.reconfigure('emails', { value: uniqueEmails });
  }

  private setupSupplierData(): void {
    effect(
      () => {
        const supplier = this.supplierObject();
        const isExistingSupplier = this.isExistingSupplier();

        if (!supplier || !isExistingSupplier) {
          return;
        }

        const noEmails = supplier?.activeChannels.every(channel => !channel.emails?.length);

        const noContactNumbers = supplier?.activeChannels.every(channel => !channel.contactNumbers?.length);

        this.form.patchValue({
          name: supplier.name,
          displayName: supplier.displayName ?? supplier.name ?? null,
          country: supplier.countryId ?? null,
          taxRegistrationNumber: supplier.metadata?.taxRegistrationNumber ?? null,
          adHoc: supplier.metadata?.adHoc ?? null,
          enableEmail: !noEmails,
          enableWhatsApp: !noContactNumbers,
          defaultTaxId: supplier.activeChannels.at(0)?.metadata?.defaultTaxId ?? null,
        });

        this.getControl('name').disable();

        this.getControl('supplier').disable();

        if (this.isCKSupplierV2Enabled()) {
          const controlsToDisable = [
            'comments',
            'minimumOrderLimit',
            'paymentMethod',
            'cutOffTime',
            'creditPeriod',
            'defaultHandlingFeePercentage',
            'defaultTaxId',
          ] as const;

          controlsToDisable.forEach(control => {
            this.getControl(control).disable();
          });
        }

        this.form.updateValueAndValidity();

        if (!this.getValue('branches')?.length) {
          return;
        }

        const mappedBranches = this.getValue('branches')
          ?.map(branch => {
            const supplierBranch = supplier.activeChannels.find(channel => channel.retailerId === branch.branch.id);

            if (supplierBranch) {
              return {
                ...branch,
                id: supplierBranch.id,
                paymentMethod: supplierBranch.metadata?.paymentMethod ?? null,
                minimumOrderLimit: supplierBranch.metadata?.minimumOrderLimit ?? null,
                cutOffTime: supplierBranch.metadata?.cutOffTime ?? null,
                creditPeriod: supplierBranch.metadata?.creditPeriod ?? null,
                defaultHandlingFeePercentage: supplierBranch.metadata?.defaultHandlingFeePercentage ?? null,
                contactNumbers: supplierBranch.contactNumbers ?? [],
                emails: supplierBranch.emails ?? [],
                preferredType: supplierBranch.preferredType,
                preferred: supplierBranch.preferredType === PreferredType.RevenueSharing,
                active: true,
              };
            }

            return { ...branch, active: false };
          })
          .filter(
            branch => branch.branch.id && branch.branch.id !== supplier.metadata?.warehouseId,
          ) as BranchesWithActiveStatus[];

        this.reconfigure('branches', { value: mappedBranches });

        this.mapContactNumbers();
        this.mapEmails();
      },
      { injector: this.#injector, allowSignalWrites: true },
    );
  }
}
