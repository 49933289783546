import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconType } from '../../../icon';
import { Tag, TagColor, TagSize, TagsModule } from '../../../tag';

export interface TextWithTagCellRendererContext<T> {
  readonly tag: Tag<T>;
  readonly text: string;
  readonly color?: TagColor;
  readonly size?: TagSize;
  readonly removable?: boolean;
  readonly customIcon?: IconType;
}

@Component({
  selector: 'supy-text-with-tag-cell-renderer',
  standalone: true,
  imports: [CommonModule, TagsModule],
  template: ` <div class="supy-text-with-tag-cell-renderer__cell">
    <span class="supy-text-with-tag-cell-renderer__text">{{ context?.text }}</span>
    <supy-tag
      [tag]="context?.tag"
      [color]="context?.color"
      [size]="context?.size"
      [removable]="context?.removable"
      [customIcon]="context?.customIcon"
    ></supy-tag>
  </div>`,
  styleUrls: ['./text-with-tag-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextWithTagCellRendererComponent<T> implements ICellRendererAngularComp {
  protected context: TextWithTagCellRendererContext<T>;

  agInit(params: ICellRendererParams<unknown, unknown, TextWithTagCellRendererContext<T>>): void {
    this.context = params.context;
  }

  refresh(): boolean {
    return false;
  }
}
