import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxExpansionPanelModule, IgxIconModule } from '@infragistics/igniteui-angular';

import { ExpansionPanelComponent } from './components';

@NgModule({
  declarations: [ExpansionPanelComponent],
  imports: [CommonModule, IgxExpansionPanelModule, IgxIconModule],
  exports: [ExpansionPanelComponent],
})
export class ExpansionPanelModule {}
