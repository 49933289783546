import '@angular/localize/init';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { InputModule } from '../input';
import { LoadingOverlayModule } from '../loading-overlay';
import { NoteAdderComponent } from './components';

@NgModule({
  declarations: [NoteAdderComponent],
  imports: [CommonModule, ButtonModule, IconModule, InputModule, LoadingOverlayModule, ReactiveFormsModule],
  exports: [NoteAdderComponent],
})
export class NoteAdderModule {}
