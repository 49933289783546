import { IgxCategoryChartModule, IgxLegendModule } from 'igniteui-angular-charts';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ColumnChartComponent } from './components';

@NgModule({
  imports: [CommonModule, IgxLegendModule, IgxCategoryChartModule],
  declarations: [ColumnChartComponent],
  exports: [ColumnChartComponent],
})
export class ColumnChartModule {}
