<div class="supy-maintenance-dialog">
  <supy-dialog
    position="center"
    initialState="open"
    overlayClass="supy-maintenance-dialog-overlay"
    [width]="width"
    [offsetTop]="offsetTop"
    [closeOnOutsideClick]="false"
    [closeOnEscape]="false"
    (dialogClosed)="dialogClosed.emit($event)"
    (dialogClosing)="dialogClosing.emit($event)"
    (dialogOpened)="dialogOpened.emit($event)"
    (dialogOpening)="dialogOpening.emit($event)"
  >
    <supy-dialog-header>
      <div class="supy-maintenance-dialog__header-wrapper">
        <supy-icon class="supy-maintenance-dialog__header-icon" name="exclamation-hex" color="warn"></supy-icon>
        <ng-container>
          <h3 class="supy-maintenance-dialog__content">Undergoing Maintenance</h3>
        </ng-container>
      </div>
    </supy-dialog-header>
    <supy-dialog-content>
      <p>
        This page is under maintenance from
        <strong>
          {{ startDate | timezone: ianaTimeZone | date: 'shortTime' }}
        </strong>
        <strong>
          {{ startDate | timezone: ianaTimeZone | date }}
        </strong>
        to
        <strong>
          {{ endDate | timezone: ianaTimeZone | date: 'shortTime' }}
        </strong>
        <strong>
          {{ endDate | timezone: ianaTimeZone | date }}
        </strong>
      </p>
      <br />

      <p *ngIf="message?.en || message?.ar; else defaultMessage">{{ message.en ? message.en : message.ar }}</p>
      <ng-template #defaultMessage>
        <p>
          We apologize for the inconvenience and hope to come back as soon as possible. Contact Support for further
          Assistance
        </p>
      </ng-template>
    </supy-dialog-content>
    <supy-dialog-footer class="supy-maintenance-dialog__footer">
      <div class="supy-maintenance-dialog__footer-buttons">
        <supy-button (buttonClick)="onContactSupport()" color="primary" name="contact"> Contact Support </supy-button>
      </div>
    </supy-dialog-footer>
  </supy-dialog>
</div>
