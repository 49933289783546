import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxChipsModule } from '@infragistics/igniteui-angular';

import { IconModule } from './../icon';
import { ChipComponent } from './components';

@NgModule({
  declarations: [ChipComponent],
  imports: [CommonModule, IgxChipsModule, IconModule],
  exports: [ChipComponent],
})
export class ChipModule {}
