import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogModule } from '../../../dialog';
import { IconModule } from '../../../icon';
import { ChartCoreModule } from '../chart-core';
import { ExpandedTreemapComponent, TreemapComponent } from './components';

@NgModule({
  declarations: [TreemapComponent, ExpandedTreemapComponent],
  imports: [CommonModule, ChartCoreModule, DialogModule, IconModule],
  exports: [TreemapComponent, ExpandedTreemapComponent, ChartCoreModule],
})
export class TreemapModule {}
