import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { ChannelItemsState } from './store';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([ChannelItemsState])],
  providers: [],
})
export class ChannelItemsModule {}
