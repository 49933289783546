import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { AvatarModule, BreadcrumbModule, TabsModule } from '@supy/components';

import { RetailerHeaderComponent } from './components';
import {
  BranchResolver,
  BranchSuppliersResolver,
  BranchUsersResolver,
  OutletResolver,
  RetailerResolver,
  RetailerUsersResolver,
  UserBranchesResolver,
  UserWithBranchesResolver,
} from './resolvers';
import { BranchesService, RetailersService } from './services';
import { RetailerSupplierItemsState, RetailerSuppliersState } from './store';

@NgModule({
  declarations: [RetailerHeaderComponent],
  imports: [
    CommonModule,
    AvatarModule,
    TabsModule,
    BreadcrumbModule,
    NgxsModule.forFeature([RetailerSuppliersState, RetailerSupplierItemsState]),
  ],
  providers: [
    RetailerResolver,
    RetailersService,
    BranchesService,
    BranchResolver,
    OutletResolver,
    UserBranchesResolver,
    BranchUsersResolver,
    BranchSuppliersResolver,
    RetailerUsersResolver,
    UserWithBranchesResolver,
  ],
  exports: [RetailerHeaderComponent],
})
export class RetailersModule {}
