import { map, Observable, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { Query, QueryPaging } from '@supy/common';
import { CurrentRetailerState } from '@supy/retailers';
import { GetCurrentUser } from '@supy/users';

import { Channel } from '../core';
import { ChannelsService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UserRetailerBranchesChannelsResolver {
  constructor(
    private readonly channelsService: ChannelsService,
    private readonly store: Store,
  ) {}

  resolve(): Observable<Channel[]> {
    return this.store.dispatch(new GetCurrentUser()).pipe(
      map(() => this.store.selectSnapshot(CurrentRetailerState.branches)),
      switchMap(branches => {
        if (branches?.length) {
          const query = new Query<Channel>({
            paging: QueryPaging.NoLimit,
            filtering: [{ by: 'retailerId', op: 'in', match: branches.map(({ id }) => id) }],
          });

          return this.channelsService.getChannels(query).pipe(map(({ data }) => data));
        }

        return of([] as Channel[]);
      }),
    );
  }
}
