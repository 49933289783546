import { isEqual } from 'lodash-es';
import { takeUntil } from 'rxjs';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { DEFAULT_QUANTITY_PRECISION, Destroyable, Uom } from '@supy/common';

import {
  InventoryIngredientNormalized,
  InventoryRecipe,
  InventoryRecipeStateEnum,
  InventoryRecipeType,
} from '../../core';

export interface RecipePortionForm {
  readonly size?: number;
  readonly yield?: number;
  readonly uom?: Uom;
  readonly isStockable?: boolean;
}

@Component({
  selector: 'supy-recipe-portion-form',
  templateUrl: './recipe-portion-form.component.html',
  styleUrls: ['./recipe-portion-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecipePortionFormComponent),
      multi: true,
    },
  ],
})
export class RecipePortionFormComponent<T> extends Destroyable implements OnInit, OnChanges, ControlValueAccessor {
  @Input() readonly pieceUom: Uom;
  @Input() readonly kgUom: Uom;
  @Input() readonly baseUoms: Uom[];
  @Input() readonly ingredients: InventoryIngredientNormalized[] = [];
  @Input() readonly recipe: InventoryRecipe;
  @Input() readonly recipeType: InventoryRecipeType;
  @Input() readonly isReadonly: boolean;
  @Input() readonly uom: Uom;
  @Input() readonly canStock: boolean;
  @Input() readonly isStockable: boolean;

  protected readonly form = new FormGroup({
    size: new FormControl<number | null>(null, { validators: Validators.required }),
    uom: new FormControl<Uom | null>(null, { validators: Validators.required }),
    yield: new FormControl<number | null>(null),
    isStockable: new FormControl<boolean>(false),
  });

  onTouched: () => void;
  onChange: (value: T) => void;

  get portionSizeValue(): number {
    return this.form.getRawValue().size;
  }

  get portionUomValue(): Uom {
    return this.form.getRawValue().uom;
  }

  get portionYieldValue(): number {
    return this.form.getRawValue().yield;
  }

  get ingredientsQuantityPerAtom(): number {
    return this.ingredients?.reduce((acc, cur) => {
      const usedAsPiecePackaging = cur.item?.packagings?.find(({ isPiece }) => isPiece);
      const usedAsPieceToAtomUom =
        cur?.packagingUnit?.isPiece && usedAsPiecePackaging ? usedAsPiecePackaging?.toAtomUom ?? 1 : null;

      return (
        acc +
        (cur?.quantityNet ?? 0) * (usedAsPieceToAtomUom ? usedAsPieceToAtomUom : cur?.packagingUnit?.toAtomUom ?? 0)
      );
    }, 0);
  }

  ngOnInit(): void {
    this.initFormData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.baseUoms.length > 0 && (this.recipeType || this.recipe)) {
      this.handleRecipeType();
    }

    if (changes.recipe && changes.recipe.isFirstChange()) {
      this.initRecipeData();
    }

    if (
      (changes.ingredients.previousValue as InventoryIngredientNormalized[])?.length &&
      !isEqual(changes.ingredients.previousValue, changes.ingredients.currentValue) &&
      this.recipeType !== InventoryRecipeType.Finished
    ) {
      setTimeout(() => this.form.patchValue({ size: null, yield: null }));
    }

    if (this.isStockable) {
      this.form.controls.isStockable.setValue(true);
      this.form.controls.isStockable.disable();
    }
  }

  initRecipeData(): void {
    setTimeout(() => {
      this.form.patchValue({
        size: this.recipe.portionSize.size,
        uom: this.uom,
      });
      this.setComputedYieldValue();

      if (this.recipe.state === InventoryRecipeStateEnum.Archived) {
        this.form.disable();
      }
    });
  }

  initFormData(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value: T) => {
      this.onChange?.(value);
    });
  }

  private handleRecipeType(): void {
    if (this.recipeType === InventoryRecipeType.Finished) {
      this.form.get('size').setValue(1);
      this.form.disable();
    } else {
      this.form.get('size').setValue(null);
      this.form.enable();
    }

    setTimeout(() => {
      if (this.recipe) {
        this.form.get('uom').disable();
      } else {
        this.form.get('uom').setValue(this.recipeType === InventoryRecipeType.Finished ? this.pieceUom : this.kgUom);
      }
    });
  }

  setComputedYieldValue(value?: number): void {
    let yieldValue: number;

    if (Number.isFinite(value)) {
      yieldValue = value;
    } else if (this.ingredientsQuantityPerAtom && this.portionSizeValue && this.portionUomValue) {
      yieldValue = Number(
        (
          (100 * this.portionSizeValue * this.portionUomValue.conversionToAtom) /
          this.ingredientsQuantityPerAtom
        ).toFixed(2),
      );
    } else {
      return;
    }
    this.form.get('yield').setValue(yieldValue);
  }

  setComputedSizeValue(): void {
    let sizeValue: number;

    if (this.recipeType === InventoryRecipeType.Finished) {
      sizeValue = 1;
    } else if (this.ingredientsQuantityPerAtom && this.portionYieldValue && this.portionUomValue) {
      sizeValue = Number(
        (
          (this.portionYieldValue * this.ingredientsQuantityPerAtom) /
          (this.portionUomValue.conversionToAtom * 100)
        ).toFixed(DEFAULT_QUANTITY_PRECISION),
      );
    } else {
      return;
    }

    this.form.get('size').setValue(sizeValue);
  }

  onFocusIn(): void {
    if (!this.portionYieldValue && !this.portionSizeValue && this.ingredientsQuantityPerAtom > 0) {
      this.setComputedYieldValue(100);
      this.setComputedSizeValue();
    }
  }

  writeValue(value: T): void {
    if (value) {
      this.form.patchValue(value);
    }
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
}
