import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IQueryResponse, Query } from '@supy/common';

import { CHANNEL_ITEMS_BFF_URI, CHANNEL_ITEMS_URI } from '../config';
import {
  CatalogChannelItemApiProps,
  CatalogGroupedChannelItem,
  ChannelItem,
  ChannelItemsPerPackaging,
  ChannelItemsProps,
  ChannelItemState,
  CheckPreferredSiblingsRequest,
  CreateChannelItemRequest,
  CreateChannelItemsRequest,
  CreateChannelItemsResponse,
  GetCatalogGroupedChannelItemsRequest,
  InventoryChannelItem,
  InventoryChannelItemApi,
  UpdateChannelItemRequest,
  UpdateChannelItemsRequest,
} from '../core';

@Injectable({
  providedIn: 'root',
})
export class ChannelItemsService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(CHANNEL_ITEMS_URI) private readonly uri: string,
    @Inject(CHANNEL_ITEMS_BFF_URI) private readonly uriBff: string,
  ) {}

  createChannelItems(body: CreateChannelItemsRequest): Observable<CreateChannelItemsResponse> {
    return this.httpClient.post<CreateChannelItemsResponse>(`${this.uri}/batch`, body);
  }

  createChannelItem(body: CreateChannelItemRequest): Observable<ChannelItem> {
    return this.httpClient.post<ChannelItem>(this.uri, body);
  }

  updateChannelItem(body: UpdateChannelItemRequest, itemId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${itemId}`, body);
  }

  updateChannelItemInAllBranches(body: UpdateChannelItemRequest, itemId: string): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/${itemId}/related`, body);
  }

  updateChannelItems(body: UpdateChannelItemsRequest): Observable<void> {
    return this.httpClient.patch<void>(`${this.uri}/batch`, body);
  }

  deleteChannelItem(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/${id}`);
  }

  deleteChannelItems(channelItemIds: string[]): Observable<void> {
    // TODO: use query params after BE is updated
    return this.httpClient.delete<void>(`${this.uri}/batch`, { body: channelItemIds });
  }

  deleteChannelItemsBff(channelItemsIds: string[]): Observable<void> {
    // TODO: use query params after BE is updated
    return this.httpClient.delete<void>(`${this.uriBff}/batch`, { body: { channelItemsIds } });
  }

  deleteAllChannelItems(channelId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.uri}/batch/${channelId}`);
  }

  getChannelItems(query: Query<ChannelItem & ChannelItemsProps>): Observable<IQueryResponse<ChannelItem>> {
    return this.httpClient.get<IQueryResponse<ChannelItem>>(this.uri, {
      params: query.toQueryParams(),
      headers: new HttpHeaders({
        'X-Version': '1',
      }),
    });
  }

  checkPreferredSiblingsBff(body: CheckPreferredSiblingsRequest): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.uriBff}/sibling/check`, body);
  }

  getChannelItemsBff(
    query: Query<ChannelItem & CatalogChannelItemApiProps>,
  ): Observable<IQueryResponse<InventoryChannelItemApi>> {
    query.filtering.setFilter({ by: 'state', match: ChannelItemState.Available, op: 'eq' });

    return this.httpClient.get<IQueryResponse<InventoryChannelItemApi>>(this.uriBff, { params: query.toQueryParams() });
  }

  getGroupedChannelItemsBff(query: Query<ChannelItemsProps>): Observable<IQueryResponse<ChannelItemsPerPackaging>> {
    return this.httpClient.get<IQueryResponse<ChannelItemsPerPackaging>>(`${this.uriBff}/grouped/packaging`, {
      params: query.toQueryParams(),
    });
  }

  getCatalogGroupedChannelItemsBff(
    body: GetCatalogGroupedChannelItemsRequest,
  ): Observable<IQueryResponse<CatalogGroupedChannelItem>> {
    return this.httpClient.post<IQueryResponse<CatalogGroupedChannelItem>>(`${this.uriBff}/grouped`, body);
  }

  getFillableChannelItemsBff(
    query: Query<ChannelItem & CatalogChannelItemApiProps>,
  ): Observable<IQueryResponse<InventoryChannelItem>> {
    return this.httpClient.get<IQueryResponse<InventoryChannelItem>>(`${this.uriBff}/fillable`, {
      params: query.toQueryParams(),
    });
  }
}
