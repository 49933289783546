import {
  EventTime,
  IdType,
  IQueryResponse,
  LocalizedData,
  LocalizedSkeletonObjectType,
  SimpleUser,
  Uom,
} from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { PackagingUnit } from '@supy/packaging';

export const EventTimeMap: Record<EventTime, string> = {
  [EventTime.StartOfDay]: 'SOD',
  [EventTime.EndOfDay]: 'EOD',
};

export enum EventState {
  Archived = 'archived',
  Submitted = 'submitted',
  Draft = 'draft',
}

export enum InventoryStockCountScopeTypeEnum {
  Item = 'item',
  Recipe = 'recipe',
}

export class InventoryStockCountScope {
  readonly type: InventoryStockCountScopeTypeEnum;
  readonly referenceId: string;

  constructor(args: NonFunctionProperties<InventoryStockCountScope>) {
    this.type = args.type;
    this.referenceId = args.referenceId;
  }

  static default(): InventoryStockCountScope {
    return new InventoryStockCountScope({
      referenceId: crypto.randomUUID(),
      type: InventoryStockCountScopeTypeEnum.Item,
    });
  }
}

export class InventoryEventDate {
  readonly creationDate: Date;
  readonly time: EventTime;
}

export class InventoryStockCountPackaging {
  readonly id: string; // added on FE
  readonly name: string; // added on FE
  readonly level: number; // added on FE
  readonly unit: PackagingUnit;
  readonly partialCount: number;
  readonly totalCount: number;
}

export class BaseInventoryStockCount {
  readonly id: string;
  readonly state: EventState;
  readonly createdBy?: SimpleUser;
  readonly updatedBy?: SimpleUser;
  readonly createdAt?: Date;
  readonly updatedAt?: Date;
}

export class InventoryStockCountItem {
  readonly id: string;
  readonly scope: InventoryStockCountScope;
  readonly name: LocalizedData;
  readonly baseUnit: Uom;
  readonly onHandQuantity: number;
  readonly onHandValue: number;
  readonly countedQuantity: number;
  readonly countedValue: number;
  readonly code?: string;
  readonly category: LocalizedSkeletonObjectType;
  readonly packagings: InventoryStockCountPackaging[];

  constructor(args: NonFunctionProperties<InventoryStockCountItem>) {
    this.id = args.id;
    this.scope = args.scope;
    this.name = args.name;
    this.baseUnit = args.baseUnit;
    this.onHandQuantity = args.onHandQuantity;
    this.onHandValue = args.onHandValue;
    this.countedQuantity = args.countedQuantity;
    this.countedValue = args.countedValue;
    this.code = args.code;
    this.category = args.category;
    this.packagings = args.packagings;
  }

  static default(): InventoryStockCountItem {
    return new InventoryStockCountItem({
      id: crypto.randomUUID(),
      scope: InventoryStockCountScope.default(),
      name: LocalizedData.default(),
      baseUnit: Uom.default(),
      onHandQuantity: 0,
      onHandValue: 0,
      countedQuantity: 0,
      countedValue: 0,
      code: null,
      category: LocalizedSkeletonObjectType.default(),
      packagings: [],
    });
  }
}

export class InventoryStockCountGridItem extends InventoryStockCountItem {
  readonly isSeparator: boolean;

  constructor(args: NonFunctionProperties<InventoryStockCountGridItem>) {
    super(args);
    this.isSeparator = args.isSeparator;
  }

  static default(): InventoryStockCountGridItem {
    return new InventoryStockCountGridItem({ ...super.default(), isSeparator: false });
  }

  static createSeparator(args: Partial<InventoryStockCountGridItem> = {}): InventoryStockCountGridItem {
    return new InventoryStockCountGridItem({ ...super.default(), ...args, isSeparator: true });
  }

  static fromItem(item: InventoryStockCountItem): InventoryStockCountGridItem {
    return new InventoryStockCountGridItem({ ...item, isSeparator: false });
  }

  static fromItemList(items: InventoryStockCountItem[]): InventoryStockCountGridItem[] {
    return items.map(item => InventoryStockCountGridItem.fromItem(item));
  }

  getCompoundIds(): string[] {
    return this.packagings.map(({ unit }) => `${this.scope.referenceId}_${unit.packagingId ?? unit.uomId}`);
  }
}

export class InventorySubStockCountSnapShot {
  readonly id: string;
  readonly name: string;
  readonly stockValue: number;
  readonly state: EventState;
  readonly itemsNumber: number;
}

export class InventoryStockCount extends BaseInventoryStockCount {
  readonly retailer: IdType;
  readonly location: IdType;
  readonly eventDate: InventoryEventDate;
  readonly subStockCounts: InventorySubStockCountSnapShot[];
  readonly subStockCount: InventorySubStockCountSnapShot;
  readonly isOpening?: boolean;
}

export interface GetStockCountItemsResponse {
  readonly id: string;
  readonly state: EventState;
  readonly eventDate: InventoryEventDate;
  readonly isOpening: boolean;
  readonly retailerId: string;
  readonly location: IdType;
  readonly items: IQueryResponse<InventoryStockCountItem>;
}

interface StockCountSnapshot {
  readonly id: string;
  readonly state: EventState;
  readonly eventDate: Date;
  readonly isOpening: boolean;
}

export interface GetSubStockCountItemsResponse {
  readonly id: string;
  readonly name?: string;
  readonly retailerId: string;
  readonly stockCount: StockCountSnapshot;
  readonly location: IdType;
  readonly items: IQueryResponse<InventoryStockCountItem>;
}

export interface GetCommonStockCountDatesRequest {
  readonly locations: IdType[];
}

export interface DateGroupedStockCounts {
  readonly eventDate: Date;
  readonly isOpening?: boolean;
}
export interface GetCommonStockCountDatesOutput {
  readonly allCountDates: DateGroupedStockCounts[];
  readonly someCountDates: DateGroupedStockCounts[];
}

export interface GetFirstEventDateResponse {
  readonly firstEventDate: Date;
}

export interface GetLastOpeningCountDateResponse {
  readonly lastOpeningCountDate: Date;
}
