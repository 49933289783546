import { Injectable, Signal, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  get toggleNotificationDrawer(): Signal<boolean> {
    return this.#toggleNotificationDrawer.asReadonly();
  }

  get toggleUserSettingsDrawer(): Signal<boolean> {
    return this.#toggleUserSettings.asReadonly();
  }

  get hasNewNotifications(): Signal<boolean> {
    return this.#hasNewNotifications.asReadonly();
  }

  readonly #toggleNotificationDrawer = signal(false);
  readonly #toggleUserSettings = signal(false);
  readonly #hasNewNotifications = signal(false);

  showNotificationDrawer(): void {
    this.#toggleNotificationDrawer.set(true);
  }

  showUserSettingsDrawer(): void {
    this.#toggleUserSettings.set(true);
  }

  hideUserSettingsDrawer(): void {
    this.#toggleUserSettings.set(false);
  }

  hideNotificationDrawer(): void {
    this.#toggleNotificationDrawer.set(false);
  }

  setHasNewNotifications(hasNew: boolean): void {
    this.#hasNewNotifications.set(hasNew);
  }
}
