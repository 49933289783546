import { catchError, EMPTY, finalize, first, map, Observable, switchMap, takeUntil, tap } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Injector,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { GridPagingMode } from '@infragistics/igniteui-angular';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { actionsExecuting } from '@ngxs-labs/actions-executing';

import {
  CatalogChannelItemApiProps,
  ChannelItem,
  ChannelItemsGetFillableItems,
  ChannelItemsService,
  ChannelItemsState,
  ChannelItemState,
  InventoryChannelItem,
} from '@supy/channel-items';
import { Channel, ChannelsService } from '@supy/channels';
import {
  FormComponent,
  Query,
  QueryBuilder,
  QueryPaging,
  SnackbarService,
  SupplierType,
  ToggleFeature,
} from '@supy/common';
import { FilterChange, FilterGroup } from '@supy/components';
import { MixpanelService } from '@supy/mixpanel';
import { Span } from '@supy/opentelemetry';
import {
  Order,
  OrdersGet,
  OrdersReset,
  OrdersSetSequence,
  OrdersState,
  OrderStatus,
  OrdersUpdate,
  OrderUpdateStatus,
  trackOrderAnalytics,
  UpdateOrderRequest,
} from '@supy/orders';
import { RetailerItemCategoriesState } from '@supy/retailer-item-categories';
import { CurrentRetailerState } from '@supy/retailers';
import { getLocalizedName, SettingsState } from '@supy/settings';

import { getFillToParBaseQuantity } from '../../helpers';
import { PlaceOrderService } from '../../services';
import { getPlaceOrderChannelItemsFiltersConfig, PlaceOrderChannelItemsMappedFilters } from './filters-config';

interface FormValue {
  readonly search: string;
  readonly categories: string[];
}

@Component({
  selector: 'supy-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceOrderComponent extends FormComponent<FormValue> implements OnInit, OnDestroy {
  readonly #placeOrderService = inject(PlaceOrderService);
  readonly #channelItemsService = inject(ChannelItemsService);
  readonly #mixPanel = inject(MixpanelService);
  readonly #store = inject(Store);
  readonly #injector = inject(Injector);
  readonly #route = inject(ActivatedRoute);
  readonly #snackbar = inject(SnackbarService);
  readonly #routeData = toSignal(this.#route.data);
  readonly #routeParamMap = toSignal(this.#route.paramMap);
  readonly #routeQueryParamMap = toSignal(this.#route.queryParamMap);
  readonly #channelId = computed(() => this.#routeParamMap().get('channelId'));
  readonly #orderId = computed(() => this.#routeQueryParamMap().get('orderId'));
  protected readonly order = toSignal(this.#store.select(OrdersState.currentOrder));
  protected readonly categories = toSignal(
    this.#store.select(RetailerItemCategoriesState.getRetailerItemCategoriesTree),
  );

  protected readonly channel = computed(() => this.#routeData()?.channel as Channel);
  protected readonly basket = this.#placeOrderService.basket;
  readonly #basket$ = toObservable(this.#placeOrderService.basket);
  protected readonly pushToInventorySettings = toSignal(this.#store.select(SettingsState.pushToInventorySettings));
  protected readonly isCk = computed(
    () =>
      this.channel()?.partyInfo?.supplier.type === SupplierType.centralKitchen &&
      !this.channel()?.partyInfo?.supplier.isExposed,
  );

  protected readonly retailer = toSignal(this.#store.select(CurrentRetailerState.retailer));
  protected readonly currency = toSignal(this.#store.select(SettingsState.currency));
  protected readonly currencyPrecision = toSignal(this.#store.select(SettingsState.currencyPrecision));
  protected readonly channelItems = signal<InventoryChannelItem[]>([]);
  protected readonly data = computed(() =>
    this.channelItems().map(channelItem => {
      const basketMap = this.#placeOrderService.basketMap();

      return basketMap.has(channelItem.id)
        ? { ...channelItem, quantity: basketMap.get(channelItem.id).quantity }
        : channelItem;
    }),
  );

  protected readonly itemsWithPositiveQuantities = computed(() => this.basket().filter(({ quantity }) => quantity > 0));
  protected readonly fillableChannelItems = toSignal(this.#store.select(ChannelItemsState.fillableItems));
  protected readonly totalQuantity = computed(() => this.basket().reduce((acc, item) => acc + (item.quantity || 0), 0));
  protected readonly totalPrice = computed(() =>
    this.basket().reduce((acc, item) => acc + (item.quantity || 0) * item.price, 0),
  );

  protected readonly isLoading = toSignal(
    this.#store.select(actionsExecuting([ChannelItemsGetFillableItems, OrdersUpdate])).pipe(map(Boolean)),
  );

  protected readonly isFillToParManyDisabled = computed(() =>
    this.basket().some(item => this.isFillToParDisabled(item)),
  );

  protected readonly isFooterDisabled = computed(() => !this.itemsWithPositiveQuantities().length || this.isLoading());
  protected readonly searchValue = toSignal(this.getValueChanges('search'));
  protected readonly categoriesValue = toSignal(this.getValueChanges('categories'));
  protected readonly orderStatus = OrderStatus;
  protected readonly toggleFeature = ToggleFeature;
  protected filtersGroup: FilterGroup<FilterChange<PlaceOrderChannelItemsMappedFilters>>;
  protected readonly paginationMode = GridPagingMode.Remote;

  private readonly fillableItems = signal<InventoryChannelItem[]>([]);
  protected page = 0;
  protected readonly limit = 12;
  protected count = 0;

  constructor(private readonly channelsService: ChannelsService) {
    super({
      search: [null],
      categories: [null],
    });
  }

  ngOnInit(): void {
    this.patchCategoriesFiltersConfig();
    this.initFetch();
    this.channelsService.notifyChannelChange(this.#channelId());
  }

  protected onFilterChanges(filters: FilterChange<PlaceOrderChannelItemsMappedFilters>): void {
    this.form.patchValue(filters);
    this.fetchChannelItems();
  }

  protected onFiltersClear(): void {
    this.resetToDefaultValue();
    this.fetchChannelItems();
  }

  protected onReview(): void {
    this.#store.dispatch(
      new Navigate(['..', 'review'], null, {
        relativeTo: this.#route,
        queryParamsHandling: 'preserve',
      }),
    );
  }

  @Span()
  protected onSave(orderId: string): void {
    const payload: UpdateOrderRequest = {
      type: OrderUpdateStatus.draft,
      ordered: this.basket().map(({ id, quantity }) => ({ productId: id, quantity })),
    };

    this.#store
      .dispatch(new OrdersUpdate({ id: orderId, body: payload }))
      .pipe(
        first(),
        catchError(() => {
          this.trackOrderAnalytics(new Order(payload), payload, this.retailer().name, false);

          return EMPTY;
        }),
        finalize(() => {
          this.#placeOrderService.clearBasket();
          this.#store.dispatch(new OrdersSetSequence({ id: null }));
        }),
      )
      .subscribe(() => {
        this.#snackbar.open($localize`:@@orders.orderSaveSuccess:Order has been saved successfully`, {
          variant: 'success',
        });
        this.trackOrderAnalytics(this.order(), payload, this.retailer().name);
        this.#store.dispatch(new Navigate(['/orders']));
      });
  }

  protected onQuantityChange(cellData: InventoryChannelItem, quantity: number): void {
    this.#placeOrderService.updateBasketItem({ ...cellData, quantity });
  }

  protected onFillToPar(channelItem: InventoryChannelItem): void {
    const newQuantity = getFillToParBaseQuantity(channelItem);

    if (newQuantity !== undefined) {
      this.#placeOrderService.updateBasketItem({
        ...channelItem,
        quantity: newQuantity,
      });
    }
  }

  protected onFillManyToPar(): void {
    this.fetchFillableChannelItems()
      .pipe(first())
      .subscribe(() => {
        this.#placeOrderService.appendBasketItems(this.fillableItems());
      });
  }

  protected shouldShowFillToParButton(channelItem: InventoryChannelItem): boolean {
    return channelItem.quantityLevels?.par !== undefined && channelItem.quantityLevels.par > 0;
  }

  protected isFillToParDisabled(channelItem: InventoryChannelItem): boolean {
    if (!this.shouldShowFillToParButton(channelItem)) {
      return true;
    }

    const currentQuantity = channelItem.quantityLevels.stock ?? 0;
    const parQuantity = channelItem.quantityLevels?.par ?? 0;

    return currentQuantity >= parQuantity;
  }

  private initFetch() {
    this.filtersGroup = new FilterGroup<FilterChange<PlaceOrderChannelItemsMappedFilters>>(
      getPlaceOrderChannelItemsFiltersConfig(),
    );
    this.resetToDefaultValue();
    this.fetchChannelItems();
  }

  @Span()
  fetchChannelItems(): void {
    effect(
      () => {
        const channelId = this.#channelId();
        const orderId = this.#orderId();

        this.page = 0;
        this.fetchData(channelId, this.page);

        if (orderId) {
          this.#basket$
            .pipe(
              first(basket => !basket.length),
              switchMap(() => this.#store.dispatch(new OrdersGet({ id: orderId }))),
              switchMap(() =>
                this.#channelItemsService.getChannelItemsBff(
                  this.getQueryForChannelItemsWithOrderItems({
                    channelId,
                    orderItemIds: this.order().ordered.map(({ productId }) => productId),
                  }),
                ),
              ),
            )
            .subscribe(({ data }) => {
              this.#placeOrderService.setBasketItems(
                InventoryChannelItem.deserializeList(
                  data.map(channelItem => ({
                    ...channelItem,
                    quantity: this.order().ordered.find(({ productId }) => productId === channelItem.id).quantity,
                  })),
                ),
              );
            });
        }
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  private getQueryForChannelItemsWithOrderItems({
    channelId,
    orderItemIds,
  }: {
    readonly channelId: string;
    readonly orderItemIds: string[];
  }): Query<ChannelItem & CatalogChannelItemApiProps> {
    const qb = new QueryBuilder<ChannelItem & CatalogChannelItemApiProps>({
      paging: QueryPaging.NoLimit,
      filtering: [
        { by: 'channelId', op: 'eq', match: channelId },
        {
          by: 'id',
          op: 'in',
          match: orderItemIds,
        },
      ],
      ordering: [{ by: 'name.en', dir: 'asc' }],
    });

    return qb.build();
  }

  fetchData(channelId: string, page: number) {
    const qb = new QueryBuilder<ChannelItem & CatalogChannelItemApiProps>({
      paging: { offset: page * this.limit, limit: this.limit },
      filtering: [{ by: 'channelId', op: 'eq', match: channelId }],
      ordering: [{ by: 'name.en', dir: 'asc' }],
    });

    const formValue = this.form.getRawValue() as FormValue;

    if (formValue.search) {
      qb.filtering.withGroup('or', [
        {
          by: 'name.ar',
          op: 'like',
          match: formValue.search,
        },
        {
          by: 'name.en',
          op: 'like',
          match: formValue.search,
        },
        {
          by: 'itemCode',
          op: 'like',
          match: formValue.search,
        },
      ]);
    }

    if (formValue.categories) {
      qb.filtering.withGroup('or', [
        {
          by: 'category.id',
          op: 'in',
          match: formValue.categories,
        },
      ]);
    }

    this.#channelItemsService
      .getChannelItemsBff(qb.build())
      .pipe(
        tap(({ metadata }) => (this.count = metadata.count)),
        map(({ data: channelItems }) => channelItems.map(InventoryChannelItem.deserialize)),
        takeUntil(this.destroyed$),
      )
      .subscribe(channelItems => {
        this.channelItems.set(channelItems);
      });
  }

  protected onPageChange(page = 0): void {
    this.fetchData(this.#channelId(), page);
    this.page = page;
  }

  private fetchFillableChannelItems(): Observable<void> {
    const channelId = this.#routeParamMap().get('channelId');
    const qb = new QueryBuilder<ChannelItem & CatalogChannelItemApiProps>({
      paging: { offset: 0, limit: -1 },
      filtering: [
        { by: 'channelId', op: 'eq', match: channelId },
        {
          by: 'state',
          op: 'eq',
          match: ChannelItemState.Available,
        },
      ],
      ordering: [{ by: 'name.en', dir: 'asc' }],
    });

    return this.#store.dispatch(new ChannelItemsGetFillableItems(qb.build())).pipe(
      switchMap(() => this.#store.selectOnce(ChannelItemsState.fillableItems)),
      tap(items => {
        if (items) {
          this.fillableItems.set(items);
        }
      }),
    ) as Observable<void>;
  }

  private trackOrderAnalytics(order: Order, body: UpdateOrderRequest, restaurantName: string, success = true) {
    trackOrderAnalytics({
      mixpanelService: this.#mixPanel,
      body,
      success,
      order,
      restaurantName,
    });
  }

  private patchCategoriesFiltersConfig(): void {
    effect(
      () => {
        this.filtersGroup?.updateSelectionProperty('categories', {
          options: this.categories(),
        });
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.#store.dispatch(new OrdersReset());
  }

  resetComponent(): void {
    this.#placeOrderService.clearBasket();
    this.channelItems.set([]);
    this.fillableItems.set([]);
    this.filtersGroup = new FilterGroup<FilterChange<PlaceOrderChannelItemsMappedFilters>>(
      getPlaceOrderChannelItemsFiltersConfig(),
    );
    this.page = 0;
    this.count = 0;
    this.form.reset({ search: null, categories: null });
  }

  protected getComputedQuantityValue(value: string): number {
    return (
      this.basket().find(({ id }) => id === value)?.quantity ??
      this.channelItems().find(({ id }) => id === value)?.quantity ??
      0
    );
  }

  protected readonly getLocalizedName = getLocalizedName;
}
