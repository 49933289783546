import {
  IgxDataChartCoreModule,
  IgxDataChartInteractivityModule,
  IgxDataChartVerticalCategoryModule,
  IgxLegendModule,
} from 'igniteui-angular-charts';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogModule } from '../../../dialog';
import { IconModule } from '../../../icon';
import { LoadingOverlayModule } from '../../../loading-overlay';
import { ChartCoreModule } from '../chart-core';
import {
  BarChartComponent,
  BarSeriesComponent,
  BubbleChartComponent,
  DivergingStackedBarChartComponent,
  DivergingStackedBarVerticalChartComponent,
  ExpandedBubbleChartComponent,
  ExpandedDivergingBarChartComponent,
  ExpandedSankeyChartComponent,
  ExpandedStackedBarChartComponent,
  SankeyChartComponent,
  ScatterPlotChartComponent,
  StackedBarChartD3Component,
} from './components';

@NgModule({
  declarations: [
    BarChartComponent,
    BarSeriesComponent,
    BubbleChartComponent,
    DivergingStackedBarChartComponent,
    DivergingStackedBarVerticalChartComponent,
    ExpandedBubbleChartComponent,
    ExpandedDivergingBarChartComponent,
    ExpandedSankeyChartComponent,
    ExpandedStackedBarChartComponent,
    SankeyChartComponent,
    ScatterPlotChartComponent,
    StackedBarChartD3Component,
  ],
  imports: [
    CommonModule,
    IgxDataChartCoreModule,
    IgxDataChartVerticalCategoryModule,
    IgxLegendModule,
    IgxDataChartInteractivityModule,
    ChartCoreModule,
    DialogModule,
    IconModule,
    LoadingOverlayModule,
  ],
  exports: [
    BarChartComponent,
    BarSeriesComponent,
    BubbleChartComponent,
    ExpandedStackedBarChartComponent,
    SankeyChartComponent,
    DivergingStackedBarChartComponent,
    DivergingStackedBarVerticalChartComponent,
    ScatterPlotChartComponent,
    StackedBarChartD3Component,
    ChartCoreModule,
  ],
})
export class BarChartModule {}
