import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '../button';
import { IconModule } from '../icon';
import { LoadingOverlayModule } from '../loading-overlay';
import { PdfViewerComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
    LoadingOverlayModule,
    ReactiveFormsModule,
    ButtonModule,
    IconModule,
  ],
  declarations: [PdfViewerComponent],
  exports: [PdfViewerComponent],
})
export class PdfViewerModule {}
