import { map, Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  DropdownTreeNode,
  FilterChange,
  FilterGroup,
  getWeeksQuartersRange,
  PredefinedRange,
  SelectType,
} from '@supy/components';

import { InventoryEventFilters, InventoryEventType } from '../../core';

type InventoryEventHeaderFilters = Omit<InventoryEventFilters, 'start' | 'end'> & {
  dateRange?: { start: Date; end: Date };
};

@Component({
  selector: 'supy-inventory-event-header',
  templateUrl: './inventory-event-header.component.html',
  styleUrls: ['./inventory-event-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryEventHeaderComponent implements OnInit {
  protected readonly inventoryEventType = InventoryEventType;
  protected readonly predefinedRanges: PredefinedRange[] = getWeeksQuartersRange();

  @Input() readonly locationsAsTree: DropdownTreeNode<string>[];
  @Input() readonly eventType: InventoryEventType;
  @Input() readonly initialFilters: InventoryEventFilters;
  @Input() readonly appliedFiltersCount: number;
  @Input() readonly hasData: boolean;
  @Input() readonly canCreate: boolean;

  protected readonly filterGroup = new FilterGroup<FilterChange<InventoryEventHeaderFilters>>({
    search: {
      placeholder: this.getSearchPlaceholder(),
      width: '14rem',
      clearable: true,
    },
    dropdowns: [
      {
        selectType: SelectType.DateRange,
        name: 'dateRange',
        placeholder: $localize`:@@inventory.chooseDateRate:Choose date range`,
        multiple: false,
        clearable: false,
        displayStrategy: 'path',
        options: [],
        width: '14rem',
      },
      {
        selectType: SelectType.DropdownTree,
        name: 'locations',
        placeholder: $localize`:@@common.branchLocations:Branch & Locations`,
        options: [],
        multiple: true,
        value: [],
        multipleSelectionStrategy: 'children',
        width: '14rem',
      },
    ],
  });

  @Output() readonly filtersChanged = this.filterGroup.change$.pipe(
    map(({ locations, archived, dateRange, search }) => {
      return { locations, archived, start: dateRange?.start, end: dateRange?.end, name: search };
    }),
  ) as Observable<InventoryEventFilters>;

  @Output() readonly filtersCleared = new EventEmitter<void>();
  @Output() readonly export = new EventEmitter<void>();

  ngOnInit(): void {
    if (this.initialFilters) {
      this.filterGroup.updateSelectionProperty('locations', { value: this.initialFilters.locations });

      if (this.initialFilters.start && this.initialFilters.end) {
        this.filterGroup.updateSelectionProperty('dateRange', {
          value: { start: new Date(this.initialFilters.start), end: new Date(this.initialFilters.end) },
        });
      }

      this.filterGroup.updateSearchProperty({ value: this.initialFilters.name });
    }

    if (this.locationsAsTree?.length) {
      this.filterGroup.updateSelectionProperty('locations', { options: this.locationsAsTree });
    }
  }

  onClearFilters(): void {
    this.filtersCleared.emit();
  }

  onExport() {
    this.export.emit();
  }

  private getSearchPlaceholder(): string {
    return $localize`:@@inventory.event.header.searchByEvent:Search by event ${
      this.eventType === this.inventoryEventType.Transfer ? 'code' : 'name'
    }`;
  }
}
