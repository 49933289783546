import { BaseChannel } from './channel.base';
import { ChannelType } from './channel.type';

type ChannelName = `${ChannelType.PrivateEncrypted}-${string}`;

export class PrivateEncryptedChannel extends BaseChannel<ChannelName> {
  get type(): ChannelType {
    return ChannelType.PrivateEncrypted;
  }

  static for(name: string): ChannelName {
    return `${ChannelType.PrivateEncrypted}-${name}`;
  }
}
