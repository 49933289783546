import { BaseRequestMetadata } from '@supy/common';

import {
  CreateInventoryProductionRequest,
  InventoryEventFilters,
  InventoryEventItemProps,
  UpdateInventoryProductionRequest,
  UploadInventoryEventAttachmentQueryParams,
} from '../../../core';

export class InventoryProductionGet {
  static readonly type = '[InventoryProduction] Get';
  constructor(public payload: { id: string; fromCache?: boolean }) {}
}

export class InventoryProductionGetMany {
  static readonly type = '[InventoryProduction] GetMany';
}

export class InventoryProductionListExport {
  static readonly type = '[InventoryProduction] ListExport';
}

export class InventoryProductionCreate {
  static readonly type = '[InventoryProduction] Create';
  constructor(readonly payload: CreateInventoryProductionRequest) {}
}

export class InventoryProductionUpdate {
  static readonly type = '[InventoryProduction] Update';
  constructor(readonly payload: UpdateInventoryProductionRequest) {}
}

export class InventoryProductionDelete {
  static readonly type = '[InventoryProduction] Delete';
  constructor(readonly id: string) {}
}

export class InventoryProductionInitFilters {
  static readonly type = '[InventoryProduction] InitFilters';
}

export class InventoryProductionPatchFilter {
  static readonly type = '[InventoryProduction] PatchFilter';
  constructor(public payload: Partial<InventoryEventFilters>) {}
}

export class InventoryProductionResetFilter {
  static readonly type = '[InventoryProduction] ResetFilter';
}

export class InventoryProductionPatchRequestMeta {
  static readonly type = '[InventoryProduction] PatchRequestMeta';
  constructor(public payload: Partial<BaseRequestMetadata>) {}
}

export class InventoryProductionGetItems {
  static readonly type = '[InventoryProduction] GetItems';

  constructor(readonly query: InventoryEventItemProps) {}
}

export class InventoryProductionResetSequence {
  static readonly type = '[InventoryProduction] ResetSequence';
}

export class InventoryProductionCheckExistence {
  static readonly type = '[InventoryProduction] CheckExistence';
}

export class InventoryProductionUploadAttachment {
  static readonly type = '[InventoryProduction] UploadAttachment';

  constructor(
    readonly payload: FormData,
    readonly queryParams: UploadInventoryEventAttachmentQueryParams,
  ) {}
}
