import { first, Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  GridPagingMode,
  IDialogCancellableEventArgs,
  IDialogEventArgs,
  IRowSelectionEventArgs,
  NoopSortingStrategy,
} from '@infragistics/igniteui-angular';
import { Currency } from '@supy.api/dictionaries';

import { ChannelItem } from '@supy/channel-items';
import { BaseRequestMetadata, BaseResponseMetadata, Destroyable, Supplier } from '@supy/common';
import { DialogComponent, FilterChange, FilterGroup, GridComponent, IDialogComponent } from '@supy/components';

import { getRetailerAddOrderItemsFiltersConfig, RetailerAddOrderItemsDrawerMappedFilters } from './filters-config';

export interface ChannelOrderItem extends ChannelItem {
  readonly quantity: number;
}

@Component({
  selector: 'supy-retailer-channel-items-drawer',
  templateUrl: './retailer-channel-items-drawer.component.html',
  styleUrls: ['./retailer-channel-items-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerChannelItemsDrawerComponent extends Destroyable implements IDialogComponent {
  @ViewChild(DialogComponent) private readonly dialog: DialogComponent;
  @ViewChild(GridComponent) private readonly grid: GridComponent;

  @Input() readonly channelItems$: Observable<ChannelItem[]>;
  @Input() readonly currency: Currency;
  @Input() readonly supplier: Supplier;
  @Input() readonly requestMetadata$: Observable<BaseRequestMetadata>;
  @Input() readonly responseMetadata$: Observable<BaseResponseMetadata>;
  @Input() readonly isLoading$: Observable<boolean>;
  @Input() readonly showPrice: boolean = true;

  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly addItemClicked = new EventEmitter<void>();
  @Output() readonly addItemsToOrderClicked = new EventEmitter<ChannelOrderItem[]>();
  @Output() readonly pageChanged = new EventEmitter<number>();
  @Output() readonly searchChanged = new EventEmitter<string>();

  protected readonly filtersGroup = new FilterGroup<
    FilterChange<FilterChange<RetailerAddOrderItemsDrawerMappedFilters>>
  >(getRetailerAddOrderItemsFiltersConfig());

  protected readonly paginationMode = GridPagingMode.Remote;
  protected readonly sortStrategy = NoopSortingStrategy.instance();
  protected readonly updates = new Map<string, ChannelOrderItem>();

  protected onFiltersChange({ search }: FilterChange<RetailerAddOrderItemsDrawerMappedFilters>): void {
    this.searchChanged.emit(search);
  }

  protected onSelectRow(rowSelectionEvent: IRowSelectionEventArgs) {
    this.channelItems$.pipe(first()).subscribe(channelItems => {
      rowSelectionEvent.added.forEach((rowId: string) => {
        const channelItem = channelItems.find(({ id }) => id === rowId);

        if (channelItem) {
          if (this.updates.has(channelItem.id)) {
            this.updateOrderedQuantity(channelItem, this.updates.get(channelItem.id).quantity);
          } else {
            this.updates.set(channelItem.id, {
              ...channelItem,
              quantity: 0,
            });
          }
        }
      });
    });
  }

  protected updateOrderedQuantity(channelItem: ChannelItem, newValue: string | number): void {
    this.updates.set(channelItem.id, {
      ...channelItem,
      quantity: Number(newValue),
    });
  }

  protected get validChannelOrderItems(): ChannelOrderItem[] {
    return Array.from(this.updates.values()).filter(({ quantity }) => quantity > 0);
  }

  protected onAdd(): void {
    this.addItemsToOrderClicked.emit(this.validChannelOrderItems);
    this.updates.clear();
    this.closeDialog();
  }

  protected getQuantity(row: ChannelOrderItem): number {
    return this.updates.get(row.id)?.quantity ?? 0;
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
