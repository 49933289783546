import { IgxDoughnutChartModule, IgxItemLegendModule } from 'igniteui-angular-charts';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DonutChartComponent } from './components';

@NgModule({
  imports: [CommonModule, IgxDoughnutChartModule, IgxItemLegendModule],
  declarations: [DonutChartComponent],
  exports: [DonutChartComponent],
})
export class DonutChartModule {}
