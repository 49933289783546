import produce from 'immer';
import { EMPTY, mergeMap, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { Query, QueryPaging, SnackbarService } from '@supy/common';

import {
  ProviderBranch,
  ProviderEnum,
  ProviderItemCategory,
  ProviderLocation,
  ProviderResource,
  ProviderSalesType,
  ProviderSupplier,
  ProviderTaxRate,
  ProviderTenant,
  ResourceTypeEnum,
  Tenant,
  TenantBranchesCount,
  TenantCategoryEnum,
  TenantLinkOAuth2Data,
  TenantRegistry,
  TenantStateEnum,
} from '../../models';
import { TenantService } from '../../services';
import {
  TenantActivate,
  TenantDelete,
  TenantGet,
  TenantGetMany,
  TenantGetMappedBranchesCount,
  TenantGetProviderBranches,
  TenantGetProviderCategories,
  TenantGetProviderLocations,
  TenantGetProviderResources,
  TenantGetProviderSalesTypes,
  TenantGetProviderSuppliers,
  TenantGetProviderTaxRates,
  TenantGetProviderTenants,
  TenantGetRegistries,
  TenantLink,
  TenantResetProviderBranches,
  TenantResetReconnecting,
  TenantSetReconnecting,
  TenantSetRegistries,
  TenantUpdate,
} from '../actions';

export interface TenantStateModel {
  readonly tenants: Tenant[];
  readonly tenantRegistries: TenantRegistry[];
  readonly selectedCategory: TenantCategoryEnum | null;
  readonly mappedBranchesCount: TenantBranchesCount[];
  readonly reconnectingTenantId: string | null;
  readonly providerTenants: ProviderTenant[];
  readonly providerBranches: ProviderBranch[];
  readonly providerLocations: ProviderLocation[];
  readonly providerSuppliers: ProviderSupplier[];
  readonly providerCategories: ProviderItemCategory[];
  readonly providerTaxRates: ProviderTaxRate[];
  readonly providerSalesTypes: ProviderSalesType[];
}

const TENANT_STATE_TOKEN = new StateToken<TenantStateModel>('tenant');

@State<TenantStateModel>({
  name: TENANT_STATE_TOKEN,
  defaults: {
    tenants: [],
    tenantRegistries: [],
    selectedCategory: null,
    mappedBranchesCount: [],
    reconnectingTenantId: null,
    providerTenants: [],
    providerBranches: [],
    providerLocations: [],
    providerSuppliers: [],
    providerCategories: [],
    providerTaxRates: [],
    providerSalesTypes: [],
  },
})
@Injectable()
export class TenantState {
  constructor(
    private readonly tenantService: TenantService,
    private readonly snackbarService: SnackbarService,
  ) {}

  static tenant(id: string) {
    return createSelector([TENANT_STATE_TOKEN], (state: TenantStateModel) => {
      return state.tenants.find(tenant => tenant.id === id);
    });
  }

  @Selector()
  static manualTenant(state: TenantStateModel) {
    return state.tenants?.find(tenant => tenant.providerBrand === ProviderEnum.Manual);
  }

  @Selector()
  static tenantRegistries(state: TenantStateModel) {
    return state.tenantRegistries;
  }

  @Selector()
  static tenants(state: TenantStateModel) {
    return state.tenants;
  }

  @Selector()
  static reconnectingTenantId(state: TenantStateModel) {
    return state.reconnectingTenantId;
  }

  static tenantsByProvider(provider: ProviderEnum) {
    return createSelector([TENANT_STATE_TOKEN], (state: TenantStateModel) => {
      return state.tenants.filter(
        tenant => tenant.providerBrand !== ProviderEnum.Manual && tenant.providerBrand === provider,
      );
    });
  }

  static tenantsByProviders(provider?: ProviderEnum) {
    return createSelector([TENANT_STATE_TOKEN], (state: TenantStateModel) => {
      return state.tenants
        .filter(
          tenant =>
            tenant.providerBrand !== ProviderEnum.Manual && (provider ? tenant.providerBrand === provider : true),
        )
        ?.reduce((group: Map<ProviderEnum, Tenant[]>, tenant) => {
          const existingGroup = group.get(tenant.providerBrand);

          if (existingGroup) {
            existingGroup.push(tenant);
          } else {
            group.set(tenant.providerBrand, [tenant]);
          }

          return group;
        }, new Map<ProviderEnum, Tenant[]>());
    });
  }

  static tenantsByCategory(category: TenantCategoryEnum) {
    return createSelector([TENANT_STATE_TOKEN], (state: TenantStateModel) => {
      return state.tenants.filter(tenant => tenant.category === category);
    });
  }

  static isManualSalesTypeOnly(tenantId: string) {
    return createSelector([TENANT_STATE_TOKEN], (state: TenantStateModel) => {
      return TenantState.tenant(tenantId)(state)?.settings?.blockProviderSalesTypesMapping;
    });
  }

  @Selector()
  static selectedCategory(state: TenantStateModel) {
    return state.selectedCategory;
  }

  @Selector()
  static mappedBranchesCount(state: TenantStateModel) {
    return state.mappedBranchesCount;
  }

  @Selector()
  static providerTenants(state: TenantStateModel) {
    return state.providerTenants;
  }

  @Selector()
  static providerBranches(state: TenantStateModel) {
    return state.providerBranches;
  }

  @Selector()
  static providerSuppliers(state: TenantStateModel) {
    return state.providerSuppliers;
  }

  @Selector()
  static providerCategories(state: TenantStateModel) {
    return state.providerCategories;
  }

  @Selector()
  static providerTaxRates(state: TenantStateModel) {
    return state.providerTaxRates;
  }

  @Selector()
  static providerSalesTypes(state: TenantStateModel) {
    return state.providerSalesTypes;
  }

  static providerResources(type: ResourceTypeEnum) {
    return createSelector([TENANT_STATE_TOKEN], (state: TenantStateModel): ProviderResource[] => {
      switch (type) {
        case ResourceTypeEnum.Location:
          return state.providerLocations;
        case ResourceTypeEnum.Branch:
          return state.providerBranches;
        case ResourceTypeEnum.Supplier:
          return state.providerSuppliers;
        case ResourceTypeEnum.TaxRate:
          return state.providerTaxRates;
        case ResourceTypeEnum.AccountingCategory:
          return state.providerCategories;
        case ResourceTypeEnum.SalesType:
          return state.providerSalesTypes;
      }

      return [];
    });
  }

  @Action(TenantGetMany)
  getTenants(ctx: StateContext<TenantStateModel>, { retailerId, categories }: TenantGetMany) {
    return this.tenantService
      .getTenants(
        new Query<Tenant>({
          filtering: [
            { by: 'retailerId', match: retailerId, op: 'eq' },
            { by: 'state', match: [TenantStateEnum.Deleted, TenantStateEnum.Archived], op: 'not-in' },
            { by: 'category', match: categories, op: 'in' },
          ],
          paging: QueryPaging.NoLimit,
        }),
      )
      .pipe(
        tap(({ data }) =>
          ctx.setState(
            produce(draft => {
              draft.tenants = data;
            }),
          ),
        ),
      );
  }

  @Action(TenantGet)
  getTenant(ctx: StateContext<TenantStateModel>, { payload }: TenantGet) {
    const existingTenant = ctx.getState().tenants.find(({ id }) => id === payload.tenantId);

    if (existingTenant && payload.fromCache) {
      return of(existingTenant);
    }

    return this.tenantService.getTenant(payload.tenantId).pipe(
      tap(tenant => {
        ctx.setState(
          produce(draft => {
            draft.tenants.push(tenant);
          }),
        );
      }),
    );
  }

  @Action(TenantGetRegistries)
  getTenantRegistries(ctx: StateContext<TenantStateModel>, { tenantId, payload }: TenantGetRegistries) {
    return this.tenantService.getTenantRegistries(tenantId, payload.retailerId).pipe(
      tap(({ data }) => {
        ctx.patchState({ tenantRegistries: data });
      }),
    );
  }

  @Action(TenantGetMappedBranchesCount)
  getMappedBranchesCount(ctx: StateContext<TenantStateModel>, { tenantIds }: TenantGetMappedBranchesCount) {
    return this.tenantService.getMappedBranchesCount(tenantIds).pipe(
      tap(({ data }) => {
        ctx.patchState({ mappedBranchesCount: data });
      }),
    );
  }

  @Action(TenantGetProviderTenants)
  getProviderTenants(ctx: StateContext<TenantStateModel>, { tenantId }: TenantGetProviderTenants) {
    return this.tenantService.getProviderTenants(tenantId).pipe(
      tap(({ data }) => {
        ctx.patchState({
          providerTenants: data,
        });
      }),
    );
  }

  @Action(TenantGetProviderBranches)
  getProviderBranches(ctx: StateContext<TenantStateModel>, { tenantId, forAccounting }: TenantGetProviderBranches) {
    return this.tenantService.getProviderBranches(tenantId, forAccounting).pipe(
      tap(({ data }) => {
        ctx.patchState({
          providerBranches: data,
        });
      }),
    );
  }

  @Action(TenantGetProviderLocations)
  getProviderLocations(ctx: StateContext<TenantStateModel>, { tenantId }: TenantGetProviderLocations) {
    return this.tenantService.getProviderLocations(tenantId).pipe(
      tap(({ data }) => {
        ctx.patchState({
          providerLocations: data,
        });
      }),
    );
  }

  @Action(TenantGetProviderSuppliers)
  getProviderSuppliers(ctx: StateContext<TenantStateModel>, { tenantId }: TenantGetProviderSuppliers) {
    return this.tenantService.getProviderSuppliers(tenantId).pipe(
      tap(({ data }) => {
        ctx.patchState({
          providerSuppliers: data,
        });
      }),
    );
  }

  @Action(TenantGetProviderCategories)
  getProviderCategories(ctx: StateContext<TenantStateModel>, { tenantId }: TenantGetProviderCategories) {
    return this.tenantService.getProviderAccountCodes(tenantId).pipe(
      tap(({ data }) => {
        ctx.patchState({
          providerCategories: data,
        });
      }),
    );
  }

  @Action(TenantGetProviderTaxRates)
  getProviderTaxRates(ctx: StateContext<TenantStateModel>, { tenantId }: TenantGetProviderTaxRates) {
    return this.tenantService.getProviderTaxRates(tenantId).pipe(
      tap(({ data }) => {
        ctx.patchState({
          providerTaxRates: data,
        });
      }),
    );
  }

  @Action(TenantGetProviderSalesTypes)
  getProviderSalesTypes(
    ctx: StateContext<TenantStateModel>,
    { tenantId, providerBranchId }: TenantGetProviderSalesTypes,
  ) {
    if (TenantState.isManualSalesTypeOnly(tenantId)(ctx.getState())) {
      ctx.patchState({
        providerSalesTypes: TenantState.tenant(tenantId)(ctx.getState())?.settings?.salesTypes,
      });

      return EMPTY;
    }

    return this.tenantService.getProviderSalesTypes(tenantId, providerBranchId).pipe(
      tap(({ data }) => {
        ctx.patchState({
          providerSalesTypes: data,
        });
      }),
    );
  }

  @Action(TenantGetProviderResources)
  getProviderResources(
    ctx: StateContext<TenantStateModel>,
    { tenantId, type, forAccounting, providerBranchId }: TenantGetProviderResources,
  ) {
    switch (type) {
      case ResourceTypeEnum.Location:
        return ctx.dispatch(new TenantGetProviderLocations(tenantId));
      case ResourceTypeEnum.Supplier:
        return ctx.dispatch(new TenantGetProviderSuppliers(tenantId));
      case ResourceTypeEnum.AccountingCategory:
        return ctx.dispatch(new TenantGetProviderCategories(tenantId));
      case ResourceTypeEnum.TaxRate:
        return ctx.dispatch(new TenantGetProviderTaxRates(tenantId));
      case ResourceTypeEnum.Branch:
        return ctx.dispatch(new TenantGetProviderBranches(tenantId, forAccounting ?? false));
      case ResourceTypeEnum.SalesType:
        return ctx.dispatch(new TenantGetProviderSalesTypes(tenantId, providerBranchId));
    }
  }

  @Action(TenantResetProviderBranches)
  resetProviderBranches(ctx: StateContext<TenantStateModel>, action: TenantResetProviderBranches) {
    ctx.patchState({ providerBranches: [] });
  }

  @Action(TenantSetRegistries)
  setTenantRegistries(ctx: StateContext<TenantStateModel>, { tenantId, payload }: TenantSetRegistries) {
    return this.tenantService.setTenantRegistries(tenantId, payload);
  }

  @Action(TenantDelete)
  deleteTenant(ctx: StateContext<TenantStateModel>, { tenantId }: TenantDelete) {
    return this.tenantService.deleteTenant(tenantId);
  }

  @Action(TenantSetReconnecting)
  setReconnecting(ctx: StateContext<TenantStateModel>, { reconnectingTenantId }: TenantSetReconnecting) {
    ctx.patchState({ reconnectingTenantId });
  }

  @Action(TenantResetReconnecting)
  resetReconnecting(ctx: StateContext<TenantStateModel>) {
    ctx.patchState({ reconnectingTenantId: null });
  }

  @Action(TenantLink)
  linkTenant(ctx: StateContext<TenantStateModel>, { provider, payload }: TenantLink) {
    const reconnectingTenantId = ctx.getState().reconnectingTenantId;

    if (reconnectingTenantId) {
      return this.tenantService.reconnectTenant(reconnectingTenantId, (payload as TenantLinkOAuth2Data).code).pipe(
        mergeMap(() => ctx.dispatch(new TenantResetReconnecting())),
        tap(() => {
          this.snackbarService.open('Successfully reconnected', { variant: 'success' });
        }),
        mergeMap(() =>
          ctx.dispatch(
            new Navigate(['/settings', 'integrations'], undefined, {
              replaceUrl: true,
            }),
          ),
        ),
      );
    }

    return this.tenantService.linkTenant(provider, payload).pipe(
      tap(() => this.snackbarService.open('Successfully connected', { variant: 'success' })),
      mergeMap(() =>
        ctx.dispatch(
          new Navigate(['/settings', 'integrations'], undefined, {
            replaceUrl: true,
          }),
        ),
      ),
    );
  }

  @Action(TenantUpdate)
  updateTenant(ctx: StateContext<TenantStateModel>, { payload }: TenantUpdate) {
    return this.tenantService.updateTenant(payload.tenantId, payload.body);
  }

  @Action(TenantActivate)
  activateTenant(ctx: StateContext<TenantStateModel>, { payload }: TenantActivate) {
    return this.tenantService.activateTenant(payload.tenantId, payload.body);
  }
}
