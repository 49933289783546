import { inject, InjectionToken } from '@angular/core';

import { CommonConfig } from '@supy/common';
import { APP_CONFIG } from '@supy/core';

export const OUTLETS_BFF_URI = new InjectionToken<string>('Outlets BFF URI', {
  factory() {
    const config = inject<CommonConfig>(APP_CONFIG);

    return `${config.apiUrlBff}${config.apiUrlPrefix}/outlets`;
  },
});
