import { HttpClient } from '@angular/common/http';
import { FactoryProvider, ModuleWithProviders, NgModule } from '@angular/core';

import { HttpClientAuthExecutor } from './auth';
import {
  ChannelAuthSimpleHandler,
  IAuthExecutorOptions,
  IWebSocketClientBaseOptions,
  UserAuthSimpleHandler,
} from './client';
import { WebSocketClientRegistryService, WebSocketClientService } from './services';

export interface IWebSocketAuthExecutorOptions {
  readonly auth: {
    readonly user: IAuthExecutorOptions;
    readonly channel: IAuthExecutorOptions;
  };
}

export type WebSocketClientOptions = IWebSocketClientBaseOptions & IWebSocketAuthExecutorOptions;

export interface IWebSocketModuleOptions {
  readonly clients: Record<PropertyKey, WebSocketClientOptions>;
}

@NgModule()
export class WebSocketModule {
  static forRoot(options: IWebSocketModuleOptions): ModuleWithProviders<WebSocketModule> {
    const clients = new Set<FactoryProvider>();

    for (const [key, client] of Object.entries(options.clients)) {
      clients.add({
        provide: key,
        useFactory(http: HttpClient) {
          const auth = {
            user: new UserAuthSimpleHandler(new HttpClientAuthExecutor(http, client.auth.user)),
            channel: new ChannelAuthSimpleHandler(new HttpClientAuthExecutor(http, client.auth.channel)),
          };

          return new WebSocketClientService({
            ...client,
            auth,
          });
        },
        deps: [HttpClient],
      });
    }

    return {
      ngModule: WebSocketModule,
      providers: [...clients, WebSocketClientRegistryService],
    };
  }
}
