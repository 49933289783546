<ng-container *ngIf="tooltipConfig() as tConfig">
  <div
    class="d3-tooltip"
    (scroll)="onScroll()"
    *ngIf="tooltipTemplate && tConfig.data && !isDisabled"
    [ngStyle]="tConfig.positioning"
  >
    <ng-container *ngTemplateOutlet="tooltipTemplate.template; context: { $implicit: tConfig.data }"></ng-container>
  </div>
</ng-container>
