import { filter, Observable } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { GridPagingMode, GridSelectionMode, IRowSelectionEventArgs } from '@infragistics/igniteui-angular';

import { BaseRequestMetadata, BaseResponseMetadata } from '@supy/common';
import { AutocompleteComponent, GridComponent, IconType, TabViewSelectItem } from '@supy/components';
import {
  AutocompleteFinishedRecipe,
  AutocompleteFinishedRecipesQueryProps,
  RecipePermissionStrategy,
} from '@supy/inventory';

import { PosIntegrationPermissionsStrategy } from '../../../pos-item';
import {
  SalesImport,
  SalesImportStateEnum,
  SalesImportStats,
  SalesTransaction,
  SalesTransactionLinkedRecipe,
  SalesTransactionStateEnum,
} from '../../models';

type SalesTransactionGridEntry = SalesTransaction & { idToOrderId: string };

@Component({
  selector: 'supy-sales-transactions',
  templateUrl: './sales-transactions.component.html',
  styleUrls: ['./sales-transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalesTransactionsComponent {
  @ViewChild(GridComponent, { static: true })
  protected readonly grid: GridComponent;

  @ViewChildren(AutocompleteComponent, { read: AutocompleteComponent })
  readonly autocompletes: QueryList<AutocompleteComponent<{ id: string }>>;

  @Input() set transactions(value: SalesTransaction[]) {
    if (value) {
      this.resetSelectedRows();
      this.gridTransactions.set(
        value.map(transaction => ({
          ...transaction,
          idToOrderId: transaction.providerOrderId
            ? `${transaction.id}:${transaction.providerOrderId}`
            : transaction.id,
        })),
      );
    }
  }

  @Input() set salesImport(value: SalesImport) {
    if (value) {
      this.salesImportValue.set(value);
      this.statuses.set(this.getStatuses(value.statistics));
    }
  }

  @Input() readonly isAutoProcessed: boolean;
  @Input() readonly isSalesTypeBlocked: boolean;
  @Input() readonly status: SalesTransactionStateEnum;
  @Input() readonly retailerId: string;
  @Input() readonly requestMetadata: BaseRequestMetadata;
  @Input() readonly responseMetadata: BaseResponseMetadata;
  @Input() readonly currencyPrecision: number;
  @Input() set recipes(value: AutocompleteFinishedRecipe[]) {
    this.recipesList.set(value);
  }

  @Input() readonly areRecipesLoading: boolean;
  @Input() readonly areRecipesLoaded: Observable<boolean>;
  @Input() readonly isExportAllowed: boolean;
  @Input() readonly isLoading: boolean;

  @Output() readonly action = new EventEmitter<void>();
  @Output() readonly export = new EventEmitter<void>();
  @Output() readonly search = new EventEmitter<string>();
  @Output() readonly transactionsDeleted = new EventEmitter<string[]>();
  @Output() readonly recipeSelected = new EventEmitter<SalesTransaction>();
  @Output() readonly recipesSearchValueChange = new EventEmitter<AutocompleteFinishedRecipesQueryProps>();
  @Output() readonly pageChange = new EventEmitter<{ page: number; isPendingAction: boolean }>();
  @Output() readonly statusChange = new EventEmitter<{
    status: SalesTransactionStateEnum;
    isPendingAction: boolean;
  }>();

  protected readonly gridTransactions = signal<SalesTransactionGridEntry[]>([]);
  protected readonly recipesList = signal<AutocompleteFinishedRecipe[]>([]);
  protected readonly currentRecipeId = signal<string | null>(null);
  protected readonly statuses = signal(this.getStatuses());
  private readonly salesImportValue = signal<SalesImport | null>(null);

  protected readonly gridSelectionMode = GridSelectionMode;
  protected readonly paginationMode = GridPagingMode.Remote;
  private readonly selectedRows = new Set<string>();

  protected readonly canUpdatePosMappingItem = toSignal(
    inject(PosIntegrationPermissionsStrategy.UpdateMappingItem).isAllowed(),
  );

  protected readonly canDeleteSalesImport = toSignal(
    inject(PosIntegrationPermissionsStrategy.DeleteImport).isAllowed(),
  );

  protected readonly canCreateRecipe = toSignal(inject(RecipePermissionStrategy.Create).isAllowed());

  get gridSelectedRows(): string[] {
    return Array.from(this.selectedRows);
  }

  get isOrderShown(): boolean {
    return this.status === SalesTransactionStateEnum.Canceled;
  }

  get isSalesTypeShown(): boolean {
    return this.isSalesTypeBlocked === false;
  }

  get actionTitle(): string | null {
    switch (this.status) {
      case SalesTransactionStateEnum.Unmapped:
        return this.isPendingAction() ? 'Map Recipes' : null;
      case SalesTransactionStateEnum.Canceled:
        return this.isPossibleToWastageConvert() ? 'Convert to Wastage' : null;
      case SalesTransactionStateEnum.Synced:
        return this.isPossibleToSubmit() ? 'Process' : null;
      default:
        return null;
    }
  }

  get bannerMessage(): string | null {
    switch (this.status) {
      case SalesTransactionStateEnum.Unmapped:
        return 'The below transactions have no recipe or sales type';
      case SalesTransactionStateEnum.Unlinked:
        return 'The below recipes lack sales type, cost center or need to be backdated';
      default:
        return null;
    }
  }

  protected isPendingAction(): boolean {
    switch (this.status) {
      case SalesTransactionStateEnum.Unmapped:
        return this.gridTransactions()?.some(transaction => transaction.linkedRecipe?.isLocalState);
      default:
        return false;
    }
  }

  protected isRecipeSelectionShown(recipe: SalesTransactionLinkedRecipe): boolean {
    return !!(
      this.status === SalesTransactionStateEnum.Unmapped &&
      this.canUpdatePosMappingItem() &&
      (recipe ? recipe.isLocalState : true)
    );
  }

  protected onRecipeSelected(recipe: AutocompleteFinishedRecipe | null, rowData: SalesTransaction): void {
    this.recipeSelected.emit({
      ...rowData,
      linkedRecipe: recipe ? { id: recipe.id, name: recipe.name, isLocalState: true } : null,
    });
  }

  protected getSelectedRows(): string[] {
    return this.grid.getSelectedRows().map(idToOrderId => idToOrderId.split(':')[0]);
  }

  protected resetRecipes(): void {
    this.recipesList.set([]);
  }

  protected resetSelectedRows(): void {
    return this.selectedRows.clear();
  }

  protected onSelectionChange(selectionArgs: IRowSelectionEventArgs): void {
    const newSelection = selectionArgs.newSelection as SalesTransactionGridEntry[];

    if (selectionArgs.allRowsSelected) {
      newSelection.forEach(({ idToOrderId }) => this.selectedRows.add(idToOrderId));

      return;
    }

    if (!newSelection?.length) {
      this.selectedRows.clear();
    }

    const addedSelection = selectionArgs.added[0] as SalesTransactionGridEntry;
    const removedSelection = selectionArgs.removed[0] as SalesTransactionGridEntry;

    if (addedSelection) {
      [...newSelection, ...this.getChildrenTransactions(addedSelection.id)].forEach(({ idToOrderId }) =>
        this.selectedRows.add(idToOrderId),
      );
    }

    if (removedSelection) {
      const childrenTransactions = this.getChildrenTransactions(removedSelection.id);
      const rowsToBeFilteredOut = childrenTransactions?.length
        ? [removedSelection.idToOrderId, ...(childrenTransactions?.map(({ idToOrderId }) => idToOrderId) ?? [])]
        : [removedSelection.idToOrderId];

      rowsToBeFilteredOut.forEach(idToOrderId => this.selectedRows.delete(idToOrderId));
    }
  }

  protected deleteTransactions(): void {
    this.transactionsDeleted.emit(this.grid?.getSelectedRows().map(idToOrderId => idToOrderId.split(':')[0]));
  }

  protected deleteTransaction(transactionId: string): void {
    this.transactionsDeleted.emit([transactionId]);
  }

  protected recipeDisplayValueFn(value: AutocompleteFinishedRecipe): string {
    return value?.name?.en;
  }

  protected getRecipeIcon(rowData: SalesTransaction): IconType | null {
    return rowData.linkedRecipe ? 'food' : null;
  }

  protected onRecipeCleared(rowData: SalesTransaction): void {
    const { linkedRecipe, ...rest } = rowData;

    this.recipeSelected.emit(rest);
  }

  protected onPageChange(page: number): void {
    this.pageChange.emit({ page, isPendingAction: this.isPendingAction() });
  }

  protected onStatusChange(status: SalesTransactionStateEnum): void {
    this.statusChange.emit({ status, isPendingAction: this.isPendingAction() });
  }

  protected onRecipesSearchValueChange(term: string, rowData: SalesTransaction): void {
    this.currentRecipeId.set(rowData.id);
    this.recipesSearchValueChange.emit({
      retailerId: this.retailerId,
      term,
      effectiveDate: new Date(rowData.businessDay).toISOString(),
    });
  }

  protected getRecipesFetchDone(rowData: SalesTransaction): Observable<boolean> {
    return this.areRecipesLoaded.pipe(filter(() => this.currentRecipeId() === rowData.id));
  }

  private getChildrenTransactions(transactionId: string): SalesTransactionGridEntry[] {
    return this.gridTransactions().filter(({ parentTransactionId }) => parentTransactionId === transactionId) ?? [];
  }

  private isPossibleToWastageConvert(): boolean {
    if (!this.salesImportValue()) {
      return false;
    }

    const { statistics } = this.salesImportValue() as SalesImport;

    return (
      statistics?.totalUnmappedItems === 0 &&
      statistics?.totalUnlinkedItems === 0 &&
      (statistics?.totalCanceledItems ?? 0) > 0
    );
  }

  private isPossibleToSubmit(): boolean {
    if (!this.salesImportValue()) {
      return false;
    }

    const { statistics, metadata, state } = this.salesImportValue() as SalesImport;

    return (
      !metadata?.autoProcessed &&
      state !== SalesImportStateEnum.Processed &&
      statistics?.totalUnmappedItems === 0 &&
      statistics?.totalUnlinkedItems === 0 &&
      statistics?.totalCanceledItems === 0 &&
      (statistics?.totalSyncedItems ?? 0) > 0
    );
  }

  private getStatuses(stats?: SalesImportStats): TabViewSelectItem<SalesTransactionStateEnum>[] {
    return [
      {
        label: $localize`:@@integrations.unmapped:Unmapped`,
        value: SalesTransactionStateEnum.Unmapped,
        icon: 'unlink',
        suffix: `${stats?.totalUnmappedItems || ''}`,
      },
      {
        label: $localize`:@@integrations.unlinked:Unlinked`,
        value: SalesTransactionStateEnum.Unlinked,
        icon: 'unlink-2',
        suffix: `${stats?.totalUnlinkedItems || ''}`,
      },
      {
        label: $localize`:@@integrations.cancelled:Void/Canceled`,
        value: SalesTransactionStateEnum.Canceled,
        icon: 'undo',
        suffix: `${stats?.totalCanceledItems || ''}`,
      },
      {
        label: $localize`:@@integrations.synced:Synced`,
        value: SalesTransactionStateEnum.Synced,
        icon: 'check-circle',
        suffix: `${stats?.totalSyncedItems || ''}`,
      },
    ];
  }

  resetAutocompletes(): void {
    this.autocompletes?.forEach(autocomplete => autocomplete.onClear());
  }
}
