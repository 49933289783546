import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';

export interface RouterStateParams<QueryParameters = unknown, Data = unknown, Params = unknown> {
  url: string;
  params: Params;
  queryParams: QueryParameters;
  data: Data;
}

/**
 * @description
 * Custom router state serializer to map {url, params, queryParams, data}
 */
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params, data } = route;

    return { url, params, queryParams, data };
  }
}
