import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UnleashModule } from '@supy/common';

import { AvatarModule } from '../avatar';
import { BreadcrumbModule } from '../breadcrumb';
import { ButtonModule } from '../button';
import { ComboboxModule } from '../combo-box';
import { DropdownModule } from '../dropdown';
import { IconModule } from '../icon';
import { LoaderModule } from '../loader';
import { SeparatorModule } from '../separator';
import { PageComponent } from './components';

@NgModule({
  declarations: [PageComponent],
  imports: [
    CommonModule,
    BreadcrumbModule,
    SeparatorModule,
    ComboboxModule,
    AvatarModule,
    DropdownModule,
    ButtonModule,
    IconModule,
    LoaderModule,
    UnleashModule,
  ],
  exports: [PageComponent],
})
export class PageModule {}
